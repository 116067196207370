import gql from 'graphql-tag';

export default gql`
  fragment authorizationErrorInfo on AuthorizationError {
    id
    message
    imageURL
    portalKey
    AuthorizationId
    createdAt
    type
    hasAuthBeenSubmitted
    hasAuthBeenDeleted
    createdByUserEmail
    institutionName
    resolutionStatus
    resolutionDetails
    resolvedBy {
      firstName
    }
    AuthorizationForm {
      title
      description
    }
  }
`;
