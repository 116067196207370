import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import BaseButton from '../../components/BaseButton';
import ReportErrorModal from '../../components/ReportErrorModal';

export const ErrorIconCss = css`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  color: ${({ theme }) => theme.red};
`;

const ErrorContainer = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme, color }) => color || theme.red};
  border-radius: 3px;
  text-align: center;
  margin: 10px 0;
  display: inline-block;
  width: 100%;
`;

const ErrorImage = styled.img`
  max-width: 100%;
  border: 1px solid ${({ theme, color }) => color || theme.darkGray};
`;

const ErrorTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  white-space: pre-wrap;
`;

const ErrorMessageContainer = styled.div`
  padding: 10px;
  margin: 10px 0;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  ${BaseButton} {
    margin-right: 20px;
  }
`;

const InlineButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
`;

const authKeysToCapture = ['id', 'selectedPortal', 'portalKey', 'portalAuthorizationId', 'customPortalSteps', 'InstitutionId'];

class PortalError extends PureComponent {
  state = { shouldDisplayReportErrorModal: false };

  getActionButtons = () => {
    const { removeTerminalAndRetry, retryFromLastStep, back, hideStandardActions } = this.props;

    if (!hideStandardActions) {
      return [
        (<BaseButton key="error_retry_button" onClick={retryFromLastStep}>Reinput last step</BaseButton>),
        (<BaseButton key="error_retrySubmission_button" onClick={removeTerminalAndRetry}>Retry submission</BaseButton>),
        (
          <BaseButton
            key="error_report_error_button"
            onClick={(e) => { e.stopPropagation(); this.setState({ shouldDisplayReportErrorModal: true }); }}
          >
            Report error
          </BaseButton>
        ),
        (<BaseButton key="error_back_button" onClick={back}>Go Back</BaseButton>),
      ];
    }

    return null;
  }

  render() {
    const { authorization, message, icon, title, reenterCredentials } = this.props;
    const { shouldDisplayReportErrorModal } = this.state;

    const error = {
      title: 'Portal Integration Error',
      details: `Error message: ${message.message}
<br />Screenshot:
<br /><img src="${message.imageURL}">
<br />
<br />Authorization:
<br />${_.join(_.map(authKeysToCapture, k => `${k}: ${JSON.stringify(authorization[k])}`), '<br />')}`,
    };

    const ErrorIconStyled = styled(icon)`
      ${ErrorIconCss}
    `;

    return (
      <div>
        <ButtonContainer>
          { this.getActionButtons() }
        </ButtonContainer>
        <ErrorContainer>
          <ErrorTitleContainer>
            <ErrorIconStyled />
            {title}
          </ErrorTitleContainer>
          <ErrorMessageContainer>
            {message.message}
          </ErrorMessageContainer>
          { reenterCredentials && (
            <InlineButtonContainer>
              <BaseButton onClick={reenterCredentials}>Re-input portal credentials to SamaCare</BaseButton>
            </InlineButtonContainer>
          ) }
          {message.imageURL && (<ErrorImage src={message.imageURL} />)}
        </ErrorContainer>
        <ReportErrorModal error={error} isOpen={shouldDisplayReportErrorModal} onClose={() => this.setState({ shouldDisplayReportErrorModal: false })} />
      </div>
    );
  }
}

export default PortalError;
