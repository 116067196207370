import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import AlarmIcon from 'react-icons/lib/go/clock';
import PropTypes from 'prop-types';

import { inputPropType } from '../taggerPropTypes';
import ImportanceMenu from './ImportanceMenu';
import Droppable from './DroppableContainer';
import Draggable from './DraggableContainer';
import getValidationError from '../inputValidation';

const PaddingContainer = styled.div`
  padding-left: 10px;
`;

const InputDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 2px;
  font-size: 14px;
  color: ${props => (props.selected ? 'white' : props.theme.darkGray)};
  background: ${props => (props.selected ? props.theme.lightGray : 'white')};

  &:hover {
    cursor: pointer;
    color: white;
    background: ${props => props.theme.lightGray};
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteButton = styled.div`
  margin-left: auto;
  color: ${props => props.theme.purple};
  border: 1px solid ${props => props.theme.purple};
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 17px;

  :hover {
    cursor: pointer;
    font-weight: 500;
    background: ${props => props.theme.purple};
    color: white;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IdContainer = styled.div`
  font-size: 10px;
`;

const BoxInputs = styled.div`
  font-size: 12px;
  cursor: pointer;
  color: ${props => (props.selected ? '#ff8c8c' : 'black')};
  font-weight: ${props => (props.selected ? 500 : 300)};
  margin-right: 5px;

  :hover {
    color: #ff8c8c;
    font-weight: 500;
  }
`;

const TooltipText = styled.div`
  width: 110px;
`;

const SamaTypes = styled.div`
  font-size: 10px;
`;

class InputHeirarchyRender extends PureComponent {
  state = { isDragging: false }

  render() {
    const { input, selectedId, setSelected, moveNode, updateInput, deleteInput, selectedBoxIndex, idPrefix } = this.props;
    const { isDragging } = this.state;

    const isSelected = input.id === selectedId;
    const validationError = getValidationError(input);

    return (
      <div id={`${idPrefix}_${input.id}`}>
        <Draggable
          id={input.id}
          onDragStart={() => { this.setState({ isDragging: true }); }}
          onDragEnd={() => { this.setState({ isDragging: false }); }}
        >
          <Droppable id={input.id} onDrop={({ id }) => { moveNode(id, input.id); }}>
            <InputContainer>
              <InputDescription onClick={() => { setSelected(input.id); }} selected={isSelected}>
                { validationError && (
                  <div>
                    <AlarmIcon style={{ color: '#ff4242', fontSize: '17px', marginLeft: '5px', marginTop: '-2px' }} data-for="input_error" data-tip="" />
                    <ReactTooltip id="input_error">
                      <TooltipText>{ validationError }</TooltipText>
                    </ReactTooltip>
                  </div>
                ) }
                { !validationError && (
                  <ImportanceMenu
                    importance={input.importance}
                    toggleImportance={(importance) => { updateInput(input.id, { ...input, importance }); }}
                    hasBoxes={!_.isEmpty(input.boxes)}
                  />
                )}
                { _.get(_.find(CONFIG.CONSTANTS.TAGGER_INPUT_TYPES, type => type.key === input.type), 'title') || 'Creating' }
                { _.get(input, 'items.length') > 0 && (
                  <IdContainer>
                    { ` - ${input.id}` }
                  </IdContainer>
                ) }
              </InputDescription>
              <DeleteButton onClick={() => { deleteInput(input.id); }}>x</DeleteButton>
            </InputContainer>
            { !_.isEmpty(input.samaTypes) && (<SamaTypes>{ ` (${input.samaTypes.join(', ')})` }</SamaTypes>) }
            { isSelected && (
              <BoxContainer>
                {
                  _.map(input.boxes, (box, i) => (
                    <BoxInputs key={`Box_Selector_${input.id}_${i}`} selected={selectedBoxIndex === i} onClick={() => { setSelected(input.id, i); }}>
                      Box
                      {' '}
                      {i}
                    </BoxInputs>
                  ))
                }
              </BoxContainer>
            ) }
          </Droppable>
        </Draggable>
        { !isDragging && _.get(input, 'items.length') > 0 && (
          <PaddingContainer>
            { _.map(input.items, item => (
              <InputHeirarchyRender {...{ ...this.props, input: item, key: item.id }} />
            )) }
          </PaddingContainer>
        ) }
      </div>
    );
  }
}

InputHeirarchyRender.propTypes = {
  deleteInput: PropTypes.func.isRequired,
  idPrefix: PropTypes.string.isRequired,
  input: inputPropType.isRequired,
  moveNode: PropTypes.func.isRequired,
  selectedBoxIndex: PropTypes.number,
  selectedId: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  updateInput: PropTypes.func.isRequired,
};

export default InputHeirarchyRender;
