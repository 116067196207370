import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import AuthIcon from 'react-icons/lib/md/assignment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import strings from 'Resources/strings';
import moment from 'moment';

import FilterAuthorizationsButton from '../../components/FilterAuthorizationsButton';
import IconButton, { IconButtonStyled } from '../../components/IconButton';
import ListFilter from '../../components/MainList/ListFilter';
import NewPriorAuthButton from '../../components/NewPriorAuthButton';
import SearchButton from '../../components/SearchButton';
import { withAuthorizationsPaginated } from '../../graphql/Authorization';
import { withCurrentAccount } from '../../graphql/Account';
import { withInsuranceCompanies } from '../../graphql/InsuranceCompany';
import filterTabs from './filterTabs';
import AuthorizationListManager from './AuthorizationListManager';
import { setAuthorizationListFilters } from '../../reducers/authorizationListReducer';

const Container = styled.div`
  display: flex;
  height: calc(100% - 50px);
  margin: 50px 75px;
  flex-direction: column;
`;

const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  font-size: 16px;
  margin-left: 10px;
  display: flex;
  align-items: center;

  ${IconButtonStyled} {
    background-color: ${props => props.theme.green};
    border: 1px solid ${props => props.theme.green};

    &:hover {
      background-color: white;
      color: ${props => props.theme.green};
    }
  }
`;

export class AuthorizationList extends PureComponent {
  state = { loadingMoreAuthorizations: false };

  clearPayerFilters;

  componentDidMount() {
    mixpanel.track('Authorization List Viewed');
  }

  getDefaultRefetchProps = () => {
    const { authorizationList } = this.props;
    const { selected, firstName, lastName, limit, sortBy, filters } = authorizationList;

    return {
      limit,
      expirationDate: _.get(filters, 'expirationDate') ? moment().add(filters.expirationDate.offset, filters.expirationDate.unit).toDate() : null,
      filterByAccount: selected.onlyMe,
      filterByExpired: selected.onlyExpired,
      locationId: _.get(filters, 'locationId'),
      insuranceCompanyId: _.get(filters, 'insuranceCompanyId'),
      firstName,
      lastName,
      sortBy,
      statuses: _.get(filters, 'status') ? [filters.status] : selected.statuses,
      filterByUpcomingDateOfServiceDays: '',
    };
  };

  setSelectedFilter = (selected) => {
    const { authorizationsPaginatedRefetch, alert, setFilters } = this.props;
    // reset page info then refetch
    mixpanel.track(`Tab Selected - ${selected.tabTitle}`);

    authorizationsPaginatedRefetch({
      filterByAccount: selected.onlyMe,
      filterByExpired: selected.onlyExpired,
      statuses: selected.statuses,
      firstName: '',
      lastName: '',
      locationId: '',
      filterByUpcomingDateOfServiceDays: '',
      insuranceCompanyId: null,
    }).then(() => {
      setFilters({ selected, firstName: '', lastName: '', filters: null, limit: 30, sortBy: 'sortByTimestamp' });
      this.clearPayerFilters();
    }).catch(() => {
      alert.error(strings.AUTHORIZATIONS.ERROR_FETCHING_RESULTS);
    });
  };

  refetchWithFilters = (filters) => {
    const { authorizationsPaginatedRefetch, alert, authorizationList, setFilters } = this.props;
    const { selected } = authorizationList;

    authorizationsPaginatedRefetch({
      ...this.getDefaultRefetchProps(),
      expirationDate: _.get(filters, 'expirationDate') ? moment().add(filters.expirationDate.offset, filters.expirationDate.unit).toDate() : null,
      locationId: _.get(filters, 'locationId'),
      statuses: _.get(filters, 'status') ? [filters.status] : selected.statuses,
      insuranceCompanyId: _.get(filters, 'insuranceCompanyId'),
      filterByUpcomingDateOfServiceDays: _.get(filters, 'filterByUpcomingDateOfServiceDays'),
    }).then(() => {
      setFilters({ filters });
    }).catch(() => {
      alert.error(strings.AUTHORIZATIONS.ERROR_FETCHING_RESULTS);
    });
  };

  iteratePage = () => {
    const { authorizationsPaginatedRefetch, alert, authorizationList, setFilters } = this.props;
    const { limit } = authorizationList;

    mixpanel.track('Authorization list extended', { count: limit + 30 });

    this.setState({ loadingMoreAuthorizations: true });
    authorizationsPaginatedRefetch({
      ...this.getDefaultRefetchProps(),
      limit: limit + 30,
    }).then(() => {
      // Purposefully not using .finally because the test framework doesn't really support it well
      this.setState({ loadingMoreAuthorizations: false });
      setFilters({ limit: limit + 30 });
    }).catch(() => {
      this.setState({ loadingMoreAuthorizations: false });
      alert.error(strings.AUTHORIZATIONS.ERROR_FETCHING_RESULTS);
    });
  };

  resortAuthorizations = (sortBy) => {
    const { authorizationsPaginatedRefetch, alert, setFilters } = this.props;

    mixpanel.track(`Authorization list resorted - ${sortBy}`);

    this.setState({ loadingMoreAuthorizations: true });
    authorizationsPaginatedRefetch({
      ...this.getDefaultRefetchProps(),
      sortBy,
    }).then(() => {
      // Purposefully not using .finally because the test framework doesn't really support it well
      this.setState({ loadingMoreAuthorizations: false });
      setFilters({ sortBy });
    }).catch(() => {
      this.setState({ loadingMoreAuthorizations: false });
      alert.error(strings.AUTHORIZATIONS.ERROR_FETCHING_RESULTS);
    });
  };

  render() {
    const { authorizationList, authorizationPage, insuranceCompanies, setFilters, account } = this.props;
    const { selected, firstName, lastName, filters, sortBy } = authorizationList;
    const { loadingMoreAuthorizations } = this.state;

    const authorizations = _.get(authorizationPage, 'authorizations', null);

    return (
      <Container>
        <FormTitle>
          <div style={{ flex: 1 }}>{ selected.title }</div>
          <SearchButton
            onSearch={(first, last) => {
              setFilters({ firstName: first, lastName: last });
              // Strangely, without the debounce, sometimes searchText isn't set
              _.debounce(() => this.refetchWithFilters(filters), 100)();
            }}
            searchValue={!!firstName || !!lastName}
          />
          <FilterAuthorizationsButton
            setClearFilters={clearFiltersMethod => this.clearPayerFilters = clearFiltersMethod}
            filters={filters}
            onFilter={this.refetchWithFilters}
          />
          <ButtonWrapper>
            <NewPriorAuthButton>
              <IconButton icon={AuthIcon}>New Authorizations</IconButton>
            </NewPriorAuthButton>
          </ButtonWrapper>
        </FormTitle>
        <ListFilter
          onSelect={this.setSelectedFilter}
          selected={selected}
          tabs={filterTabs}
          onChange={this.refetchWithFilters}
        />
        { authorizations && (
        <AuthorizationListManager
          integratedPortals={_.get(account, 'institution.integratedPortals')}
          authorizations={authorizations}
          insuranceCompanies={insuranceCompanies}
          emptyStateText={selected.emptyState}
          getNextPage={this.iteratePage}
          hasNextPage={authorizationPage.totalCount > authorizations.length}
          setSortBy={this.resortAuthorizations}
          sortedBy={sortBy}
          tableConfig={{
            headers: ['Patient', 'Description', 'Status', 'Details'],
            columns: ['patient', 'description', 'status', 'authorizationDetails'],
          }}
          isLoading={loadingMoreAuthorizations}
          selected={selected}
          includeActionRow
        />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: toSet => dispatch(setAuthorizationListFilters(toSet)),
  };
}

export const AuthorizationListEnhanced = compose(
  withAuthorizationsPaginated, withInsuranceCompanies, withCurrentAccount,
)(withAlert(AuthorizationList));

// connect must be executed after compose so that the redux authorizationList store values
// are available for use by the initial authorizationsPaginated fetch on mount
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationListEnhanced);
