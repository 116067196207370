import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import _ from 'lodash';
import Patient from 'AuthorizationSharedComponents/Patient';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import { withCreatePatientOnAuthorization, withUpdatePatient } from '../../graphql/Patient';
import { setFormFields } from '../../reducers/formReducer';
import ReferralSelectFormModal from './ReferralSelectFormModal';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class ReferralInitializeSection extends PureComponent {
  state = { showSelectFormModal: false }

  submit = async () => {
    const { authorization, isInvalid, firstName, lastName, dob, createPatientOnAuthorization } = this.props;

    if (isInvalid()) { return; }
    if (!authorization.patient) {
      await createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(), lastName: lastName.trim(), dob,
          },
        },
      });
    }

    this.setState({ showSelectFormModal: true });
  }

  render() {
    const { authorization, lastName, firstName, disabled, nextStep, referralForms } = this.props;
    const { showSelectFormModal } = this.state;

    return (
      <FormContainer>
        <Patient
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          patientId={_.get(authorization, 'patient.id')}
          currentStep={authorization.formDetails.currentStep}
        />
        <Section
          section={{
            items: [CONFIG.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE],
            title: 'Send notification for major status updates?',
          }}
        />
        <Section
          childrenAfterSection
          section={{
            items: [CONFIG.DEFAULT_FIELDS.REFERRAL_PHONE_NUMBER],
            title: 'Referral Fax Number',
          }}
          disabled={disabled}
        />
        <FormSubmitButtons submit={this.submit} />
        { showSelectFormModal && (
          <ReferralSelectFormModal
            open={showSelectFormModal}
            close={() => { this.setState({ showSelectFormModal: false }); }}
            authorization={authorization}
            nextStep={nextStep}
            referralForms={referralForms}
          />
        )}
      </FormContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    firstName: state.form.results[CONFIG.DEFAULT_FIELDS.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[CONFIG.DEFAULT_FIELDS.PATIENT_LAST_NAME.key],
    dob: state.form.results[CONFIG.DEFAULT_FIELDS.PATIENT_DOB.key],
    state: state.form.results[CONFIG.DEFAULT_FIELDS.INSURANCE_STATE.key],
    disabled: state.form.disabled || ownProps.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = dispatch => ({
  set(fields) { dispatch(setFormFields(fields)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withCreatePatientOnAuthorization, withUpdatePatient)(ReferralInitializeSection)
);
