import React from 'react';

import Section from 'AuthorizationSharedComponents/Section';
import PrescriberSelector from './PrescriberSelector';
import { transformSections } from '../../../../util/sectionConfigurationManager';

const dFields = CONFIG.DEFAULT_FIELDS;
const PrescriberDetails = ({ prescriberId, disabled, authorization }) => (
  <div>
    <Section
      section={transformSections([{
        items: [
          [dFields.PRESCRIBER_FIRST_NAME, dFields.PRESCRIBER_LAST_NAME],
          [dFields.PRESCRIBER_SPECIALTY, dFields.PRESCRIBER_NPI],
          dFields.PRESCRIBER_TIN,
        ],
        title: 'Prescriber Details',
      }], authorization.formFieldConfig)[0]}
      disabled={disabled}
    >
      <PrescriberSelector authorizationId={authorization.id} disabled={disabled} prescriberId={prescriberId} />
    </Section>
  </div>
);

export default PrescriberDetails;
