import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import Upload from '../../components/Upload';
import BaseButton from '../../components/BaseButton';
import { withSendSecureFile } from '../../graphql/SendSecureFile';

const Container = styled.div`
  padding: 10px;
  width: 600px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${BaseText} {
    margin: 10px;
  }
`;

const Seperator = styled.div`
  margin: 10px 0;
`;

const baseState = { sendTo: '', awsKey: '', sending: false, subject: '', message: '' };
class SendSecureFile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...baseState, awsKey: props.defaultFile, institutionName: props.fileInstitutionName };
  }

  onDropSuccess = (__, res) => {
    this.setState({ awsKey: res.data.fields.key });
  }

  onDropError = (e) => {
    const { alert } = this.props;
    alert.error(`There was an error uploading the document. ${e}`);
  }

  send = async () => {
    const { alert, sendSecureFile } = this.props;
    const { sendTo, awsKey, subject, message } = this.state;

    try {
      this.setState({ sending: true });
      await sendSecureFile({ variables: { sendTo, awsKey, message, subject } });
      this.setState({ ...baseState });
      alert.success('Success');
    } catch (e) {
      alert.error(`Error ${e}`);
      this.setState({ sending: false });
    }
  }

  render() {
    const { awsKey, sendTo, sending, subject, message, institutionName } = this.state;

    return (
      <Container>
        <Upload
          onDrop={this.onDrop}
          onDropSuccess={this.onDropSuccess}
          onDropError={this.onDropError}
        />
        { awsKey && (<div>READY TO SEND YOUR DELIGHTFUL FILE (only one upload a time)</div>) }
        { institutionName && (<div>{`Be sure you're sending to a "${institutionName}" user`}</div>) }
        <Seperator>
          { !sending && (
            <div>
              <BaseText
                placeholder="Email"
                onChange={(e) => { this.setState({ sendTo: e.target.value }); }}
                value={sendTo}
              />
              <BaseText
                placeholder="Subject"
                onChange={(e) => { this.setState({ subject: e.target.value }); }}
                value={subject}
              />
              <BaseText
                placeholder="Message"
                onChange={(e) => { this.setState({ message: e.target.value }); }}
                value={message}
              />
            </div>
          ) }
        </Seperator>
        <BaseButton disabled={!(awsKey && sendTo)} onClick={this.send}>Send Message</BaseButton>
      </Container>
    );
  }
}

export default compose(withSendSecureFile)(withAlert(SendSecureFile));
