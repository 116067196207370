import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';

import strings from 'Resources/strings';
import colors from 'Resources/colors';
import { withCreateAuthorizationError } from '../graphql/AuthorizationError';
import Modal from './Modal';
import { ModalBody } from './ModalStyledComponents';
import BaseButton from './BaseButton';


const CustomModalContent = styled(ModalBody)`
  display: flex;
  justify-content: center;
  width: 100%
`;

const ErrorButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  ${BaseButton} {
    margin-top: 5px;
  }
`;

const ResponseTextArea = styled.textarea`
  resize: none;
  height: 200px;
  padding: 10px 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  &:focus { outline: 0 }
`;

const { IN_PROCESS_FORM_AUTH, IN_PROCESS_PORTAL_AUTH } = CONFIG.CONSTANTS.AUTHORIZATION_ERROR_MODAL_CASES;
const { AUTHORIZATION_ERROR_TYPES } = CONFIG.CONSTANTS;
const ERRORS_REQUIRING_EXPLAINATION = [AUTHORIZATION_ERROR_TYPES.FORM_RENDER_INCORRECT, AUTHORIZATION_ERROR_TYPES.OTHER];

class AuthorizationErrorModal extends PureComponent {
  state = {
    showErrorOptions: false,
    explainationStatus: null,
    formText: '',
  }

  onClose = () => {
    const { close } = this.props;
    this.setState({
      showErrorOptions: false,
      explainationStatus: null,
      formText: '',
    });
    close();
  }

  createAuthorizationError = async (type, message) => {
    const { authorizationId, createAuthorizationError, alert, deleteAuth } = this.props;

    if (_.includes(ERRORS_REQUIRING_EXPLAINATION, type) && !message) {
      alert.error('Explaination is required for this error type type');
    } else {
      try {
        await createAuthorizationError({ variables: { authorizationId, type, message } });
        alert.success(strings.REPORT_ERROR.ERROR_SUBMIT_SUCCESS);
      } catch (e) {
        alert.error(strings.REPORT_ERROR.ERROR_SUBMIT_FAILURE);
      } finally {
        deleteAuth();
        this.onClose();
      }
    }
  }

  render() {
    const { LOGIN, SELECTION_UNAVAILABLE, OTHER, FORM_MISSING, PAYER_MISSING, FORM_RENDER_INCORRECT } = AUTHORIZATION_ERROR_TYPES;
    const { open, deleteAuth, authErrorCase } = this.props;
    const { showErrorOptions, explainationStatus, formText } = this.state;
    const OtherButton = <BaseButton onClick={() => { this.setState({ explainationStatus: OTHER }); }}>Other</BaseButton>;

    if (explainationStatus) {
      return (
        <Modal header="Please provide a description of the issue" onClick={this.onClose} open={open}>
          <CustomModalContent>
            <ErrorButtonContainer>
              <ResponseTextArea value={formText} onChange={(e) => { this.setState({ formText: e.target.value }); }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <BaseButton
                  color={colors.lightGray}
                  style={{ width: '45%' }}
                  onClick={() => { this.setState({ explainationStatus: null }); }}
                >
                    Back
                </BaseButton>
                <BaseButton style={{ width: '45%' }} onClick={() => { this.createAuthorizationError(explainationStatus, formText); }}>Submit</BaseButton>
              </div>
            </ErrorButtonContainer>
          </CustomModalContent>
        </Modal>
      );
    }

    if (showErrorOptions) {
      return (
        <Modal header="Please select a description of the issue" onClick={this.onClose} open={open}>
          <CustomModalContent>
            <ErrorButtonContainer>
              {authErrorCase === IN_PROCESS_PORTAL_AUTH ? (
                <>
                  <BaseButton onClick={() => { this.createAuthorizationError(LOGIN); }}>
                    {strings.REPORT_ERROR.LOGIN_ERROR_BUTTON}
                  </BaseButton>
                  <BaseButton onClick={() => { this.createAuthorizationError(SELECTION_UNAVAILABLE); }}>
                    {strings.REPORT_ERROR.SELECTION_UNAVAILABLE_ERROR_BUTTON}
                  </BaseButton>
                </>
              ) : (
                // Initiate Form Auth Error is implied here
                <>
                  <BaseButton onClick={() => { this.createAuthorizationError(FORM_MISSING); }}>
                    {strings.REPORT_ERROR.FORM_MISSING}
                  </BaseButton>
                  <BaseButton onClick={() => { this.createAuthorizationError(PAYER_MISSING); }}>
                    {strings.REPORT_ERROR.PAYER_MISSING}
                  </BaseButton>
                </>
              )}
              {OtherButton}
              <BaseButton color={colors.lightGray} onClick={() => { this.setState({ showErrorOptions: false }); }}>Back</BaseButton>
            </ErrorButtonContainer>
          </CustomModalContent>
        </Modal>
      );
    }

    return (
      <Modal header="Reason for Deleting Authorization" onClick={this.onClose} open={open}>
        <CustomModalContent>
          <ErrorButtonContainer>
            <BaseButton onClick={() => { deleteAuth(); this.onClose(); }}>Authorization not needed</BaseButton>
            {authErrorCase === IN_PROCESS_FORM_AUTH
              ? <BaseButton onClick={() => { this.setState({ explainationStatus: FORM_RENDER_INCORRECT }); }}>This form looks wrong</BaseButton>
              : <BaseButton onClick={() => { this.setState({ showErrorOptions: true }); }}>SamaCare Error</BaseButton>}
          </ErrorButtonContainer>
        </CustomModalContent>
      </Modal>
    );
  }
}

export default compose(withCreateAuthorizationError)(withAlert(AuthorizationErrorModal));
