import _ from 'lodash';
import axios from 'axios';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import strings from 'Resources/strings';

import SamaDropzone from './SamaDropzone';

const MAX_FILE_SIZE_B = 50000000;
export const WARN_FILE_SIZE_B = 10000000;
const LARGE_FILE_WARNING_ALERT_TIMEOUT_MS = 1000 * 10;

export class Upload extends PureComponent {
  state = { loading: false }

  onDrop = (acceptedFiles) => {
    const { alert, onDrop = () => {}, onDropSuccess = () => {}, onDropError = () => {} } = this.props;

    // warn about large uploads
    const hasLargeFiles = !_.isEmpty(_.filter(acceptedFiles, ({ size }) => size > WARN_FILE_SIZE_B));
    if (hasLargeFiles) {
      alert.info(strings.NEW_AUTHORIZATIONS.LARGE_FILE_WARNING(WARN_FILE_SIZE_B / 1000000), { timeout: LARGE_FILE_WARNING_ALERT_TIMEOUT_MS });
    }

    this.setState({ loading: true });
    onDrop();
    acceptedFiles.forEach((file) => {
      axios.post('/upload', { name: file.name, contentType: file.type, contentDisposition: 'inline' })
        .then((res) => {
          this.setState({ loading: false });
          const data = _.reduce(res.data.fields, (dataField, fieldValue, fieldKey) => {
            dataField.append(fieldKey, fieldValue);
            return dataField;
          }, new FormData());
          data.append('file', file);

          return axios({
            method: 'POST',
            data,
            url: res.data.url,
          })
            .then(() => { onDropSuccess(file, res); })
            .catch(() => { throw new Error('Error uploading'); });
        })
        .catch(() => {
          this.setState({ loading: false });
          onDropError();
          alert.error('There was an error uploading the file');
        });
    });
  }

  onDropRejected = () => {
    const { alert, customMaxSize } = this.props;
    alert.error(strings.NEW_AUTHORIZATIONS.LARGE_FILE_ERROR((customMaxSize || MAX_FILE_SIZE_B) / 1000000));
    this.setState({ loading: false });
  }

  render() {
    const {
      disabled,
      multiple = false,
      customMaxSize = MAX_FILE_SIZE_B,
      dropzoneStyle = {},
      acceptedTypes = [CONFIG.CONSTANTS.CONTENT_TYPES.PDF],
    } = this.props;
    const { loading } = this.state;

    return (
      <SamaDropzone
        disabled={disabled}
        loading={loading}
        style={dropzoneStyle}
        accept={acceptedTypes}
        className="dropzone"
        maxSize={customMaxSize}
        multiple={multiple}
        onDrop={this.onDrop}
        onDropRejected={this.onDropRejected}
      />
    );
  }
}

export default withAlert(Upload);
