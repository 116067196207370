import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';

import { DRAGGABLE_TYPE } from './DraggableContainer';

const Droppable = styled.div`
  border: 1px solid ${props => (props.isOver ? props.theme.red : 'transparent')};
`;

class DroppableContainer extends PureComponent {
  render() {
    const { children, connectDropTarget, isOver } = this.props;

    return connectDropTarget(
      <div>
        <Droppable isOver={isOver}>
          { children }
        </Droppable>
      </div>
    );
  }
}

export default DropTarget(
  DRAGGABLE_TYPE,
  {
    drop: (props, monitor) => { props.onDrop(monitor.getItem()); },
    canDrop: (props, monitor) => (monitor.getItem().id !== props.id),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }),
)(DroppableContainer);
