import _ from 'lodash';
import React, { PureComponent } from 'react';
import io from 'socket.io-client';

const MESSAGE_TYPES = CONFIG.CONSTANTS.INTEGRATION_MESSAGE_TYPES;
const STOCK_MESSAGES = CONFIG.CONSTANTS.STOCK_INTEGRATION_MESSAGES;
const provideIntegrationWebSockets = WrappedComponent => (
  class IntegrationWebSocketProvider extends PureComponent {
    state = { ioClient: null, runnerId: null, latestMessage: null, shouldRefetchAuthorization: false }

    componentWillUnmount() {
      this.closeSocket();
    }

    closeSocket = () => {
      const { ioClient } = this.state;
      if (ioClient) {
        ioClient.close();
      }
    }

    send = (message) => {
      const { ioClient, runnerId } = this.state;
      this.setState({ latestMessage: null });

      ioClient.emit('message', { ...message, runnerId });
    }

    restart = () => {
      this.send({ message: STOCK_MESSAGES.RESTART });
    }

    ping = () => {
      const { ioClient } = this.state;
      this.setState({ isAlive: false });

      return new Promise((resolve, reject) => {
        if (!ioClient) {
          reject();
        } else {
          this.send({ message: STOCK_MESSAGES.PING });
          setTimeout(() => {
            const { isAlive } = this.state;
            if (isAlive) {
              resolve();
            } else {
              reject();
            }
          }, 1000);
        }
      });
    }

    registerAndBeginIntegration = (runnerId) => {
      const { ioClient } = this.state;

      if (ioClient) {
        ioClient.close();
      }

      let newIOClient = null;

      if (_.includes(['dev', 'test', ''], CONFIG.NODE_ENV)) {
        newIOClient = io(`http://${CONFIG.SITE_DOMAIN}:${CONFIG.PORT}`);
      } else {
        newIOClient = io(`https://${CONFIG.SITE_DOMAIN}`);
      }

      this.setState({ ioClient: newIOClient, runnerId, latestMessage: null }, () => {
        newIOClient.on('connect', () => {
          this.send({ message: STOCK_MESSAGES.REGISTER_CONNECTION });
          this.send({ message: STOCK_MESSAGES.BEGIN_INTEGRATION });
        });

        newIOClient.on('message', (body) => {
          if (body.type === MESSAGE_TYPES.PONG) {
            this.setState({ isAlive: true });
          } else if (body.type === MESSAGE_TYPES.REFETCH_AUTHORIZATION) {
            this.setState({ shouldRefetchAuthorization: true });
          } else {
            this.setState({ latestMessage: body });
          }
        });
      });
    }

    unsetRefetchAuthorization = () => {
      this.setState({ shouldRefetchAuthorization: false });
    }

    render() {
      const { latestMessage, runnerId, shouldRefetchAuthorization } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          latestIntegrationMessage={latestMessage}
          sendIntegrationMessage={this.send}
          kickoffIntegration={this.registerAndBeginIntegration}
          pingIntegration={this.ping}
          sendRestart={this.restart}
          stopIntegration={this.closeSocket}
          runnerId={runnerId}
          unsetRefetchAuthorization={this.unsetRefetchAuthorization}
          shouldRefetchAuthorization={shouldRefetchAuthorization}
        />
      );
    }
  }
);

export default provideIntegrationWebSockets;
