import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import TiChevronLeft from 'react-icons/lib/ti/chevron-left';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import colors from 'Resources/colors';
import { withCurrentAccount } from '../graphql/Account';
import { withUpdateAuthorization } from '../graphql/Authorization';
import { logout } from '../reducers/session';
import ROUTE_PATHS from '../routes/ROUTE_PATHS';
import SwitchCurrentInstitution from './SwitchCurrentInstitution';
import AlertModal from './AlertModal';
import BaseButton from './BaseButton';

const logoutWidth = 120;
const Container = styled.div`
  width: 100%;
  display: ${props => (props.hide ? 'none' : 'flex')};
  height: 50px;
  flex-direction: row;
  background-color: ${props => props.theme.purple};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height 100%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
  flex: 1;
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
`;

const Logo = styled.img`
  height: 30px;
  width: 30px;
`;

const ReadOnlyContainer = styled.div`
  display: flex;
  margin: auto;
  color: white;
  align-items: center;
`;

const TabButton = styled.div`
  font-weight: ${props => (props.selected ? '500' : '200')};
  border-bottom: ${props => (props.selected ? '3px solid #2BBF96' : '3px solid transparent')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100%);
  cursor: pointer;
  padding: 0 30px;
  width: 250px;
  text-align: center;
  &:hover {
    border-bottom: 3px solid #2BBF96;
  };
`;

const BackContainer = styled.div`
  margin-left: 25px;
  display: flex;
  flex: 1;
  color: white;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const BackButton = styled(BaseButton)`
  color: white;
  border-color: ${props => props.theme.lightGray};
  background-color: ${props => props.theme.lightGray};
  &:hover {
    color: white;
    background-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.gray)};
    border-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.gray)};
  }
`;

const BackArrow = styled(TiChevronLeft)`
  margin-right: 10px;
`;

const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${logoutWidth}px;
  color: white;
  height: 100%;
  margin-left: auto;

  &:hover {
    font-weight: 500;
    cursor: pointer;
    background-color: white;
    color: ${props => props.theme.purple};
  }
`;

export class Header extends PureComponent {
  state = { showWarningModal: false };

  navigateToAuth = () => {
    const { toAuths } = this.props;
    toAuths();
  }

  clickHandler = (path) => {
    const { warnUnsent } = this.props;
    if (!warnUnsent) {
      this.navigateToAuth(path);
    } else {
      this.setState({ showWarningModal: true });
    }
  }

  getIndexButtons = (path) => {
    const { toAuths, account, toSupport, toAdmin } = this.props;
    const { showWarningModal } = this.state;

    if (_.includes([ROUTE_PATHS.FORM_AUTHORIZATION, ROUTE_PATHS.PORTAL_AUTHORIZATION, ROUTE_PATHS.REFERRAL], path)) {
      return (
        <BackContainer onClick={() => { this.clickHandler(path); }}>
          {showWarningModal && (
            <AlertModal
              styleOverride={{ color: colors.defaultFontColor }}
              header="Edit and Resubmit Authorization is Incomplete"
              closeModal={() => { this.setState({ showWarningModal: false }); }}
              open={showWarningModal}
              content="You are leaving BEFORE this authorization has been re-sent to the payer. Are you sure you would like to leave?"
              buttons={(
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                  <BackButton style={{ width: '45%' }} onClick={() => { this.setState({ showWarningModal: false }); }}>Back</BackButton>
                  <BaseButton
                    style={{ width: '45%' }}
                    onClick={() => { this.setState({ showWarningModal: false }); this.navigateToAuth(path); }}
                  >
                    Yes
                  </BaseButton>
                </div>
              )}
            />
          )}
          <BackArrow />
          Back to Authorization List
        </BackContainer>
      );
    }

    return (
      <ButtonContainer>
        <TabButton onClick={toAuths} selected={path === ROUTE_PATHS.AUTHORIZATION_LIST}>Authorizations</TabButton>
        { _.get(account, 'isAdmin') && (<TabButton onClick={toAdmin} selected={path === ROUTE_PATHS.ADMIN_ROUTE}>Admin</TabButton>) }
        { _.get(account, 'isSamaUser') && (<TabButton onClick={toSupport} selected={path === ROUTE_PATHS.SUPPORT_ROUTE}>Support</TabButton>) }
      </ButtonContainer>
    );
  }

  render() {
    const { location, back, account } = this.props;

    return (
      <Container hide={_.some([ROUTE_PATHS.LOGIN, ROUTE_PATHS.RESET_PASSWORD_BASE], route => _.includes(location.pathname, route))}>
        {/* isAuth signals for style changes required for when we have a back button vs don't have a back button */}
        <LogoContainer isAuth={location.pathname === '/'} onClick={() => { this.navigateToAuth(location.pathname); }}>
          <Logo src="../../assets/whiteLogo.svg" />
        </LogoContainer>
        { _.get(account, 'isReadOnly') && <ReadOnlyContainer>READ ONLY</ReadOnlyContainer> }
        { this.getIndexButtons(location.pathname, back) }
        { _.get(account, 'isSamaUser') && (<SwitchCurrentInstitution account={account} />) }
        <LogoutContainer onClick={logout}>Logout</LogoutContainer>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.router.location,
    warnUnsent: state.form.warnUnsent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toAdmin: () => { dispatch(push(ROUTE_PATHS.ADMIN_ROUTE)); },
    toSupport: () => { dispatch(push(ROUTE_PATHS.SUPPORT_ROUTE)); },
    toAuths: () => { dispatch(push(ROUTE_PATHS.AUTHORIZATION_LIST)); },
    back: () => { dispatch(goBack()); },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(compose(withCurrentAccount, withUpdateAuthorization)(withAlert(Header)));
