import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colors from 'Resources/colors';
import strings from 'Resources/strings';
import IntegratedDownloadedFilesSuggestionModal from 'AuthorizationSharedComponents/IntegratedDownloadedFilesSuggestionModal';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import Section from 'AuthorizationSharedComponents/Section';
import Segment from 'Segment/Segment';
import { BaseText } from 'Segment/StyledComponents';

import LoadingSpinner from '../LoadingSpinner';
import BaseButton from '../BaseButton';
import { withFormById } from '../../graphql/AuthorizationForm';
import { withReportError } from '../../graphql/RequestHelp';
import { setFormFields, setRequiredFields, unsetRequiredFields, setShouldIncludeCoverLetter } from '../../reducers/formReducer';
import { transformSpecToInputs, getInputValue } from '../../util/inputConfigurationManager';
import InputManager from './InputManager';
import AlertModal from '../AlertModal';

const Container = styled.div`
  display: flex;
  height: calc(100% - 50px);
  flex-direction: column;
  overflow-x: scroll;
`;

const OverlayShadow = styled.div`
  position: absolute;
  background-color: black;
  opacity: 0.2;
  z-index: ${props => props.theme.zLow};
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: ${props => props.theme.zMiddle};
  height: 100%;
  width: 100%;
`;

const EditorContainer = styled.div`
  width: ${props => props.theme.pdfWidth};
  position: relative;
  margin-bottom: 20px;
`;

const ScrollContainer = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  max-height: 1000px;
  overflow: scroll;
`;

const PDFImage = styled.img`
  width: 100%;
  z-index: ${props => props.theme.zDefault};
`;

const UnsupportedAuthorizationView = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.red};
  border: 2px solid #5d5d5d;
  margin-bottom: 40px;
  padding: 50px;
`;

const ColorLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ColorContainer = styled.div`
  margin: 0 10px 10px 0;
  color: ${props => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Color = styled.div`
  border-radius: 3px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  margin-right: 5px;
`;

const TestViewHeader = styled.div`
  color: ${props => props.theme.darkGray};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const WarningPill = styled.div`
  border-radius: 20px;
  background: white;
  color: ${props => props.color || props.theme.red};
  border: 1px solid ${props => props.color || props.theme.red};
  padding: 4px;
  font-size: 11px;
  margin: 0 8px 8px 0;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin: 0 5px;
    padding: 10px;
  }
`;

const ModalContent = styled.div`
  margin: 10px 0;
`;

const ErrorInfoText = styled.div`
  margin-bottom: 10px;
`;

const IncorrectAssociationHelper = styled.div`
  background-color: ${props => props.theme.lightPurple};
  padding: 20px;
`;

const TriggerIncorrectAssociationText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.purple};
  font-weight: 500;
`;

const types = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;
const importances = CONFIG.CONSTANTS.INPUT_IMPORTANCE;

export const getIsDisplayHidden = (input, inputs, results) => {
  if (!input.displayIf || getInputValue(input, results)) {
    return false;
  }

  const displayIf = _.find(inputs, { id: input.displayIf });
  // If the displayIf parent has a box
  if (displayIf && displayIf.x && displayIf.y) {
    return !getInputValue(displayIf, results);
  }
  // If the displayIf parent has no box, then check the grand parent's value
  if (displayIf.parentId) {
    return !getInputValue(_.find(inputs, { id: displayIf.parentId }), results);
  }
  // Otherwise, it is hidden
  return true;
};

export class PDFEditor extends PureComponent {
  state = {
    isSubmitting: false,
    dimensionRatios: {},
    shouldDisplayCoverSheetModal: false,
    showAdditionalNotes: false,
    showReportErrorModal: false,
    reportErrorNote: '',
  };

  imageContainer = null;

  overlayScrollRef = null;

  getPositionProps = (input) => {
    if (types.RADIO_GROUP.key === input.type) return {};

    const { form } = this.props;
    const { dimensionRatios } = this.state;
    const { widthRatio, heightRatio } = dimensionRatios[input.page] || { widthRatio: 0, heightRatio: 0 };

    return {
      top: (input.y + (input.page * form.taggedHeight)) * heightRatio,
      left: input.x * widthRatio,
      width: input.width * widthRatio,
      height: input.height * heightRatio,
    };
  }

  getDisplayProps = (input, inputs) => {
    const { results, disabled, segmentLoading } = this.props;
    const isDisplayHidden = getIsDisplayHidden(input, inputs, results);
    const value = getInputValue(input, results);

    return {
      isDisplayHidden,
      disabled: disabled || segmentLoading,
      value,
      background: value ? colors.disabledGray : input.background,
    };
  }

  getDisplayInput = (input, inputs) => {
    const { set } = this.props;

    return {
      ...this.getDisplayProps(input, inputs),
      ...this.getPositionProps(input, inputs),
      onChange: set,
      ..._.pick(input, ['id', 'type', 'samaTypes', 'importance', 'siblingId', 'tabIndex', 'characterBoxes']),
    };
  }

  getChildren = _.memoize((input, inputs) => {
    const inputChildren = _.filter(inputs, { parentId: input.id });
    return _.map(inputChildren, child => ({ ..._.omit(this.getDisplayInput(child, inputs), 'onChange') }));
  }, (input, inputs) => {
    const { results } = this.props;
    const parentInput = _.find(inputs, { id: input.parentId });
    const parentHasValue = parentInput ? !!getInputValue(parentInput, results) : 'false';
    return `${input.id}${parentHasValue}`;
  }).bind(this)

  getSiblings = _.memoize((input, inputs) => {
    const inputSiblings = _.filter(inputs, { id: input.id });
    return _.map(inputSiblings, sibling => ({ ...this.getDisplayInput(sibling, inputs) }));
  }, input => (input.id))

  calculateDimensionRatios = (img, i) => {
    const { form } = this.props;
    this.setState(prevState => ({
      dimensionRatios: {
        ...prevState.dimensionRatios,
        [i]: {
          widthRatio: img.clientWidth / form.taggedWidth,
          heightRatio: img.clientHeight / form.taggedHeight,
        },
      },
    }));
  }

  shouldDisplayEMRLoading = (emrFiles, createdAt) => (
    !_.isNil(emrFiles) && _.isEmpty(emrFiles) && moment().diff(createdAt, 'minutes') < CONFIG.CONSTANTS.ONCOEMR_FORM_DELAY_MINUTES
  );

  submit = (shouldIncludeCoverLetter) => {
    const { onNext } = this.props;
    onNext(
      () => {
        this.setState({ isSubmitting: true });
      },
      () => { this.setState({ isSubmitting: false }); },
      () => {
        if (_.isNil(shouldIncludeCoverLetter)) {
          this.setState({ shouldDisplayCoverSheetModal: true });
          return false;
        }
        return true;
      },
      this.scrollToRequired,
    );
  };

  setCoverLetterAndSubmit = (shouldIncludeCoverLetter) => {
    const { setCoverLetter } = this.props;
    setCoverLetter(shouldIncludeCoverLetter);
    this.setState({ shouldDisplayCoverSheetModal: false }, () => this.submit(shouldIncludeCoverLetter));
  };

  getCoverLetterButtons = () => {
    const { showAdditionalNotes } = this.state;

    if (showAdditionalNotes) {
      return (
        <ModalButtonContainer>
          <BaseButton
            style={{ width: '100%' }}
            onClick={() => { this.setState({ showAdditionalNotes: false }); }}
          >
            Cancel
          </BaseButton>
          <BaseButton
            style={{ width: '100%' }}
            onClick={() => { this.setCoverLetterAndSubmit(true); }}
          >
            Submit
          </BaseButton>
        </ModalButtonContainer>
      );
    }

    return (
      <ModalButtonContainer>
        <BaseButton
          style={{ width: '100%' }}
          onClick={() => { this.setCoverLetterAndSubmit(false); }}
        >
          {strings.COMMON.NO}
        </BaseButton>
        <BaseButton
          style={{ width: '100%' }}
          onClick={() => { this.setState({ showAdditionalNotes: true }); }}
        >
          {strings.COMMON.YES}
        </BaseButton>
      </ModalButtonContainer>
    );
  }

  getCoverLetterBodyContent = () => {
    const { set } = this.props;
    const { showAdditionalNotes } = this.state;

    if (showAdditionalNotes) {
      return (
        <ModalContent>
          <Segment
            item={CONFIG.DEFAULT_FIELDS.COVER_LETTER_NOTE}
            set={set}
          />
        </ModalContent>
      );
    }

    return (<ModalContent>{strings.NEW_AUTHORIZATIONS.COVER_SHEET_MODAL_DESCRIPTION}</ModalContent>);
  }

  reportError = async () => {
    const { reportError, form, reportErrorUserDetails, alert } = this.props;
    const { reportErrorNote } = this.state;

    if (!reportErrorNote) {
      alert.error('Missing error details');
    } else {
      try {
        await reportError({ variables: {
          title: 'Form tagged improperly',
          message: `User has reported form with title: ${form.title}`
          + '<br/>'
          + `description: ${form.description}`
          + '<br/>'
          + 'is tagged improperly.'
          + `They have reported that: ${reportErrorNote}`
          + '<br/>'
          + `The user is: ${reportErrorUserDetails}`,
        } });

        this.setState({ showReportErrorModal: false, reportErrorNote: '' });
        alert.info('Your request has been submitted');
      } catch (e) {
        alert.error('There was an error submitting your request. Please try again later');
      }
    }
  }

  scrollToRequired = () => {
    const { results, form } = this.props;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const inputs = transformSpecToInputs(form.specification);
    const scrollTo = _.find(inputs, input => !getInputValue(input, results) && input.y && input.importance === importances.REQUIRED.key);

    if (scrollTo) {
      const { top } = this.getDisplayInput(scrollTo, inputs);
      this.overlayScrollRef.scrollTo({ top: Math.max(top - 50, 0), behavior: 'smooth' });
    }
  }

  render() {
    const {
      onBack, form, loading, segmentLoading, setRequired, unsetRequired, authorization, results, isTagger, requiredSamaTypes, showOncoEMRLoader,
    } = this.props;
    const { isSubmitting, dimensionRatios, shouldDisplayCoverSheetModal, showReportErrorModal, reportErrorNote } = this.state;

    if (loading) {
      return null;
    }

    const formSubmitButtons = (
      <FormSubmitButtons
        back={onBack}
        submit={this.submit}
        disabled={isSubmitting || segmentLoading}
        loading={isSubmitting || segmentLoading}
      />
    );

    if (_.includes(CONFIG.CONSTANTS.AUTHORIZATION_VERSION.LEGACY, authorization.version)) {
      return (
        <Container>
          <UnsupportedAuthorizationView>
            This is an authorization created on a previous version of our software.
            While this view is no longer supported, you can still see a full reference of your authorization on the next step.
          </UnsupportedAuthorizationView>
          {formSubmitButtons}
        </Container>
      );
    }

    if (!form) {
      return (
        <Container>
          <UnsupportedAuthorizationView>
            This is an authorization was created using a form that has been removed from our system.
            While this view is no longer supported, you can still see a full reference of your authorization on the next step.
          </UnsupportedAuthorizationView>
          {formSubmitButtons}
        </Container>
      );
    }
    const samaTypeArray = _.flatten(_.map(form.specification, 'samaTypes'));
    const samaTypeWarnings = _.reject(requiredSamaTypes, (type) => {
      if (_.isArray(type)) {
        return _.some(samaTypeArray, t => _.includes(samaTypeArray, t));
      }
      return _.includes(samaTypeArray, type);
    });

    const inputs = transformSpecToInputs(form.specification);

    return (
      <Container>
        <ColorLegend>
          {
            _.map([importances.REQUIRED, importances.IMPORTANT, importances.DEFAULT], importance => (
              <ColorContainer key={`importance_legend_${importance.key}`} color={importance.color}>
                <Color color={importance.color} />
                { importance.title }
              </ColorContainer>
            ))
          }
          <ColorContainer key="importance_legend_not_important" color="#777777">
            <Color color="#777777" />
            Filled / Low priority
          </ColorContainer>
        </ColorLegend>
        <div style={{ display: 'flex' }}>
          <EditorContainer>
            <OverlayShadow />
            <ScrollContainer ref={(ref) => { this.overlayScrollRef = ref; }}>
              { _.values(dimensionRatios).length === form.imgURLs.length // Don't display until all pages are available
                ? (
                  <Overlay>
                    { // if all pages have loaded then display all inputs, otherwise filter by inputs on loaded pages
                      _.map(inputs, input => (
                        <InputManager
                          key={`InputManager_${input.id}_${input.siblingId}`}
                          formattedInput={this.getDisplayInput(input, inputs)}
                          inputChildren={this.getChildren(input, inputs)}
                          setRequired={setRequired}
                          unsetRequired={unsetRequired}
                          siblings={this.getSiblings(input, inputs)}
                        />
                      )) }
                  </Overlay>
                ) : (<LoadingSpinner open />) }
              { _.map(form.imgURLs, (imgURL, i) => (
                <PDFImage key={`pdf_editor_page_${i}`} src={imgURL} onLoad={event => this.calculateDimensionRatios(event.target, i)} />
              ))}
            </ScrollContainer>
          </EditorContainer>
          {isTagger && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '8px', width: '35%' }}>
              <TestViewHeader>SamaTypes Not Tagged on Form:</TestViewHeader>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {_.map(samaTypeWarnings, (type) => {
                  if (_.isArray(type)) {
                    return <WarningPill>{`${type[0]} OR ${type[1]}`}</WarningPill>;
                  }
                  return <WarningPill>{type}</WarningPill>;
                })}
              </div>
              <TestViewHeader>Prepopulated  Config Data:</TestViewHeader>
              {// This map returns only true for text keys / sama types and ignores custom fields
              _.map(_.filter(_.keys(results), key => _.isNaN(_.parseInt(key))),
                (key, i) => (
                  <div key={`showKeys-${i}-${key}`}>
                    <b>{key}</b>
                    {`: ${results[key]}`}
                  </div>
                ))}
            </div>
          )}
        </div>
        {!isTagger && (
        <>
          <IncorrectAssociationHelper>
            { 'Is there an error in the way we\'ve encoded the form above? ' }
            <TriggerIncorrectAssociationText onClick={() => { this.setState({ showReportErrorModal: true }); }}>
              Click here
            </TriggerIncorrectAssociationText>
            { ' to have SamaCare resolve this issue.' }
          </IncorrectAssociationHelper>
          <Section
            section={{
              items: [{ key: 'Attachments', title: 'Please select additional attachments', type: CONFIG.CONSTANTS.FORM_TYPES.UPLOAD }],
              title: 'Additional attachments',
            }}
            childrenAfterSection
          >
            {
              this.shouldDisplayEMRLoading(authorization.integratedEMRFilesDownloaded, authorization.createdAt) && showOncoEMRLoader && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LoadingSpinner style={{ marginRight: '10px' }} colorTop={colors.darkPurple} color={colors.purple} />
                  Checking OncoEMR for possible patient attachments... (this can take several minutes)
                </div>
              )
            }
            {!_.isEmpty(authorization.integratedEMRFilesDownloaded) && (<IntegratedDownloadedFilesSuggestionModal authorization={authorization} />)}
          </Section>
          {formSubmitButtons}
          <AlertModal
            buttons={this.getCoverLetterButtons()}
            content={this.getCoverLetterBodyContent()}
            header={strings.NEW_AUTHORIZATIONS.COVER_SHEET_MODAL_HEADER}
            open={shouldDisplayCoverSheetModal}
            closeModal={() => { this.setState({ shouldDisplayCoverSheetModal: false }); }}
          />
        </>
        )}
        {
          <AlertModal
            header="Additional Details"
            open={showReportErrorModal}
            closeModal={() => { this.setState({ showReportErrorModal: false }); }}
            content={(
              <div>
                <ErrorInfoText>
                  Please let us know what errors you are seeing
                </ErrorInfoText>
                <BaseText
                  value={reportErrorNote || ''}
                  onChange={(e) => { this.setState({ reportErrorNote: e.target.value }); }}
                  placeholder="I am seeing..."
                  style={{ marginLeft: 0 }}
                />
              </div>
            )}
            buttons={(
              <BaseButton
                style={{ width: '100%' }}
                key="reportError"
                onClick={this.reportError}
              >
                Report Error
              </BaseButton>
              )}
          />
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    disabled: state.form.disabled,
    segmentLoading: state.form.segmentLoading,
  };
}

const mapDispatchToProps = dispatch => ({
  set(fields) { dispatch(setFormFields(fields)); },
  setRequired(toSet) { dispatch(setRequiredFields(toSet)); },
  unsetRequired(toUnset) { dispatch(unsetRequiredFields(toUnset)); },
  setCoverLetter(shouldIncludeCoverLetter) { dispatch(setShouldIncludeCoverLetter(shouldIncludeCoverLetter)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withFormById, withReportError)(withAlert(PDFEditor)));
