import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import { withDefaultProps } from './utils';
import signatureInfo from './fragments/signatureInfo';

const GET_SIGNATURES = 'getSignatures';
export const withAddSignature = graphql(
  gql`
    mutation addSignature($data: String!) {
      createSignature(dataUrl: $data) {
        ...signatureInfo
      }
    }
    ${signatureInfo}
  `,
  { name: 'addSignature', options: () => ({ refetchQueries: () => [GET_SIGNATURES] }) },
);

export const withSignatures = graphql(
  gql`
    query ${GET_SIGNATURES} {
      signaturesFindAll {
        ...signatureInfo
      }
    }
    ${signatureInfo}
  `, {
    props: withDefaultProps(({ data }) => ({ signatures: _.get(data, 'signaturesFindAll') })),
  }
);

export const signatureByIdQuery = gql`
  query signatureById($id: Int!) {
    signatureById(id: $id) { ...signatureInfo }
  }
  ${signatureInfo}
`;

export const withDeleteSignature = graphql(
  gql`
    mutation deleteSignature($id: Int!) {
      deletePrescriberSignature(id: $id)
    }
  `, { name: 'deleteSignature', options: () => ({ refetchQueries: () => [GET_SIGNATURES] }) },
);
