import gql from 'graphql-tag';

import authorizationCorrespondenceInfo from './authorizationCorrespondenceInfo';
import noteInfo from './noteInfo';

export default gql`
  fragment authorizationInfo on Authorization {
    id
    config
    status
    formId
    formDetails
    updatedAt
    submittedAt
    createdByUser
    checkRequested
    createdAt
    version
    InstitutionId
    institutionAuthorizationId
    isArchived
    displayReviewWarning
    hideFromExpiring
    portalKey
    ICDs
    HCPCSCodes
    isSupported
    isReferral
    portalTitle
    requiresAssociationReview
    emailReminderAt
    emailReminderDescription

    customPortalSteps {
      key
      id
      display
      value
      isTerminal
      message
      type
      subType
      title
      requiresHardReset
      imageURL
      isDraft
      errorGroup
    }

    patient {
      id
      firstName
      lastName
      InsuranceCompanyId
      institutionPatientId
      dob
    }

    insuranceCompany {
      id
      name
      responseRangeMax
      responseRangeMin
      notReceivedNonApprovals
    }

    latestCorrespondence {
      ...authorizationCorrespondenceInfo
    }

    correspondences {
      ...authorizationCorrespondenceInfo
    }

    latestNote {
      ...noteInfo
    }

    formFieldConfig {
      requiredFields
      hiddenFields
    }

    followUp {
      id
      isReviewed
      type
    }
  }

  ${authorizationCorrespondenceInfo}
  ${noteInfo}
`;
