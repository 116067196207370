import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withDefaultProps } from './utils';
import authorizationInfo from './fragments/authorizationInfo';
import patientInfo from './fragments/patientInfo';

export const patientsFindAll = graphql(
  gql`
    query PatientsRootQuery($where: JSON) {
      patientsFindAll(where: $where) {
        ...patientInfo
      }
    }
    ${patientInfo}
  `,
  {
    fetchPolicy: 'no-cache',
    props: withDefaultProps(({ data }) => ({ patients: _.get(data, 'patientsFindAll') })),
  }
);

export const withPatient = graphql(
  gql`
    query Patient($id: Int) {
      patientById(id: $id) {
        ...patientInfo

        authorizations {
          id
          status
          config
          updatedAt
        }
      }
    }
    ${patientInfo}
  `,
  {
    options: props => ({ variables: { id: props.patientId } }),
    props: withDefaultProps(({ data }) => ({ patient: data.patientById })),
  }
);

export const withCreatePatientOnAuthorization = graphql(
  gql`
    mutation createPatientOnAuthorization($authorizationId: Int, $patientProps: JSON) {
      createPatientOnAuthorization(authorizationId: $authorizationId, patientProps: $patientProps) {
        ...authorizationInfo
      }
    }
    ${authorizationInfo}
  `, {
    name: 'createPatientOnAuthorization',
  }
);

export const withUpdatePatient = graphql(
  gql`
    mutation updatePatient($id: Int!, $patch: JSON) {
      updatePatientById(id: $id, patch: $patch) {
        ...patientInfo
      }
    }
    ${patientInfo}
  `,
  {
    name: 'updatePatient',
  },
);

export const findDuplicateAuthorizationsForPatientQuery = gql`
  query findDuplicateAuthorizationsForPatient($id: Int!) {
    findDuplicateAuthorizationsForPatient(id: $id) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;
