import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';

import Modal from './Modal';
import CorrespondenceViewer from './CorrespondenceViewer';
import { withUpdateAuthorization, withUndoAutoAssociatedResponse } from '../graphql/Authorization';
import AutoAssociationDetailsContainer from './AutoAssociationDetailsContainer';

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

class AutoAssociatedModal extends PureComponent {
  state = {};

  handleReviewAutoAssociationConfirmation = async (authorizationID) => {
    const { updateAuthorization, alert, closeModal } = this.props;

    try {
      closeModal();
      await updateAuthorization({ variables: { id: authorizationID, patch: { requiresAssociationReview: false } } });
      alert.success('Successfully confirmed the automated association for this authorization!');
    } catch {
      alert.error('There was an error confirming the automated association for this authorization.');
    }
  }

  handleIncorrectAutoAssociation = async (authorizationId) => {
    const { alert, undoAutoAssociatedResponse, closeModal } = this.props;

    try {
      await undoAutoAssociatedResponse({ variables: { authorizationId, isAutoAssociation: true } });
      alert.success('Successfully removed the incorrect automated association!');
    } catch {
      alert.error('There was an error removing the incorrect automated association.');
    } finally {
      closeModal();
    }
  }

  render() {
    const { authorization, closeModal } = this.props;

    return (
      <Modal header="Automated Response Review" open={authorization} onClick={closeModal}>
        <TwoPaneContainer>
          <CorrespondenceViewer correspondence={authorization.latestCorrespondence} width="700px" height="550px" />
          <AutoAssociationDetailsContainer
            authorization={authorization}
            handleReviewAutoAssociationConfirmation={this.handleReviewAutoAssociationConfirmation}
            handleIncorrectAutoAssociation={this.handleIncorrectAutoAssociation}
          />
        </TwoPaneContainer>
      </Modal>
    );
  }
}

export default (compose(withUpdateAuthorization, withUndoAutoAssociatedResponse)(withAlert(AutoAssociatedModal)));
