import _ from 'lodash';

export const retinaOptions = [
  { dose: '2 mg', code: 'J0178', drugName: 'Eylea' },
  { dose: '0.3 mg', code: 'J2778', drugName: 'Lucentis' },
  { dose: '0.5 mg', code: 'J2778', drugName: 'Lucentis' },
  { dose: '0.7 mg', code: 'J7312', drugName: 'Ozurdex' },
  { dose: '1.25 mg', code: 'J3590', drugName: 'Avastin' },
  { dose: '1.25 mg', code: 'C9257', drugName: 'Avastin' },
  { dose: '1.25 mg', code: 'J9035', drugName: 'Avastin' },
  { dose: '0.19 mg', code: 'J7313', drugName: 'Iluvien' },
  { dose: '0.18 mg', code: 'J7314', drugName: 'Yutiq' },
  { dose: '1.25mg', code: 'J7316', drugName: 'Jetrea' },
  { dose: '6 mg', code: 'J0179', drugName: 'Beovu' },
  { dose: '0.3 mg', code: 'J2503', drugName: 'Macugen' },
  { dose: '0.1 mg', code: 'J3396', drugName: 'Visudyne' },
  { dose: '1 mg', code: 'J3300', drugName: 'Triesence' },
  { dose: '', code: 'J3301', drugName: 'Kenalog' },
  { dose: '', code: '99204', drugName: 'Office Visit (ov)' },
  { dose: '', code: '99199', drugName: 'Office Visit (ov)' },
  { dose: '', code: '92014', drugName: 'Office Visit (ov)' },
];

export const oncologyOptions = [
  { dose: '60 mg', code: 'J0897', drugName: 'Prolia' },
  { dose: '120 mg', code: 'J0897', drugName: 'Xgeva' },
  { dose: '1 mg', code: 'J9299', drugName: 'Opdivo' },
  { dose: '10 mg', code: 'J9173', drugName: 'Imfinzi' },
  { dose: '0.5 mg', code: 'Q5108', drugName: 'Fulphila' },
  { dose: '1 mg', code: 'J9306', drugName: 'Perjeta' },
  { dose: '1 microgram', code: 'J0881', drugName: 'Aranesp' },
  { dose: '1000 units', code: 'J0885', drugName: 'Procrit' },
  { dose: '1000 units', code: 'J0885', drugName: 'Epogen' },
  { dose: '100 units', code: 'Q5105', drugName: 'Retacrit' },
  { dose: '1 microgram', code: 'J0888', drugName: 'Mircera' },
  { dose: '1 mg', code: 'J9271', drugName: 'Keytruda' },
  { dose: '10 mg', code: 'J9355', drugName: 'Herceptin' },
  { code: 'J1750', drugName: 'Iron Dextran', dose: '50 mg' },
  { code: 'J1756', drugName: 'Venofer', dose: '1 mg' },
  { code: 'J1950', drugName: 'Leuprolide Acetate (Lupron)', dose: '3.75 mg' },
  { code: 'Q5119', drugName: 'Rituximab', dose: '10 mg' },
  { code: 'J2780', drugName: 'Ranitidine hydrochloride', dose: '25 mg' },
  { code: 'J2796', drugName: 'Romiplostim', dose: '10 mcg' },
  { code: 'J2916', drugName: 'Na ferric gluconate complex', dose: '12.5 mg' },
  { code: 'J2920', drugName: 'Methylprednisolone', dose: '' },
  { code: 'J2997', drugName: 'Alteplase recombinant', dose: '1 mg' },
  { code: 'J3420', drugName: 'Cyanocobalamin (B-12)', dose: '1000 mcg' },
  { code: 'J3475', drugName: 'Magnesium Sulfate', dose: '500 mg' },
  { code: 'J3480', drugName: 'Potassium Chloride', dose: '2 mcg' },
  { code: 'J9267', drugName: 'Taxol', dose: '1 mg' },
  { code: 'J1439', drugName: 'Injectafer', dose: '1 mg' },
  { code: 'J1453', drugName: 'Emend', dose: '1 mg' },
  { code: 'Q0138', drugName: 'Feraheme', dose: '1 mg' },
  { code: 'Q0139', drugName: 'Feraheme', dose: '1 mg' },
  { code: 'J9055', drugName: 'Erbitux', dose: '100 mg' },
  { code: 'J9055', drugName: 'Erbitux', dose: '200 mg' },
  { code: 'J9312', drugName: 'Rituxan', dose: '10 mg' },
  { code: 'J9145', drugName: 'Darzalex', dose: '100mg' },
  { code: 'J9145', drugName: 'Darzalex', dose: '400mg' },
  { code: 'J2505', drugName: 'Neulasta', dose: '6mg' },
  { code: 'Q5101', drugName: 'Zarxio', dose: '1 mcg' },
  { code: 'J9042', drugName: 'Adcetris', dose: '1 mg' },
  { code: 'J9303', drugName: 'Vectibix', dose: '100mg' },
  { code: 'J1300', drugName: 'Soliris', dose: '10mg' },
  { code: 'J9264', drugName: 'Abraxane', dose: '1 mg' },
  { code: 'J9229', drugName: 'Besponsa', dose: '0.9 mg' },
  { code: 'J9325', drugName: 'Imlygic', dose: '1MM / ml' },
  { code: 'J9325', drugName: 'Imlygic', dose: '100MM / ml' },
  { code: 'Q5115', drugName: 'Truxima', dose: '10 mg' },
  { code: 'J1626', drugName: 'Granisetron', dose: '0.1 mg' },
  { code: 'J2469', drugName: 'Aloxi', dose: '0.25 mg' },
  { code: 'J3489', drugName: 'Zometa', dose: '1 mg' },
  { code: 'J2469', drugName: 'Aloxi', dose: '0.075 mg' },
  { code: 'J1454', drugName: 'Akynzeo', dose: '235 mg' },
  { code: 'J9228', drugName: 'Yervoy', dose: '1 mg' },
  { code: 'J9302', drugName: 'Arzerra', dose: '1000 mg' },
  { code: 'J9302', drugName: 'Arzerra', dose: '100 mg' },
  { code: 'J9155', drugName: 'Frimagon', dose: '1 mg' },
  { code: 'J9119', drugName: 'Libtayo', dose: '1 mg' },
  { code: 'J9263', drugName: 'Oxaliplatin', dose: '0.5 mg' },
  { code: 'J9023', drugName: 'Bavencio', dose: '20 mg/mL' },
  { code: 'J9034', drugName: 'Bendeka', dose: '1 mg' },
  { code: 'J9039', drugName: 'Blincyto', dose: '1 mcg' },
  { code: 'J9308', drugName: 'Cyramza', dose: '5 mg' },
  { code: 'J9395', drugName: 'Faslodex', dose: '25 mg' },
  { code: 'J9207', drugName: 'Ixempra', dose: '1 mg' },
  { code: 'J9354', drugName: 'Kadcyla', dose: '1 mg' },
  { code: 'J9313', drugName: 'Lumoxiti', dose: '.01 mg' },
  { code: 'A9513', drugName: 'Lutathera', dose: '1 MCI' },
  { code: 'J9203', drugName: 'Mylotarg', dose: '.1mg' },
  { code: 'Q5110', drugName: 'Nivestym', dose: '1 mcg' },
  { code: 'C9038', drugName: 'Poteligeo', dose: '1 mg' },
  { code: 'J2502', drugName: 'Signifor', dose: '1 mg' },
  { code: 'J9022', drugName: 'Tecentriq', dose: '10 mg' },
  { code: 'J9041', drugName: 'Velcade', dose: '0.1 mg' },
  { code: 'Q5111', drugName: 'Udenyca', dose: '6 mg' },
  { code: 'J9033', drugName: 'Treanda', dose: '25 mg' },
  { code: 'J9033', drugName: 'Treanda', dose: '100 mg' },
  { code: 'J9047', drugName: 'mgKrypolis', dose: '10 mg' },
  { code: 'J9047', drugName: 'mgKrypolis', dose: '30 mg' },
  { code: 'J9047', drugName: 'mgKrypolis', dose: '60 mg' },
  { code: 'J2353', drugName: 'Sandostatin', dose: '10 mg' },
  { code: 'J2353', drugName: 'Sandostatin', dose: '20 mg' },
  { code: 'J2353', drugName: 'Sandostatin', dose: '30 mg' },
  { code: 'J1930', drugName: 'Somatuline', dose: '60 mg' },
  { code: 'J1930', drugName: 'Somatuline', dose: '90 mg' },
  { code: 'J1930', drugName: 'Somatuline', dose: '120 mg' },
  { code: 'J9043', drugName: 'Jetvana', dose: '60 mg' },
  { code: 'J2597', drugName: 'Desmopressin', dose: '0.1 mg' },
  { code: 'J2597', drugName: 'Desmopressin', dose: '0.2 mg' },
  { code: 'J1438', drugName: 'Enbrel', dose: '50 mg' },
  { code: 'J0135', drugName: 'Humira', dose: '40 mg' },
  { code: 'J1602', drugName: 'Simponi Aria', dose: '1 mg' },
  { code: 'J0129', drugName: 'Orencia', dose: '10 mg' },
  { code: 'J3262', drugName: 'Actemra', dose: '1 mg' },
  { code: 'J0717', drugName: 'Cimzia', dose: '1 mg' },
  { code: 'Q5104', drugName: 'Renflexis', dose: '10 mg' },
  { code: 'Q5103', drugName: 'Inflectra', dose: '10 mg' },
  { code: 'J1745', drugName: 'Remicade', dose: '10 mg' },
  { code: 'J9176', drugName: 'Empliciti', dose: '1 mg' },
  { code: 'J9176', drugName: 'Empliciti', dose: '10 mg' },
  { code: 'J9176', drugName: 'Empliciti', dose: '20 mg' },
  { code: 'J9301', drugName: 'Gazyva', dose: '10 mg' },
  { code: 'J9330', drugName: 'Torisel', dose: '15 mg' },
  { code: 'J9330', drugName: 'Torisel', dose: '20 mg' },
  { code: 'J9330', drugName: 'Torisel', dose: '25 mg' },
  { code: 'J9217', drugName: 'Leuprolide (Eligard)', dose: '7.5 mg' },
  { code: 'Q2043', drugName: 'Provenge', dose: '' },
  { code: 'Q5118', drugName: 'Zirabev', dose: '100 mg' },
  { code: 'Q5118', drugName: 'Zirabev', dose: '400 mg' },
  { code: 'J9205', drugName: 'Onivyde', dose: '70 mg' },
  { code: 'J9202', drugName: 'Zoladex', dose: '3.6 mg' },
  { code: 'J9035', drugName: 'Avastin', dose: '' },
  { code: 'J3490', drugName: 'Zulresso', dose: '1 mg' },
  { code: 'C9055', drugName: 'Zulresso', dose: '1 mg' },
  { code: 'J9036', drugName: 'Belrapzo', dose: '1 mg' },
  { code: 'J0640', drugName: 'Leucovorin', dose: '50 mg' },
  { code: 'Q5106', drugName: 'Retacrit', dose: '' },
  { code: 'J9025', drugName: 'Vidaza', dose: '100 mg' },
  { code: 'J1442', drugName: 'Neupogen', dose: '1 mcg' },
  { code: 'J8530', drugName: 'Cytoxan', dose: '25 mg' },
];

const formatOptions = (options) => {
  const formattedOptions = _.map(options, (opt) => {
    const label = `[${opt.code}] ${opt.drugName} ${opt.dose}`;
    return {
      ...opt,
      value: label,
      label,
    };
  });

  if (formattedOptions.length !== _.uniqBy(formattedOptions, 'label').length) {
    throw new Error('Cannot have duplicate labels');
  }

  return formattedOptions;
};

export default {
  [CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.RETINA]: formatOptions(retinaOptions),
  [CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.ONCOLOGY]: formatOptions(oncologyOptions),
};
