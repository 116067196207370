import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import UnexpectedErrorIcon from 'react-icons/lib/md/error-outline';
import ServiceDownIcon from 'react-icons/lib/md/watch-later';
import strings from 'Resources/strings';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import PortalTimeout from './PortalTimeout';
import PortalHCPCS from './PortalHCPCS';
import PortalICD from './PortalICD';
import PortalError from './PortalError';
import PortalTable from './PortalTable';
import PortalFillTable from './PortalFillTable';
import PortalImagePrompt from './PortalImagePrompt';
import PortalText from './PortalText';
import PortalComplete from './PortalComplete';
import PortalSubmitted from './PortalSubmitted';
import PortalUpload from './PortalUpload';
import LoadingSpinner from '../../components/LoadingSpinner';
import LargeLoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import BaseButton from '../../components/BaseButton';
import { withUpdateAuthorization } from '../../graphql/Authorization';
import PortalLogin from './PortalLogin';
import PortalErrorModal from './PortalErrorModal';
import PortalPriorSteps from './PortalPriorSteps';
import Modal from '../../components/Modal';
import { ModalBody, ModalFooter, ModalContent } from '../../components/ModalStyledComponents';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingSpinnerStyled = styled(LoadingSpinner)`
  border: 4px solid rgba(114, 113, 243, 0.2);
  border-top-color: rgb(114, 113, 243);
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${({ theme, color }) => color || theme.purple};
  border-radius: 3px;
  ${BaseButton} {
    margin-left: auto;
  }
`;

const StepContainer = styled.div`
  padding: 10px;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  justify-content: center;
  ${BaseButton} {
    width: 300px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const MESSAGE_TYPES = CONFIG.CONSTANTS.INTEGRATION_MESSAGE_TYPES;
const COMPONENT_TYPES = CONFIG.CONSTANTS.INTEGRATION_COMPONENT_TYPES;

export class PortalSpecificSteps extends Component {
  constructor(props) {
    super(props);
    this.state = { openUserCredentialModal: false, closeUserSubmissionModal: false };

    props.setTriggerReinputLogin(() => { this.setState({ openUserCredentialModal: true }); });
  }

  componentDidMount = async () => {
    const { restartIntegration, authorization } = this.props;
    try {
      await restartIntegration();
      if (!authorization.submittedAt) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch (e) {
      if (_.includes(e.message, CONFIG.CONSTANTS.ERROR_TYPES.PORTAL_MISSING_CREDENTIALS)) {
        this.setState({ openUserCredentialModal: true });
      }
    }
  }

  componentWillUnmount() {
    const { stopIntegration, setTriggerReinputLogin } = this.props;
    stopIntegration();
    setTriggerReinputLogin(null);
  }

  sendResponse = async (response) => {
    const { authorization, sendIntegrationMessage, updateAuthorization, alert, latestIntegrationMessage } = this.props;
    const previousStep = _.find(authorization.customPortalSteps, { id: response.id });

    if (!previousStep || previousStep.isDraft) {
      const formattedResponse = {
        ..._.omit(response, 'data'),
        requiresHardReset: latestIntegrationMessage.requiresHardReset,
      };

      try {
        if (!latestIntegrationMessage.skipSaving) {
          await updateAuthorization(
            { variables: {
              id: authorization.id,
              patch: { customPortalSteps: _.reject(authorization.customPortalSteps, { isDraft: true }).concat(formattedResponse) } },
            }
          );
        }

        if (!formattedResponse.isDraft) {
          await sendIntegrationMessage(_.omit(formattedResponse, 'priorValue'));
        }
      } catch (e) {
        console.error(e);
        alert.error('There was an error sending your response to the server, please refresh and try again');
      }
    }
  };

  removeTerminalAndRetry = () => {
    const { authorization } = this.props;
    if (_.get(_.last(authorization.customPortalSteps), 'isTerminal')) {
      this.resetToStep(_.last(authorization.customPortalSteps).id);
    } else {
      // In this case, the authorization is out of date in the front-end.
      // Therefore, we should just restart with 'past steps'
      this.resetToStep(null, true);
    }
  };

  retryFromLastStep = () => {
    const { authorization } = this.props;
    this.resetToStep(_.last(authorization.customPortalSteps).id);
  };

  resetToStep = (stepId, resetWithSameSteps) => {
    const { authorization } = this.props;

    let portalStepsAfterReset = authorization.customPortalSteps.slice(0, _.findIndex(authorization.customPortalSteps, { id: stepId }));
    if (resetWithSameSteps) {
      portalStepsAfterReset = authorization.customPortalSteps;
    }

    this.updatePortalStepsAndRestart(portalStepsAfterReset);
  };

  updatePortalStepsAndRestart = async (newSteps) => {
    const { authorization, updateAuthorization, restartIntegration, alert } = this.props;

    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { customPortalSteps: newSteps } } });
      restartIntegration();
    } catch {
      alert.error('There was an error updating your authorization, please refresh and try again');
    }
  }

  unsetStep = (stepId) => {
    const { authorization } = this.props;

    const newSteps = _.reject(authorization.customPortalSteps, step => (step.id === stepId || step.isTerminal));

    this.updatePortalStepsAndRestart(newSteps);
  }

  resetAuthorization = () => {
    const { authorization, updateAuthorization, restartIntegration, alert, goToInitialStep } = this.props;

    updateAuthorization({ variables: { id: authorization.id, patch: { customPortalSteps: [], formDetails: { step: 1 }, PatientId: null } } })
      .then(() => {
        restartIntegration();
        goToInitialStep();
      })
      .catch((e) => {
        console.error(e);
        alert.error('There was an error resetting your authorization, please refresh and try again');
      });
  };

  getErrorContent = (authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials) => {
    const { restartIntegration, takeStepsBack } = this.props;
    let icon;
    let title;
    let isReenterCredentials = false;
    let isDefaultCase = false;

    switch (message.subType) {
      case MESSAGE_TYPES.ERROR_SUB_TYPE.SERVICE_DOWN:
        icon = ServiceDownIcon;
        title = strings.INTEGRATED_AUTHORIZATIONS.ERROR_TITLES.SERVICE_DOWN;
        break;
      case MESSAGE_TYPES.ERROR_SUB_TYPE.WRONG_CREDENTIALS:
        icon = UnexpectedErrorIcon;
        title = 'Invalid login credentials';
        isReenterCredentials = true;
        isDefaultCase = true;
        break;
      case MESSAGE_TYPES.ERROR_SUB_TYPE.MULTIPLE_SESSIONS:
        icon = UnexpectedErrorIcon;
        title = 'Multiple sessions error';
        break;
      default:
        icon = UnexpectedErrorIcon;
        title = strings.INTEGRATED_AUTHORIZATIONS.ERROR_TITLES.UNEXPECTED;
        isDefaultCase = true;
    }
    return isDefaultCase ? (
      <PortalErrorModal
        authorization={authorization}
        message={message}
        title={title}
        retryFromLastStep={retryFromLastStep}
        removeTerminalAndRetry={removeTerminalAndRetry}
        restartIntegration={restartIntegration}
        updatePortalStepsAndRestart={this.updatePortalStepsAndRestart}
        unsetStep={this.unsetStep}
        resetToStep={this.resetToStep}
        reenterCredentials={reenterCredentials}
        hideStandardActions={isReenterCredentials}
        takeStepsBack={takeStepsBack}
        getPriorSteps={this.getPriorSteps}
        retry={() => { this.resetToStep(null, true); }}
      />
    ) : (
      <PortalError
        authorization={authorization}
        title={title}
        message={message}
        hideStandardActions={isReenterCredentials}
        reenterCredentials={
          isReenterCredentials
            ? reenterCredentials
            : null
        }
        removeTerminalAndRetry={removeTerminalAndRetry}
        retryFromLastStep={retryFromLastStep}
        back={back}
        icon={icon}
      />
    );
  };

  getTerminalStepContent = (authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials) => {
    let content = null;

    switch (message.type) {
      case MESSAGE_TYPES.ERROR:
        content = this.getErrorContent(authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials);
        break;
      default:
        console.error('Unrecognized type on integration message', message);
    }
    return content;
  };

  getIntegrationMessageContent = (authorization, message, sendResponse, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials) => {
    let content = null;
    switch (message.type) {
      case MESSAGE_TYPES.ERROR:
        content = this.getErrorContent(authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials);
        break;
      case MESSAGE_TYPES.LOADING:
        content = (<LargeLoadingSpinner open />);
        break;
      case MESSAGE_TYPES.INTEGRATION_UPDATE:
        content = (
          <LoadingContainer>
            <LoadingSpinnerStyled height={40} width={40} />
            { _.get(message, 'message') || 'Building your authorization' }
          </LoadingContainer>
        );
        break;
      case MESSAGE_TYPES.SUBMISSION_SUCCESS:
        content = (<PortalSubmitted />);
        break;
      default:
        switch (message.componentType) {
          case COMPONENT_TYPES.TABLE:
            content = (<PortalTable message={message} onSelect={sendResponse} />);
            break;
          case COMPONENT_TYPES.TEXT:
            content = (<PortalText message={message} onSelect={sendResponse} />);
            break;
          case COMPONENT_TYPES.ICD:
            content = (<PortalICD message={message} onSelect={sendResponse} authorization={authorization} />);
            break;
          case COMPONENT_TYPES.HCPCS:
            content = (<PortalHCPCS message={message} onSelect={sendResponse} authorization={authorization} />);
            break;
          case COMPONENT_TYPES.UPLOAD:
            content = (<PortalUpload message={message} onSelect={sendResponse} authorization={authorization} />);
            break;
          case COMPONENT_TYPES.COMPLETE:
            content = (<PortalComplete message={message} onSubmit={sendResponse} />);
            break;
          case COMPONENT_TYPES.FILL_TABLE:
            content = (<PortalFillTable message={message} onSubmit={sendResponse} />);
            break;
          case COMPONENT_TYPES.IMAGE_PROMPT:
            content = (<PortalImagePrompt message={message} onSelect={sendResponse} />);
            break;
          default:
            console.error('Unrecognized type on integration message', message);
            content = (
              <LoadingContainer>
                <LoadingSpinnerStyled height={40} width={40} />
                { _.get(message, 'message') || 'Loading...' }
              </LoadingContainer>
            );
        }
    }
    return content;
  };

  render() {
    const {
      latestIntegrationMessage, back, authorization, nextStep, navigateToMainList, navigateToResponse, restartIntegration, runnerId,
    } = this.props;
    const { openUserCredentialModal, closeUserSubmissionModal } = this.state;

    let content = null;
    let openUserSubmissionModal;

    if (!closeUserSubmissionModal) {
      openUserSubmissionModal = _.get(latestIntegrationMessage, 'type') === MESSAGE_TYPES.SUBMISSION_SUCCESS;
    }

    if (_.get(latestIntegrationMessage, 'type') === MESSAGE_TYPES.SUCCESS_AND_IMMEDIATE_RESPONSE) {
      navigateToResponse();
    }

    if (_.get(_.last(authorization.customPortalSteps), 'isTerminal')) {
      content = this.getTerminalStepContent(
        authorization,
        _.last(authorization.customPortalSteps),
        this.removeTerminalAndRetry,
        this.retryFromLastStep,
        back,
        () => { this.setState({ openUserCredentialModal: true }); }
      );
    } else if (latestIntegrationMessage) {
      content = this.getIntegrationMessageContent(
        authorization,
        latestIntegrationMessage,
        this.sendResponse,
        this.removeTerminalAndRetry,
        this.retryFromLastStep,
        back,
        () => { this.setState({ openUserCredentialModal: true }); }
      );
    } else if (!latestIntegrationMessage) {
      content = (
        <LoadingContainer>
          <LoadingSpinnerStyled height={40} width={40} />
          Proceeding to next step...
        </LoadingContainer>
      );
    }

    return (
      <FormContainer>
        <PortalTimeout message={latestIntegrationMessage} runnerId={runnerId} restartIntegration={restartIntegration} />
        { authorization.customPortalSteps.length > 0 && (
          <PortalPriorSteps
            authorization={authorization}
            resetToStep={this.resetToStep}
            unsetStep={this.unsetStep}
          />
        ) }
        { !authorization.submittedAt && (
          <Section section={{ title: 'Current Step' }}>
            <StepContainer>
              { content }
            </StepContainer>
          </Section>
        ) }
        { authorization.submittedAt && (
          <Section section={{ title: 'Authorization Submitted' }}>
            <PortalSubmitted />
          </Section>
        ) }
        <PortalLogin
          open={openUserCredentialModal}
          portalKey={authorization.portalKey}
          onSubmit={() => {
            this.setState({ openUserCredentialModal: false });
            restartIntegration();
          }}
          closeModal={() => { this.setState({ openUserCredentialModal: false }); }}
        />
        <FormSubmitButtons back={back} submit={nextStep} />
        { openUserSubmissionModal && (
          <Modal header="Submission Success!" open={openUserSubmissionModal} onClick={() => { this.setState({ closeUserSubmissionModal: true }); }}>
            <ModalBody>
              <CustomModalContent>
                Your portal authorization has been successfully submitted!
                SamaCare will keep track of the authorization and automatically look for a response from the payer every morning.
              </CustomModalContent>
            </ModalBody>
            <CustomModalFooter>
              <BaseButton onClick={() => { navigateToMainList(); }}>Return to Authorizations List</BaseButton>
            </CustomModalFooter>
          </Modal>
        ) }
      </FormContainer>
    );
  }
}

export default compose(withUpdateAuthorization)(withAlert(PortalSpecificSteps));
