import _ from 'lodash';
import React, { PureComponent } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { withCurrentAccount } from '../../graphql/Account';
import { withToggleNoteHidden } from '../../graphql/Note';

const TIME_STAMP_FORMAT = 'MM/DD, h a';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const NoteSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
  padding: 5px;
`;

const NoteDetails = styled.div`
  font-size: 12px;
  display: inline;
  color: ${props => props.theme.gray};
`;

const HideNoteButton = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 5px auto;
  border: 1px solid ${props => props.theme.purple};
  color: ${props => props.theme.purple};
  padding: 0 10px;
  border-radius: 3px;

  &:hover {
    font-weight: bold;
  }
`;

export class Note extends PureComponent {
  toggleNote = async (e) => {
    const { alert, note, toggleNoteHidden } = this.props;
    e.stopPropagation();

    try {
      await toggleNoteHidden({ variables: { id: note.id } });
    } catch {
      alert.error('There was an error hiding/unhiding this note');
    }
  }

  render() {
    const { note, account } = this.props;

    return (
      <Container>
        <NoteSubContainer>
          { note.note }
          <NoteDetails>
            { `${note.createdBy.firstName} ${_.first(note.createdBy.lastName)}. ` }
            {' @ '}
            { moment(note.createdAt).format(TIME_STAMP_FORMAT) }
          </NoteDetails>
        </NoteSubContainer>
        { _.get(account, 'isSamaUser') && (
          <HideNoteButton onClick={this.toggleNote}>
            { note.isHidden ? 'Unhide' : 'Hide' }
          </HideNoteButton>
        ) }
      </Container>
    );
  }
}

export default compose(withToggleNoteHidden, withCurrentAccount)(withAlert(Note));
