import _ from 'lodash';
import React, { PureComponent } from 'react';

import { BaseInputCleave, handleInputFocus, getPropsFromInput } from './BaseInput';

class PhoneInput3 extends PureComponent {
  constructor(props) {
    super(props);
    const onlyNumeric = (props.value || '').replace(/\D/g, '');
    const splitPhoneNumber = _.compact([onlyNumeric.slice(0, 3), onlyNumeric.slice(3, 6), onlyNumeric.slice(6)]);
    this.state = {
      partInputs: {
        0: {
          options: { blocks: [3], delimiter: '-', numericOnly: true },
          placeholder: '555',
          value: splitPhoneNumber[0],
        },
        1: {
          options: { blocks: [3], delimiter: '-', numericOnly: true },
          placeholder: '515',
          value: splitPhoneNumber.length > 1 ? splitPhoneNumber[1] : '',
        },
        2: {
          options: { blocks: [4], delimiter: '-', numericOnly: true },
          placeholder: '5175',
          value: splitPhoneNumber.length > 2 ? splitPhoneNumber[2] : '',
        },
      },
    };
  }

  updateValue = (target, part, partIndex) => {
    const { partInputs } = this.state;
    // update state
    this.setState({ partInputs: { ...partInputs, [partIndex]: { ...partInputs[partIndex], value: target.value } } }, () => {
      const { partInputs: updatedPartInputs } = this.state;
      // store values for reference in state callback
      handleInputFocus(target, updatedPartInputs, partIndex);
      // trigger results update
      part.onChange({ [part.id]: _.join(_.filter(_.map(updatedPartInputs, input => input.value), s => s), '-') });
    });
  }

  render() {
    const { parts } = this.props;
    const { partInputs } = this.state;

    return (
      <div>
        {
          _.map(parts, (part, i) => (
            <BaseInputCleave
              key={`phone3_input_${part.id}_${i}`}
              ref={(input) => { partInputs[i].ref = input; }}
              {...getPropsFromInput(part)}
              onChange={(e) => {
                this.updateValue(e.target, part, i);
              }}
              value={partInputs[i].value}
              options={partInputs[i].options}
              placeholder={partInputs[i].placeholder}
            />
          ))
        }
      </div>
    );
  }
}

export default PhoneInput3;
