import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const createAuthUploadFromAwsKey = gql`
  mutation createAuthUploadFromAwsKey($awsKey: String!, $title: String!, $authorizationId: Int!, $maxFileSizeMB: Int) {
    createAuthUploadFromAwsKey(awsKey: $awsKey, title: $title, authorizationId: $authorizationId, maxFileSizeMB: $maxFileSizeMB) {
      id
      awsKey
      title
    }
  }
`;

export const withCreateAuthUploadFromAwsKey = graphql(createAuthUploadFromAwsKey, { name: 'createAuthUploadFromAwsKey' });
