import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import ExpirationDateFilter from '../ExpirationDateFilter';
import LocationFilter from '../LocationFilter';
import DateOfServiceFilter from '../DateOfServiceFilter';
import AuthorizationStatusFilter from '../AuthorizationStatusFilter';

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  margin-bottom: 20px;
`;

const FilterContainer = styled.div`
  padding: 10px 20px;
  text-align: center;
  color: ${props => (props.selected ? 'black' : props.theme.lightGray)};
  font-weight: 500;
  border-bottom: 3px solid ${props => (props.selected ? props.theme.purple : 'transparent')};
  margin-right: 5px;

  &:hover {
    cursor: pointer;
    color: black;
    font-weight: 500;
    border-bottom: 3px solid ${props => props.theme.purple};
  }
`;

const FilterRightContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const DateOfServiceAndAuthStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 8px;
`;

class ListFilter extends PureComponent {
  render() {
    const { onSelect, tabs, selected = {}, onChange, hideFilters } = this.props;

    return (
      <Container>
        { _.map(tabs, tab => (
          <FilterContainer
            key={tab.tabTitle}
            onClick={() => { onSelect(tab); }}
            selected={tab.tabTitle === _.get(selected, 'tabTitle')}
          >
            { tab.tabTitle }
          </FilterContainer>
        )) }
        { !hideFilters && (
          <FilterRightContainer>
            { selected.shouldShowLocationFilter && <LocationFilter onChange={onChange} /> }
            { selected.onlyExpired && (<div style={{ width: '10px' }} />)}
            { selected.onlyExpired && (<ExpirationDateFilter onChange={onChange} />)}
            { !selected.onlyExpired && (
              <DateOfServiceAndAuthStatusContainer>
                <DateOfServiceFilter onChange={onChange} />
              </DateOfServiceAndAuthStatusContainer>
            ) }
            { !selected.onlyExpired && (
              <DateOfServiceAndAuthStatusContainer>
                <AuthorizationStatusFilter onChange={onChange} />
              </DateOfServiceAndAuthStatusContainer>
            ) }
          </FilterRightContainer>
        ) }
      </Container>
    );
  }
}

export default ListFilter;
