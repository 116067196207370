import gql from 'graphql-tag';
import _ from 'lodash';
import { graphql } from 'react-apollo';

import noteInfo from './fragments/noteInfo';
import { AUTHORIZATION_PAGINATION_QUERY_NAME, GET_AUTHORIZATION_QUERY_NAME } from './Authorization';
import { withDefaultProps } from './utils';

const AUTHORIZATION_QUERY_NAME = 'getAuthorizationNotes';
export const withAuthorizationNotesQuery = gql`
  query ${AUTHORIZATION_QUERY_NAME}($authorizationId: Int!) {
    getAuthorizationNotes(authorizationId: $authorizationId) {
      ...noteInfo
    }
  }
  ${noteInfo}
`;

export const withAuthorizationNotes = graphql(
  withAuthorizationNotesQuery,
  {
    options: props => ({
      variables: { authorizationId: props.authorization.id },
    }),
    props: withDefaultProps(({ data }) => ({
      authorizationNotes: _.get(data, 'getAuthorizationNotes'),
    })),
  }
);


export const withCreateAuthorizationNote = graphql(
  gql`
    mutation createAuthorizationNote($note: String!, $authorizationId: Int!) {
      createAuthorizationNote(note: $note, authorizationId: $authorizationId) {
        ...noteInfo
      }
    }
    ${noteInfo}
  `,
  {
    name: 'createAuthorizationNote',
    options: () => ({ refetchQueries: () => [AUTHORIZATION_QUERY_NAME, AUTHORIZATION_PAGINATION_QUERY_NAME, GET_AUTHORIZATION_QUERY_NAME] }),
  }
);

export const withCreateReviewNote = graphql(
  gql`
    mutation createReviewNote($authorizationId: Int!) {
      createReviewNote(authorizationId: $authorizationId) {
        ...noteInfo
      }
    }
    ${noteInfo}
  `,
  {
    name: 'createReviewNote',
  }
);

export const withToggleNoteHidden = graphql(
  gql`
    mutation toggleNoteHidden($id: Int!) {
      toggleNoteHidden(id: $id) {
        ...noteInfo
      }
    }
    ${noteInfo}
  `,
  { name: 'toggleNoteHidden' },
);
