import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withSupportedAuthorizationFollowUps, withResolveSupportedFollowUp } from '../../../graphql/SupportedAuthorizationFollowUp';
import BaseButton from '../../../components/BaseButton';
import Modal from '../../../components/Modal';
import { ModalFooter } from '../../../components/ModalStyledComponents';
import AuthorizationListManager from '../../Authorizations/AuthorizationListManager';
import { BaseText } from '../../../components/Segment/StyledComponents';

const Container = styled.div`
  width: 1500px;
  margin-left: 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
  font-weight: bold;
`;

const ScrollContainer = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  height: ${props => props.height || '450px'};
  min-width: 100px;
  overflow: scroll;
`;

const ModalBody = styled.div`
  width: 650px;
  height: 450px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const DecisionButton = styled(BaseButton)`
  color: ${props => props.isSelected ? 'white' : props.theme.purple};
  background-color: ${props => props.isSelected ? props.theme.purple : 'white'};
  text-align: center;
  margin: 3px 0;
  flex: 1;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

class SupportedFollowUpsDashboard extends PureComponent {
  state = { followUpToResolveId: null, resolvedStatus: '', resolveDetails: '' }

  resolve = async () => {
    const { resolveSupportedFollowUp, alert } = this.props;
    const { followUpToResolveId, resolvedStatus, resolveDetails } = this.state;

    if (!resolvedStatus) {
      alert.error('Expected a resolution status');
    }

    try {
      await resolveSupportedFollowUp({
        variables: { id: followUpToResolveId, status: resolvedStatus, details: resolveDetails },
      });
      this.setState({ followUpToResolveId: null, resolvedStatus: '', resolveDetails: '' });
      alert.info('Success');
    } catch {
      alert.error('There was an error');
    }
  }

  render() {
    const { data, insuranceCompanies } = this.props;
    const { followUpToResolveId, resolvedStatus, resolveDetails } = this.state;

    const followUps = _.get(data, 'getUnresolvedFollowUps', []);
    const authorizations = _.sortBy(_.map(followUps, 'authorization'), ['InsuranceCompanyId', 'InstitutionId']);

    return (
      <Container>
        <TitleContainer>Support Follow Ups</TitleContainer>
        <ScrollContainer height="750px">
          <AuthorizationListManager
            minWidth="600px"
            authorizations={authorizations}
            insuranceCompanies={insuranceCompanies}
            alwaysOpenInBlank
            emptyStateText="None Found"
            tableConfig={{
              headers: ['Patient', 'Status', 'Details'],
              columns: [
                'stackedPatient',
                'status',
                'authorizationDetails',
                { type: 'customButton',
                  text: 'Resolve',
                  onClick: ({ id }) => {
                    this.setState({ followUpToResolveId: _.find(followUps, { AuthorizationId: parseInt(id, 10) }).id });
                  },
                },
              ],
            }}
          />
        </ScrollContainer>
        <Modal
          open={!!followUpToResolveId}
          header="Select Resolution Status"
          onClick={() => { this.setState({ followUpToResolveId: null, resolvedStatus: '' }); }}
        >
          <ModalBody>
            <ModalButtonContainer>
              { _.map(
                _.without(
                  _.values(CONFIG.CONSTANTS.SUPPORT_AUTHORIZATION_FOLLOWUP_STATUS),
                  CONFIG.CONSTANTS.SUPPORT_AUTHORIZATION_FOLLOWUP_STATUS.UNRESOLVED
                ),
                status => (
                  <DecisionButton
                    onClick={() => { this.setState({ resolvedStatus: status }); }}
                    isSelected={status === resolvedStatus}
                  >
                    { status }
                  </DecisionButton>
                )
              ) }
            </ModalButtonContainer>
            <div style={{ margin: '10px 0', textAlign: 'center' }}>
              User facing details (leaving this blank is okay)
            </div>
            <BaseText
              value={resolveDetails || ''}
              onChange={(e) => { this.setState({ resolveDetails: e.target.value }); }}
              placeholder="User facing note"
              style={{ marginLeft: 0 }}
            />
          </ModalBody>
          <CustomModalFooter>
            <BaseButton disabled={!resolvedStatus} onClick={this.resolve}>Resolve</BaseButton>
          </CustomModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default compose(withSupportedAuthorizationFollowUps, withResolveSupportedFollowUp)(withAlert(SupportedFollowUpsDashboard));
