import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withRunStatusChecker } from '../../graphql/StatusChecker';
import Modal from '../../components/Modal';
import LoadingButton from '../../components/LoadingButton';
import UserInput from './UserInput';

const UserInputBody = styled.div`
  margin: 10px 0;
`;

const TextBody = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const STATUSES = CONFIG.CONSTANTS.STATUS_CHECKER_STATUSES;
export class CheckAuthorizationStatus extends PureComponent {
  state = { isNew: true, currentMessage: null, responses: {}, loading: false }

  sendStatusCheckerMessage = async () => {
    const { runStatusChecker, location, alert } = this.props;
    const { responses, isNew } = this.state;

    const params = new URLSearchParams(location.search);

    this.setState({ loading: true });

    try {
      const response = await runStatusChecker({
        variables: {
          accountId: params.get('accountId'),
          key: params.get('key'),
          responses,
          isNewRunner: isNew,
        },
      });
      this.setState({ currentMessage: _.get(response, 'data.runStatusChecker') });
    } catch (e) {
      alert.error('There was an error checking your authorization status, please reach out to SamaCare via the chat button at the bottom right');
    } finally {
      this.setState({ loading: false, isNew: false });
    }
  }

  setResponse = (key, val) => {
    const { responses } = this.state;
    this.setState({ responses: { ...responses, [key]: val } });
  }

  getModalContent = () => {
    const { currentMessage, responses, loading } = this.state;

    if (_.get(currentMessage, 'status') === STATUSES.SUCCESS) {
      return (
        <div>
          <TextBody>{ currentMessage.text }</TextBody>
        </div>
      );
    }

    if (_.get(currentMessage, 'status') === STATUSES.ERROR) {
      return (
        <div>
          <TextBody>There is an error</TextBody>
          <div>{ currentMessage.text }</div>
        </div>
      );
    }

    if (_.get(currentMessage, 'status') === STATUSES.PROCESSING) {
      return (
        <div>
          <TextBody>{ currentMessage.text }</TextBody>
          <UserInputBody>
            {
              _.map(currentMessage.userInputs, input => (
                <UserInput input={input} onChange={this.setResponse} value={responses[input.key]} />
              ))
            }
          </UserInputBody>
          <LoadingButton style={{ width: '100%' }} loading={loading} onClick={this.sendStatusCheckerMessage}>
            Submit
          </LoadingButton>
        </div>
      );
    }

    return (
      <div>
        <TextBody>
          Hello, you are here because when checking on the status of your authorizations there was an error that required your input. This happens
          when you password expires or when your payer requires multi-factor authentication. Please bear with us, and checking the status of your
          authorization should take less than a minute
        </TextBody>
        <LoadingButton style={{ width: '100%' }} loading={loading} onClick={this.sendStatusCheckerMessage}>
          Begin Checking Authorizations
        </LoadingButton>
      </div>
    );
  }

  render() {
    return (
      <Modal
        open
        header="Check Authorization Statuses"
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}

export default compose(withRunStatusChecker)(withAlert(CheckAuthorizationStatus));
