import _ from 'lodash';
import React, { Component } from 'react';

import CheckInput from './InputTypes/CheckInput';
import DateInput from './InputTypes/DateInput';
import DateInput3 from './InputTypes/DateInput3';
import EmailInput from './InputTypes/EmailInput';
import ImmutableInput from './InputTypes/ImmutableInput';
import PhoneInput from './InputTypes/PhoneInput';
import PhoneInput2 from './InputTypes/PhoneInput2';
import PhoneInput3 from './InputTypes/PhoneInput3';
import RadioGroup from './InputTypes/RadioGroupInput';
import TextInput from './InputTypes/TextInput';
import TextMultilineInput from './InputTypes/TextMultilineInput';
import ZipInput from './InputTypes/ZipInput';
import ZipInput2 from './InputTypes/ZipInput2';
import { isImmutableInput } from '../../util/inputConfigurationManager';

const types = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;
class InputManager extends Component {
  componentDidMount() {
    const { formattedInput, setRequired } = this.props;
    if (formattedInput.importance === 'required' && !isImmutableInput(formattedInput)) {
      setRequired({ id: formattedInput.id, samaTypes: formattedInput.samaTypes });
    }
  }

  componentWillUnmount() {
    const { formattedInput, unsetRequired } = this.props;
    if (formattedInput.importance === 'required') {
      unsetRequired({ id: formattedInput.id, samaTypes: formattedInput.samaTypes });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { formattedInput } = this.props;
    // If display hidden changes
    if (formattedInput.isDisplayHidden !== nextProps.formattedInput.isDisplayHidden) { return true; }
    // If value changes
    return !_.isEqual(formattedInput.value, nextProps.formattedInput.value);
  }

  getInputRenderer() {
    const { inputChildren, siblings, formattedInput } = this.props;
    if (isImmutableInput(formattedInput)) {
      return (<ImmutableInput input={formattedInput} />);
    }

    switch (formattedInput.type) {
      case types.TEXT_MULTILINE.key:
        return (<TextMultilineInput input={formattedInput} />);
      case types.DATE.key:
        return (<DateInput input={formattedInput} />);
      case types.DATE3.key:
        if (formattedInput.siblingId !== 0) return null;
        return (<DateInput3 value={formattedInput.value} parts={siblings} />);
      case types.EMAIL.key:
        return (<EmailInput input={formattedInput} />);
      case types.NUMBER.key:
        return (<TextInput input={formattedInput} number />);
      case types.PHONE.key:
        return (<PhoneInput input={formattedInput} />);
      case types.PHONE2.key:
        if (formattedInput.siblingId !== 0) return null;
        return (<PhoneInput2 value={formattedInput.value} parts={siblings} />);
      case types.PHONE3.key:
        if (formattedInput.siblingId !== 0) return null;
        return (<PhoneInput3 value={formattedInput.value} parts={siblings} />);
      case types.RADIO.key:
      case types.RADIO_CIRCLE.key:
        return null;
      case types.RADIO_GROUP.key:
        return (
          <RadioGroup
            onChange={formattedInput.onChange}
            radioGroupId={formattedInput.id}
            background={formattedInput.background}
            radioButtons={inputChildren}
          />
        );
      case types.CHECK.key:
      case types.CHECK_CIRCLE.key:
        return (<CheckInput input={formattedInput} />);
      case types.ZIP.key:
        return (<ZipInput input={formattedInput} />);
      case types.ZIP2.key:
        if (formattedInput.siblingId !== 0) return null;
        return (<ZipInput2 value={formattedInput.value} parts={siblings} />);
      default:
        return (<TextInput input={formattedInput} />);
    }
  }

  render() {
    return this.getInputRenderer();
  }
}

export default InputManager;
