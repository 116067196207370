import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import mouseTrap from 'react-mousetrap';
import Axios from 'axios';

import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Tagger from './Tagger';
import InputHierarchy from './InputHierarchy';
import InputSpecification from './InputSpecification';
import TaggerHeader from './TaggerHeader';
import { moveBox, moveAllBoxes, flattenInputs, updateFlatInput, addChild, moveNode, deleteNode, deleteBox, generateInput, cloneNode } from './taggerUtils';
import { withCurrentAccount } from '../../graphql/Account';
import { PDFEditor } from '../../components/PDFEditor';
import TestFormModal from './TestFormModal';
import generateFakePatientConfig from '../../util/generateFakePatientConfig';

const Container = styled.div`
  display: flex;
  height: calc(100% - 50px);
  margin: 10px;
  flex-direction: column;
`;

const WarningText = styled.div`
  color: ${props => props.theme.red};
  text-align: center;
`;
const TaggerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex: 1;
`;

const DISPLAY_BY_OPTIONS = [
  { label: 'Type', value: 'type' },
  { label: 'Sama Type', value: 'samaType' },
  { label: 'Box Index', value: 'boxIndex' },
  { label: 'Character Box Count', value: 'charBoxes' },
  { label: 'Parent Id', value: 'parentId' },
];

const defaultFields = CONFIG.DEFAULT_FIELDS;
const taggerTypes = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;
const REQUIRED_SAMA_TYPES = [
  // using a nested array for OR logic
  defaultFields.PATIENT_FIRST_NAME.key,
  defaultFields.PATIENT_LAST_NAME.key,
  defaultFields.PATIENT_DOB.key,
  defaultFields.PATIENT_MEMBER_ID.key,
  [defaultFields.ICD_0.key, defaultFields.ALL_ICDS.key],
  [defaultFields.HCPCS_0.key, defaultFields.ALL_HCPCS.key],
  defaultFields.PRESCRIBER_NPI.key,
  defaultFields.PRESCRIBER_TIN.key,
  defaultFields.PRESCRIBER_FIRST_NAME.key,
  defaultFields.PRESCRIBER_LAST_NAME.key,
];

export const MULTIBOX_TYPES = [
  taggerTypes.DATE3.key,
  taggerTypes.PHONE2.key,
  taggerTypes.PHONE3.key,
  taggerTypes.ZIP2.key,
];

export class PDFTagger extends PureComponent {
  state = {
    clipboardId: '',
    displayBy: DISPLAY_BY_OPTIONS[0],
    pages: [],
    loading: false,
    displayTagger: true,
    addBox: false,
    currentPage: 0,
    lastCreatedType: '',
    selectedId: '',
    selectedBoxIndex: null,
    inputs: [],
    // Test view specific fields
    showResultsModal: false,
    testViewResults: generateFakePatientConfig(),
    testViewTaggedHeight: 0,
    imageContainer: null,
  };

  hierarchyRef = null;

  componentDidMount() {
    const { bindShortcut } = this.props;

    bindShortcut(['del', 'backspace'], this.deleteSelectedBox);
    bindShortcut(['command+del', 'ctrl+backspace', 'ctrl+del', 'command+backspace'], this.deleteSelectedInput);
    bindShortcut(['up', 'down', 'left', 'right'], this.moveSelectedBox);
    bindShortcut(['ctrl+c', 'command+c'], this.copyToClipboard);
    bindShortcut(['ctrl+v', 'command+v'], this.pasteFromClipboard);

    window.onbeforeunload = () => ('Warning! Leaving will erase all progress');
    this.clearInterval = setInterval(() => {
      Axios.get(`${window.location.origin}/healthcheck`);
    }, 10 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.clearInterval);
  }

  moveAllBoxes = (shift) => {
    const { inputs, currentPage } = this.state;
    this.setState({ inputs: moveAllBoxes(inputs, currentPage, shift) });
  }

  moveSelectedBox = (e) => {
    const { selectedBoxIndex, selectedId, inputs } = this.state;

    if (selectedBoxIndex !== null && selectedId) {
      e.stopPropagation();
      e.preventDefault();

      switch (e.code) {
        case 'ArrowUp':
          this.setState({ inputs: moveBox(inputs, selectedId, selectedBoxIndex, { y: -1, x: 0 }) });
          break;
        case 'ArrowDown':
          this.setState({ inputs: moveBox(inputs, selectedId, selectedBoxIndex, { y: 1, x: 0 }) });
          break;
        case 'ArrowLeft':
          this.setState({ inputs: moveBox(inputs, selectedId, selectedBoxIndex, { y: 0, x: -1 }) });
          break;
        case 'ArrowRight':
          this.setState({ inputs: moveBox(inputs, selectedId, selectedBoxIndex, { y: 0, x: 1 }) });
          break;
        default:
          break;
      }
    }
  }

  createInput = (toCreate = {}) => {
    const { inputs, lastCreatedType } = this.state;

    const newInput = generateInput({ ...toCreate, type: toCreate.type || lastCreatedType });

    this.setState({ inputs: inputs.concat(newInput) });
    this.setSelected(newInput.id, 0);
  }

  updateFlatInput = (id, newInput, options = { selectedBoxIndex: null }) => {
    const { inputs, selectedBoxIndex } = this.state;

    this.setState({
      inputs: updateFlatInput(inputs, newInput, id),
      lastCreatedType: (newInput.type || ''),
      selectedBoxIndex: _.isNumber(options.selectedBoxIndex) ? options.selectedBoxIndex : selectedBoxIndex,
      addBox: _.includes(MULTIBOX_TYPES, newInput.type),
    });
  }

  addChild = (rootNodeId, child) => {
    const { inputs, lastCreatedType } = this.state;
    const lastCreatedSpread = lastCreatedType ? { type: lastCreatedType } : {};
    const newInput = generateInput({ ...child, ...lastCreatedSpread });
    this.setState({ inputs: addChild(inputs, rootNodeId, newInput) });
    this.setSelected(newInput.id, 0);
  }

  createInputAtHierarchy = (toCreate, parentId) => {
    const { lastCreatedType } = this.state;

    if (!parentId) {
      this.createInput(toCreate);
    } else {
      const newInput = generateInput({ ...toCreate, type: toCreate.type || lastCreatedType });
      this.addChild(parentId, newInput);
    }
  }

  copyToClipboard = () => {
    const { selectedId } = this.state;
    this.setState({ clipboardId: selectedId });
  }

  pasteFromClipboard = () => {
    const { clipboardId, inputs } = this.state;
    const { alert } = this.props;
    if (clipboardId) {
      const newNode = cloneNode(inputs, clipboardId);
      this.setState({ inputs: inputs.concat(newNode) });
      this.setSelected(newNode.id, 0);
    } else {
      alert.error('Clipboard is empty');
    }
  }

  deleteInput = (toDeleteId) => {
    const { inputs } = this.state;
    this.setState({ inputs: deleteNode(inputs, toDeleteId) });
  }

  deleteSelectedBox = () => {
    const { inputs, selectedId, selectedBoxIndex } = this.state;

    if (selectedId && _.isNumber(selectedBoxIndex)) {
      this.setState({ inputs: deleteBox(inputs, selectedId, selectedBoxIndex), selectedBoxIndex: null });
    }
  }

  deleteSelectedInput = () => {
    const { inputs, selectedId } = this.state;

    if (selectedId) {
      this.setState({ inputs: deleteNode(inputs, selectedId) });
      this.setSelected(null, null);
    }
  }

  setAddBox = (isAdding) => {
    this.setState({ addBox: isAdding });
  }

  moveNode = (toMoveId, newParentId) => {
    const { inputs } = this.state;

    this.setState({ inputs: moveNode(inputs, toMoveId, newParentId) });
  }

  setImageRef = (ref) => {
    this.setState({ imageContainer: ref });
  }

  set = (toSet) => {
    const { testViewResults } = this.state;
    this.setState({ testViewResults: { ...testViewResults, ...toSet } });
  }

  setSelected = (selectedId, selectedBoxIndex = 0) => {
    const { inputs } = this.state;

    const input = _.find(inputs, { id: selectedId });
    if (input) {
      this.setState({ selectedId, selectedBoxIndex, addBox: _.includes(MULTIBOX_TYPES, input.type) });
    } else {
      this.setState({ selectedId, selectedBoxIndex, addBox: false });
    }
  }

  getErrorMessage = () => {
    // Not sure where these numbers come from, but seems to work
    // Stolen from https://stackoverflow.com/questions/1713771/how-to-detect-page-zoom-level-in-all-modern-browsers
    let errorMessage = '';
    const screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
    const isZoomed = (screenCssPixelRatio < 0.98 || screenCssPixelRatio > 1.02);

    if (isZoomed) {
      errorMessage += 'Please set your browser zoom to 100%, please reset your browser zoom to 100%. ';
    }

    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = window.opr !== undefined;
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1;

    if (!(isChromium && vendorName === 'Google Inc.' && !isOpera && !isIEedge)) {
      errorMessage += 'This app is only designed to work in Chrome';
    }

    return errorMessage;
  }

  render() {
    const {
      inputs,
      pages,
      currentPage,
      loading,
      selectedId,
      displayBy,
      selectedBoxIndex,
      addBox,
      displayTagger,
      showResultsModal,
      testViewResults,
      testViewTaggedWidth,
      testViewTaggedHeight,
      imageContainer,
    } = this.state;

    const taggerInputs = flattenInputs(inputs);
    let hierarchyWidth = 100;
    if (this.hierarchyRef) {
      hierarchyWidth = this.hierarchyRef.getBoundingClientRect().width;
    }
    return (
      <Container>
        { this.getErrorMessage() && (<WarningText>{this.getErrorMessage()}</WarningText>) }
        <TaggerHeader
          setNewInputs={(newInputs) => { this.setState({ inputs: newInputs }); }}
          setLoading={(isLoading) => { this.setState({ loading: isLoading }); }}
          newUpload={(_pages) => { this.setState({ pages: _pages, currentPage: 0, inputs: [] }); }}
          setNewImage={(_pages) => { this.setState({ pages: _pages, currentPage: 0 }); }}
          step={(isForward) => { this.setState({ currentPage: currentPage + (isForward ? 1 : -1) }); }}
          currentPage={currentPage}
          pageLength={pages.length}
          displayByOptions={DISPLAY_BY_OPTIONS}
          displayBy={displayBy}
          setDisplayBy={(_displayBy) => { this.setState({ displayBy: _displayBy }); }}
          inputs={inputs}
          imageContainer={imageContainer}
          isTestView={!displayTagger}
          toggleTestView={(isTestView) => {
            if (isTestView) {
              this.setState({
                showResultsModal: true,
                testViewTaggedWidth: imageContainer.getBoundingClientRect().width,
                testViewTaggedHeight: imageContainer.getBoundingClientRect().height,
              });
            } else {
              this.setState({ displayTagger: true });
            }
          }}
        />
        { displayTagger && (pages.length > 0) && (
          <TaggerContainer>
            <Tagger
              inputs={taggerInputs}
              currentPage={pages[currentPage]}
              selectedId={selectedId}
              setSelected={(_selectedId, boxId) => { this.setSelected(_selectedId, boxId); }}
              updateInput={this.updateFlatInput}
              createInput={this.createInputAtHierarchy}
              displayBy={displayBy}
              selectedBoxIndex={selectedBoxIndex}
              addBox={addBox}
              setDimensions={this.setDimensions}
              currentPageNumber={currentPage}
              setImageRef={this.setImageRef}
            />
            <Column ref={(ref) => { this.hierarchyRef = ref; }}>
              <InputHierarchy
                inputs={inputs}
                selectedId={selectedId}
                moveNode={this.moveNode}
                updateInput={this.updateFlatInput}
                setSelected={(_selectedId, boxId) => { this.setSelected(_selectedId, boxId); }}
                deleteInput={this.deleteInput}
                selectedBoxIndex={selectedBoxIndex}
                width={hierarchyWidth}
              />
            </Column>
            <Column>
              <InputSpecification
                input={_.find(taggerInputs, input => input.id === selectedId)}
                updateInput={this.updateFlatInput}
                addChild={this.addChild}
                setAddBox={this.setAddBox}
                addBoxActive={addBox}
                selectedBoxIndex={selectedBoxIndex}
                addRootInput={() => { this.createInput({ type: CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.RADIO_GROUP.key }); }}
                moveToRoot={(id) => { this.moveNode(id, null); }}
                moveAllBoxes={this.moveAllBoxes}
              />
            </Column>
          </TaggerContainer>
        ) }
        { !displayTagger && (
          <PDFEditor
            results={testViewResults}
            form={{
              imgURLs: pages,
              taggedWidth: testViewTaggedWidth,
              taggedHeight: testViewTaggedHeight,
              specification: inputs,
            }}
            set={(toSet) => { this.setState(prevState => ({ testViewResults: { ...prevState.testViewResults, ...toSet } })); }}
            loading={false}
            setRequired={() => {}}
            unsetRequired={() => {}}
            onBack={() => {}}
            onNext={() => {}}
            authorization={{}}
            requiredSamaTypes={REQUIRED_SAMA_TYPES}
            isTagger
          />
        )}
        <TestFormModal
          open={showResultsModal}
          closeModal={() => { this.setState({ showResultsModal: false }); }}
          renderTestAuth={() => { this.setState({ showResultsModal: false, displayTagger: false }); }}
          set={this.set}
          code={testViewResults[defaultFields.HCPCS_0.key]}
          drugName={testViewResults[defaultFields.PRIMARY_DRUG_NAME.key]}
          dose={testViewResults[defaultFields.PRIMARY_DOSE.key]}
          urgentRequest={testViewResults[defaultFields.URGENT_REQUEST.key]}
          initiationRequest={testViewResults[defaultFields.INITIATION_REQUEST.key]}
          specification={inputs}
        />
        <LoadingSpinner open={loading} />
      </Container>
    );
  }
}

export default compose(withCurrentAccount)(DragDropContext(HTML5Backend)(withAlert(mouseTrap(PDFTagger))));
