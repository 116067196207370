import React from 'react';
import styled from 'styled-components';
import CloseIcon from 'react-icons/lib/md/close';

import Backdrop from './Backdrop';

const ModalContainer = styled.div`
  z-index: ${props => props.theme.zTop};
  background-color: white;
  padding: 30px 20px;
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ModalHeader = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: ${props => props.theme.purple};
`;

const ModalBody = styled.div`
  width: 100%;
`;

const CloseModal = styled(CloseIcon)`
  position: absolute;
  height: 25px;
  width: 20px;
  right: 15px;
  top: 15px;
  color: ${props => props.theme.purple};
  cursor: pointer;
`;

const Modal = ({ children, header, open, onClick, styleOverride }) => {
  if (open) {
    return (
      <div onClick={(e) => { e.stopPropagation(); }}>
        <Backdrop onClick={(e) => { e.stopPropagation(); if (onClick) { onClick(e); } }} />
        <ModalContainer style={styleOverride}>
          { onClick && (<CloseModal onClick={(e) => { e.stopPropagation(); onClick(e); }} />) }
          <ModalBody>
            <ModalHeader>{header}</ModalHeader>
            { children }
          </ModalBody>
        </ModalContainer>
      </div>
    );
  }
  return (<div />);
};

export default Modal;
