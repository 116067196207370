import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import Upload from '../../Upload';
import BaseButton from '../../BaseButton';
import AlertModal from '../../AlertModal';
import ListFilter from '../../MainList/ListFilter';
import CorrespondenceViewer from '../../CorrespondenceViewer';
import AutoAssociatedModal from '../../AutoAssociatedModal';
import FollowUpModal from '../../FollowUpModal';
import { withCreateAuthorizationCorrespondence, withDeleteCorrespondence } from '../../../graphql/AuthorizationCorrespondence';
import { withUndoAutoAssociatedResponse } from '../../../graphql/Authorization';
import { withCurrentAccount } from '../../../graphql/Account';
import { withCreateReviewNote } from '../../../graphql/Note';
import hasActionableFollowUp from '../../../util/hasActionableFollowUp';

const { DATE_FORMAT } = CONFIG.CONSTANTS;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.purple};
  border-bottom: 1px solid #CCCCCC;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
`;

const SectionDescription = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
`;

const EditResponses = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CautionText = styled.div`
  color: #ff5555;
`;

const IncorrectAssociationHelper = styled.div`
  background-color: ${props => props.theme.lightPurple};
  padding: 20px;
`;

const TriggerIncorrectAssociationText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.purple};
  font-weight: 500;
`;

export class ResponseSection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFormId: _.get(_.find(props.authorization.correspondences, { isLatest: true }), 'id'),
      showModal: false,
      showIncorrectAssociationModal: false,
      showReviewAssociationModal: props.authorization.requiresAssociationReview,
      followUpModalIsOpen: hasActionableFollowUp(props.authorization),
    };
  }

  componentDidMount = async () => {
    const { authorization, createReviewNote } = this.props;
    if (authorization.displayReviewWarning) {
      await createReviewNote({ variables: { authorizationId: authorization.id } });
    }
  }

  deleteCurrentCorrespondence = async () => {
    const { selectedFormId } = this.state;
    const { deleteCorrespondence, alert, authorization } = this.props;
    try {
      await deleteCorrespondence({ variables: { id: selectedFormId } });
      alert.info('Successfully deleted correspondence');
      this.setState({ showModal: false, selectedFormId: _.get(_.find(authorization.correspondences, { isLatest: true }), 'id') });
    } catch (e) {
      alert.error('There was an error in deleting the correspondence');
    }
  }

  onDropSuccess = async (__, res) => {
    const { createAuthorizationCorrespondence, authorization, alert } = this.props;
    try {
      await createAuthorizationCorrespondence({
        variables: {
          authorizationId: authorization.id,
          fileId: res.data.id,
          type: CONFIG.CONSTANTS.AUTHORIZATION_CORRESPONDENCE_TYPES.RESPONSE,
        },
      });
      this.setState({ selectedFormId: _.get(_.find(authorization.correspondences, { isLatest: true }), 'id') });
      alert.info('Successfully uploaded new correspondence');
    } catch (e) {
      alert.error('There was an error uploading the document.');
    }
  }

  onDropError = () => {
    const { alert } = this.props;
    alert.error('There was an error uploading the document.');
  }

  getCorrespondences = () => {
    const { authorization } = this.props;
    return _.reject(_.sortBy(authorization.correspondences, ['isLatest', 'createdAt']), { isLatest: false, fileURL: null });
  }

  getCurrentCorrespondence = (correspondences) => {
    const { selectedFormId } = this.state;
    return _.find(correspondences, { id: selectedFormId }) || _.find(correspondences, { isLatest: true });
  }

  undoAutoAssociatedResponse = async () => {
    const { undoAutoAssociatedResponse, authorization, alert } = this.props;

    try {
      await undoAutoAssociatedResponse({ variables: { authorizationId: authorization.id, isAutoAssociation: false } });
      alert.info('The SamaCare team has been notified.');
    } catch {
      alert.error('There was an error reporting this error, please reach out directly');
    } finally {
      this.setState({ showIncorrectAssociationModal: false });
    }
  }

  render() {
    const {
      selectedFormId,
      showModal,
      showIncorrectAssociationModal,
      showReviewAssociationModal,
      followUpModalIsOpen,
    } = this.state;
    const { authorization, back } = this.props;
    const correspondences = this.getCorrespondences();
    const currentCorrespondence = this.getCurrentCorrespondence(correspondences);
    const tabs = correspondences.map(({ createdAt, id }, i) => ({
      title: `${moment(createdAt).format(DATE_FORMAT)} (${i + 1} of ${correspondences.length})`,
      tabTitle: `${moment(createdAt).format(DATE_FORMAT)} (${i + 1} of ${correspondences.length})`,
      id,
    }));

    return (
      <FormContainer>
        { _.size(correspondences) > 1
        && (
          <ListFilter
            onSelect={(selection) => {
              this.setState({ selectedFormId: selection.id });
            }}
            selected={_.find(tabs, { id: selectedFormId })}
            tabs={tabs}
            hideFilters
          />
        )}
        { _.get(currentCorrespondence, 'fileURL') ? (
          <CorrespondenceViewer correspondence={currentCorrespondence} height="800px" width="100%" />
        ) : (
          <div>
            {_.isEmpty(correspondences)
              ? 'There are no responses associated with this authorization'
              : `This response was created without an associated file.
                  This happens when a response is received over the phone or if the user hasn't uploaded the associated form for this response.
                  You can view the notes in the main list to find more details on the response or add a form below.
                `}
          </div>
        )}
        {
          (_.includes(
            [
              ...CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
              ...CONFIG.CONSTANTS.BI_STATUSES_ALLOWED_ON_UPDATE,
            ],
            authorization.status,
          ))
        && (
          <div>
            {
              _.get(currentCorrespondence, 'isLatest') && _.get(currentCorrespondence, 'fileURL') && (
                <IncorrectAssociationHelper>
                  { 'Is this response attached to the wrong place? ' }
                  <TriggerIncorrectAssociationText onClick={() => { this.setState({ showIncorrectAssociationModal: true }); }}>
                    Click here
                  </TriggerIncorrectAssociationText>
                  { ' to have SamaCare resolve this issue.' }
                </IncorrectAssociationHelper>
              )
            }
            <div>
              <SectionTitle>Modify insurance responses</SectionTitle>
              <SectionDescription>
                Upload additional insurance responses for this authorization, or delete responses that have previously been associated.
              </SectionDescription>
            </div>
            <EditResponses>
              <Upload onDropSuccess={this.onDropSuccess} onDropError={this.onDropError} />
              { !_.get(currentCorrespondence, 'isLatest')
              && (
                <BaseButton
                  style={{ width: '300px', height: '50px' }}
                  color="red"
                  key="deleteCorrespondenceModal"
                  onClick={() => { this.setState({ showModal: true }); }}
                >
                  Delete
                </BaseButton>
              )}
            </EditResponses>
          </div>
        )}
        <FormSubmitButtons back={back} />
        {
          <AlertModal
            header="Confirmation Delete"
            open={showModal}
            closeModal={() => { this.setState({ showModal: false }); }}
            content={(
              <CautionText>
                This will permanently delete this message from this authorization. This cannot be undone.
              </CautionText>
            )}
            buttons={(
              <BaseButton
                style={{ width: '100%' }}
                key="deleteCorrespondenceButton"
                onClick={this.deleteCurrentCorrespondence}
              >
                I am sure
              </BaseButton>
              )}
          />
        }
        {
          <AlertModal
            header="Confirm Incorrect Response"
            open={showIncorrectAssociationModal}
            closeModal={() => { this.setState({ showIncorrectAssociationModal: false }); }}
            content={(
              <CautionText>
                By clicking below, you will send this response back to SamaCare and set this authorization back to the pending status
              </CautionText>
            )}
            buttons={(
              <BaseButton
                style={{ width: '100%' }}
                key="undoAutoAssociatedResponse"
                onClick={this.undoAutoAssociatedResponse}
              >
                I am sure
              </BaseButton>
              )}
          />
        }
        { showReviewAssociationModal && (
          <AutoAssociatedModal authorization={authorization} closeModal={() => this.setState({ showReviewAssociationModal: false })} />
        ) }
        { followUpModalIsOpen && (
          <FollowUpModal
            redirect={() => { this.setState({ followUpModalIsOpen: false }); }}
            followUpId={authorization.followUp.id}
            correspondence={authorization.latestCorrespondence}
            onClose={() => { this.setState({ followUpModalIsOpen: false }); }}
          />
        ) }
      </FormContainer>
    );
  }
}

export default compose(
  withCreateAuthorizationCorrespondence,
  withDeleteCorrespondence,
  withCurrentAccount,
  withCreateReviewNote,
  withUndoAutoAssociatedResponse,
)(withAlert(ResponseSection));
