import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withDefaultProps } from './utils';

import authorizationErrorInfo from './fragments/authorizationErrorInfo';

export const withCreateAuthorizationError = graphql(
  gql`
    mutation createAuthorizationError($authorizationId: Int!, $type: String!, $message: String) {
      createAuthorizationError(authorizationId: $authorizationId, type: $type, message: $message) {
        id
      }
    }
  `,
  {
    name: 'createAuthorizationError',
  }
);
export const AUTHORIZATION_ERRORS_QUERY_NAME = 'getAuthorizationErrors';

export const getAuthorizationErrorsQuery = gql`
  query ${AUTHORIZATION_ERRORS_QUERY_NAME} {
    getAuthorizationErrors {
      ...authorizationErrorInfo
    }
  }
  ${authorizationErrorInfo}
`;

export const withAuthorizationErrors = graphql(
  getAuthorizationErrorsQuery,
  {
    options: { fetchPolicy: 'network-only' },
    props: withDefaultProps(({ data }) => ({ authorizationErrors: _.get(data, 'getAuthorizationErrors') })),
  }
);
