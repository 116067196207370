import gql from 'graphql-tag';

export default gql`
  fragment accountInfo on Account {
    id
    phone
    extension
    fax
    firstName
    lastName
    isSamaUser
    isAdmin
    email
    isReadOnly
    isDeactivated

    institution {
      name
      id
      featureFlags  {
        EMRPullEnabled
      }
      integratedPortals
      ReferralForms {
        id
        title
        pdfURL
        description
      }
      practicingSpecialty
    }
  }
`;
