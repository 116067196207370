import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import colors from 'Resources/colors';
import strings from 'Resources/strings';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { withDuplicateAuthorization } from '../graphql/Authorization';
import getAuthorizationRoute from '../util/getAuthorizationRoute';
import Modal from './Modal';
import BaseButton from './BaseButton';
import LoadingButton from './LoadingButton';
import { ModalBody, ModalFooter, ModalContent } from './ModalStyledComponents';

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
  white-space: normal;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  ${BaseButton} {
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

export class DuplicateAuthorizationButton extends PureComponent {
  state = { open: false, loading: false };

  toggleModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    const { open } = this.state;

    this.setState({ open: !open });
  }

  duplicateAuthorization = async (setNewForm) => {
    const { duplicateAuthorization, toAuthorization, authorization, alert } = this.props;

    this.setState({ loading: true });
    try {
      const { data } = await duplicateAuthorization({ variables: { authorizationId: authorization.id, setNewForm } });
      const duplicated = data.duplicateAuthorization;

      this.setState({ loading: false });
      this.toggleModal();

      toAuthorization(duplicated.id, getAuthorizationRoute(duplicated));
    } catch (e) {
      alert.error('There was an error duplicating your authorization. If this problem persists, please contact Samacare.');
      this.setState({ loading: false });
      this.toggleModal();
    }
  }

  render() {
    const { children, authorization } = this.props;
    const { open, loading } = this.state;

    const isPortalAuthorization = authorization.portalKey;

    return (
      <div>
        <Modal header="Duplicate Authorization" onClick={this.toggleModal} open={open}>
          <ModalBody>
            <CustomModalContent>
              <div>
                Duplicating an authorization will create a new
                <span style={{ color: colors.presubmissionPink }}> draft </span>
                authorization based off the original.
                { isPortalAuthorization && (' If choosing a new form then all non-structured information will be lost.') }
              </div>
              <div style={{ marginTop: 10 }}>
                { strings.DUPLICATE_AUTHORIZATIONS.MODAL_WARN }
              </div>
            </CustomModalContent>
            <CustomModalFooter>
              <LoadingButton loading={loading} onClick={() => { this.duplicateAuthorization(false); }}>Duplicate</LoadingButton>
              { !isPortalAuthorization && (
                <LoadingButton loading={loading} onClick={() => { this.duplicateAuthorization(true); }}>Duplicate, use new form</LoadingButton>
              ) }
            </CustomModalFooter>
          </ModalBody>
        </Modal>
        <div onClick={this.toggleModal}>
          { children }
        </div>
      </div>
    );
  }
}


const mapDispatchProps = dispatch => ({
  toAuthorization(id, routePrefix) {
    const route = `${routePrefix}?step=1&id=${id}`;
    dispatch(push(route));
  },
});

export default connect(() => ({}), mapDispatchProps)(
  compose(withDuplicateAuthorization)(withAlert(DuplicateAuthorizationButton))
);
