import PropTypes from 'prop-types';

const boxPropType = PropTypes.exact({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  characterBoxes: PropTypes.string,
  tabIndex: PropTypes.number,
});

export const displayByPropType = PropTypes.exact({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const flatInputProps = {
  boxes: PropTypes.arrayOf(boxPropType).isRequired,
  id: PropTypes.string.isRequired,
  importance: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  samaTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  onlyFocusChildrenIfTrue: PropTypes.bool.isRequired,
};
export const flatInputPropType = PropTypes.exact(flatInputProps);

export const inputPropType = PropTypes.exact({
  ...flatInputProps,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
});
