import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { withUpdateAuthorization } from '../../graphql/Authorization';
import Modal from '../../components/Modal';
import { FormList } from '../NewAuthorization/InitializeSection/SelectFormModal/FormList';
import { ModalBodyLarge } from '../../components/ModalStyledComponents';

class ReferralSelectFormModal extends PureComponent {
  selectForm = async (selectedFormId) => {
    const { updateAuthorization, authorization, alert, nextStep } = this.props;

    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { formId: selectedFormId } } });
      await nextStep();
    } catch {
      alert.error('There was an error selecting an insurance form');
    }
  }

  render() {
    const { open, close, authorization, referralForms } = this.props;

    return (
      <Modal header="Select Insurance Form" onClick={close} open={open}>
        <ModalBodyLarge>
          <FormList
            authorizationId={authorization.id}
            onSelect={this.selectForm}
            referralForms={referralForms}
          />
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default compose(withUpdateAuthorization)(withAlert(ReferralSelectFormModal));
