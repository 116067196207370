import _ from 'lodash';
import { Query, compose } from 'react-apollo';
import React, { PureComponent } from 'react';
import DownCaret from 'react-icons/lib/ti/arrow-sorted-down';
import UpCaret from 'react-icons/lib/ti/arrow-sorted-up';

import styled, { css } from 'styled-components';
import mixpanel from 'mixpanel-browser';
import { BaseText } from 'Segment/StyledComponents';

import Note from './Note';
import { withDeleteAuthorization } from '../../graphql/Authorization';
import { withAuthorizationNotesQuery, withCreateAuthorizationNote } from '../../graphql/Note';
import BaseButton from '../BaseButton';

const Container = styled.div`
  width: 100%;
  min-width: 250px;
`;

const activeStyles = css`
  background: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  padding: 5px;
  border-bottom: 1px solid ${props => (props.expanded ? props.theme.purple : props.theme.darkGray)};
  cursor: pointer;

  ${props => (props.expanded ? `${activeStyles}` : '')}

  &:hover {
    background: white;
    border-bottom: 1px solid ${props => props.theme.purple};
  }
`;

const EmptyNotes = styled.div`
  text-align: center;
  padding: 20px 20px 10px 20px;
  color: ${props => props.theme.darkGray};
`;

const NoteSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
  padding: 5px;
`;

const AddOrViewNotes = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: ${props => props.theme.purple};
  margin: auto 0 auto auto;
`;

const AddNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${BaseText} {
    flex: 1;
  }
  ${BaseButton} {
    width: 50px;
    margin-left: 5px;
  }
`;

const AdditionalNotes = styled.div`
  position: absolute;
  width: ${props => props.width}px;
  max-height: 200px;
  top: auto;
  border: 1px solid ${props => props.theme.purple};
  border-top: 0;
  background: white;
  padding: 10px 10px 0 10px;
  overflow: scroll;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const HideNotesButton = styled(BaseButton)`
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 5px;
`;

class AuthorizationNotes extends PureComponent {
  state = { expanded: false, width: null, newNote: '' }

  ref = null;

  noteRef = null;

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.toggleExpand);
  }

  toggleExpand = (e) => {
    const { expanded } = this.state;

    if (!(this.noteRef && this.noteRef.contains(e.target))) {
      mixpanel.track('Notes expanded');
      // Moving this higher in the call stack will stop propagation to the add note button
      e.stopPropagation();
      if (!expanded) {
        const bounding = this.ref.getBoundingClientRect();

        this.setState({
          expanded: !expanded,
          width: bounding.width,
          newNote: '',
        }, () => {
          document.body.addEventListener('click', this.toggleExpand);
        });
      } else {
        this.setState({ expanded: !expanded, newNote: '' }, () => {
          document.body.removeEventListener('click', this.toggleExpand);
        });
      }
    }
  }

  submitNewNote = () => {
    const { createAuthorizationNote, authorizationId } = this.props;
    const { newNote } = this.state;

    createAuthorizationNote({ variables: { note: newNote, authorizationId } })
      .then(() => {
        this.setState({ newNote: '' });
      })
      .catch(() => {});
  }

  submitOnEnter = (e) => {
    if (e.key === 'Enter') { this.submitNewNote(); }
  }

  updateNewNote = (e) => {
    this.setState({ newNote: e.target.value });
  }

  render() {
    const { latestNote, authorizationId, hideNotes, deleteAuthorization } = this.props;
    const { expanded, width, newNote } = this.state;

    return (
      <Container>
        <NoteContainer ref={ref => this.ref = ref} expanded={expanded} onClick={this.toggleExpand}>
          { latestNote ? (
            <Note note={latestNote} />
          ) : (
            <NoteSubContainer>
              No activity with this authorization
            </NoteSubContainer>
          )}
          <AddOrViewNotes>
            { hideNotes && (<HideNotesButton onClick={hideNotes}>View Details</HideNotesButton>) }
            { expanded ? (<UpCaret />) : (<DownCaret />) }
          </AddOrViewNotes>
        </NoteContainer>
        { expanded && (
          <AdditionalNotes ref={ref => this.noteRef = ref} onClick={(e) => { e.stopPropagation(); }} width={width}>
            <AddNoteContainer>
              <BaseText placeholder="Begin typing to add a note" onKeyPress={this.submitOnEnter} onChange={this.updateNewNote} value={newNote} />
              <BaseButton disabled={!newNote} onClick={() => { mixpanel.track('Note added by User'); this.submitNewNote(); }}>Add</BaseButton>
            </AddNoteContainer>
            <Query query={withAuthorizationNotesQuery} variables={{ authorizationId }}>
              {({ loading, data }) => {
                if (loading || !data) { return (<div>Loading</div>); }
                const additionalNotes = _.filter(data.getAuthorizationNotes, note => (note.id !== latestNote.id));

                if (_.isEmpty(additionalNotes)) { return (<EmptyNotes>No additional notes</EmptyNotes>); }

                return _.map(additionalNotes, note => (
                  <Note key={`note_sub_container_${note.id}`} note={note} />
                ));
              }}
            </Query>
            {CONFIG.NODE_ENV === 'demo' && (
              <BaseButton onClick={() => { deleteAuthorization({ variables: { id: authorizationId } }); }}>Delete This Authorization</BaseButton>
            )}
          </AdditionalNotes>
        )}
      </Container>
    );
  }
}
export default compose(withCreateAuthorizationNote, withDeleteAuthorization)(AuthorizationNotes);
