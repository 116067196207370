import _ from 'lodash';
import styled from 'styled-components';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import { withFaxAssociatedAuthorizations } from '../../../../graphql/Authorization';
import { withDeleteFormNumber } from '../../../../graphql/FormNumber';
import BaseButton from '../../../../components/BaseButton';
import Modal from '../../../../components/Modal';
import { ModalContent } from '../../../../components/ModalStyledComponents';
import ROUTE_PATHS from '../../../ROUTE_PATHS';

const CustomModalContent = styled(ModalContent)`
  width: 600px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Link = styled.a`
  display: block;
`;

class DeleteFax extends PureComponent {
  deleteNumber = async () => {
    const { id, deleteFormNumber, alert, clear } = this.props;
    try {
      await deleteFormNumber({
        variables: {
          id,
        },
      });
      clear();
      alert.info('Successfully deleted record');
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  }

  render() {
    const { authorizations, id, description, number, clear } = this.props;
    let statusMessage = `Are you sure want to delete \n "${description} - ${number}"`;
    if (!_.isEmpty(authorizations)) {
      statusMessage = 'Draft / Edit and Resubmit Authorizations still exist using this number';
    }
    if (authorizations) {
      return (
        <Modal header="Delete Fax Number" onClick={() => { clear(); }} open>
          <CustomModalContent>
            {statusMessage}
            {authorizations.map(auth => (
              <Link
                key={`deleteFax-auth-${auth.id}`}
                href={`${window.location.origin}/#${ROUTE_PATHS.FORM_AUTHORIZATION}?step=1&id=${auth.id}`}
              >
                {`Authorization ID ${auth.id}`}
              </Link>
            ))}
            {_.isEmpty(authorizations) && (
              <BaseButton style={{ width: '500px' }} onClick={() => { this.deleteNumber(id); }}>
              Yes
              </BaseButton>
            )}
          </CustomModalContent>
        </Modal>
      );
    }
    return (<div />);
  }
}

export default compose(withFaxAssociatedAuthorizations, withDeleteFormNumber)(withAlert(DeleteFax));
