import React, { PureComponent } from 'react';

import { genericInput } from '../InputPropTypes';
import { BaseInputCleave, getPropsFromInput } from './BaseInput';

class ZipInput extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <BaseInputCleave
        {...getPropsFromInput(input)}
        onChange={(e) => { input.onChange({ [input.id]: e.target.value }); }}
        value={input.value || ''}
        placeholder="87109"
        options={{ delimiter: '-', blocks: [5, 4], numericOnly: true }}
      />
    );
  }
}

ZipInput.propTypes = {
  input: genericInput.isRequired,
};

export default ZipInput;
