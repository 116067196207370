import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import _ from 'lodash';
import colors from 'Resources/colors';

import PortalSelectionModal from './PortalSelectionModal';
import MenuButton from './MenuButton';
import ROUTE_PATHS from '../routes/ROUTE_PATHS';
import { withCurrentAccount } from '../graphql/Account';
import { withCreateAuthorization, withUpdateAuthorization } from '../graphql/Authorization';

const getDefaultConfig = (account, overrides = {}) => ({
  [CONFIG.DEFAULT_FIELDS.REQUESTED_BY.key]: `${account.firstName} ${account.lastName}`,
  [CONFIG.DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]: account.phone,
  [CONFIG.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION.key]: account.extension,
  [CONFIG.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE.key]: 'On',
  [CONFIG.DEFAULT_FIELDS.DEFAULT_CHECKMARK_TRUE.key]: 'On',
  ...overrides,
});

const MenuItems = styled.div`
  padding: 10px;
  text-align: center;
  font-weight: 300;

  :hover {
    cursor: pointer;
    background-color: ${colors.lightGray};
  }
`;

export class NewPriorAuthButton extends PureComponent {
  state = { portalSelectionOpen: false }

  click = async (portalKeyOrReferral, insuranceCompany) => {
    const {
      createAuthorization, patientId, account, toPA, toPortalPA, alert, toReferral, configOverrides,
    } = this.props;
    const isReferral = portalKeyOrReferral === 'REFERRAL';

    let navigateMethod = toPA;
    if (isReferral) {
      navigateMethod = toReferral;
    } else if (portalKeyOrReferral) {
      navigateMethod = toPortalPA;
    }

    let confOverrides = (configOverrides || {});
    if (isReferral) {
      confOverrides = { [CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]: 'Referral Request', ...confOverrides };
    }

    const defaultFields = {
      PatientId: patientId,
      status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
      config: getDefaultConfig(
        account,
        confOverrides,
      ),
      ...(isReferral ? { isReferral: true } : {}),
    };

    if (portalKeyOrReferral && !isReferral) {
      defaultFields.portalKey = portalKeyOrReferral;
      defaultFields.config[CONFIG.DEFAULT_FIELDS.INSURANCE_COMPANY.key] = insuranceCompany;
    }

    try {
      const res = await createAuthorization({ variables: { defaultFields } });
      navigateMethod(res.data.createAuthorization.id);
    } catch {
      alert.error('There was an error creating a new Authorization');
    }
  };

  getAuthorizationSelections = () => {
    const { account } = this.props;

    let baseSelection = [(<MenuItems key="newFormAuth" onClick={() => { this.click(false); }}>Form Authorization</MenuItems>)];

    if (account) {
      if (!_.isEmpty(account.institution.ReferralForms)) {
        baseSelection = baseSelection.concat(
          (<MenuItems key="referralAuth" onClick={() => { this.click('REFERRAL'); }}>Referral</MenuItems>)
        );
      }

      if (!_.isEmpty(account.institution.integratedPortals)) {
        baseSelection = baseSelection.concat(
          (<MenuItems key="newWebAuth" onClick={() => { this.setState({ portalSelectionOpen: true }); }}>Web Portal Authorization</MenuItems>)
        );
      }
    }

    return baseSelection;
  }

  render() {
    const { children, style, account } = this.props;
    const { portalSelectionOpen } = this.state;

    const selections = this.getAuthorizationSelections();
    if (selections.length === 1) {
      return (<div onClick={() => { this.click(false); }} style={style}>{children}</div>);
    }

    return (
      <div>
        <MenuButton
          color={colors.green}
          menuButtons={selections}
          buttonText="New Prior Autorization"
        >
          {children}
        </MenuButton>
        { portalSelectionOpen && (
          <PortalSelectionModal
            open={portalSelectionOpen}
            portals={account.institution.integratedPortals}
            closeModal={() => { this.setState({ portalSelectionOpen: false }); }}
            onSelect={this.click}
          />
        ) }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toPA(id) { dispatch(push(`${ROUTE_PATHS.FORM_AUTHORIZATION}?step=1&id=${id || ''}`)); },
    toPortalPA(id) { dispatch(push(`${ROUTE_PATHS.PORTAL_AUTHORIZATION}?step=1&id=${id || ''}`)); },
    toReferral(id) { dispatch(push(`${ROUTE_PATHS.REFERRAL}?step=1&id=${id || ''}`)); },
  };
}

export default connect(() => ({}), mapDispatchToProps)(
  compose(withCreateAuthorization, withUpdateAuthorization, withCurrentAccount)(withAlert(NewPriorAuthButton))
);
