import React from 'react';
import { DragSource } from 'react-dnd';

function Draggable(props) {
  const { children, connectDragSource } = props;

  return connectDragSource(<div>{ children }</div>);
}

export const DRAGGABLE_TYPE = 'tagDraggable';
export default DragSource(
  DRAGGABLE_TYPE,
  {
    beginDrag: props => ({ id: props.id }),
    canDrag: props => (props.canDrag),
  },
  connect => ({ connectDragSource: connect.dragSource() }),
)(Draggable);
