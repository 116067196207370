import gql from 'graphql-tag';

export default gql`
  fragment institutionInfo on Institution {
    id
    name
    faxResponseNumber
    practicingSpecialty
  }
`;
