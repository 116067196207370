import React from 'react';
import { BaseCleave } from './StyledComponents';

export const PhoneCleave = ({ onChange, number, name, disabled = false, highlight }) => (
  <BaseCleave
    placeholder="555-515-5175"
    options={{ delimiter: '-', blocks: [3, 3, 4], numericOnly: true }}
    onChange={onChange}
    name={name}
    value={number}
    disabled={disabled}
    highlight={highlight}
  />
);

export const ZipCleave = ({ onChange, number, name, disabled = false, highlight }) => (
  <BaseCleave
    placeholder="94105-9552"
    options={{ delimiter: '-', blocks: [5, 4], numericOnly: true }}
    onChange={onChange}
    name={name}
    value={number}
    disabled={disabled}
    highlight={highlight}
  />
);

export const ExtensionCleave = ({ onChange, number, name, disabled = false, highlight }) => (
  <BaseCleave
    placeholder="1234"
    options={{ blocks: [4], numericOnly: true }}
    onChange={onChange}
    name={name}
    value={number}
    disabled={disabled}
    highlight={highlight}
  />
);
