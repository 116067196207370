import styled from 'styled-components';

export const ModalBody = styled.div`
  width: 400px;
`;

export const ModalBodyLarge = styled.div`
  width: 600px;
`;

export const ModalBodyXL = styled.div`
  width: 800px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ModalContent = styled.div`
  text-align: center;
`;
