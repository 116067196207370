import _ from 'lodash';

export function withDefaultProps(cb) {
  return (res) => {
    const props = cb(res);

    if (_.get(res, 'data.loading')) {
      props.loading = true;
    }
    if (_.get(res, 'data.error')) {
      props.error = true;
    }
    if (_.get(res, 'data.refetch')) {
      props.refetch = res.data.refetch;
    }
    return props;
  };
}
