import getMatchingIndicesFromTextFragments from './getMatchingIndicesFromTextFragments';

const denialIndicators = [
  'Your request was denied',
  'We\'ve denied the medical services',
  'We denied the medical services',
  'Notice of denial of medical coverage',
  'Why did we deny your request',
  'this customer does not have active benefits with',
];

const getDenialRecs = fullText => (getMatchingIndicesFromTextFragments(denialIndicators, fullText));

export default getDenialRecs;
