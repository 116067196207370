import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import colors from 'Resources/colors';

import BaseButton from '../../../components/BaseButton';
import ReportError from '../ReportError';

const DecisionButton = styled(BaseButton)`
  color: ${props => props.isSelected ? 'white' : props.color || props.theme.purple};
  background-color: ${props => props.isSelected ? props.color || props.theme.purple : 'white'};
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin: 5px 0;
  }
`;

const NextStepWrapper = styled.div`
  margin-top: 10px;
  max-width: 525px;
`;

const MessageTitleWrapper = styled.div`
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: ${({ isAccentuated }) => isAccentuated ? 500 : 400};
  color: ${({ isAccentuated, theme }) => isAccentuated ? theme.red : theme.defaultFontColor};
`;

const HelperTextTitle = styled.div`
  font-weight: 500;
  margin: 10px;
  font-size: 18px;
`;

const HelperInstructions = styled.div`
  margin: 10px 0;
`;

export const errorModalConstants = {
  EXPIRING: 'EXPIRING',
  REINPUT: 'REINPUT',
  OTHER: 'OTHER',
};

const REINPUT_CREDENTIALS_TEXT = 'I want to reinput my login credentials';
export default class PortalErrorLoginNextStepActions extends PureComponent {
  state = {
    selection: '',
  };

  getActions = () => {
    const { reenterCredentials } = this.props;
    const { EXPIRING, OTHER, REINPUT } = errorModalConstants;

    return [
      {
        title: 'My password is expiring / has expired',
        action: () => {
          this.setState({
            selection: EXPIRING,
          });
        },
        isSelected: selection => selection === EXPIRING,
      },
      {
        title: 'I can\'t tell what the error is',
        action: () => {
          this.setState({
            selection: OTHER,
          });
        },
        isSelected: selection => selection === OTHER,
      },
      {
        title: 'Back to reinput credentials',
        action: () => {
          this.setState({
            selection: '',
          });
          reenterCredentials();
        },
        isSelected: selection => selection === REINPUT,
        color: colors.gray,
      },
    ];
  };

  render() {
    const { message, authorization } = this.props;
    const { selection } = this.state;
    const { EXPIRING, OTHER } = errorModalConstants;

    const isStandardErrorMessage = message.message.includes(CONFIG.CONSTANTS.PORTAL_STANDARD_ERROR_MESSAGE);

    return (
      <div>
        <MessageTitleWrapper isAccentuated={!isStandardErrorMessage}>
          { message.message }
        </MessageTitleWrapper>
        <ModalButtonContainer>
          { _.map(this.getActions(), action => (
            <DecisionButton
              key={action.title}
              onClick={action.action}
              isSelected={action.isSelected(selection)}
              color={action.color}
            >
              { action.title }
            </DecisionButton>
          )) }
        </ModalButtonContainer>
        <NextStepWrapper>
          { selection === EXPIRING && (
            <div>
              <HelperTextTitle>
                Expired / Expiring password
              </HelperTextTitle>
              If you password is expired or expiring, you will have to update it through the payer website
              before you can submit an authorization through SamaCare. To do that, you should:
              <HelperInstructions>1. Log in to your payer website and follow their instructions to reset your password.</HelperInstructions>
              <HelperInstructions>
                2. Return to this authorization and click the button above that says:
                <b>
                  { ` ${REINPUT_CREDENTIALS_TEXT}` }
                  .
                </b>
              </HelperInstructions>
              <HelperInstructions>
                3. You should then type your UPDATED username and password into the window that opens. From there, things should work smoothly again
              </HelperInstructions>
            </div>
          )}
          { selection === OTHER && (
            <ReportError
              isSamaError
              authorization={authorization}
              imageURL={message.imageURL}
              errorMessage={message.errorMessage}
            />
          ) }
        </NextStepWrapper>
      </div>
    );
  }
}
