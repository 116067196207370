import React, { PureComponent } from 'react';
import mixpanel from 'mixpanel-browser';

import DownloadFormsModal from './DownloadFormsModal';

class DownloadFormsButton extends PureComponent {
  state = { open: false };

  toggleModal = (e) => {
    e.stopPropagation();
    mixpanel.track('Download Forms modal opened');
    const { open } = this.state;

    this.setState({ open: !open });
  }

  render() {
    const { children, authorization } = this.props;
    const { open } = this.state;
    return (
      <div>
        <DownloadFormsModal header="Download Forms" onClick={this.toggleModal} open={open} authorizationId={authorization.id} />
        <div onClick={this.toggleModal}>
          { children }
        </div>
      </div>
    );
  }
}

export default DownloadFormsButton;
