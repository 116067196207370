import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import moment from 'moment';

import { AUTHORIZATION_PAGINATION_QUERY_NAME, GET_AUTHORIZATION_QUERY_NAME } from './Authorization';
import authorizationCorrespondenceInfo from './fragments/authorizationCorrespondenceInfo';
import { withDefaultProps } from './utils';

const withCreateAuthorizationCorrespondenceMutation = gql`
  mutation createAuthorizationCorrespondence($authorizationId: Int!, $fileId: Int!, $type: String!, $startDate: DateTime,
      $endDate: DateTime, $code: String, $authorizedProcedures: [JSON]) {
    createAuthorizationCorrespondence(authorizationId: $authorizationId, fileId: $fileId, type: $type, startDate: $startDate,
      endDate: $endDate, code: $code, authorizedProcedures: $authorizedProcedures) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
export const withCreateAuthorizationCorrespondence = graphql(withCreateAuthorizationCorrespondenceMutation, {
  name: 'createAuthorizationCorrespondence',
  options: () => ({
    refetchQueries: () => [AUTHORIZATION_PAGINATION_QUERY_NAME, GET_AUTHORIZATION_QUERY_NAME],
  }),
});

const withUpdateLatestAuthorizationCorrespondenceMutation = gql`
  mutation updateLatestAuthorizationCorrespondence(
    $authorizationId: Int,
    $type: String,
    $startDate: DateTime,
    $endDate: DateTime,
    $code: String,
    $authorizedProcedures: [JSON]
  ) {
    updateLatestAuthorizationCorrespondence(
      authorizationId: $authorizationId,
      type: $type,
      startDate: $startDate,
      endDate: $endDate,
      code: $code,
      authorizedProcedures: $authorizedProcedures
    ) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
export const withLatestUpdateAuthorizationCorrespondence = graphql(
  withUpdateLatestAuthorizationCorrespondenceMutation,
  {
    name: 'updateLatestAuthorizationCorrespondence',
    options: () => ({
      refetchQueries: () => [AUTHORIZATION_PAGINATION_QUERY_NAME, GET_AUTHORIZATION_QUERY_NAME],
    }),
  },
);

const INSURANCE_RESPONSE_QUERY_NAME = 'getInsuranceResponses';
const withInsuranceResponsesQuery = gql`
  query ${INSURANCE_RESPONSE_QUERY_NAME} {
    getInsuranceResponses {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;

const withUpdateAuthorizationCorrespondenceMutation = gql`
  mutation updateAuthorizationCorrespondence($id: Int!, $patch: JSON!) {
    updateAuthorizationCorrespondence(
      patch: $patch,
      id: $id,
    ) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
export const withUpdateAuthorizationCorrespondence = graphql(
  withUpdateAuthorizationCorrespondenceMutation,
  {
    name: 'updateAuthorizationCorrespondence',
    options: () => ({
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }),
  },
);

const withDeleteCorrespondenceMutation = gql`
  mutation deleteCorrespondence($id: Int!) {
    deleteCorrespondence(id: $id)
  }
`;
export const withDeleteCorrespondence = graphql(
  withDeleteCorrespondenceMutation,
  { name: 'deleteCorrespondence', options: () => ({ refetchQueries: () => [GET_AUTHORIZATION_QUERY_NAME] }) },
);

export const withInsuranceResponses = graphql(withInsuranceResponsesQuery, {
  props: withDefaultProps(({ data }) => ({ insuranceResponses: data.getInsuranceResponses, refetchTimeStamp: moment().format('h:mm a') })),
  options: {
    pollInterval: 60 * 1000 * 5,
  },
});

const withSetCorrespondenceOnAuthorizationMutation = gql`
  mutation setCorrespondenceOnAuthorization($authorizationId: Int!, $correspondenceId: Int!) {
    setCorrespondenceOnAuthorization(authorizationId: $authorizationId, correspondenceId: $correspondenceId) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;

export const withSetCorrespondenceOnAuthorization = graphql(
  withSetCorrespondenceOnAuthorizationMutation,
  {
    name: 'setCorrespondenceOnAuthorization',
    options: () => ({
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }),
  },
);

export const withHideAuthorizationCorrespondence = graphql(
  gql`
    mutation hideAuthorizationCorrespondence($id: Int!) {
      hideAuthorizationCorrespondence(id: $id)
    }
  `,
  {
    name: 'hideCorrespondence',
    options: () => ({
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }),
  },
);
