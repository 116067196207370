import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import { PhoneCleave, ZipCleave } from 'Segment/BaseComponents';
import { BaseText } from 'Segment/StyledComponents';

import { withUpsertLocation, withDeleteLocation } from '../../../graphql/Location';
import BaseButton from '../../../components/BaseButton';

const sharedRowStyles = `
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const TableRow = styled.tr`
  ${sharedRowStyles}
  &:hover {
    background-color: ${props => props.theme.lightPurple};
    cursor: pointer;
  }
`;

const TableRowEdit = styled.tr`
  ${sharedRowStyles}
  height: 175px;
  background-color: ${props => props.theme.lightPurple};
`;

const Cell = styled.td`
  padding: 16px;
  font-weight: 300;
`;

const Name = styled.div`
  color: ${props => props.theme.purple};
`;

const Fax = styled.div`
  color: ${props => props.theme.darkGray};
`;

const AddressBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export class LocationRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { location: props.location, editMode: props.location.name === CONFIG.CONSTANTS.DEFAULT_LOCATION_NAME };
  }

  delete = async () => {
    const { deleteLocation, alert } = this.props;
    const { location } = this.state;

    try {
      await deleteLocation({ variables: { id: location.id } });
      alert.success('Success');
    } catch (e) {
      alert.error(`There was an error deleting this location, ${e.message}`);
    }
  }

  upsert = async () => {
    const { upsertLocation, alert } = this.props;
    const { location } = this.state;

    try {
      let zipHandler = '';
      if (location.zip) {
        zipHandler = location.zip.length === 6 ? location.zip.slice(0, 5) : location.zip;
      }

      await upsertLocation({ variables: { id: location.id, patch: { ...location, zip: zipHandler } } });
      this.setState({ editMode: false });
      alert.success('Success');
    } catch (e) {
      alert.error(`There was an error updating this location, ${e.message}`);
    }
  }

  handleChange = (e) => {
    const { target } = e;
    const { location } = this.state;
    this.setState({ location: { ...location, [target.name]: target.value } });
  }

  render() {
    const { editMode, location } = this.state;
    if (editMode) {
      return (
        <TableRowEdit>
          <Cell>
            <BaseText style={{ marginBottom: '8px' }} placeholder="Location Name" name="name" value={location.name || ''} onChange={this.handleChange} />
            <PhoneCleave name="fax" number={location.fax || ''} onChange={this.handleChange} />
          </Cell>
          <Cell>
            <BaseText placeholder="Address" name="address" value={location.address || ''} onChange={this.handleChange} />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
              <BaseText style={{ marginRight: '8px' }} placeholder="City" name="city" value={location.city || ''} onChange={this.handleChange} />
              <BaseText
                style={{ width: '35%', marginRight: '8px' }}
                placeholder="State" name="state"
                value={location.state || ''}
                onChange={this.handleChange}
              />
              <ZipCleave placeholder="Zip" name="zip" number={location.zip || ''} onChange={this.handleChange} />
            </div>
          </Cell>
          <Cell>
            <BaseText placeholder="Facility Name" name="facilityName" value={location.facilityName || ''} onChange={this.handleChange} />
          </Cell>
          <Cell>
            <BaseText placeholder="Location NPI" name="NPI" value={location.NPI || ''} onChange={this.handleChange} />
          </Cell>
          <Cell>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <BaseButton
                onClick={() => { this.setState({ editMode: false }); }}
                style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px' }}
              >
                X
              </BaseButton>
            </div>
            <BaseText placeholder="Tax ID" name="taxId" value={location.taxId || ''} onChange={this.handleChange} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <BaseButton
                style={{ width: '30%', marginRight: '8px' }}
                onClick={this.delete}
              >
                Delete
              </BaseButton>
              <BaseButton
                style={{ width: '30%' }}
                onClick={this.upsert}
              >
                Save
              </BaseButton>
            </div>
          </Cell>
        </TableRowEdit>
      );
    }
    return (
      <TableRow onClick={() => { this.setState({ editMode: true }); }}>
        <Cell>
          <Name>{location.name}</Name>
          <Fax>{location.fax}</Fax>
        </Cell>
        <Cell>
          <AddressBox>
            <div>{location.address}</div>
            <div>
              {`${location.city || ''} ${location.state || ''} ${location.zip || ''}`}
            </div>
          </AddressBox>
        </Cell>
        <Cell>
          {location.facilityName}
        </Cell>
        <Cell>
          {location.NPI}
        </Cell>
        <Cell>
          {location.taxId}
        </Cell>
      </TableRow>
    );
  }
}

export default compose(withUpsertLocation, withDeleteLocation)(withAlert(LocationRow));
