import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { BaseCleave } from './StyledComponents';

class SegmentText extends PureComponent {
  render() {
    const { item, handleChange, result, disabled } = this.props;
    const onBlur = _.get(item, 'onBlur', () => {});

    return (
      <BaseCleave
        style={{ width: '100px' }}
        disabled={disabled}
        onChange={(event) => { handleChange({ [item.key]: event.target.value }); }}
        onBlur={onBlur}
        value={result || ''}
        placeholder="5175"
        options={{
          blocks: [4],
          numericOnly: true,
        }}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentText);
