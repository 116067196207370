import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import SectionHeader from './SectionHeader';

const TableContainer = styled.div`
  padding: 10px;
`;

const DataTable = styled.table`
  margin-top: 10px;
`;

const RowIndicator = styled.div`
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.gray : theme.purple};
  height: 10px;
  width: 10px;
  border-radius: 3px;
`;

const Row = styled.tr`
  margin: 5px 0;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.purple};
    ${RowIndicator} {
      background: ${({ theme }) => theme.purple};
    }
  }
`;

const DisabledRow = styled.tr`
  margin: 5px 0;
  color: ${({ theme }) => theme.darkGray};
  font-style: italic;
`;

const Cell = styled.td`
  padding: 10px 15px;
`;

const PortalTable = ({ message, onSelect }) => (
  <TableContainer>
    <SectionHeader>{ message.title }</SectionHeader>
    <DataTable>
      { message.dataHeaders && (
        <tr>
          <Cell />
          { _.map(message.dataHeaders, header => (<Cell key={`${message.id}_header_${header}`}><b>{ header }</b></Cell>)) }
        </tr>
      )}
      <tbody>
        {
          _.map(message.data, (row) => {
            if (row.disabled) {
              return (
                <DisabledRow key={row.key}>
                  <Cell><RowIndicator disabled /></Cell>
                  { _.map([].concat(row.display), cell => <Cell key={`${cell}${row.value}`}>{ cell }</Cell>) }
                </DisabledRow>
              );
            }

            return (
              <Row
                key={row.value}
                onClick={() => {
                  onSelect({ ...message, ...row, display: [].concat(row.display).join(', ') });
                }}
              >
                <Cell><RowIndicator /></Cell>
                { _.map([].concat(row.display), cell => <Cell key={`${cell}${row.value}`}>{ cell }</Cell>) }
              </Row>
            );
          })
        }
      </tbody>
    </DataTable>
  </TableContainer>
);

export default PortalTable;
