import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Select, { Creatable } from 'react-select';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';

import { withDeleteFormOrFaxFilter } from '../../../../graphql/FormFilter';
import STATES from '../../../../../resources/states';
import DRUGS from '../../../NewAuthorization/InitializeSection/drugOptions';


const sharedSelectStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin-right: 20px;
`;

const StateSelect = styled(Select)`
  ${sharedSelectStyles}
`;
const DrugSelect = styled(Creatable)`
  ${sharedSelectStyles}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 300;
  margin: 20px 0px;
`;

const FilterItemWrapper = styled.div`
  border-radius: 3px;
  width: 50%;
  border: 1px solid ${props => props.theme.purple};
  margin: 0 8px 16px 0;
  padding: 0 10px;
`;

const Cell = styled.div`
  border-radius: 3px;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid ${props => props.theme.purple};
  display: flex;
  align-items: center;

  div {
    color: ${props => props.theme.purple};
    min-width: 30px;
    cursor: pointer;
    text-align: right;

    &:hover {
      font-weight: 500;
    }
  }
`;

const DeleteX = styled.div`
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: ${props => props.theme.purple};
  }
`;

const stateOptions = _.map(STATES, (value, key) => ({
  value: key,
  label: value,
}));

const drugOptions = _.map(_.uniqBy(_.flatten(_.values(DRUGS)), 'code'), ({ drugName, code }) => ({
  value: code,
  label: `${code} - ${drugName}`,
}));

class FilterBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { states: props.states, jCodes: props.jCodes };
  }

  deleteFilter = async () => {
    const { id, alert, associationKey, deleteFormOrFaxFilter } = this.props;
    try {
      await deleteFormOrFaxFilter({ variables: { id, isFormFilter: (associationKey === 'formId') } });
    } catch (e) {
      alert.error(`There was an error ${e}`);
    }
  }

  editFilterList = (key, value, isRemove) => {
    const stateHandler = prevState => ({
      [key]: isRemove ? _.without(prevState[key], value) : _.uniq([...prevState[key], value]),
    });
    this.setState(stateHandler, this.updateFilterInfo);
  }

  updateFilterInfo = async () => {
    const { upsertFunction, alert, id, associationKey, associationId } = this.props;
    try {
      const { states, jCodes } = this.state;
      await upsertFunction({
        variables: { filterId: id, [associationKey]: associationId, states, jCodes },
      });
      alert.info('Successfully updated record');
    } catch (e) {
      const { states, jCodes } = this.props;
      alert.error(`Failed to update ${e}`);
      this.setState({ states, jCodes });
    }
  }

  render() {
    const { associationKey, filterText } = this.props;
    const { states, jCodes } = this.state;

    return (
      <FilterItemWrapper>
        <div style={{ display: 'flex', margin: '10px', justifyContent: 'space-between' }}>
          <div style={{ width: '50%' }}>{filterText}</div>
          <DeleteX onClick={this.deleteFilter}>X</DeleteX>
        </div>
        <Row>
          {_.map(states, value => (
            <Cell key={`state-item-${value}`}>
              { value }
              <div onClick={() => { this.editFilterList('states', value, true); }}>X</div>
            </Cell>
          ))}
          <StateSelect
            onChange={({ value }) => { this.editFilterList('states', value, false); }}
            options={stateOptions}
            placeholder="Add a state"
          />
        </Row>
        {associationKey === 'formId' && (
          <Row>
            {_.map(jCodes, value => (
              <Cell key={`drug-item-${value}`}>
                { value }
                <div onClick={() => { this.editFilterList('jCodes', value, true); }}>Remove</div>
              </Cell>
            ))}
            <DrugSelect
              onChange={({ value }) => { this.editFilterList('jCodes', value, false); }}
              options={drugOptions}
              placeholder="Add a jCode"
            />
          </Row>
        )}
      </FilterItemWrapper>
    );
  }
}

export default compose(withDeleteFormOrFaxFilter)(withAlert(FilterBox));
