import _ from 'lodash';
import { compose } from 'react-apollo';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { withFollowUpReviewed } from '../../graphql/FollowUp';
import EditAndResubmitButton from '../EditAndResubmitButton';
import RequestResendButton from '../RequestResendButton';
import FollowUpButton from '../FollowUpButton';
import ActionRequiredRowContainer from './ActionRequiredRowContainer';
import BaseButton from '../BaseButton';

const AutoAssociatedData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

const FollowUpButtons = styled(BaseButton)`
  margin-left: 10px;
  width: 175px;
`;

class AutomatedRow extends PureComponent {
  dismiss = async () => {
    const { followUpReviewed, row } = this.props;
    await followUpReviewed({ variables: { id: row.model.followUp.id } });
  }

  render() {
    const { i, keyOff, row } = this.props;
    const authorization = row.model;
    const { patient } = authorization;
    const { GENERAL_QUESTIONS, PRINTABLE_FORM, EDIT_AND_RESUBMIT, RESUBMIT } = CONFIG.CONSTANTS.FOLLOW_UP_TYPES;
    const patientName = `${_.upperFirst(patient.firstName)} ${_.upperFirst(patient.lastName)}`;

    let textContent;
    let buttonContent;
    let ButtonComponent;
    if (authorization.followUp.type === GENERAL_QUESTIONS) {
      textContent = `This authorization for ${patientName} requires a response to follow-up questions prior to receiving a response.`
        + ' You can respond to the payer directly through SamaCare by clicking "Respond to payer"';
      buttonContent = 'Respond to payer';
      ButtonComponent = FollowUpButton;
    } else if (authorization.followUp.type === PRINTABLE_FORM) {
      textContent = `This authorization for ${patientName} requires a response to a payer form that needs filling out prior to receiving a response.`
        + ' You can print the form by clicking "Print payer response"';
      buttonContent = 'Print payer response';
      ButtonComponent = FollowUpButton;
    } else if (authorization.followUp.type === EDIT_AND_RESUBMIT) {
      textContent = `This authorization for ${patientName} requires changes be made to the information originally supplied payer form.`
      + ' Click "Edit and Resubmit" to make edits to your original fax and re-send the authorization';
      buttonContent = 'Edit and Resubmit';
      ButtonComponent = EditAndResubmitButton;
    } else if (authorization.followUp.type === RESUBMIT) {
      textContent = `This authorization for ${patientName} requires resending to this payer at a different fax number.`
        + ' You can resubmit this authorization to the payer directly through SamaCare by clicking "Resubmit"';
      buttonContent = 'Resubmit';
      ButtonComponent = RequestResendButton;
    }

    return (
      <ActionRequiredRowContainer keyOff={keyOff} i={i} row={row}>
        <AutoAssociatedData>
          { textContent }
          <FollowUpButtons onClick={this.dismiss}>Dismiss</FollowUpButtons>
          <ButtonComponent
            followUp={authorization.followUp}
            correspondence={authorization.latestCorrespondence}
            authorizationId={authorization.id}
          >
            <FollowUpButtons>{buttonContent}</FollowUpButtons>
          </ButtonComponent>
        </AutoAssociatedData>
      </ActionRequiredRowContainer>
    );
  }
}

export default compose(withFollowUpReviewed)(AutomatedRow);
