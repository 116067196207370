import _ from 'lodash';
import React from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';


import LocationRow from './LocationRow';
import BaseButton from '../../../components/BaseButton';
import { withUpsertLocation } from '../../../graphql/Location';

const TableContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
`;

const TableHeader = styled.th`
  text-align: left;
  font-weight: 500;
  color: ${props => props.theme.lightGray};
`;

const generateLocationRow = location => (
  <LocationRow
    key={`location-id-${location.id}`}
    location={location}
  />
);

const headers = ['Name / Fax', 'Address', 'Facility Name', 'NPI', 'Tax ID'];
const LocationsTable = ({ locationList, alert, upsertLocation }) => (
  <TableContainer>
    <thead>
      <tr>
        {headers.map(columnName => (
          <TableHeader key={`column-${columnName}`}>
            {columnName !== _.last(headers)
              ? <div style={{ padding: '0 16px' }}>{columnName}</div>
              : (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ padding: '0 16px' }}>{columnName}</div>
                  <BaseButton
                    style={{ width: '100px' }}
                    onClick={async () => {
                      try {
                        await upsertLocation({ variables: { patch: { name: CONFIG.CONSTANTS.DEFAULT_LOCATION_NAME } } });
                        alert.success('Success');
                      } catch (e) {
                        alert.error(`There was an error creating this account, ${e.message}`);
                      }
                    }}
                  >
                    New
                  </BaseButton>
                </div>
              )}
          </TableHeader>
        ))}
      </tr>
    </thead>
    <tbody>
      {locationList.map(location => (generateLocationRow(location)))}
    </tbody>
  </TableContainer>
);

export default compose(withUpsertLocation)(withAlert(LocationsTable));
