import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import { withDefaultProps } from './utils';
import accountInfo from './fragments/accountInfo';
import supportAccountInfo from './fragments/supportAccountInfo';

const CURRENT_ACCOUNT_QUERY_NAME = 'CurrentAccount';
export const withCurrentAccountQuery = gql`
  query CurrentAccount {
    currentAccount {
      ...accountInfo
      portalCredentials
    }
  }
  ${accountInfo}
`;

const ACCOUNTS_FIND_ALL_QUERY_NAME = 'AccountsFindAll';
const withAccountsQuery = gql`
  query ${ACCOUNTS_FIND_ALL_QUERY_NAME} {
    accountsFindAll {
      ...supportAccountInfo
    }
  }
  ${supportAccountInfo}
`;

const withPrescribersQuery = gql`
  query Prescribers {
    prescribersFindAll {
      ...accountInfo
      label
    }
  }
  ${accountInfo}
`;

export const withCurrentAccount = graphql(withCurrentAccountQuery, {
  props: withDefaultProps(({ data }) => ({ account: _.get(data, 'currentAccount') })),
});

export const withAccounts = graphql(withAccountsQuery, {
  props: withDefaultProps(({ data }) => ({ accounts: _.get(data, 'accountsFindAll') })),
  options: props => ({
    fetchPolicy: 'network-only',
    variables: { InstitutionId: props.InstitutionId },
  }),
});

export const withPrescribers = graphql(withPrescribersQuery, {
  props: withDefaultProps(({ data }) => ({ prescribers: _.get(data, 'prescribersFindAll') })),
});

const withResetPasswordMutation = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
export const withResetPassword = graphql(withResetPasswordMutation, { name: 'resetPassword' });

const withGeneratePasswordTokenMutation = gql`
  mutation generatePasswordToken($id: Int!) {
    generatePasswordToken(id: $id)
  }
`;
export const withGeneratePasswordToken = graphql(withGeneratePasswordTokenMutation, { name: 'generatePasswordToken' });

const withUpdatePasswordMutation = gql`
  mutation updatePassword($nonce: String!, $password: String!) {
    updatePassword(nonce: $nonce, password: $password)
  }
`;
export const withUpdatePassword = graphql(withUpdatePasswordMutation, { name: 'updatePassword' });

const withUpdateAccountInstitutionMutation = gql`
  mutation updateAccountInstitution($InstitutionId: Int!) {
    updateAccountInstitution(InstitutionId: $InstitutionId) {
      ...accountInfo
    }
  }
  ${accountInfo}
`;
export const withUpdateAccountInstitution = graphql(withUpdateAccountInstitutionMutation, { name: 'updateAccountInstitution' });

const withUpsertAccountMutation = gql`
  mutation upsertAccount($id: Int, $patch: JSON!, $InstitutionId: Int) {
    upsertAccount(id: $id, patch: $patch, InstitutionId: $InstitutionId) {
      ...supportAccountInfo
    }
  }
  ${supportAccountInfo}
`;

export const withUpsertAccount = graphql(
  withUpsertAccountMutation,
  {
    name: 'upsertAccount',
    options: { refetchQueries: () => [ACCOUNTS_FIND_ALL_QUERY_NAME] },
  }
);

export const withUpsertPortalCredentials = graphql(
  gql`
    mutation upsertPortalCredentials($credentials: JSON!) {
      upsertPortalCredentials(credentials: $credentials) {
        ...accountInfo
      }
    }
    ${accountInfo}
  `,
  {
    name: 'upsertPortalCredentials',
    options: { refetchQueries: () => [CURRENT_ACCOUNT_QUERY_NAME] },
  }
);

export const withUnlockAccount = graphql(
  gql`
    mutation unlockAccount($id: Int!) {
      unlockAccount(id: $id) {
        ...accountInfo
      }
    }
    ${accountInfo}
  `,
  {
    name: 'unlockAccount',
  }
);
