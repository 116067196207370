import React from 'react';
import styled from 'styled-components';

const Required = styled.span`
  color: ${props => props.theme.red};
  font-weight: 500;
  font-size: 30px;
  line-height: 0px;

  span {
    vertical-align: sub;
  }
`;

const RequiredTag = () => (<Required><span>*</span></Required>);

export default RequiredTag;
