import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { compose, Query } from 'react-apollo';
import styled from 'styled-components';
import MdAutorenew from 'react-icons/lib/md/autorenew';
import Select from 'react-select';
import colors from 'Resources/colors';

import { getPortalIntegrationErrorsQuery, withPortalList } from '../../../graphql/Portal';
import MainList from '../../../components/MainList';
import BaseButton from '../../../components/BaseButton';
import PortalErrorAndNightlyTestModal from '../../../components/PortalErrorAndNightlyTestModal';
import ErrorResolutionModal from '../ErrorResolutionModal';

const Container = styled.div`
  width: 1500px;
  margin-left: 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const LoadingOrNoErrors = styled.div`
  padding: 20px 10px 10px 15px;
  color: ${props => props.theme.darkGray};
`;

const DetailsContainer = styled.div`
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 1s ease;
  white-space: nowrap;
  max-height: 200px;
  max-width: 250px;

  &:hover {
    white-space: pre-line;
    word-break: break-word;
    cursor: pointer;
  }
`;

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin-left: 15px;
`;

class IntegrationErrorDashboard extends PureComponent {
  state = {
    expandImage: false,
    errorModalErrorId: null,
    resolutionModalErrorId: null,
    showResolvedErrors: false,
    portalIntegrationErrors: [],
    daysLookBack: null,
    filterByPortal: null,
  }

  getTableCells = (error, onClick, hasBeenResolved) => {
    const keyPrefix = `PortalError_${error.id}`;

    if (hasBeenResolved) {
      return [
        (<div key={`${keyPrefix}_ErrorId`}>{error.id}</div>),
        (<div key={`${keyPrefix}_AuthId`}>{error.AuthorizationId}</div>),
        (<div key={`${keyPrefix}_CreatedAt`}>{moment(error.createdAt).format('M/D/YY')}</div>),
        (<div key={`${keyPrefix}_ResolvedBy`}>{error.resolvedBy.firstName}</div>),
        (<div key={`${keyPrefix}_PortalKey`}>{error.portalKey}</div>),
        (<div key={`${keyPrefix}_Institution`}>{error.institutionName}</div>),
        (<div key={`${keyPrefix}_Status`}>{error.resolutionStatus}</div>),
        (<DetailsContainer key={`${keyPrefix}_Details`}>{error.resolutionDetails || 'N/A'}</DetailsContainer>),
      ];
    }

    const rowStyle = error.type === CONFIG.CONSTANTS.AUTHORIZATION_ERROR_TYPES.CONTINUE_AFTER_UNHANDLED_ERROR ? { color: colors.warningRed } : {};
    const rows = [
      { key: `${keyPrefix}_ErrorId`, content: error.id },
      { key: `${keyPrefix}_AuthId`, content: error.AuthorizationId },
      { key: `${keyPrefix}_CreatedAt`, content: moment(error.createdAt).format('M/D/YY, h:mm:ss a') },
      { key: `${keyPrefix}_AuthSubmitted`, content: error.hasAuthBeenSubmitted ? 'Yes' : 'No' },
      { key: `${keyPrefix}_AuthDeleted`, content: error.hasAuthBeenDeleted ? 'Yes' : 'No' },
      { key: `${keyPrefix}_UserEmail`, content: error.createdByUserEmail },
      { key: `${keyPrefix}_Institution`, content: error.institutionName },
    ];

    return _.concat(
      _.map(rows, row => (<div key={row.key} style={rowStyle}>{row.content}</div>)),
      [(<BaseButton key={`${keyPrefix}_ResolveButton`} onClick={(e) => { e.stopPropagation(); onClick(); }} style={rowStyle}>Resolve</BaseButton>)]
    );
  }

  onPortalErrorRowClick = (err) => {
    this.setState({ errorModalErrorId: err.id });
  }

  handleSelectFilterChange = (selectedOption, key) => {
    const val = _.get(selectedOption, 'value', null);
    this.setState({ [key]: val });
  }

  render() {
    const { portalList } = this.props;
    const {
      expandImage,
      errorModalErrorId,
      resolutionModalErrorId,
      showResolvedErrors,
      portalIntegrationErrors,
      daysLookBack,
      filterByPortal,
    } = this.state;

    const unresolvedHeaders = ['ErrorId', 'AuthId', 'CreatedAt', 'AuthSubmitted', 'AuthDeleted', 'UserEmail', 'Institution', ''];
    const resolvedHeaders = ['ErrorId', 'AuthId', 'Date', 'Resolved By', 'Portal Key', 'Institution', 'Status', 'Details', ''];
    const headers = showResolvedErrors ? resolvedHeaders : unresolvedHeaders;

    const timeRangeOptions = [
      { value: null, label: 'Past 7 Days' },
      { value: 14, label: 'Past 14 Days' },
      { value: 30, label: 'Past 30 Days' },
    ];

    let portalFilterOptions;
    if (portalList) {
      portalFilterOptions = [{ value: null, label: 'All Portals' }, ..._.map(portalList, portal => ({ value: portal, label: portal }))];
    }

    const errorModalError = _.find(portalIntegrationErrors, { id: errorModalErrorId });

    return (
      <Container>
        <TitleContainer>
          <BaseButton
            style={{ width: '250px' }}
            onClick={() => { this.setState({ showResolvedErrors: !showResolvedErrors, daysLookBack: null, filterByPortal: null }); }}
          >
            <div style={{ marginRight: '25px' }}>{ showResolvedErrors ? 'Resolved Portal Errors' : 'Unresolved Portal Errors'}</div>
            <MdAutorenew />
          </BaseButton>
          { showResolvedErrors ? (
            <>
              <StyledSelect
                options={timeRangeOptions}
                value={_.find(timeRangeOptions, { value: daysLookBack })}
                onChange={(e) => { this.handleSelectFilterChange(e, 'daysLookBack'); }}
                clearable
                searchable
              />
              <StyledSelect
                options={portalFilterOptions}
                value={_.find(portalFilterOptions, { value: filterByPortal })}
                onChange={(e) => { this.handleSelectFilterChange(e, 'filterByPortal'); }}
                clearable
                searchable
              />
            </>
          ) : (
            <div style={{ marginLeft: '10px', fontWeight: 'bold' }}>
              (Last 7 Days) - [To resolve errors in red, run loadProdAuthLocallyById + resubmit auth as soon as possible]
            </div>
          ) }
        </TitleContainer>
        <Query
          query={getPortalIntegrationErrorsQuery}
          variables={{ hasBeenResolved: showResolvedErrors, daysToLookBack: daysLookBack, filterByPortalKey: filterByPortal }}
          onCompleted={data => this.setState({ portalIntegrationErrors: data.getPortalIntegrationErrors })}
          fetchPolicy="no-cache"
        >
          {({ loading, data }) => {
            if (loading) { return (<LoadingOrNoErrors>Loading...</LoadingOrNoErrors>); }
            if (_.isEmpty(portalIntegrationErrors)) { return (<LoadingOrNoErrors>No portal errors!</LoadingOrNoErrors>); }

            return (
              <MainList
                headers={headers}
                keyOff={showResolvedErrors ? 'resolvedPortalErrorList' : 'unresolvedPortalErrorList'}
                onClick={this.onPortalErrorRowClick}
                dataRows={_.map(data.getPortalIntegrationErrors, err => ({ cells: this.getTableCells(err, () => {
                  if (!showResolvedErrors) {
                    this.setState({ resolutionModalErrorId: err.id });
                  }
                }, showResolvedErrors),
                model: err }))}
              />
            );
          }}
        </Query>
        { errorModalErrorId && (
          <PortalErrorAndNightlyTestModal
            header="Portal Error Image"
            open={errorModalErrorId}
            modalOrBackOnClick={() => { this.setState({ errorModalErrorId: null, resolutionModalErrorId: null }); }}
            expandImageOnClick={() => { this.setState({ expandImage: true }); }}
            errorModalError={errorModalError}
            expandImage={expandImage}
            condenseImageOnClick={() => { this.setState({ expandImage: false }); }}
            handleResolve={() => { this.setState({ errorModalErrorId: null, resolutionModalErrorId: errorModalErrorId }); }}
            shouldAllowResolve={!showResolvedErrors}
          />
        ) }
        { resolutionModalErrorId && (
          <ErrorResolutionModal
            errorId={resolutionModalErrorId}
            header="Resolve Error"
            clearState={() => { this.setState({ resolutionModalErrorId: null }); }}
          />
        ) }
      </Container>
    );
  }
}

export default compose(withPortalList)(IntegrationErrorDashboard);
