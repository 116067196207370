const SET_FILTERS = 'SET_OTHER_FILTERS';

const initialState = { patientId: null };

export function setOtherAuthorizationsListFilters(toSet) {
  return { type: SET_FILTERS, toSet };
}

export default function otherAuthorizationsList(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, ...action.toSet };
    default:
      return state;
  }
}
