import React, { PureComponent } from 'react';
import styled from 'styled-components';
import strings from 'Resources/strings';

import AuthorizationListManager from '../routes/Authorizations/AuthorizationListManager';
import Modal from './Modal';
import BaseButton from './BaseButton';
import { ModalBodyLarge, ModalFooter, ModalContent } from './ModalStyledComponents';

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
  width: 120px;
  margin-left: 10px;
  padding: 5px 10px;
`;

const CustomModalContent = styled(ModalContent)`
  margin: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
`;

export default class AuthorizationModal extends PureComponent {
  render() {
    const {
      headerText, authorizations, hasNextPage, iteratePage, hasPreviousPage, closeModal, open, bodyText, customButtons,
    } = this.props;

    return (
      <div>
        <Modal
          header={headerText}
          onClick={this.toggleModal}
          open={open}
        >
          <ModalBodyLarge>
            { bodyText && (
              <CustomModalContent>
                { bodyText }
              </CustomModalContent>
            ) }
            <CustomModalContent>
              { authorizations && (
                <AuthorizationListManager
                  authorizations={authorizations}
                  emptyStateText={strings.AUTHORIZATIONS.NO_OTHER_AUTHORIZATIONS}
                  getNextPage={() => { iteratePage(true); }}
                  getPreviousPage={() => { iteratePage(false); }}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  tableConfig={{
                    headers: ['Created at', 'Description', 'Status'],
                    columns: ['createdAt', 'description', 'statusIndicator'],
                  }}
                  minWidth="600px"
                  includeActionRow={false}
                  alwaysOpenInBlank
                />
              )}
            </CustomModalContent>
            <CustomModalFooter>
              { (customButtons) || (<GrayButton onClick={closeModal}>{strings.COMMON.CLOSE}</GrayButton>) }
            </CustomModalFooter>
          </ModalBodyLarge>
        </Modal>
      </div>
    );
  }
}
