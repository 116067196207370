import gql from 'graphql-tag';

export default gql`
  fragment authorizationFormInfo on AuthorizationForm {
    id
    pdfURL
    title
    description
    specification
    imgURLs
    imgKeys
    taggedWidth
    taggedHeight
  }
`;
