import gql from 'graphql-tag';

export default gql`
  fragment supportAccountInfo on Account {
    id
    phone
    extension
    fax
    firstName
    lastName
    email
    type
    specialty
    NPI
    TIN
    licenseNumber
    DEA
    label
    isAdmin
    isReadOnly
    isDeactivated
    isLockedOut
  }
`;
