import gql from 'graphql-tag';

export default gql`
  fragment HCPCSCodeInfo on HCPCSCode {
    id
    code
    longDescription
    shortDescription
  }
`;
