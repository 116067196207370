import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import Creatable from 'react-select/lib/Creatable';
import Patient from 'AuthorizationSharedComponents/Patient';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import SegmentSelector from 'Segment/SegmentSelector';

import { withCurrentAccount } from '../../../graphql/Account';
import { withInsuranceCompanies } from '../../../graphql/InsuranceCompany';
import { withCreatePatientOnAuthorization, withUpdatePatient, findDuplicateAuthorizationsForPatientQuery } from '../../../graphql/Patient';
import { setFormFields } from '../../../reducers/formReducer';
import SelectFormModal from './SelectFormModal';
import RequiredTag from '../../../components/RequiredTag';
import BaseButton from '../../../components/BaseButton';
import AuthorizationModal from '../../../components/AuthorizationModal';
import MissingInformationModal from './MissingInformationModalContent';
import Modal from '../../../components/Modal';
import drugOptions from './drugOptions';
import filterInsuranceCompanyOptions from '../../../util/filterInsuranceCompanyOptions';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextButton = styled.div`
  padding: 5px 10px;
  margin: 0 0 3px 10px;
  text-decoration: underline;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.darkPurple};
    font-weight: 500;
  }
`;
const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Selector = styled(Creatable)`
  width: 600px;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const fields = CONFIG.DEFAULT_FIELDS;
export class InitializeSection extends PureComponent {
  state = { showSelectFormModal: false, showMissingInfoModal: false, foundDuplicateAuthorizations: [] }

  companySelect = null

  checkForDuplicateAuthorizations = () => {
    const { authorization, client } = this.props;

    client.query({
      query: findDuplicateAuthorizationsForPatientQuery,
      variables: { id: authorization.id },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => {
      if (!_.isEmpty(data.findDuplicateAuthorizationsForPatient)) {
        this.setState({ foundDuplicateAuthorizations: data.findDuplicateAuthorizationsForPatient });
      } else {
        this.setState({ showSelectFormModal: true });
      }
    });
  }

  submit = async () => {
    const { authorization, isInvalid, results, set, saveChanges, createPatientOnAuthorization, dob, firstName, lastName } = this.props;
    const requiredFieldKeys = [fields.INSURANCE_STATE.key, fields.INSURANCE_COMPANY.key];

    set({ [fields.ALL_HCPCS.key]: results[fields.HCPCS_0.key] });

    if (isInvalid(_.some(requiredFieldKeys, key => (!results[key])) || !results[fields.PRIMARY_DRUG_NAME.key])) { return; }
    if (authorization.formId && authorization.submissionPhoneNumber) {
      await this.success();
    } else {
      if (!authorization.patient) {
        await createPatientOnAuthorization({
          variables: {
            authorizationId: authorization.id,
            patientProps: {
              firstName: firstName.trim(), lastName: lastName.trim(), dob,
            } },
        });
      }
      await saveChanges();
      await this.checkForDuplicateAuthorizations();
    }
  }

  success = () => {
    const {
      nextStep, authorization, dob, firstName, lastName, createPatientOnAuthorization,
    } = this.props;

    if (!authorization.patient) {
      createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(), lastName: lastName.trim(), dob,
          },
        },
      }).then(nextStep);
    } else {
      nextStep();
    }
  }

  setDrug = (name = '', jcode = '', dose = '') => {
    const { set } = this.props;

    set({
      [fields.PRIMARY_DRUG_NAME.key]: name,
      [fields.HCPCS_0.key]: jcode,
      [fields.ALL_HCPCS.key]: jcode,
      [fields.PRIMARY_DOSE.key]: dose,
    });
  }

  render() {
    const {
      authorization, insuranceCompanies, lastName, firstName, dob, disabled, set, state, results, account,
    } = this.props;
    const { showSelectFormModal, showMissingInfoModal, foundDuplicateAuthorizations } = this.state;
    const selectedCompany = _.find(insuranceCompanies, { name: results[fields.INSURANCE_COMPANY.key] });

    if (!account) { return (<div />); }

    let insuranceSection = [fields.PATIENT_MEMBER_ID, fields.INSURANCE_STATE];
    if (account.institution.practicingSpecialty === CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.ONCOLOGY) {
      insuranceSection = [fields.PATIENT_GROUP_NUMBER, ...insuranceSection];
    }
    return (
      <FormContainer>
        <Patient
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          patientId={_.get(authorization, 'patient.id')}
          currentStep={authorization.formDetails.currentStep}
        />
        <Section
          section={{
            items: [
              fields.PATIENT_ADDRESS_LINE,
              [fields.PATIENT_CITY, fields.PATIENT_STATE, fields.PATIENT_ZIP],
            ],
            title: 'Patient Address',
          }}
        />
        <Section
          section={{
            items: (
              results[fields.PRIMARY_DRUG_NAME.key]
                ? [fields.PRIMARY_DRUG_NAME, fields.PRIMARY_DOSE, fields.HCPCS_0]
                : []
            ),
            title: 'Primary drug',
          }}
        >
          { !results[fields.PRIMARY_DRUG_NAME.key] && (
            <div>
              <SelectorTitle>
                Drug name (search by J-Code or Name)
                <RequiredTag />
              </SelectorTitle>
              <Selector
                options={drugOptions[account.institution.practicingSpecialty]}
                onChange={(selected) => {
                  this.setDrug(_.get(selected, 'drugName'), _.get(selected, 'code'), _.get(selected, 'dose'));
                }}
                onNewOptionClick={(selected) => {
                  this.setDrug(selected.label);
                }}
              />
            </div>
          ) }
        </Section>
        <Section
          section={{
            items: [fields.EMAIL_NOTIFICATION_TYPE],
            title: 'Send notification for major status updates?',
          }}
        />
        <Section
          childrenAfterSection
          section={{
            items: insuranceSection,
            title: 'Patient Insurance Information',
          }}
        >
          <SingleLine>
            <SegmentSelector
              disabled={disabled}
              labelKey="name"
              onChange={(company) => {
                if (company) {
                  set({ [fields.INSURANCE_COMPANY.key]: company.name });
                } else {
                  set({ [fields.INSURANCE_COMPANY.key]: null });
                }
              }}
              options={_.sortBy(insuranceCompanies, 'name')}
              setRef={ref => this.companySelect = ref}
              title={(
                <TitleContainer>
                  Insurance Organization
                  <RequiredTag />
                </TitleContainer>
              )}
              value={selectedCompany}
              valueKey="id"
              sorted
              filterOption={filterInsuranceCompanyOptions}
            />
            <TextButton onClick={() => { this.setState({ showMissingInfoModal: true }); }}>Request Support for Additional Insurers</TextButton>
          </SingleLine>
        </Section>
        <FormSubmitButtons submit={this.submit} />
        { showSelectFormModal && (
          <SelectFormModal
            authorization={authorization}
            close={() => { this.setState({ showSelectFormModal: false }); }}
            jCode={results[fields.HCPCS_0.key]}
            formId={authorization.formId}
            insuranceCompanyId={selectedCompany.id}
            insuranceName={selectedCompany.name}
            onSubmit={this.success}
            open={showSelectFormModal}
            set={set}
            state={state}
          />
        )}
        { !_.isEmpty(foundDuplicateAuthorizations) && (
          <AuthorizationModal
            open
            authorizations={foundDuplicateAuthorizations}
            iteratePage={() => {}}
            headerText="Possible duplicate authorizations found"
            bodyText="Someone in your office recently submitted authorization(s) for this patient. To save time and effort, check
to ensure you're not duplicate an authorization."
            customButtons={[
              (<BaseButton key="duplicateGoBack" onClick={() => { this.setState({ foundDuplicateAuthorizations: [] }); }}>Go Back</BaseButton>),
              (
                <BaseButton
                  style={{ marginLeft: 10 }}
                  key="duplicateContinue"
                  onClick={() => { this.setState({ foundDuplicateAuthorizations: [], showSelectFormModal: true }); }}
                >
                  Continue with Authorization
                </BaseButton>),
            ]}
          />
        )}
        { showMissingInfoModal && (
          <Modal header="Request Increased Support" onClick={() => { this.setState({ showMissingInfoModal: false }); }} open={showMissingInfoModal}>
            <MissingInformationModal
              type="Insurance Company"
              authorizationId={authorization.id}
              onCancel={() => { this.setState({ showMissingInfoModal: false }); }}
              insuranceCompanyId={_.get(selectedCompany, 'id')}
              state={state}
            />
          </Modal>
        ) }
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.form.results[fields.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[fields.PATIENT_LAST_NAME.key],
    dob: state.form.results[fields.PATIENT_DOB.key],
    state: state.form.results[fields.INSURANCE_STATE.key],
    disabled: state.form.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = dispatch => ({
  set(toSet) { dispatch(setFormFields(toSet)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withCreatePatientOnAuthorization, withUpdatePatient, withInsuranceCompanies, withCurrentAccount)(withApollo(InitializeSection))
);
