import _ from 'lodash';
import React, { PureComponent } from 'react';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import colors from '../../resources/colors';
import strings from '../../resources/strings';
import { withUpdateAccountInstitution } from '../graphql/Account';
import { withInstitutions } from '../graphql/Institution';

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;

  .Select-value-label {
    color: ${colors.red} !important;
    font-weight: 500;
  }
`;

export class SwitchCurrentInstitution extends PureComponent {
  handleChange = async (selectedOption) => {
    const { updateAccountInstitution, alert } = this.props;

    if (selectedOption) {
      try {
        await updateAccountInstitution({
          variables: {
            InstitutionId: _.get(selectedOption, 'value'),
          },
        });
        alert.success(strings.SWITCH_INSTITITIONS.SUCCESS);
        setTimeout(() => window.location.reload(), 1000);
      } catch {
        alert.error(strings.SWITCH_INSTITITIONS.ERROR);
      }
    }
  };

  render() {
    const { account, institutions } = this.props;

    if (!account || !account.isSamaUser || !institutions) {
      return null;
    }

    return (
      <StyledSelect
        value={_.get(_.find(institutions, ['id', account.institution.id]), 'id')}
        onChange={this.handleChange}
        options={_.map(institutions, ({ id, name }) => ({ value: id, label: name }))}
        clearable={false}
      />
    );
  }
}

export default compose(withUpdateAccountInstitution, withInstitutions)(withAlert(SwitchCurrentInstitution));
