import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3px 0;
  width: 100%;
  border-radius: 3px;
  background-color: #f5f5f5;
  padding: 2px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const Button = styled.div`
  padding: 2px;
  border: 1px solid ${props => props.theme.purple};
  border-radius: 3px;
  text-align: center;
  height: 20px;
  width: 20px;
  background-color: white;

  :hover {
    cursor: pointer;
    font-weight: bold;
    border-width: 2px;
  }
`;

const RecommendationEvidence = ({
  fullText, startIndex, endIndex, endMultiplierIncrements = 20, startMultiplierIncrements = 20,
}) => {
  const [rangeMultiplier, setRangeMultiplier] = useState(1);

  const pre = fullText.slice(Math.max(startIndex - rangeMultiplier * startMultiplierIncrements, 0), startIndex);
  const text = fullText.slice(startIndex, endIndex);
  const post = fullText.slice(endIndex, endIndex + rangeMultiplier * endMultiplierIncrements);
  return (
    <Container>
      <div style={{ whiteSpace: 'pre-line' }}>
        { pre }
        <b style={{ display: 'inline' }}>{ text }</b>
        { post }
      </div>
      <ButtonContainer>
        <Button onClick={() => setRangeMultiplier(rangeMultiplier + 1)}>+</Button>
        <Button onClick={() => setRangeMultiplier(Math.max(rangeMultiplier - 1, 1))}>-</Button>
      </ButtonContainer>
    </Container>
  );
};

export default RecommendationEvidence;
