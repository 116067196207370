import gql from 'graphql-tag';

import authorizationInfo from './authorizationInfo';
import authorizationCorrespondenceInfo from './authorizationCorrespondenceInfo';

export default gql`
  fragment detailedAuthorizationInfo on Authorization {
    ...authorizationInfo
    submittedFormURL
    submissionPhoneNumber
    shouldBeResent

    integratedEMRFilesDownloaded {
      category
      title
      url
      key
      date
    }

    integratedEMRProcedures {
      name
      code
      icd
      quantity
    }

    attachments {
      id
      title
      awsKey
    }
  }

  ${authorizationInfo}
  ${authorizationCorrespondenceInfo}
`;
