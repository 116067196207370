import React from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import { ModalContent } from './ModalStyledComponents';

const ModalBody = styled.div`
  width: 450px;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
`;

const AlertModal = ({
  open, closeModal, buttons, header, content, styleOverride,
}) => (
  <Modal header={header} onClick={closeModal} open={open} styleOverride={styleOverride}>
    <ModalBody>
      <CustomModalContent>
        <div>{ content }</div>
      </CustomModalContent>
      { buttons }
    </ModalBody>
  </Modal>
);

export default AlertModal;
