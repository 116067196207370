import _ from 'lodash';

const getMatchingIndicesFromTextFragments = (fragments, fullText) => (
  _.flatten(_.map(fragments, (fragment) => {
    const regex = new RegExp(fragment, 'gi');

    let result;
    const indices = [];
    // This loops through all the matches and returns the matching index
    // eslint-disable-next-line
    while (result = regex.exec(fullText)) {
      indices.push([result.index, result.index + fragment.length]);
    }

    return indices;
  }))
);

export default getMatchingIndicesFromTextFragments;
