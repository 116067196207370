import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { BaseText } from 'Segment/StyledComponents';
import SegmentSelector from 'Segment/SegmentSelector';
import BaseButton from '../../../components/BaseButton';
import PatientCredentialInstitutionCell from './PatientCredentialInstitutionCell';

const TableRow = styled.tr`
  &:hover {
    background-color: ${props => props.theme.disabledGray};
    cursor: pointer;
  }
  height: 100px;
`;

const TableRowEdit = styled.tr`
  background-color: ${props => props.theme.disabledGray};
`;

const Cell = styled.td`
  padding: 8px;
  font-weight: 300;
`;

const FinalEditCell = styled.td`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 150px;
`;

export class PatientIntegrationsRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      username: props.username,
      password: props.password,
      loginURL: props.loginURL,
      officeKey: props.officeKey,
      editMode: false,
    };
  }

  upsertCredential = async () => {
    const { id, upsert, institution, alert } = this.props;
    const { title, username, password, loginURL, officeKey } = this.state;

    try {
      await upsert({ variables: { id, institutionId: institution.id, patch: { title, username, password, loginURL, officeKey } } });
      alert.success('Success');
    } catch (e) {
      alert.error(`There was an error updating this credential, ${e.message}`);
    } finally {
      this.setState({ editMode: false });
    }
  }

  generateEditCell = key => (
    <Cell>
      <BaseText
        // eslint-disable-next-line react/destructuring-assignment
        placeholder={key} value={this.state[key]} onChange={({ target }) => { this.setState({ [key]: target.value }); }}
      />
    </Cell>
  )

  render() {
    const { username, password, loginURL, officeKey, institution, patientIntegrationTitles, errorLogs } = this.props;
    const { title, editMode } = this.state;
    if (editMode) {
      return (
        <TableRowEdit>
          <Cell>{institution.name}</Cell>
          <Cell>
            <SegmentSelector
              style={{ flex: 1, marginBottom: '8px' }}
              width="100%"
              options={_.map(patientIntegrationTitles, integrationTitle => ({ label: integrationTitle, value: integrationTitle }))}
              onChange={({ value }) => { this.setState({ title: value }); }}
              value={{ value: title, label: title }}
              clearable={false}
            />
          </Cell>
          {this.generateEditCell('username')}
          {this.generateEditCell('password')}
          {this.generateEditCell('loginURL')}
          <FinalEditCell>
            <BaseButton
              onClick={() => { this.setState({ editMode: false }); }}
              style={{ padding: '6px', borderRadius: '5px', width: '32px' }}
            >
              X
            </BaseButton>
            {this.generateEditCell('officeKey')}
            <BaseButton
              style={{ width: '125px' }}
              onClick={this.upsertCredential}
            >
              Save
            </BaseButton>
          </FinalEditCell>
        </TableRowEdit>
      );
    }
    return (
      <TableRow onClick={() => { this.setState({ editMode: true }); }}>
        <PatientCredentialInstitutionCell name={institution.name} errorLogs={errorLogs} />
        <Cell>{title}</Cell>
        <Cell>{username}</Cell>
        <Cell>{password}</Cell>
        <Cell>{loginURL}</Cell>
        <Cell>{officeKey}</Cell>
      </TableRow>
    );
  }
}

export default PatientIntegrationsRow;
