import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import FormFinder from './FormFinder';
import Modal from '../../components/Modal';
import BaseButton from '../../components/BaseButton';

const RelativeButton = styled(BaseButton)`
  position: relative;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;

  ${BaseButton} {
    margin: 10px;
    position: relative;
  }
`;

class ImportConfigButton extends PureComponent {
  state = { open: false, uploadAsNew: false, uploadAsExisting: false };

  exportLocally = () => {
    const { exportLocally } = this.props;
    exportLocally();
    this.setState({ open: false, uploadAsNew: false, uploadAsExisting: false });
  }

  upsert = (id, opts) => {
    const { upsertForm, alert } = this.props;

    upsertForm(id, opts).then(() => {
      this.setState({ open: false, uploadAsNew: false, uploadAsExisting: false });
      alert.success('Changes saved!');
    });
  }

  render() {
    const { importedRemoteFormId } = this.props;
    const { open, title, description, uploadAsNew, uploadAsExisting } = this.state;

    return (
      <div>
        <RelativeButton onClick={() => { this.setState({ open: true, uploadAsNew: false, uploadAsExisting: false }); }}>
          Export this Config
        </RelativeButton>
        <Modal open={open} header="Export" onClick={() => { this.setState({ open: false }); }}>
          { !(uploadAsNew || uploadAsExisting) && (
            <ButtonContainer>
              <BaseButton onClick={this.exportLocally}>
                Export Locally
              </BaseButton>
              {
                _.isNil(importedRemoteFormId) ? (
                  <span>
                    <BaseButton onClick={() => { this.setState({ uploadAsNew: true }); }}>Upload New Form</BaseButton>
                    <BaseButton onClick={() => { this.setState({ uploadAsExisting: true }); }}>Update Existing Form</BaseButton>
                  </span>
                ) : (
                  <BaseButton onClick={() => { this.upsert(importedRemoteFormId); }}>Update Imported Form</BaseButton>
                )
              }
            </ButtonContainer>
          ) }
          { uploadAsNew && (
            <div>
              Title
              <BaseText onChange={(e) => { this.setState({ title: e.target.value }); }} value={title || ''} />
              Description
              <BaseText onChange={(e) => { this.setState({ description: e.target.value }); }} value={description || ''} />
              <BaseButton onClick={() => { this.upsert(null, { title, description }); }}>Upload</BaseButton>
            </div>
          )}
          { uploadAsExisting && (<FormFinder onClick={(form) => { this.upsert(form.id); }} />) }
        </Modal>
      </div>
    );
  }
}

export default withAlert(ImportConfigButton);
