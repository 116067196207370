import ROUTE_PATHS from '../routes/ROUTE_PATHS';

const getAuthorizationRoute = (authorization) => {
  if (authorization.isReferral) {
    return ROUTE_PATHS.REFERRAL;
  }

  if (authorization.portalKey) {
    return ROUTE_PATHS.PORTAL_AUTHORIZATION;
  }

  return ROUTE_PATHS.FORM_AUTHORIZATION;
};

export default getAuthorizationRoute;
