import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import Select from 'react-select';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import strings from 'Resources/strings';
import { BaseText } from 'Segment/StyledComponents';

import { withUpdateAuthorizationStatus } from '../graphql/Authorization';
import { withCurrentAccount } from '../graphql/Account';
import { withCreateFollowUp } from '../graphql/FollowUp';
import BaseButton from './BaseButton';
import Modal from './Modal';
import { ModalBody, ModalFooter } from './ModalStyledComponents';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const ModalContent = styled.div`
  padding: 20px 0;
`;

const CustomModalFooter = styled(ModalFooter)`
  ${BaseButton} {
    width: 150px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 0 7px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${BaseButton} {
    margin-left: 5px;
  }
`;

const AdditionalInfoContainer = styled.div`
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.red};
  text-align: center;
  margin-bottom: 20px;
  white-space: normal;
`;

const DetailsButton = styled(BaseButton)`
  background-color: ${props => (props.selected ? props.theme.purple : 'white')};
  color: ${props => (props.selected ? 'white' : props.theme.purple)};
  flex: 1;
  margin-left: ${props => (props.isFirst ? 0 : 5)}px;
`;

const statePropsToUnsetOnChange = {
  nonApprovalInfo: '',
  errorMessage: null,
  followUpType: null,
  nonApprovalType: null,
  isNonApproval: false,
};

const STATUSES = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
const NONAPPROVALS = CONFIG.CONSTANTS.USER_NONAPPROVAL_TYPES;
export class UpdateAuthorizationStatusButton extends PureComponent {
  state = { open: false, selectedOption: {}, originalOption: null, ...statePropsToUnsetOnChange };

  toggleModal = (e) => {
    e.stopPropagation();
    const { authorization, defaultTo } = this.props;
    const { open } = this.state;

    const defaultSelected = defaultTo || authorization.status;
    this.setState({
      open: !open,
      selectedOption: { value: defaultSelected, label: defaultSelected.replace(/_/g, ' ') },
      originalOption: authorization.status,
      ...statePropsToUnsetOnChange,
    });
  };

  getErrorMessage = () => {
    const { selectedOption, originalOption, nonApprovalInfo, nonApprovalType, isNonApproval } = this.state;

    if (
      (originalOption !== selectedOption.value)
      && (_.includes(CONFIG.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON, selectedOption.value) || isNonApproval)
    ) {
      if (_.isNil(nonApprovalType)) {
        return 'Please select a reason for status change';
      }
      if (
        _.includes([NONAPPROVALS.OTHER, NONAPPROVALS.CLINICAL_DENIAL_OTHER, NONAPPROVALS.PAYER_POLICY_DENIAL], nonApprovalType)
        && !nonApprovalInfo
      ) {
        return 'Please add additional info for "Other" reason';
      }
    }

    return null;
  };

  statusChangeHandler = (selectedOption) => {
    const errorMessage = selectedOption.value === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
      ? strings.UPDATE_AUTH_STATUS.EDIT_AND_RESEND_NOTE : null;
    this.setState({ selectedOption, ...statePropsToUnsetOnChange, errorMessage });
  };

  nextStepChangeHandler = (selectedOption) => {
    this.setState({ followUpType: selectedOption.value });
  }

  getReasonInputs = () => {
    const { authorization, account } = this.props;
    const { selectedOption, originalOption, nonApprovalType, isNonApproval, followUpType } = this.state;

    let buttons;

    const nonApprovalReasonSelect = (
      <StyledSelect
        value={{ value: nonApprovalType, label: nonApprovalType }}
        onChange={(selected) => { this.setState({ nonApprovalType: selected.value }); }}
        options={_.map(_.values(CONFIG.CONSTANTS.USER_NONAPPROVAL_TYPES), selection => ({ label: selection, value: selection }))}
      />
    );
    if (originalOption !== selectedOption.value) {
      if (_.includes(CONFIG.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON, selectedOption.value)) {
        buttons = nonApprovalReasonSelect;
      }

      // Only display approval markings if it's a Sama User. Otherwise, user is correcting their own mistake and is
      // Not considered a non-approval
      if (_.includes([STATUSES.PENDING, STATUSES.EDIT_AND_RESUBMIT, STATUSES.ACTION_REQUIRED], selectedOption.value) && account.isSamaUser) {
        buttons = (
          <div>
            <ButtonContainer>
              {
                _.map([{ value: true, title: 'IS non-approval' }, { value: false, title: 'NOT non-approval' }], (isNA, i) => (
                  <DetailsButton
                    key={`denial_reason_button_${isNA.value}`}
                    onClick={() => { this.setState({ isNonApproval: isNA.value, nonApprovalType: null }); }}
                    selected={isNonApproval === isNA.value}
                    isFirst={i === 0}
                  >
                    { isNA.title }
                  </DetailsButton>
                ))
              }
            </ButtonContainer>
            { isNonApproval && (
              <div>
                <SelectorTitle>
                  Please select the nonapproval reason
                </SelectorTitle>
                { nonApprovalReasonSelect }
              </div>
            ) }
          </div>
        );
      }
    }

    if (buttons) {
      const nextStepHelperOptions = _.values(CONFIG.CONSTANTS.FOLLOW_UP_TYPES);
      return (
        <AdditionalInfoContainer>
          <SelectorTitle>
            Please mark the
            {` ${selectedOption.value} `}
            reason
          </SelectorTitle>
          { buttons }
          <SelectorTitle>Additional Info</SelectorTitle>
          <BaseText onChange={(event) => { this.setState({ nonApprovalInfo: event.target.value }); }} />
          {
            (account.isSamaUser && !authorization.portalKey) && (
              <div>
                <SelectorTitle>Next Step Helper</SelectorTitle>
                <StyledSelect
                  value={followUpType}
                  onChange={this.nextStepChangeHandler}
                  options={_.map(nextStepHelperOptions, selection => ({ label: selection.replace(/_/g, ' '), value: selection }))}
                  clearable
                />
              </div>
            )
          }
        </AdditionalInfoContainer>
      );
    }
    return null;
  };

  updateStatus = async (e) => {
    e.stopPropagation();
    const { updateAuthorizationStatus, alert, authorization, onUpdateSuccess, createFollowUp } = this.props;
    const { selectedOption, nonApprovalInfo, nonApprovalType, followUpType } = this.state;

    if (this.getErrorMessage()) {
      this.setState({ errorMessage: this.getErrorMessage() });
    } else {
      try {
        await updateAuthorizationStatus({
          variables: {
            id: authorization.id,
            status: selectedOption.value,
            nonApprovalInfo,
            nonApprovalType,
          },
        });

        if (followUpType) {
          await createFollowUp({ variables: { authorizationId: authorization.id, type: followUpType } });
        }

        this.setState({ open: false });
        if (onUpdateSuccess) { onUpdateSuccess(); }
      } catch (err) {
        alert.error('There was an error updating the Authorization');
      }
    }
  };

  isUpdateDisabled = () => {
    const { account } = this.props;
    const { selectedOption, originalOption, nonApprovalType, isNonApproval } = this.state;

    if (!selectedOption || selectedOption.value === originalOption) {
      // if the selected option matches the original one then disable submit
      return true;
    }
    // if a status requiring a reason is selected and no reason has been chosen then disable submit if isSamaUser
    return (
      _.includes(
        CONFIG.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON,
        selectedOption.value
      )
      || isNonApproval
    )
      && !nonApprovalType
      && account.isSamaUser;
  };

  render() {
    const { children, authorization, styleOverrides } = this.props;
    const { open, selectedOption, originalOption, errorMessage } = this.state;

    let allowedStatuses = _.without(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES, CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT);
    if (authorization.portalKey) {
      allowedStatuses = CONFIG.CONSTANTS.PORTAL_AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Modal header="Update authorization status" onClick={this.toggleModal} open={open}>
          <ModalBody>
            <ModalContent>
              <SelectorTitle>Update the status of this authorization.</SelectorTitle>
              <StyledSelect
                value={selectedOption}
                onChange={this.statusChangeHandler}
                options={_.map(_.reject(allowedStatuses, status => status === originalOption),
                  option => ({
                    value: option,
                    label: (
                      option === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
                        ? strings.UPDATE_AUTH_STATUS.EDIT_AND_RESEND_BUTTON_LABEL
                        : _.upperFirst(option.replace(/_/g, ' '))
                    ) }))}
                clearable={false}
                searchable={false}
              />
              { this.getReasonInputs() }
            </ModalContent>
            { errorMessage && (<ErrorMessage>{ errorMessage }</ErrorMessage>) }
            <CustomModalFooter>
              <BaseButton onClick={this.updateStatus} disabled={this.isUpdateDisabled()}>Update Details</BaseButton>
            </CustomModalFooter>
          </ModalBody>
        </Modal>
        <BaseButton
          style={{ padding: '3px', fontSize: '12px', marginRight: '5px', display: 'inline-block', ...(styleOverrides || {}) }}
          onClick={this.toggleModal}
          data-for="updateAuthorizationStatusButton"
          data-tip=""
        >
          { children }
        </BaseButton>
        <ReactTooltip id="updateAuthorizationStatusButton">Manually update this authorization status</ReactTooltip>
      </div>
    );
  }
}

export default compose(withCurrentAccount, withUpdateAuthorizationStatus, withCreateFollowUp)(withAlert(UpdateAuthorizationStatusButton));
