import React from 'react';
import styled from 'styled-components';
import ThumbsUpIcon from 'react-icons/lib/md/thumb-up';

const IconStyled = styled(ThumbsUpIcon)`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  color: ${({ theme }) => theme.green};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.green};
  border-radius: 3px;
  white-space: pre-wrap;
`;

const PortalSubmitted = () => (
  <div>
    <LoadingContainer>
      <IconStyled />
      Authorization Submitted Successfully
    </LoadingContainer>
  </div>
);

export default PortalSubmitted;
