import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import { BaseMultiline, BaseText, BaseCleave } from 'Segment/StyledComponents';
import { PhoneCleave } from 'Segment/BaseComponents';

import AlertModal from '../../components/AlertModal';
import BaseButton from '../../components/BaseButton';
import RequiredTag from '../../components/RequiredTag';
import SectionHeader from './SectionHeader';

const TableContainer = styled.div`
  padding: 10px;

  ${BaseButton} {
    margin-top: 10px;
  }
`;

// Segment selectors can return false as a non-null value, but empty strings are default values
// for text selectors, and are null.
// This is relatively confusing, sorry.
export const hasPortalValue = value => (!!(value || value === false));

export class PortalText extends PureComponent {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const defaultState = { validationModalOpen: false };

    if (_.get(message, 'priorValue')) {
      this.state = { ...defaultState, results: message.priorValue };
    } else {
      this.state = {
        ...defaultState,
        results: _.reduce(
          message.data,
          (initialResults, current) => ({ ...initialResults, [current.key]: current.initialValue || '' }),
          {}
        ),
      };
    }

    this.saveDraft = _.throttle(this._saveDraft, 5000, { leading: false, trailing: true });
  }

  componentWillUnmount() {
    this.saveDraft.cancel();
  }

  componentDidUpdate(prevProps, prevState) {
    const { results } = this.state;
    const prevResults = prevState.results;

    if (!_.isEqual(results, prevResults)) {
      this.saveDraft();
    }
  }

  setValue = (newValue, key) => {
    const { results } = this.state;

    this.setState({ results: { ...results, [key]: newValue } });
  }

  _saveDraft = async () => {
    const { onSelect, message, alert } = this.props;
    const { results } = this.state;

    try {
      await onSelect({
        key: message.key,
        value: results,
        isDraft: true,
      });
      alert.success('Your progress has been saved!');
    } catch (e) {
      alert.error('There was an error saving your draft');
    }
  }

  render() {
    const { message, onSelect } = this.props;
    const { results, validationModalOpen } = this.state;

    return (
      <TableContainer>
        <SectionHeader>{ message.title }</SectionHeader>
        { _.map(message.data, (textInput) => {
          let input;
          if (textInput.multiline) {
            input = (
              <BaseMultiline
                key={`portalMultiline_${textInput.key}`}
                onChange={(e) => { this.setValue(e.target.value, textInput.key); }}
                value={results[textInput.key] || ''}
              />
            );
          } else if (textInput.select) {
            input = (
              <Select
                key={`portalSelect_${textInput.key}`}
                value={results[textInput.key]}
                onChange={(selected) => { this.setValue(_.isArray(selected) ? selected : selected.value, textInput.key); }}
                multi={textInput.multi}
                options={textInput.options}
              />
            );
          } else if (textInput.date) {
            input = (
              <BaseCleave
                key={`portalDate_${textInput.key}`}
                options={{ date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' }}
                onChange={(e) => { this.setValue(e.target.value, textInput.key); }}
                value={results[textInput.key] || ''}
              />
            );
          } else if (textInput.phone) {
            input = (
              <PhoneCleave
                key={`portalPhone_${textInput.key}`}
                onChange={(e) => { this.setValue(e.target.rawValue, textInput.key); }}
                number={results[textInput.key] || ''}
              />
            );
          } else if (textInput.number) {
            input = (
              <BaseCleave
                key={`portalNumber_${textInput.key}`}
                options={{ numericOnly: true }}
                onChange={(e) => { this.setValue(e.target.value, textInput.key); }}
                value={results[textInput.key] || ''}
              />
            );
          } else {
            input = (
              <BaseText
                key={`portalText_${textInput.key}`}
                onChange={(e) => { this.setValue(e.target.value, textInput.key); }}
                value={results[textInput.key] || ''}
              />
            );
          }
          return (
            <div style={{ marginTop: 10 }} key={`portal_text_key_${message.key}_${textInput.key}`}>
              <div>
                {textInput.display}
                { textInput.required && (<RequiredTag />) }
              </div>
              {input}
            </div>
          );
        }) }
        <AlertModal
          header="Missing Required Fields"
          closeModal={() => { this.setState({ validationModalOpen: false }); }}
          open={validationModalOpen}
          content="Please ensure all required fields are filled in"
        />
        <BaseButton
          onClick={() => {
            if (_.every(message.data, input => ((input.required && hasPortalValue(results[input.key])) || !input.required))) {
              onSelect({
                ...message,
                value: results,
                // Will return null for nulled display values
                // Compacting them ensures we don't display nulled PortalText values, cleaning the display
                display: _.compact(_.map(results, (value, key) => {
                  const textInput = _.find(message.data, { key });
                  // If there is no portal value, don't show it in the display.
                  // Otherwise, the display gets clogged up
                  if (!hasPortalValue(value)) {
                    return null;
                  }

                  return `${textInput.display}: ${_.isObject(value) ? JSON.stringify(value) : value}`;
                })).join('\n'),
              });
            } else {
              this.setState({ validationModalOpen: true });
            }
          }}
        >
          Submit
        </BaseButton>
      </TableContainer>
    );
  }
}

export default withAlert(PortalText);
