import React, { PureComponent } from 'react';
import _ from 'lodash';
import strings from 'Resources/strings';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { withApollo } from 'react-apollo';
import { BaseMultiline } from 'Segment/StyledComponents';

import BaseButton from '../../components/BaseButton';
import { reportError } from '../../graphql/RequestHelp';

const authKeysToCapture = ['id', 'selectedPortal', 'portalKey', 'customPortalSteps', 'InstitutionId'];

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const getError = (message, imageURL, authorization, isSamaError) => ({
  title: 'Portal Integration Error',
  details: `Error message: ${message}
  ${isSamaError ? '<br />User reported this as a SamaError' : ''}
  <br />Screenshot:
  <br /><img src="${imageURL}">
  <br />
  <br />Authorization:
  <br />${_.join(_.map(authKeysToCapture, k => `${k}: ${JSON.stringify(authorization[k])}`), '<br />')}`,
});

class ReportError extends PureComponent {
  state = { userReportedDetails: '', successfullySubmitted: false }

  handleReportError = async (error) => {
    const { alert, client } = this.props;
    const { userReportedDetails } = this.state;
    const { title, details } = error;

    try {
      await client.mutate({
        mutation: reportError,
        variables: { title, message: `${details}<br /><br />${userReportedDetails}` },
      });
      this.setState({ successfullySubmitted: true });
    } catch (e) {
      alert.error(strings.MISSING_INFORMATION.ERROR);
    }
  };

  render() {
    const { errorMessage, authorization, imageURL, isSamaError } = this.props;
    const { userReportedDetails, successfullySubmitted } = this.state;

    return (
      <div style={{ marginTop: '10px' }}>
        <div>
          SamaCare will address this issue as soon as possible. Please add a few details so we can properly figure out how to best help.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <BaseMultiline onChange={(e) => { this.setState({ userReportedDetails: e.target.value }); }} value={userReportedDetails} />
        </div>
        <ModalButtonContainer>
          { !successfullySubmitted && (
            <BaseButton
              key="reportErrorModal-submit"
              onClick={() => this.handleReportError(getError(errorMessage, imageURL, authorization, isSamaError))}
            >
              {strings.REPORT_ERROR.SUBMIT}
            </BaseButton>
          ) }
          { successfullySubmitted && (
            <div>Error successfully submitted, the Samacare team will follow up within 2 business hours</div>
          ) }
        </ModalButtonContainer>
      </div>
    );
  }
}

export default withAlert(withApollo(ReportError));
