import React from 'react';
import _ from 'lodash';
import { compose } from 'react-apollo';
import SegmentSelector from 'Segment/SegmentSelector';

import { withUpdateFormDetails } from '../../../../graphql/Authorization';
import { withLocations } from '../../../../graphql/Location';

function locationToConfig(location) {
  return {
    [CONFIG.DEFAULT_FIELDS.LOCATION_NAME.key]: location.facilityName,
    [CONFIG.DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]: location.address,
    [CONFIG.DEFAULT_FIELDS.PRESCRIBER_CITY.key]: location.city,
    [CONFIG.DEFAULT_FIELDS.PRESCRIBER_STATE.key]: location.state,
    [CONFIG.DEFAULT_FIELDS.PRESCRIBER_ZIP.key]: location.zip,
    [CONFIG.DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]: location.taxId,
    [CONFIG.DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]: location.NPI,
  };
}

const LocationSelector = ({
  set, authorizationId, locationId, updateFormDetails, locations, disabled,
}) => {
  const selectedLocation = _.find(locations, { id: locationId });

  return (
    <div>
      <SegmentSelector
        disabled={disabled}
        onChange={(e) => {
          if (e) {
            const selected = _.find(locations, { id: e.value });
            updateFormDetails({ variables: { id: authorizationId, details: { locationId: e.value } } });
            set(locationToConfig(selected));
          } else {
            updateFormDetails({ variables: { id: authorizationId, details: { locationId: null } } });
          }
        }}
        options={_.map(locations, location => ({ value: location.id, label: location.name }))}
        title="Saved Offices"
        value={selectedLocation ? { value: selectedLocation.id, label: selectedLocation.name } : ''}
        sorted
      />
    </div>
  );
};

export default compose(withUpdateFormDetails, withLocations)(LocationSelector);
