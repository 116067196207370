import getMatchingIndicesFromTextFragments from './getMatchingIndicesFromTextFragments';

const numberIndicators = [
  'Auth #',
  'Authorization#',
  'Reference Number:',
  'Reference ID',
  'Reference No:',
  'Referral #:',
  'Prior Authorization Request #:',
];

const getAuthorizationNumberRecs = fullText => (getMatchingIndicesFromTextFragments(numberIndicators, fullText));

export default getAuthorizationNumberRecs;
