import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import Section from 'AuthorizationSharedComponents/Section';
import { SegmentTitleContainer, Container as SegmentContainer } from 'Segment/Segment';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import { withFormById } from '../../../graphql/AuthorizationForm';
import ICDSelector from '../../ICDSelector';
import HCPCSSelector from '../../HCPCSSelector';
import { setFormFields } from '../../../reducers/formReducer';
import RequiredTag from '../../RequiredTag';
import { countMaxSamaTypesByPrefix } from '../../../util/inputConfigurationManager';
import { icdChangeHandler, hcpcsChangeHandler } from '../../../util/requestDetailsChangeHandlers';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const defaultFields = CONFIG.DEFAULT_FIELDS;

export class RequestDetails extends PureComponent {
  hasICDAndHCPCSCodes = () => {
    const { results } = this.props;

    return !!(results[defaultFields.ALL_ICDS.key] && results[defaultFields.HCPCS_0.key] && results[defaultFields.HCPCS_QUANTITY_0.key]);
  }

  submit = () => {
    const { triggerRequiredModalOnInvalid, nextStep } = this.props;

    if (this.hasICDAndHCPCSCodes()) {
      nextStep();
    } else {
      triggerRequiredModalOnInvalid();
    }
  }

  getICDAndHCPCSHelperText = (max, codeType) => {
    if (max > 0) {
      return `${codeType} Codes (Max ${max} codes. Limit set by selected payer form. If you want to add`
      + " additional codes, please add them in the additional clinical notes on the payer's form.)";
    }

    return `${codeType} Codes.`;
  }

  render() {
    const { set, back, authorization, disabled, form, highlightRequiredFields } = this.props;

    const maxHCPCS = countMaxSamaTypesByPrefix(
      _.get(form, 'specification', []),
      CONFIG.DEFAULT_FIELDS.HCPCS_0.key.substring(0, 6),
    );
    const maxICDs = countMaxSamaTypesByPrefix(
      _.get(form, 'specification', []),
      CONFIG.DEFAULT_FIELDS.ICD_0.key.substring(0, 4),
    );

    return (
      <FormContainer>
        <Section
          section={{
            items: [[CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION, { ...CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE, warnIfPast: true }]],
            title: 'Request Description (Internal Use Only, to help identify requests)',
          }}
        />
        <Section
          section={{
            items: [
              {
                key: 'requestUrgency',
                title: 'Is this an urgent request?',
                type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
                options: [defaultFields.URGENT_REQUEST, defaultFields.NOT_URGENT_REQUEST],
                required: true,
              },
              {
                key: 'requestType',
                title: 'Is this a start or continuation request?',
                type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
                options: [defaultFields.INITIATION_REQUEST, defaultFields.CONTINUATION_REQUEST],
                required: true,
              },
            ],
            title: 'Request Type',
          }}
        />
        <Section section={{ title: 'Patient Diagnosis' }}>
          <SegmentContainer width="600px">
            <SegmentTitleContainer paddingleft="7px">
              { this.getICDAndHCPCSHelperText(maxICDs, 'ICD-10') }
              <RequiredTag />
            </SegmentTitleContainer>
            <ICDSelector
              disabled={disabled}
              max={maxICDs || 10}
              initializedICDs={authorization.ICDs}
              onChange={(newIcds) => { icdChangeHandler(newIcds, set); }}
              highlightRequiredFields={highlightRequiredFields}
            />
          </SegmentContainer>
        </Section>
        <Section section={{ title: 'Services' }}>
          <SegmentTitleContainer paddingleft="7px">
            { this.getICDAndHCPCSHelperText(maxHCPCS, 'HCPCS') }
            <RequiredTag />
          </SegmentTitleContainer>
          <HCPCSSelector
            disabled={disabled}
            highlightRequiredFields={highlightRequiredFields}
            max={maxHCPCS || 10}
            initializedHCPCSCodes={authorization.HCPCSCodes}
            hideModifiers
            onChange={(newHcpcs) => { hcpcsChangeHandler(newHcpcs, set); }}
          />
        </Section>
        <FormSubmitButtons back={back} submit={this.submit} />
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    disabled: state.form.disabled,
    highlightRequiredFields: state.form.highlightRequiredFields,
  };
}

const mapDispatchToProps = dispatch => ({
  set(fields) { dispatch(setFormFields(fields)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withFormById)(RequestDetails));
