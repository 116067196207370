import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './reducers/index';

export default function configureStore(initialState = {}, history) {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
  /* eslint-enable */
  // Middleware order matters
  const middlewares = [routerMiddleware(history)];
  const enhancers = applyMiddleware(...middlewares);
  const store = createStore(createRootReducer(history), initialState, composeEnhancers(enhancers));

  return store;
}
