import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withDefaultProps } from './utils';

export const AUTHORIZATION_ERRORS_QUERY_NAME = 'getAuthorizationEvents';

export const getAuthorizationEventsQuery = gql`
  query ${AUTHORIZATION_ERRORS_QUERY_NAME}($authorizationId: Int!) {
    getAuthorizationEvents(authorizationId: $authorizationId) {
      id
      type
      appliedStatus
      createdAt
      supportedAuthorizationFollowUp {
        id
        status
        details
        createdAt
        updatedAt
      }
    }
  }
`;

export const withAuthorizationEvents = graphql(
  getAuthorizationEventsQuery,
  {
    options: { fetchPolicy: 'network-only' },
    props: withDefaultProps(({ data }) => ({ authorizationEvents: _.get(data, 'getAuthorizationEvents') })),
  }
);
