import styled from 'styled-components';

const TaggerDeleteButton = styled.div`
  margin-left: auto;
  color: ${props => props.theme.purple};
  border: 1px solid ${props => props.theme.purple};
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 17px;

  :hover {
    cursor: pointer;
    font-weight: 500;
    background: ${props => props.theme.purple};
    color: white;
  }
`;

export default TaggerDeleteButton;
