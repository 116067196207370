import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import { withUpsertPortalCredentials, withCurrentAccount } from '../../../graphql/Account';
import BaseButton from '../../../components/BaseButton';

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin: 5px 0;
  }
`;

const MessageTitleWrapper = styled.div`
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: ${({ isAccentuated }) => isAccentuated ? 500 : 400};
  color: ${({ isAccentuated, theme }) => isAccentuated ? theme.red : theme.defaultFontColor};
`;

export const errorModalConstants = {
  EXPIRING: 'EXPIRING',
  REINPUT: 'REINPUT',
  OTHER: 'OTHER',
};

const SectionTitle = styled.div`
  color: ${props => props.theme.gray};
  font-weight: 500;
  margin-top: 10px;
  margin-right: auto;
  text-align: left;
`;

class PortalErrorLoginNextStepActions extends PureComponent {
  constructor(props) {
    super(props);
    const { account, portalKey } = this.props;
    const creds = _.find(_.get(account, 'portalCredentials') || {}, { key: portalKey }) || {};

    this.state = { username: creds.username || '', password: creds.password || '' };
  }

  submit = async () => {
    const { upsertPortalCredentials, onSubmit, portalKey, alert } = this.props;
    const { username, password } = this.state;

    try {
      await upsertPortalCredentials({ variables: { credentials: { key: portalKey, username, password } } });
      await onSubmit();
    } catch (e) {
      alert.error(e.message);
    }
  }

  render() {
    const { message, toggleNonCredentialError } = this.props;
    const { username, password } = this.state;

    return (
      <div>
        <MessageTitleWrapper>{ message.message }</MessageTitleWrapper>
        <ModalButtonContainer>
          <SectionTitle>Username</SectionTitle>
          <BaseText
            onChange={(e) => { this.setState({ username: e.target.value }); }}
            value={username || ''}
          />
          <SectionTitle>Password</SectionTitle>
          <BaseText
            onChange={(e) => { this.setState({ password: e.target.value }); }}
            value={password || ''}
          />
          <BaseButton onClick={this.submit}>
            Update credentials
          </BaseButton>
          <BaseButton color="#757575" onClick={toggleNonCredentialError}>
            My credentials are correct, this is a different error
          </BaseButton>
        </ModalButtonContainer>
      </div>
    );
  }
}

export default compose(withUpsertPortalCredentials, withCurrentAccount)(withAlert(PortalErrorLoginNextStepActions));
