import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import DownArrow from 'react-icons/lib/md/keyboard-arrow-down';
import mixpanel from 'mixpanel-browser';

import colors from 'Resources/colors';
import BaseButton from './BaseButton';

const UpArrow = styled.div`
  position: absolute;
  right: 3px;
  top: -9px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 9px solid ${colors.purple};
`;

const MenuContainer = styled.div`
  position: absolute;
  background: white;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 2px;
  border: 1px solid ${({ color }) => color || colors.purple};
  z-index: ${props => props.theme.zUpper};

  ${UpArrow} {
    border-bottom: 9px solid ${({ color }) => color || colors.purple};
  }
`;

class MenuButton extends PureComponent {
  state = { showMenu: false };

  updateLeft = () => {
    const widthDifference = this.menuContainerRef.getBoundingClientRect().width - this.mainButtonRef.getBoundingClientRect().width;
    this.setState({ menuLeft: this.mainButtonRef.getBoundingClientRect().left - widthDifference });
  };

  resize = _.throttle(this.updateLeft, 250);

  componentWillUnmount() {
    // Just in case of hanging event listeners
    window.removeEventListener('resize', this.resize);
    document.removeEventListener('click', this.closeMenu);
  }

  toggleMenu = (e) => {
    const { showMenu } = this.state;
    if (!showMenu) {
      this.openMenu(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.closeMenu(e, true);
    }
  };

  openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    mixpanel.track('Menu opened');
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
      this.updateLeft();
      window.addEventListener('resize', this.resize);
    });
  };

  closeMenu = (e, force = false) => {
    if (force || !this.menuRef.contains(e.target)) {
      // close the menu if the event did not originate from within it
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
        window.removeEventListener('resize', this.resize);
      });
    }
  };

  render() {
    const { buttonText, menuButtons, children, color } = this.props;
    const { showMenu, menuLeft } = this.state;
    return (
      <div ref={(ref) => { this.menuRef = ref; }}>
        <div ref={(ref) => { this.mainButtonRef = ref; }} onClick={this.toggleMenu}>
          { children || (
            <BaseButton>
              <div style={{ marginRight: '5px', fontSize: '12px' }}>{buttonText}</div>
              <DownArrow />
            </BaseButton>
          ) }
        </div>
        {
          showMenu && (
            <MenuContainer color={color} style={{ left: menuLeft }} ref={(ref) => { this.menuContainerRef = ref; }}>
              <UpArrow />
              { menuButtons }
            </MenuContainer>
          )
        }
      </div>
    );
  }
}

export default MenuButton;
