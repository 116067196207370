import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import ClearIcon from 'react-icons/lib/md/clear';
import FilterIcon from 'react-icons/lib/md/filter-list';
import styled from 'styled-components';
import SegmentSelector from 'Segment/SegmentSelector';

import { withInsuranceCompanies } from '../graphql/InsuranceCompany';
import AlertModal from './AlertModal';
import IconButton, { IconButtonStyled } from './IconButton';
import LoadingButton from './LoadingButton';

const Container = styled.div`
  display: flex;
  font-size: 16px;
`;

const ModalContent = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  ${IconButtonStyled} {
    background-color: ${props => (props.clearState ? props.theme.purple : 'white')};
    border: 1px solid ${props => props.theme.purple};
    color: ${props => (!props.clearState ? props.theme.purple : 'white')};
    &:hover {
      background-color: ${props => props.theme.purple};
      color: white;
    }
  }
`;

const initialState = { isLoading: false, openModal: false, stateFilters: {}, areFiltersApplied: false };
class FilterAuthorizationsButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      stateFilters: props.filters ? props.filters : {},
      areFiltersApplied: props.filters && !_.isEmpty(props.filters),
    };
    if (props.setClearFilters) {
      props.setClearFilters(this.clearAll);
    }
  }

  onChange = (filters) => {
    this.setState({ stateFilters: filters });
  };

  clearAll = () => {
    this.setState(initialState);
  };

  apply = () => {
    const { onFilter = () => {} } = this.props;
    const { stateFilters } = this.state;
    const { insuranceCompanyId } = stateFilters;
    let areFiltersApplied;
    if (insuranceCompanyId) {
      areFiltersApplied = true;
      onFilter({ insuranceCompanyId });
    } else {
      areFiltersApplied = false;
      onFilter(null);
    }
    this.setState({ openModal: false, areFiltersApplied });
  };

  render() {
    const { insuranceCompanies } = this.props;
    const { isLoading, openModal, stateFilters, areFiltersApplied } = this.state;
    const { insuranceCompanyId } = stateFilters;

    return (
      <Container>
        <AlertModal
          buttons={(
            <LoadingButton
              style={{ width: '100%' }}
              loading={isLoading}
              onClick={this.apply}
              disabled={!insuranceCompanyId}
            >
              Apply
            </LoadingButton>
          )}
          content={(
            <ModalContent>
              <SegmentSelector
                title="Insurance company"
                value={insuranceCompanyId}
                onChange={(e) => { this.onChange({ ...stateFilters, insuranceCompanyId: e ? e.value : null }); }}
                options={_.map(insuranceCompanies,
                  option => ({ value: option.id, label: option.name }))}
                clearable
                searchable
                width="100%"
              />
            </ModalContent>
          )}
          header="Filter authorizations"
          open={openModal}
          closeModal={() => { this.setState({ openModal: false }); }}
        />
        { !areFiltersApplied
          && (
            <IconButtonWrapper>
              <IconButton icon={FilterIcon} onClick={() => { this.setState({ openModal: true }); }}>
                Filter by Payer
              </IconButton>
            </IconButtonWrapper>
          )
        }
        { areFiltersApplied
          && (
            <IconButtonWrapper clearState>
              <IconButton
                icon={ClearIcon} onClick={() => {
                  this.setState({ stateFilters: {} }, this.apply);
                }}
              >
                Clear filters
              </IconButton>
            </IconButtonWrapper>
          )
        }
      </Container>
    );
  }
}

export default compose(withInsuranceCompanies)(withAlert(FilterAuthorizationsButton));
