import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import ROUTE_PATHS from '../../ROUTE_PATHS';
import Modal from '../../../components/Modal';
import BaseButton from '../../../components/BaseButton';
import ErrorRow from './ErrorRow';
import { withResolvePortalMetric } from '../../../graphql/Portal';

const ModalBody = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  margin: 10px;
  font-weight: 500;
  font-size: 20px;
`;

const AuthorizationSummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const CenteredButton = styled(BaseButton)`
  margin: auto;
`;

const SummaryTile = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-right: 10px;
  flex: 1;
  text-align: center;
  ${props => props.clickable
    ? `
      background-color: ${props.selected ? props.theme.purple : 'white'};
      color: ${props.selected ? 'white' : props.theme.purple};
      border: 1px solid ${props.theme.purple};
      border-radius: 3px;
      :hover {
        background-color: ${props.theme.purple};
        color: white;
        cursor: pointer;
      }
    `
    : ''}
`;

const TableContainer = styled.div`
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
`;

const ErrorsTable = styled.table`
  width: 100%;

  td {
    text-align: center;
  }
`;

const openAuthorizationInNewWindow = (id) => {
  const route = `${ROUTE_PATHS.PORTAL_AUTHORIZATION}?step=1&id=${id}`;
  window.open(`#${route}`, '_blank');
};

const { PRESUBMISSION, ACTION_REQUIRED, PENDING, SENT } = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
class PortalDetailsModal extends PureComponent {
  state = { statusesToShow: [] }

  getAuthorizationList() {
    const { selectedPortalMetrics } = this.props;
    const { statusesToShow } = this.state;

    const filtered = _.filter(selectedPortalMetrics.total, auth => _.includes(statusesToShow, auth.status));
    if (!_.isEmpty(filtered)) {
      return (
        <div>
          <SectionTitle>{ `${filtered.length} Authorizations` }</SectionTitle>
          <TableContainer>
            <ErrorsTable>
              <tbody>
                <tr>
                  <th>Id</th>
                  <th>Created At</th>
                  <th>Status</th>
                </tr>
                {
                  _.map(filtered, auth => (
                    <tr>
                      <td>
                        <CenteredButton onClick={() => { openAuthorizationInNewWindow(auth.id); }}>
                          { `View ${auth.id}` }
                        </CenteredButton>
                      </td>
                      <td>{ moment(auth.createdAt).format('MM/DD/YYYY') }</td>
                      <td>{ auth.status }</td>
                    </tr>
                  ))
                }
              </tbody>
            </ErrorsTable>
          </TableContainer>
        </div>
      );
    }

    return null;
  }

  handleResolvePortalError = async (id) => {
    const { resolvePortalMetric, alert } = this.props;

    try {
      await resolvePortalMetric({ variables: { id, isForAuthError: true } });
      alert.success('Success');
    } catch {
      alert.error('Error resolving portal error');
    }
  }

  render() {
    const { selectedPortalMetrics, close } = this.props;
    const { statusesToShow } = this.state;

    selectedPortalMetrics.errors = _.filter(selectedPortalMetrics.errors, { hasBeenResolved: false });

    const summaryTiles = [
      { title: 'Total (excludes deleted)', statuses: _.values(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES) },
      { title: 'Terminal Statuses', statuses: CONFIG.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES, clickable: true },
      { title: 'Draft Status', statuses: [PRESUBMISSION], clickable: true },
      { title: 'Pending Status', statuses: [ACTION_REQUIRED, PENDING, SENT], clickable: true },
    ];
    return (
      <Modal
        header={`${selectedPortalMetrics.key} Portal Details`}
        onClick={close}
        open={selectedPortalMetrics}
      >
        <ModalBody>
          <SectionTitle>Authorizations Summary</SectionTitle>
          <AuthorizationSummary>
            { _.map(summaryTiles, config => (
              <SummaryTile
                key={config.title}
                clickable={config.clickable}
                selected={_.isEqual(config.statuses, statusesToShow)}
                onClick={() => {
                  if (config.clickable) {
                    this.setState({ statusesToShow: config.statuses });
                  }
                }}
              >
                <b>
                  { config.title }
                </b>
                <div>
                  { _.filter(selectedPortalMetrics.total, auth => (_.includes(config.statuses, auth.status))).length }
                </div>
              </SummaryTile>
            ))}
          </AuthorizationSummary>
          { this.getAuthorizationList() }
          <SectionTitle>
            {`[${selectedPortalMetrics.errors.length}] Unresolved errors in last 30 days`}
          </SectionTitle>
          {
            (selectedPortalMetrics.errors.length > 0) && (
              <TableContainer>
                <ErrorsTable>
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <th>Error Message</th>
                      <th>AWS Image Key</th>
                      <th>View Auth</th>
                    </tr>
                    {
                      _.map(selectedPortalMetrics.errors, err => (
                        <ErrorRow error={err} key={`PortalError_${err.id}`} handleResolve={this.handleResolvePortalError} />
                      ))
                    }
                  </tbody>
                </ErrorsTable>
              </TableContainer>
            )
          }
        </ModalBody>
      </Modal>
    );
  }
}

export default compose(withResolvePortalMetric)(withAlert(PortalDetailsModal));
