import '@babel/polyfill';
import _ from 'lodash';
import { getValue } from '../shared-code';

function transformItemToInput(item, parentId, displayIfKey, box, siblingId) {
  return {
    ...box,
    ...(!_.isNil(parentId) ? { parentId } : {}),
    ...(displayIfKey ? { displayIf: displayIfKey } : {}),
    ...(_.omit(item, ['boxes', 'items', 'onlyFocusChildrenIfTrue'])),
    ...(!_.isNil(siblingId) ? { siblingId } : {}),
    background: _.find(CONFIG.CONSTANTS.INPUT_IMPORTANCE, { key: item.importance }).color,
  };
}

export const transformSpecToInputs = (spec, parentId, displayIfKey) => _.reduce(spec, (inputs, item) => {
  if (item.items) {
    // eslint-disable-next-line no-param-reassign
    inputs = inputs.concat(transformSpecToInputs(item.items, item.id, item.onlyFocusChildrenIfTrue ? item.id : displayIfKey));
  }
  if (!_.isEmpty(item.boxes)) {
    return inputs.concat(_.map(item.boxes, (box, i) => transformItemToInput(item, parentId, displayIfKey, box, item.boxes.length > 1 ? i : null)));
  }
  if (item.type === 'radioGroup') {
    return inputs.concat(transformItemToInput(item, parentId, displayIfKey));
  }

  return inputs;
}, []);

export const getInputValue = (input, results) => getValue(results, input.id, input.samaTypes);

export const isImmutableInput = input => (
  input.type === CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.SIGNATURE.key
    || !_.isEmpty(_.intersection(input.samaTypes, _.map(_.values(CONFIG.CONSTANTS.CUSTOM_FIELDS), 'key')))
);

export const countMaxSamaTypesByPrefix = (specification, prefix) => {
  const allTaggedSamaTypes = _.flatten(_.map(specification, 'samaTypes'));
  // Trying to make this logic dynamic requires ICD_ to be pluralized and it gets too messy, opting for a specific check
  if ((prefix === 'ICD_' && _.includes(allTaggedSamaTypes, CONFIG.DEFAULT_FIELDS.ALL_ICDS.key))
    || (prefix === 'HCPCS_' && _.includes(allTaggedSamaTypes, CONFIG.DEFAULT_FIELDS.ALL_HCPCS.key))) {
    return null;
  }
  const withPrefix = _.filter(allTaggedSamaTypes, samaType => (new RegExp(`^${prefix}[0-9]{1,2}`).test(samaType)));
  return withPrefix.length;
};
