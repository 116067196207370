import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withDefaultProps } from './utils';
import insuranceCompanyInfo from './fragments/insuranceCompanyInfo';

const INSURANCE_COMPANIES_QUERY = 'insuranceCompanies';
export const withInsuranceCompanies = graphql(
  gql`
    query ${INSURANCE_COMPANIES_QUERY} {
      insuranceCompaniesFindAll {
        ...insuranceCompanyInfo
      }
    }
    ${insuranceCompanyInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ insuranceCompanies: data.insuranceCompaniesFindAll })),
  }
);

export const withCreateInsuranceCompany = graphql(
  gql`
    mutation createInsuranceCompany($name: String!) {
      createInsuranceCompany(name: $name) {
        ...insuranceCompanyInfo
      }
    }
    ${insuranceCompanyInfo}
  `,
  {
    name: 'createInsuranceCompany',
    options: () => ({ refetchQueries: () => [INSURANCE_COMPANIES_QUERY] }),
  }
);
