import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';

import ICDSelector from '../../components/ICDSelector';
import BaseButton from '../../components/BaseButton';
import SectionHeader from './SectionHeader';
import IntegratedProceduresDetails from './IntegratedProceduresDetails';

export class PortalICD extends PureComponent {
  constructor(props) {
    super(props);
    const { message } = this.props;

    this.state = { icds: _.get(message, 'priorValue', {}) };
    this.saveDraft = _.throttle(this._saveDraft, 5000, { trailing: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { icds } = this.state;
    const prevResults = prevState.icds;

    if (!_.isEqual(icds, prevResults)) {
      this.saveDraft();
    }
  }

  // Set by ICDSelector
  setICD;

  requeryResults = (searchTerm) => {
    const { refetch } = this.props;
    return refetch({ searchTerm });
  }

  _saveDraft = async () => {
    const { onSelect, message, alert } = this.props;
    const { icds } = this.state;

    try {
      await onSelect({ key: message.key, value: icds, isDraft: true });
      alert.success('Your progress has been saved!');
    } catch (e) {
      alert.error('There was an error saving your draft');
    }
  }

  render() {
    const { message, onSelect, authorization } = this.props;
    const { icds } = this.state;

    return (
      <div>
        { !_.isEmpty(authorization.integratedEMRProcedures) && (
          <IntegratedProceduresDetails authorization={authorization} setICD={(icd) => { this.setICD(icd); }} isICDConfiguration />
        ) }
        <SectionHeader>{ message.title }</SectionHeader>
        <ICDSelector
          max={message.max}
          onChange={(newIcds) => { this.setState({ icds: newIcds }); }}
          initializedICDs={icds}
          setAddICDFromParent={(fn) => { this.setICD = fn; }}
        />
        <BaseButton
          style={{ marginTop: 10 }}
          disabled={_.isEmpty(icds)}
          onClick={() => { onSelect({ ...message, value: icds, display: _.keys(icds).join(', ') }); }}
        >
          Submit
        </BaseButton>
      </div>
    );
  }
}

export default withAlert(PortalICD);
