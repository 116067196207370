import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import BaseButton from '../../components/BaseButton';

export const ErrorIconCss = css`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  color: ${({ theme }) => theme.red};
`;

const ErrorContainer = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme, color }) => color || theme.red};
  border-radius: 3px;
  text-align: center;
  margin: 10px 0;
  display: inline-block;
  width: 100%;
`;

const ErrorImage = styled.img`
  max-width: 100%;
  border: 1px solid ${({ theme, color }) => color || theme.darkGray};
`;

const ErrorMessageContainer = styled.div`
  padding: 10px;
  margin: 10px 0;
  white-space: pre-wrap;
  font-weight: 500;
`;

const InlineButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;

  ${BaseButton} {
    margin: 0 10px;
  }
`;

class PortalError extends PureComponent {
  render() {
    const { message, onSelect } = this.props;

    return (
      <div>
        <ErrorContainer>
          <ErrorMessageContainer>
            {message.title}
          </ErrorMessageContainer>
          <InlineButtonContainer>
            {
              _.map(message.buttons, button => (
                <BaseButton onClick={() => { onSelect({ ...message, value: button.value }); }}>
                  {button.label}
                </BaseButton>
              ))
            }
          </InlineButtonContainer>
          <ErrorImage src={message.imageUrl} />
        </ErrorContainer>
      </div>
    );
  }
}

export default PortalError;
