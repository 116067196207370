import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import formFilterInfo from './fragments/formFilterInfo';
import { DETAILED_FORMS_QUERY } from './AuthorizationForm';
import { FORM_NUMBERS_QUERY } from './FormNumber';

export const withUpsertFormFilter = graphql(
  gql`
    mutation upsertFormFilter($filterId: Int, $jCodes: [String], $states: [String], $type: String, $formId: Int!) {
      upsertFormFilter(filterId: $filterId, jCodes: $jCodes, states: $states, type: $type, formId: $formId) {
        ...formFilterInfo
      }
    }
    ${formFilterInfo}
  `,
  {
    name: 'upsertFormFilter',
    options: () => ({ refetchQueries: () => [DETAILED_FORMS_QUERY] }),
  }
);

export const withDeleteFormOrFaxFilter = graphql(
  gql`
    mutation deleteFormOrFaxFilter($id: Int!, $isFormFilter: Boolean!) {
      deleteFormOrFaxFilter(id: $id, isFormFilter: $isFormFilter)
    }
  `,
  {
    name: 'deleteFormOrFaxFilter',
    options: () => ({ refetchQueries: () => [DETAILED_FORMS_QUERY, FORM_NUMBERS_QUERY] }),
  }
);
