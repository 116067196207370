import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';

import { withTerminatePortalIntegration } from '../../graphql/Portal';
import AlertModal from '../../components/AlertModal';
import BaseButton from '../../components/BaseButton';

const TIME_AFTER_WARNING = 1 * 60 * 1000;
const WARNING_TIMEOUT = CONFIG.CONSTANTS.PORTAL_TIMEOUT_MINUTES * 60 * 1000 - TIME_AFTER_WARNING;
const MESSAGE_TYPES = CONFIG.CONSTANTS.INTEGRATION_MESSAGE_TYPES;

export class PortalTimeout extends PureComponent {
  state = {
    terminateAuthorizationTimeout: null,
    warningTimeout: null,
    displayTerminateAuthorizationModal: false,
    displayWarningModal: false,
  }

  clearState = () => {
    const { terminateAuthorizationTimeout, warningTimeout } = this.state;

    clearTimeout(terminateAuthorizationTimeout);
    clearTimeout(warningTimeout);
    this.setState({ displayWarningModal: false, displayTerminateAuthorizationModal: false });
  }

  async restartPortalTimeout() {
    const { terminatePortalIntegration, runnerId } = this.props;

    this.clearState();

    await new Promise((resolve) => { this.setState({ warningTimeout: setTimeout(resolve, WARNING_TIMEOUT) }); });
    this.setState({ displayWarningModal: true });

    await new Promise((resolve) => {
      this.setState({
        terminateAuthorizationTimeout: setTimeout(resolve, TIME_AFTER_WARNING),
      });
    });

    await terminatePortalIntegration({ variables: { uuid: runnerId } });
    this.setState({ displayWarningModal: false, displayTerminateAuthorizationModal: true });
  }

  componentWillUnmount() {
    this.clearState();
  }

  async componentDidUpdate(prevProps) {
    const prevMessage = prevProps.message;
    const { message } = this.props;

    const isError = _.get(message, 'type') === MESSAGE_TYPES.ERROR;

    if (isError) {
      this.clearState();
    }

    if (!_.isEqual(message, prevMessage) && !isError) {
      await this.restartPortalTimeout();
    }
  }

  render() {
    const { restartIntegration } = this.props;
    const { displayWarningModal, displayTerminateAuthorizationModal } = this.state;

    if (displayWarningModal && !displayTerminateAuthorizationModal) {
      return (
        <AlertModal
          buttons={(
            <BaseButton
              style={{ width: '100%' }}
              onClick={() => { this.setState({ displayWarningModal: false }); }}
            >
              Confirm
            </BaseButton>
          )}
          content={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              This authorization will timeout in 1 minute if you take no actions.
            </div>
          )}
          header="Authorization timeout"
          open
          closeModal={() => { this.setState({ displayWarningModal: false }); }}
        />
      );
    }

    if (displayTerminateAuthorizationModal) {
      return (
        <AlertModal
          buttons={(
            <BaseButton
              style={{ width: '100%' }}
              onClick={async () => {
                this.setState({ displayTerminateAuthorizationModal: false });
                await restartIntegration();
              }}
            >
              Restart Authorization
            </BaseButton>
          )}
          content={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              This authorization has timed out, click restart to kick-off this integration.
              Most portals will timeout sessions after a certain amount of time or after prolonged periods of inactivity.
              However, after restarting the integration, any previously entered information has been saved and will be pre-inputted for you.
            </div>
          )}
          header="Authorization Timed Out"
          open
        />
      );
    }

    return (<div />);
  }
}

export default compose(withTerminatePortalIntegration)(PortalTimeout);
