import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import { withDefaultProps } from './utils';
import locationInfo from './fragments/locationInfo';

export const LOCATIONS_QUERY = 'locations';

export const withLocations = graphql(
  gql`
    query ${LOCATIONS_QUERY} {
      currentAccount {
        id

        institution {
          id
          locations {
            ...locationInfo
          }
        }
      }
    }
    ${locationInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ locations: _.get(data, 'currentAccount.institution.locations') })),
  }
);

const withUpdateLocationMutation = gql`
  mutation upsertLocation($id: Int, $patch: JSON!) {
    upsertLocation(id: $id, patch: $patch) {
      ...locationInfo
    }
  }
  ${locationInfo}
`;

export const withUpsertLocation = graphql(withUpdateLocationMutation, {
  name: 'upsertLocation',
  options: () => ({ refetchQueries: () => [LOCATIONS_QUERY] }),
});

const withDeleteLocationMutation = gql`
  mutation deleteLocation($id: Int!) {
    deleteLocation(id: $id)
  }
`;

export const withDeleteLocation = graphql(withDeleteLocationMutation, {
  name: 'deleteLocation',
  options: () => ({ refetchQueries: () => [LOCATIONS_QUERY] }),
});
