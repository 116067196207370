// set purple to green in staging
const purple = CONFIG.NODE_ENV === 'staging' ? '#74A089' : '#7271F3';

export default {
  darkGray: '#5d5d5d',
  lightestGray: '#f1f1f1',
  lightGray: '#CCCCCC',
  disabledGray: '#e8e8e8',
  gray: '#757575',
  purple,
  darkPurple: '#1918b0',
  green: '#2BBF96',
  red: '#ff4040',
  warningRed: '#ff4242',
  orange: 'orange',
  presubmissionPink: '#ff9a9a',
  // Primarily used as a default color for auth statuses
  dullGray: '#949494',
  defaultFontColor: '#212121',
  lightPurple: '#e8e7fd',
};
