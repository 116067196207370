import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import { transformSections } from '../../util/sectionConfigurationManager';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const sections = [
  { items: [CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION], title: 'Request Description (Internal Use Only, to help identify requests)' },
  {
    items: [{ ...CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE, required: true, warnIfPast: true }, { ...CONFIG.DEFAULT_FIELDS.END_DATE_OF_SERVICE, warnIfPast: true }],
    title: 'Request Dates of Service',
  },
  {
    items: [
      {
        key: 'requestType',
        title: 'Is this a continuation or initiation request?',
        type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
        options: [CONFIG.DEFAULT_FIELDS.INITIATION_REQUEST, CONFIG.DEFAULT_FIELDS.CONTINUATION_REQUEST],
        required: true,
      },
      {
        key: 'requestUrgency',
        title: 'Is this an urgent request?',
        type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
        options: [
          CONFIG.DEFAULT_FIELDS.URGENT_REQUEST,
          CONFIG.DEFAULT_FIELDS.NOT_URGENT_REQUEST,
          CONFIG.DEFAULT_FIELDS.RETRO_REQUEST,
          CONFIG.DEFAULT_FIELDS.EMERGENT_REQUEST,
        ],
        required: true,
      },
    ],
    title: 'Request Type and Urgency',
  },
  {
    items: [
      CONFIG.DEFAULT_FIELDS.PATIENT_MEMBER_ID,
      {
        key: 'genderSelector',
        title: 'Patient gender',
        type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
        options: [
          CONFIG.DEFAULT_FIELDS.PATIENT_GENDER_MALE,
          CONFIG.DEFAULT_FIELDS.PATIENT_GENDER_FEMALE,
          CONFIG.DEFAULT_FIELDS.PATIENT_GENDER_OTHER,
        ],
      },
    ],
    title: 'Additional Patient Insurance Information',
  },
];

class RequestDetails extends PureComponent {
  render() {
    const { authorization, back, nextStep, disabled } = this.props;

    return (
      <FormContainer>
        {_.map(
          transformSections(sections, authorization.formFieldConfig),
          (section, i) => (
            <Section
              key={`portalAuth_requestDetailSection_${i}`}
              section={section}
              disabled={disabled}
            />
          ),
        )}
        <FormSubmitButtons back={back} submit={nextStep} />
      </FormContainer>
    );
  }
}

export default RequestDetails;
