import React from 'react';
import Section from 'AuthorizationSharedComponents/Section';

import { transformSections } from '../../../../util/sectionConfigurationManager';

const segmentItems = [
  CONFIG.DEFAULT_FIELDS.REQUESTED_BY,
  CONFIG.DEFAULT_FIELDS.REQUESTED_BY_PHONE,
  CONFIG.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION,
];

const DetailsForm = ({ disabled, authorization }) => (
  <Section
    section={
      transformSections([{
        items: [segmentItems],
        title: 'Requested By',
      }], authorization.formFieldConfig)[0]
    }
    disabled={disabled}
  />
);

export default DetailsForm;
