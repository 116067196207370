import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import PrescriberDetails from './PrescriberDetails';
import LocationDetails from './LocationDetails';
import RequesterDetails from './RequesterDetails';
import { setFormFields } from '../../../reducers/formReducer';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class Prescriber extends PureComponent {
  render() {
    const { back, nextStep, set, results, authorization, disabled } = this.props;

    return (
      <FormContainer>
        <RequesterDetails authorization={authorization} results={results} set={set} disabled={disabled} />
        <PrescriberDetails
          authorization={authorization}
          authorizationId={authorization.id}
          disabled={disabled}
          prescriberId={authorization.formDetails.prescriberId}
          set={set}
        />
        <LocationDetails authorization={authorization} disabled={disabled} set={set} />
        <FormSubmitButtons back={back} submit={nextStep} />
      </FormContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    results: state.form.results,
    disabled: state.form.disabled || ownProps.disabled,
  };
}

const mapDispatchToProps = dispatch => ({
  set(fields) { dispatch(setFormFields(fields)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Prescriber);
