import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { inputPropType } from '../taggerPropTypes';
import InputHierarchyRender from './InputHierarchyRender';

const Container = styled.div`
  position: fixed;
  height: 550px;
  overflow-y: scroll;
  width: ${props => props.width || 140}px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const ID_PREFIX = 'InputHierarchyElem';
class InputHeirarchy extends PureComponent {
  ref = null;

  componentDidUpdate(prevProps) {
    const { selectedId } = this.props;

    if (selectedId && prevProps.selectedId !== selectedId) {
      if (document.getElementById(`${ID_PREFIX}_${selectedId}`)) {
        // When new inputs are created, need time for them to render
        setTimeout(() => { document.getElementById(`${ID_PREFIX}_${selectedId}`).scrollIntoView({ behavior: 'smooth' }); }, 50);
      }
    }
  }

  render() {
    const { inputs, selectedId, setSelected, moveNode, updateInput, deleteInput, selectedBoxIndex, width } = this.props;

    return (
      <Container width={width} ref={(ref) => { this.ref = ref; }}>
        { _.map(inputs, input => (
          <InputHierarchyRender
            key={`heirachyView_${input.id}`}
            idPrefix={ID_PREFIX}
            moveNode={moveNode}
            selectedId={selectedId}
            setSelected={setSelected}
            selectedBoxIndex={selectedBoxIndex}
            input={input}
            updateInput={updateInput}
            deleteInput={deleteInput}
          />
        )) }
      </Container>
    );
  }
}

InputHeirarchy.propTypes = {
  deleteInput: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(inputPropType).isRequired,
  moveNode: PropTypes.func.isRequired,
  selectedBoxIndex: PropTypes.number,
  selectedId: PropTypes.string,
  setSelected: PropTypes.func.isRequired,
  updateInput: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

export default InputHeirarchy;
