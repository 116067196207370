import React, { PureComponent } from 'react';

import { genericInput } from '../InputPropTypes';
import { BaseInput, getPropsFromInput } from './BaseInput';

class EmailInput extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <BaseInput
        type="email"
        placeholder="sama@pa.solution"
        {...getPropsFromInput(input)}
        onChange={(e) => { input.onChange({ [input.id]: e.target.value }); }}
        value={input.value || ''}
      />
    );
  }
}

EmailInput.propTypes = {
  input: genericInput.isRequired,
};

export default EmailInput;
