import React, { useState } from 'react';

import ListFilter from '../../../components/MainList/ListFilter';
import FormEdit from './routes/FormEdit';
import FaxEdit from './routes/FaxEdit';

const tabs = [
  { title: 'Authorization Forms', tabTitle: 'Authorization Forms' },
  { title: 'Form Numbers', tabTitle: 'Form Numbers' },
];

const SupportFormsNumbers = () => {
  const [view, setView] = useState(tabs[0]);
  return (
    <div style={{ margin: '0 30px' }}>
      <ListFilter
        onSelect={(tab) => {
          setView(tab);
        }}
        selected={view}
        tabs={tabs}
        hideFilters
      />
      { (view === tabs[0]) && (<FormEdit />) }
      { (view === tabs[1]) && (<FaxEdit />) }
    </div>
  );
};

export default SupportFormsNumbers;
