const RESET_PASSWORD_BASE = '/reset-password';

const ROUTE_PATHS = {
  PORTAL_AUTHORIZATION: '/portal-authorization',
  AUTHORIZATION_LIST: '/',
  LOGIN: '/login',
  FORM_AUTHORIZATION: '/new-authorization',
  REFERRAL: '/referral',
  TAGGER: '/pdfTagger',
  RESET_PASSWORD_BASE,
  RESET_PASSWORD: `${RESET_PASSWORD_BASE}/:nonce`,
  ADMIN_ROUTE: '/admin',
  SUPPORT_ROUTE: '/support',
  SECURE_FILE: '/secure-file',
  CHECK_AUTHORIZATION_STATUS: '/check-status',
};

export default ROUTE_PATHS;
