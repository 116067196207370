import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import BaseButton from '../../components/BaseButton';

const Cell = styled.td`
  padding: 10px 15px;

  div {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 175px;
  }
`;

const ErrorModalCell = styled.td`
  padding: 5px 10px;

  div {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
`;

const ExpandButton = styled(BaseButton)`
  margin: 0 15px;
  width: 150px;
  font-size: 12px;
  padding: 3px 5px;
`;

class PortalFillTableResultsDisplay extends PureComponent {
  state = {
    showDetails: false,
  };

  render() {
    const { showDetails } = this.state;
    const { display, isCondensed } = this.props;

    if (_.isArray(display)) {
      const rows = showDetails ? display[1] : display[1].slice(0, 2);
      return (
        <table>
          <thead>
            <tr>
              { _.map(display[0], header => isCondensed ? (<ErrorModalCell><div><u>{ header }</u></div></ErrorModalCell>)
                : (<Cell><div><u>{ header }</u></div></Cell>))
              }
            </tr>
          </thead>
          <tbody>
            { _.map(rows, row => (
              <tr>
                { _.map(row, element => isCondensed ? (<ErrorModalCell><div>{ element }</div></ErrorModalCell>)
                  : (<Cell><div>{ element }</div></Cell>))
                }
              </tr>
            )) }
          </tbody>
          { display[1].length > 2 && (showDetails
            ? (
              <div>
                <ExpandButton
                  onClick={() => this.setState({ showDetails: false })}
                  data-for="hideExpandButton"
                  data-tip=""
                >
                  Collapse Details
                </ExpandButton>
                <ReactTooltip id="hideExpandButton">
                  Click here to hide the extended results!
                </ReactTooltip>
              </div>
            )
            : (
              <div>
                <ExpandButton
                  onClick={() => this.setState({ showDetails: true })}
                  data-for="expandButton"
                  data-tip=""
                >
                  Expand Details
                </ExpandButton>
                <ReactTooltip id="expandButton">
                  Click here to view all the results!
                </ReactTooltip>
              </div>
            )
          ) }
        </table>
      );
    }

    return (
      <div style={{ whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis' }}>{ display }</div>
    );
  }
}

export default PortalFillTableResultsDisplay;
