import _ from 'lodash';
import React, { PureComponent } from 'react';

import { BaseInputCleave, handleInputFocus, getPropsFromInput } from './BaseInput';

class ZipInput2 extends PureComponent {
  constructor(props) {
    super(props);
    const splitZip = _.split(props.value, '-', 2);
    this.state = {
      partInputs: {
        0: {
          options: { blocks: [5], delimiter: '-', numericOnly: true },
          placeholder: '87109',
          value: splitZip[0],
        },
        1: {
          options: { blocks: [4], delimiter: '-', numericOnly: true },
          placeholder: '1102',
          value: splitZip.length > 1 ? splitZip.slice(1).join('-') : '',
        },
      },
    };
  }

  updateValue = (target, part, partIndex) => {
    const { partInputs } = this.state;
    // update state
    this.setState({ partInputs: { ...partInputs, [partIndex]: { ...partInputs[partIndex], value: target.value } } }, () => {
      const { partInputs: updatedPartInputs } = this.state;
      // store values for reference in state callback
      handleInputFocus(target, updatedPartInputs, partIndex);
      // trigger results update
      part.onChange({ [part.id]: _.join(_.filter(_.map(updatedPartInputs, input => input.value), s => s), '-') });
    });
  }

  render() {
    const { parts } = this.props;
    const { partInputs } = this.state;

    if (parts.length !== 2 && CONFIG.NODE_ENV !== 'production') {
      throw new Error('Invalid Zip2');
    }

    return (
      <div>
        {
          _.map(parts, (part, i) => (
            <BaseInputCleave
              key={`zip2_input_${part.id}_${i}`}
              ref={(input) => { partInputs[i].ref = input; }}
              {...getPropsFromInput(part)}
              onChange={(e) => {
                this.updateValue(e.target, part, i);
              }}
              value={partInputs[i].value}
              options={partInputs[i].options}
              placeholder={partInputs[i].placeholder}
            />
          ))
        }
      </div>
    );
  }
}

export default ZipInput2;
