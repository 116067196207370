import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const withCreateTestPDF = graphql(
  gql`
    mutation createTestPDF($fileKey: String, $formSpecification: JSON, $formMetadata: JSON) {
      createTestPDF(fileKey: $fileKey, formSpecification: $formSpecification, formMetadata: $formMetadata)
    }
  `,
  { name: 'createTestPDF' },
);
