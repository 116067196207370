import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Radio, RadioGroup } from 'react-radio-group';

import { CheckmarkContainer, CheckmarkImageContainer, getPropsFromInput } from './BaseInput';

class RadioGroupInput extends PureComponent {
  constructor(props) {
    super(props);

    const preSelectedValue = (_.get(_.find(props.radioButtons, radio => (!!radio.value)), 'id') || '');
    this.state = { selectedValue: preSelectedValue };

    if (preSelectedValue) {
      props.onChange({ [props.radioGroupId]: true });
    }
  }

  setRadio = (radioId) => {
    const { onChange, radioGroupId, radioButtons } = this.props;
    this.setState({ selectedValue: radioId });
    onChange(_.reduce(radioButtons, (toSet, btn) => ({ ...toSet, [radioGroupId]: !!radioId, [btn.id]: radioId ? btn.id === radioId : null }), {}));
  }

  render() {
    const { radioButtons, background } = this.props;
    const { selectedValue } = this.state;

    return (
      <RadioGroup
        onChange={this.setRadio}
        selectedValue={selectedValue}
      >
        {
          _.map(radioButtons, item => (
            <CheckmarkContainer
              key={item.id}
              {...(item.disabled ? _.omit(getPropsFromInput(item), 'tabIndex') : getPropsFromInput(item))}
              background={background}
              displayHidden={item.displayHidden && !selectedValue}
              isCircle={item.type === CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.RADIO_CIRCLE.key}
              onKeyUp={({ key }) => {
                if (key === 'Enter') {
                // toggle the radio on enter key
                  this.setRadio(item.id === selectedValue ? null : item.id);
                }
              }}
            >
              <Radio
                value={item.id}
                disabled={item.disabled}
                onClick={({ target }) => {
                  if (target.value === selectedValue) {
                  // deselect a radio if it is the currently
                  // selected value and is clicked again
                    this.setRadio(null);
                  }
                }}
              />
              <CheckmarkImageContainer disabled={item.disabled} />
            </CheckmarkContainer>
          ))
        }
      </RadioGroup>
    );
  }
}

export default RadioGroupInput;
