import filterTabs from '../routes/Authorizations/filterTabs';

const SET_FILTERS = 'SET_FILTERS';

const initialState = {
  selected: filterTabs[0], firstName: '', lastName: '', filters: null, limit: 30, sortBy: 'sortByTimestamp', insuranceCompanyId: null,
};

export function setAuthorizationListFilters(toSet) {
  return { type: SET_FILTERS, toSet };
}

export default function authorizationList(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, ...action.toSet };
    default:
      return state;
  }
}
