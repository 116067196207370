import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export default styled.div`
  border-radius: 50%;
  width: ${props => props.width || 20}px;
  height: ${props => props.height || 20}px;
  border: 4px solid ${props => props.color || 'rgba(255,255,255,0.2)'};
  border-top-color: ${props => props.colorTop || 'rgba(255,255,255)'};
  animation: ${spin} 1s infinite linear;
  margin-right: 20px;
`;
