import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { withCreateSupportedFollowUp } from '../graphql/SupportedAuthorizationFollowUp';

export class SupportedFollowUpButton extends PureComponent {
  click = async (e) => {
    const { createSupportedFollowUp, alert, id } = this.props;
    e.stopPropagation();
    try {
      await createSupportedFollowUp({ variables: { id } });
      alert.success('Successfully requested an Authorization Follow Up from SamaCare');
    } catch (err) {
      alert.error('There was an error requesting the Authorization Follow Up');
    }
  };

  render() {
    const { children } = this.props;
    return (<div onClick={this.click}>{children}</div>);
  }
}

export const SupportedFollowUpButtonWithAlert = withAlert(SupportedFollowUpButton);

export default compose(withCreateSupportedFollowUp)(SupportedFollowUpButtonWithAlert);
