import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import Select from 'react-select';
import { PhoneCleave } from 'Segment/BaseComponents';
import { BaseMultiline } from 'Segment/StyledComponents';

import { withCreateFormNumber } from '../../../../graphql/FormNumber';
import BaseButton from '../../../../components/BaseButton';
import Modal from '../../../../components/Modal';
import { ModalBodyLarge } from '../../../../components/ModalStyledComponents';

const Container = styled.div`
  padding: 20px 0;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FormSubmitButton = styled(BaseButton)`
  margin-left: 10px;
`;

class SelectPhoneList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      number: props.number,
      description: '',
      insuranceCompanyId: null,
    };
  }

  setNewNumber = () => {
    const { createFormNumber, onClose, alert } = this.props;
    const { number, description, insuranceCompanyId } = this.state;

    if (!insuranceCompanyId || !number || !description) {
      alert.error('Missing required field');
      return;
    }

    createFormNumber({ variables: { number, description, insuranceCompanyId } })
      .then(() => { alert.success('Success'); onClose(); })
      .catch((e) => { alert.error(`Error: ${e.message}`); });
  }

  render() {
    const { insuranceCompanies, onClose } = this.props;
    const { number, description, insuranceCompanyId } = this.state;

    return (
      <Modal header="Add Fax Number" onClick={onClose} open>
        <ModalBodyLarge>
          <Container>
            <SelectorTitle>Fax Number</SelectorTitle>
            <PhoneCleave
              number={number}
              onChange={(e) => { this.setState({ number: e.target.value }); }}
            />
            <SelectorTitle>Associate an insurance company</SelectorTitle>
            <Select
              onChange={(selected) => { this.setState({ insuranceCompanyId: _.get(selected, 'id', null) }); }}
              labelKey="name"
              valueKey="id"
              options={insuranceCompanies}
              value={_.find(insuranceCompanies, { id: insuranceCompanyId }) || {}}
            />
            <SelectorTitle>Description</SelectorTitle>
            <BaseMultiline onChange={(e) => { this.setState({ description: e.target.value }); }} value={description} />
          </Container>
          <ButtonContainer>
            <FormSubmitButton onClick={this.setNewNumber}>Set New Number</FormSubmitButton>
          </ButtonContainer>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default compose(withCreateFormNumber)(withAlert(SelectPhoneList));
