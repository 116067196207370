import React, { PureComponent } from 'react';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import colors from 'Resources/colors';

import FormList from './FormList';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SelectPhoneList from './SelectPhoneList';
import FormConfirmationView from './FormConfirmationView';
import { withUpdateAuthorization, withAvailityLookup } from '../../../../graphql/Authorization';
import Modal from '../../../../components/Modal';
import { ModalBodyLarge } from '../../../../components/ModalStyledComponents';

class SelectFormModal extends PureComponent {
  state = { step: 0, loading: true, authorizationFormId: null, availitySuggestedNumber: null };

  componentDidMount = async () => {
    const { availityLookup, authorization } = this.props;
    try {
      const availityLookupResponse = await availityLookup({ variables: {
        id: authorization.id,
      } });
      const availitySuggestedNumber = _.get(availityLookupResponse, 'data.availityLookup');
      if (availitySuggestedNumber) {
        this.setState({ availitySuggestedNumber });
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  setFormNumber = (formNumberId) => {
    const { authorization, updateAuthorization, onSubmit, alert } = this.props;

    updateAuthorization({ variables: { id: authorization.id, patch: { FormNumberId: formNumberId } } })
      .then(onSubmit)
      .catch(() => {
        alert.error('There was an error setting this submission number');
      });
  }

  selectForm = (selectedFormId) => {
    const { updateAuthorization, authorization, alert } = this.props;

    updateAuthorization({ variables: { id: authorization.id, patch: { formId: selectedFormId } } })
      .then(() => {
        this.setState({ step: 1, authorizationFormId: selectedFormId });
      })
      .catch(() => {
        alert.error('There was an error selecting an insurance form');
      });
  }

  render() {
    const {
      open, close, authorization, insuranceCompanyId, insuranceName, state, jCode,
    } = this.props;
    const { step, loading, availitySuggestedNumber, authorizationFormId } = this.state;

    let headerText = 'Select payer form';
    if (step === 1) { headerText = 'Confirm payer form'; }
    if (step === 2) { headerText = 'Select payer fax number'; }
    return (
      <Modal header={headerText} onClick={close} open={open}>
        <ModalBodyLarge>
          { step === 0 && (
            <FormList
              jCode={jCode}
              authorizationId={authorization.id}
              insuranceName={insuranceName}
              insuranceCompanyId={insuranceCompanyId}
              onSelect={this.selectForm}
              state={state}
            />
          ) }
          { step === 1 && (
            <FormConfirmationView
              formId={authorizationFormId}
              onBack={() => { this.setState({ step: 0 }); }}
              onSubmit={() => { this.setState({ step: 2 }); }}
            />
          ) }
          { step === 2 && (loading ? <LoadingSpinner style={{ margin: '100px auto' }} colorTop={colors.darkPurple} color={colors.purple} /> : (
            <SelectPhoneList
              authorizationFormId={authorizationFormId}
              insuranceCompanyId={insuranceCompanyId}
              state={state}
              onSelect={this.setFormNumber}
              faxSuggestionOverride={availitySuggestedNumber}
              onBack={() => { this.setState({ step: 1 }); }}
            />
          ))}
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default compose(withUpdateAuthorization, withAvailityLookup)(withAlert(SelectFormModal));
