import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import { withDefaultProps } from './utils';
import patientIntegrationCredentialInfo from './fragments/patientIntegrationCredentialInfo';

const PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME = 'PatientIntegrationCredentialsFindAll';

const withPatientIntegrationCredentialsQuery = gql`
  query ${PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME} {
    patientIntegrationCredentialsFindAll {
      ...patientIntegrationCredentialInfo
    }
  }
  ${patientIntegrationCredentialInfo}
`;

export const withPatientIntegrationCredentials = graphql(withPatientIntegrationCredentialsQuery, {
  props: withDefaultProps(({ data }) => ({ patientIntegrationCredentials: _.get(data, 'patientIntegrationCredentialsFindAll') })),
  options: { fetchPolicy: 'network-only' },
});

const withPatientIntegrationTitlesQuery = gql`
  query patientIntegrationTitlesFindAll {
    patientIntegrationTitlesFindAll
  }
`;

export const withPatientIntegrationTitles = graphql(withPatientIntegrationTitlesQuery, {
  props: withDefaultProps(({ data }) => ({ patientIntegrationTitles: _.get(data, 'patientIntegrationTitlesFindAll') })),
});

const withUpsertPatientIntegrationCredentialMutation = gql`
  mutation upsertPatientIntegrationCredential($id: Int, $institutionId: Int $patch: JSON!) {
    upsertPatientIntegrationCredential(id: $id, institutionId: $institutionId, patch: $patch) {
      ...patientIntegrationCredentialInfo
    }
  }
  ${patientIntegrationCredentialInfo}
`;

export const withUpsertPatientIntegrationCredential = graphql(
  withUpsertPatientIntegrationCredentialMutation,
  {
    name: 'upsertPatientIntegrationCredential',
    options: { refetchQueries: () => [PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME] },
  }
);
