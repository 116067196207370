import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'react-apollo';

import ROUTE_PATHS from '../../ROUTE_PATHS';
import UpdateAuthorizationStatusButton from '../../../components/UpdateAuthorizationStatusButton';
import { withUpdateAuthorization } from '../../../graphql/Authorization';
import BaseButton from '../../../components/BaseButton';
import ReportError from '../ReportError';
import PortalPriorSteps from '../PortalPriorSteps';

const DecisionButton = styled(BaseButton)`
  color: ${props => props.isSelected ? 'white' : props.theme.purple};
  background-color: ${props => props.isSelected ? props.theme.purple : 'white'};
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin: 5px 0;
  }
`;

const ButtonWrapper = styled.div`
  flex-direction: column;
`;

const NextStepWrapper = styled.div`
  margin-top: 10px;
  max-width: 525px;
`;

const MessageTitleWrapper = styled.div`
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: ${({ isAccentuated }) => isAccentuated ? 500 : 400};
  color: ${({ isAccentuated, theme }) => isAccentuated ? theme.red : theme.defaultFontColor};
`;

const TextWrapper = styled.div`
  margin: 10px 0;
`;

const BoldTextWrapper = styled.div`
  font-weight: bold;
`;

export const errorModalConstants = {
  RETRY: 'retry',
  OTHER: 'other',
  PRIOR_STEPS: 'priorSteps',
  LAST_STEP: 'lastStep',
  REPORT_ERROR: 'reportError',
  PATIENT_DETAILS: 'patientDetails',
  PRESCRIBER_DETAILS: 'prescriberDetails',
  RESUBMISSION: 'resubmission',
  SAMA_ERROR: 'samaError',
  UPDATE_STATUS_AND_COMPLETE_AUTHORIZATION: 'UPDATE_STATUS_AND_COMPLETE_AUTHORIZATION',
};

const portalErrorGroupsConfig = CONFIG.CONSTANTS.PORTAL_AUTH_ERROR_GROUPS;
export class PortalErrorMainNextStepActions extends PureComponent {
  state = {
    stepOneSelection: '',
    stepTwoSelection: '',
  };

  getFirstStepActions = () => {
    const { reenterCredentials, authorization, hideErrorModal, removeTerminalAndRetry, message } = this.props;
    const { RETRY, OTHER, REPORT_ERROR, SAMA_ERROR, UPDATE_STATUS_AND_COMPLETE_AUTHORIZATION } = errorModalConstants;

    const defaultFirstStepActions = [
      {
        title: 'I want to retry the authorization',
        action: () => {
          removeTerminalAndRetry();
        },
        dataFor: 'retrySubmission',
        dataTip: 'retrySubmission',
        isSelected: () => false,
        reactTooltip: 'Often times retrying will fix unexpected errors!',
        shouldAddMargin: true,
        [portalErrorGroupsConfig.ERROR_GROUP_KEY]: portalErrorGroupsConfig.RETRY_OR_REPORT_GROUP,
      },
      {
        title: 'I want to reinput some information from a previous step',
        action: () => {
          this.setState({
            stepOneSelection: RETRY,
            stepTwoSelection: '',
          });
        },
        isSelected: selection => selection === RETRY,
      },
      {
        title: 'Reinput login credentials',
        action: () => {
          this.setState({
            stepOneSelection: '',
            stepTwoSelection: '',
          });

          hideErrorModal();
          reenterCredentials();
        },
        isSelected: () => false,
        isHidden: _.reject(authorization.customPortalSteps, { isTerminal: true }).length !== 0,
      },
      {
        title: 'I can\'t tell what the error is',
        action: () => {
          this.setState({
            stepOneSelection: OTHER,
            stepTwoSelection: '',
          });
        },
        isSelected: selection => selection === OTHER,
      },
      {
        title: 'This looks like a SamaCare error',
        action: () => {
          this.setState({
            stepOneSelection: '',
            stepTwoSelection: REPORT_ERROR,
          });
        },
        isSelected: selection => selection === SAMA_ERROR,
        [portalErrorGroupsConfig.ERROR_GROUP_KEY]: portalErrorGroupsConfig.RETRY_OR_REPORT_GROUP,
      },
    ];

    if (message[portalErrorGroupsConfig.ERROR_GROUP_KEY] === portalErrorGroupsConfig.RETRY_OR_REPORT_GROUP) {
      return _.filter(defaultFirstStepActions, { [portalErrorGroupsConfig.ERROR_GROUP_KEY]: portalErrorGroupsConfig.RETRY_OR_REPORT_GROUP });
    }

    if (message[portalErrorGroupsConfig.ERROR_GROUP_KEY] === portalErrorGroupsConfig.TERMINATE_AUTH_WITH_STATUS_CHANGE) {
      return [{
        title: 'Update Status and Complete Authorization',
        action: () => {
          this.setState({
            stepOneSelection: UPDATE_STATUS_AND_COMPLETE_AUTHORIZATION,
            stepTwoSelection: '',
          });
        },
        isUpdateAuthorizationStatus: true,
        isSelected: selection => selection === SAMA_ERROR,
      }];
    }

    return defaultFirstStepActions;
  };

  getSecondStepActions = () => {
    const { retryFromLastStep, authorization, takeStepsBack, removeTerminalAndRetry } = this.props;
    const { LAST_STEP, RETRY, PRIOR_STEPS, PATIENT_DETAILS, PRESCRIBER_DETAILS, RESUBMISSION, REPORT_ERROR } = errorModalConstants;
    const { stepOneSelection } = this.state;

    return [
      {
        title: 'I would like to reinput information from',
        lastStepTitle: `${_.filter(authorization.customPortalSteps, { isTerminal: false }).length === 0
          ? null : _.last(_.filter(authorization.customPortalSteps, { isTerminal: false })).title}`,
        action: () => {
          this.setState({ stepTwoSelection: LAST_STEP });
          retryFromLastStep();
        },
        isHidden: stepOneSelection !== RETRY || _.filter(authorization.customPortalSteps, { isTerminal: false }).length === 0,
      },
      {
        title: 'I would like to reinput information from another previous question asked by this authorization',
        action: () => {
          this.setState({ stepTwoSelection: PRIOR_STEPS });
        },
        isSelected: selection => selection === PRIOR_STEPS,
        isHidden: stepOneSelection !== RETRY || _.filter(authorization.customPortalSteps, { isTerminal: false }).length === 0,
      },
      {
        title: 'Patient member ID or service dates',
        action: () => {
          this.setState({ stepTwoSelection: PATIENT_DETAILS });
          takeStepsBack(2);
          removeTerminalAndRetry();
        },
        isHidden: stepOneSelection !== RETRY,
      },
      {
        title: 'Prescriber or location details',
        action: () => {
          this.setState({ stepTwoSelection: PRESCRIBER_DETAILS });
          takeStepsBack(1);
          removeTerminalAndRetry();
        },
        isHidden: stepOneSelection !== RETRY,
      },
      {
        title: 'Yes',
        action: () => {
          this.setState({ stepTwoSelection: RESUBMISSION });
          removeTerminalAndRetry();
        },
        dataFor: 'retryButtonHelper',
        dataTip: 'retryButton',
        isHidden: stepOneSelection === '' || stepOneSelection === RETRY,
        reactTooltip: 'Click here to immediately retry submitting the authorization!',
      },
      {
        title: 'No, it\'s not',
        action: (e) => {
          e.stopPropagation();
          this.setState({ stepTwoSelection: REPORT_ERROR });
        },
        dataFor: 'reportErrorModal',
        dataTip: 'reportError',
        isHidden: stepOneSelection === '' || stepOneSelection === RETRY,
        reactTooltip: 'Click here to report this error!',
        isSelected: selection => selection === REPORT_ERROR,
      },
    ];
  };

  render() {
    const { message, authorization, unsetStep, resetToStep, toList, updateAuthorization } = this.props;
    const { stepOneSelection, stepTwoSelection } = this.state;
    const { RETRY, PRIOR_STEPS, REPORT_ERROR, SAMA_ERROR } = errorModalConstants;

    const isStandardErrorMessage = message.message.includes(CONFIG.CONSTANTS.PORTAL_STANDARD_ERROR_MESSAGE);

    const secondStepQuestion = selection => selection === RETRY
      ? 'What type of information would you like to reinput?' : 'Is this the first time experiencing this error with this authorization?';

    return (
      <div>
        <MessageTitleWrapper isAccentuated={!isStandardErrorMessage}>
          { message.message }
        </MessageTitleWrapper>
        <ModalButtonContainer>
          { _.map(this.getFirstStepActions(), ({
            isSelected,
            title,
            isHidden,
            dataFor = '',
            dataTip = '',
            shouldAddMargin,
            action,
            reactTooltip,
            isUpdateAuthorizationStatus,
          }) => {
            if (isHidden) return null;
            if (isUpdateAuthorizationStatus) {
              return (
                <UpdateAuthorizationStatusButton
                  authorization={authorization}
                  onUpdateSuccess={async () => {
                    await updateAuthorization({
                      variables: {
                        id: authorization.id,
                        patch: { submittedAt: new Date() },
                      },
                    });
                    toList();
                  }}
                  styleOverrides={{
                    width: '100%',
                    margin: 0,
                    padding: '10px',
                    fontSize: '16px',
                  }}
                >
                  { title }
                </UpdateAuthorizationStatusButton>
              );
            }

            return (
              <DecisionButton
                key={title}
                onClick={action}
                isSelected={isSelected(stepOneSelection)}
                data-for={dataFor}
                data-tip={dataTip}
                style={shouldAddMargin ? { margin: '0px 0px 20px' } : {}}
              >
                { title }
                { dataFor ? (
                  <ReactTooltip id={dataFor}>
                    { reactTooltip }
                  </ReactTooltip>
                ) : null }
              </DecisionButton>
            );
          }) }
        </ModalButtonContainer>
        <NextStepWrapper>
          <TextWrapper>
            { stepOneSelection !== '' ? secondStepQuestion(stepOneSelection) : null }
          </TextWrapper>
          { _.map(this.getSecondStepActions(), action => (
            action.isHidden ? null : (
              <ButtonWrapper>
                <ModalButtonContainer>
                  <DecisionButton
                    key={action.title}
                    onClick={action.action}
                    isSelected={action.isSelected && action.isSelected(stepTwoSelection)}
                    data-for={action.dataFor || ''}
                    data-tip={action.dataTip || ''}
                  >
                    <div>
                      <div>{action.title}</div>
                      <div>{action.lastStepTitle ? (<BoldTextWrapper>{action.lastStepTitle}</BoldTextWrapper>) : ''}</div>
                    </div>
                  </DecisionButton>
                  { action.dataFor ? (
                    <ReactTooltip id={action.dataFor}>
                      { action.reactTooltip }
                    </ReactTooltip>
                  ) : null }
                </ModalButtonContainer>
              </ButtonWrapper>
            )
          )) }
        </NextStepWrapper>
        <NextStepWrapper>
          { stepTwoSelection === PRIOR_STEPS && (
            <PortalPriorSteps
              authorization={authorization}
              resetToStep={resetToStep}
              unsetStep={unsetStep}
              isCondensed
            />
          )}
          { stepTwoSelection === REPORT_ERROR && (
            <ReportError
              isSamaError={stepOneSelection === SAMA_ERROR}
              authorization={authorization}
              imageURL={message.imageURL}
              errorMessage={message.errorMessage}
            />
          ) }
        </NextStepWrapper>
      </div>
    );
  }
}

const mapDispatchProps = dispatch => ({
  toList() {
    dispatch(push(ROUTE_PATHS.AUTHORIZATION_LIST));
  },
});

export default connect(() => ({}), mapDispatchProps)(compose(withUpdateAuthorization)(PortalErrorMainNextStepActions));
