import React from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import BaseButton from './BaseButton';
import { ModalBody, ModalFooter, ModalContent } from './ModalStyledComponents';

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  marginTop: 10px;
  ${BaseButton} {
    width: 100%;
    height: 40px;
  }
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
  margin: 10px 0;
  line-height: 125%;
`;

const ListElement = styled.li`
  margin: 10px 0;
`;

const SupportedAuthorizationModal = ({ open, closeModal }) => (
  <Modal header="SamaCare Supported Authorizations" open={open} onClick={closeModal}>
    <ModalBody>
      <CustomModalContent>
        <b>This is a SamaCare Supported Drug:</b>
        <ul style={{ margin: '10px 10px 0 0', textAlign: 'left' }}>
          <ListElement>
            We will proactively check on this request on your behalf to ensure the payer response is obtained as quickly as possible,
            based on our data about how fast the payer typically responds.
          </ListElement>
          <ListElement>
            For Supported Drugs, you can also request our support team follow up on the authorization by clicking
            &quot;Request Follow Up&quot; on the dashboard next to a drug.
          </ListElement>
          <ListElement>Supported Authorizations have a 50% faster resolution time on average.</ListElement>
        </ul>
      </CustomModalContent>
      <CustomModalFooter>
        <BaseButton onClick={closeModal}>Return</BaseButton>
      </CustomModalFooter>
    </ModalBody>
  </Modal>
);

export default SupportedAuthorizationModal;
