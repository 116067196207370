import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import DownloadFormsModal from './DownloadFormsModal';
import ROUTE_PATHS from '../routes/ROUTE_PATHS';
import FollowUpModal from './FollowUpModal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

class FollowUpButton extends PureComponent {
  state = {
    showFollowUpModal: false,
  };

  closeModal = () => { this.setState({ showFollowUpModal: false }); }

  render() {
    const { showFollowUpModal } = this.state;
    const { followUp, correspondence, children, toAuthorization, authorizationId } = this.props;
    return (
      <ButtonWrapper onClick={(e) => { e.stopPropagation(); this.setState({ showFollowUpModal: true }); }}>
        {showFollowUpModal && (CONFIG.CONSTANTS.FOLLOW_UP_TYPES.PRINTABLE_FORM === followUp.type
          ? (
            <DownloadFormsModal
              header="Print Forms"
              onClick={this.closeModal}
              authorizationId={authorizationId}
              open
            />
          )
          : (
            <FollowUpModal
              redirect={() => { toAuthorization(authorizationId); }}
              followUpId={followUp.id}
              correspondence={correspondence}
              onClose={this.closeModal}
            />
          )
        )}
        { children }
      </ButtonWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toAuthorization(id) {
    const { step } = CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL.RESPONSE;
    const route = `${ROUTE_PATHS.FORM_AUTHORIZATION}?step=${step}&id=${id}`;
    dispatch(push(route));
  },
});

export default connect(() => ({}), mapDispatchToProps)(FollowUpButton);
