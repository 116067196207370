import gql from 'graphql-tag';

export default gql`
  fragment formNumberInfo on FormNumber {
    id
    number
    description
    forbiddenReason
    isFormSpecified
    canBeDeletedByEndUser
  }
`;
