import { withAlert } from 'react-alert';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import FilterBox from './FilterBox';

const EmptyFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  width: 50%;
  margin: 0 8px 16px 0;
  height: 200px;
  border-radius: 3px;
  background-color: ${props => props.theme.lightPurple};
  text-align: center;
  &:hover {
    border: 1px solid ${props => props.theme.purple};
    cursor: pointer;
    background-color: white;
  }
`;
const { SUGGEST, HIDE_UNLESS } = CONFIG.CONSTANTS.FILTER_TYPES;
class FiltersContainer extends PureComponent {
  addFilter = async (type) => {
    const { upsertFunction, alert, associationKey, associationId } = this.props;
    try {
      await upsertFunction({ variables: { [associationKey]: associationId, type } });
      alert.info('Successfully created filter');
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  }

  render() {
    const { suggestFilter, hideFilter, upsertFunction, associationId, associationKey } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        {suggestFilter ? (
          <FilterBox
            states={suggestFilter.states}
            jCodes={suggestFilter.jCodes}
            type={suggestFilter.type}
            key={`filter-box-${suggestFilter.id}`}
            id={suggestFilter.id}
            upsertFunction={upsertFunction}
            associationKey={associationKey}
            associationId={associationId}
            filterText="Suggest Filter"
          />
        ) : <EmptyFilter onClick={() => { this.addFilter(SUGGEST); }}>Click to create a Suggest Filter</EmptyFilter>}
        {hideFilter ? (
          <FilterBox
            states={hideFilter.states}
            jCodes={hideFilter.jCodes}
            type={hideFilter.type}
            key={`filter-box-${hideFilter.id}`}
            id={hideFilter.id}
            upsertFunction={upsertFunction}
            associationKey={associationKey}
            associationId={associationId}
            filterText="HideUnless Filter"
          />
        ) : <EmptyFilter onClick={() => { this.addFilter(HIDE_UNLESS); }}>Click to create a HideUnless Filter</EmptyFilter>}
      </div>
    );
  }
}

export default withAlert(FiltersContainer);
