import React, { PureComponent } from 'react';

import BaseButton from '../BaseButton';

class SegmentButton extends PureComponent {
  render() {
    const { onClick, text, disabled } = this.props;

    return (
      <BaseButton
        disabled={disabled}
        onClick={onClick}
      >
        { text }
      </BaseButton>
    );
  }
}

export default SegmentButton;
