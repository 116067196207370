import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'connected-react-router';

import 'url-search-params-polyfill';
import { Provider as AlertProvider } from 'react-alert';
import colors from 'Resources/colors';
import dimensions from 'Resources/dimensions';
import AlertTemplate from 'react-alert-template-basic';
// This needs to be /index - it's an ie11 thing
import 'unfetch/polyfill/index';
import './reset.css';
import 'sanitize.css/sanitize.css';
import 'react-select/dist/react-select.css';
import './mixpanel';

import UnsupportedBrowser from './UnsupportedBrowser';
import apolloClient from './apollo';
import App from './App';
import store, { history } from './reduxStore';

const GlobalStyles = createGlobalStyle`
  html, body, #app {
    color: #212121;
    height: 100%;
    font-family: 'Avenir', 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
    margin: 0;
    padding: 0;
  }
  *::placeholder {
    color: #CCCCCC;
  }
`;

const MOUNT_NODE = document.getElementById('app');

const options = {
  position: 'top right',
  timeout: 5000,
  offset: '30px',
  transition: 'scale',
};

// These variables are set in client/index.js
// eslint-disable-next-line
const isSupported = !(isIE || isEdge);

render(
  (
    <Provider store={store}>
      { !isSupported && (<UnsupportedBrowser />) }
      <ThemeProvider theme={{ ...colors, ...dimensions }}>
        <ConnectedRouter history={history}>
          <ApolloProvider client={apolloClient}>
            <AlertProvider template={AlertTemplate} {...options}>
              <App />
            </AlertProvider>
            <GlobalStyles />
          </ApolloProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  ),
  MOUNT_NODE
);
