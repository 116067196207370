import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import _ from 'lodash';
import { withForms, withReportMissingInformation } from '../../../../graphql/AuthorizationForm';
import BaseButton from '../../../../components/BaseButton';
import MissingInformationModalContent from '../MissingInformationModalContent';

const TitleContainer = styled.div`
  font-weight: 500;
  padding: 10px 0 10px 0;
  margin: 10px 5px 10px 5px;
  font-size: 12px;
  border-bottom: 2px solid ${props => props.theme.darkGray};
`;

const ScrollContainer = styled.div`
  max-height: 250px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const themeColor = '#003d88';
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ margin }) => margin}px;
  border-left: 4px solid ${themeColor};
  padding-left: 10px;
`;

const FormDetails = styled.div`
  flex: 1;
`;

const FormDescription = styled.div`
  font-size: 12px;
  color: ${props => props.theme.gray};
`;

const FormTitle = styled.div`
  font-weight: 500;
`;

const FormPreview = styled.a`
  font-weight: 500;
  pointer: cursor;
`;

const SelectFormButton = styled(BaseButton)`
  padding: '5px';
  font-size: 12px;
  margin-left: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const EmptyState = styled.div`
  font-size: 16px;
  margin: 20px;
  text-align: center;
`;

export class FormList extends PureComponent {
  state = { requestNewForm: false };

  renderFormContent = (index, form, onSelect) => (
    <FormContainer margin={index === 0 ? '0' : '10'} key={`formSelectContainer-formId-${form.id}`}>
      <FormDetails>
        <FormTitle>
          {form.title}
          {' '}
          <FormPreview href={form.pdfURL} target="_blank">(Click to Preview Form)</FormPreview>
        </FormTitle>
        <FormDescription>{form.description}</FormDescription>
      </FormDetails>
      <SelectFormButton color={themeColor} onClick={() => { onSelect(form.id); }}>Select Form</SelectFormButton>
    </FormContainer>
  );

  renderMissingFormButton = () => (
    <ButtonContainer>
      <BaseButton
        color="#c02d4c"
        onClick={() => { this.setState({ requestNewForm: true }); }}
      >
        The Form I need is Missing
      </BaseButton>
    </ButtonContainer>
  );

  renderFormTitles = (form, forms) => (
    <TitleContainer>
      { form.title }
      { ` (${forms.length} Forms)` }
    </TitleContainer>
  );

  render() {
    const { onSelect, forms, insuranceName, authorizationId, state, insuranceCompanyId, referralForms } = this.props;
    const { requestNewForm } = this.state;

    if (_.get(forms, 'hasForms')) {
      const { stateForms, payerForms, suggestedForms } = forms;
      if ((suggestedForms.length || payerForms.length || stateForms.length) && !requestNewForm) {
        return (
          <div>
            { _.map(_.filter([
              { title: 'Suggested Forms', forms: suggestedForms },
              { title: `${insuranceName} Mandated Forms`, forms: payerForms },
              { title: 'State Mandated Forms', forms: stateForms },
            ], 'forms.length'), config => (
              <div key={`form_selection_modal_${config.title}`}>
                { this.renderFormTitles(config, config.forms) }
                <ScrollContainer>
                  { _.map(config.forms, (form, index) => this.renderFormContent(index, form, onSelect)) }
                </ScrollContainer>
              </div>
            )) }
            { this.renderMissingFormButton() }
          </div>
        );
      }
    } else if (!_.isEmpty(referralForms) && !requestNewForm) {
      return (
        <div>
          { _.map(referralForms, (form, index) => (
            <div key={`referral_form_selection_modal_${form.title}`}>
              { this.renderFormTitles(form, referralForms) }
              <ScrollContainer>
                { this.renderFormContent(index, form, onSelect) }
              </ScrollContainer>
            </div>
          )) }
          { this.renderMissingFormButton() }
        </div>
      );
    }

    if (requestNewForm) {
      return (
        <MissingInformationModalContent
          authorizationId={authorizationId}
          onCancel={() => { this.setState({ requestNewForm: false }); }}
          insuranceCompanyId={insuranceCompanyId}
          state={state}
        />
      );
    }

    return (
      <EmptyState>
        No forms were found matching the specified insurance and plan information. Please report the missing form using the button below.
      </EmptyState>
    );
  }
}

export default compose(withForms, withReportMissingInformation)(FormList);
