import React, { PureComponent } from 'react';

import AuthorizationTimelineModal from './AuthorizationTimelineModal';

export class AuthorizationTimelineButton extends PureComponent {
  state = { open: false };

  toggleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { children, authorization } = this.props;
    const { open } = this.state;
    return (
      <div onClick={(e) => { e.stopPropagation(); this.toggleModal(); }}>
        { children }
        {open && (
          <AuthorizationTimelineModal
            authorizationId={authorization.id}
            currentStatus={authorization.status}
            authorizationCreatedAt={authorization.createdAt}
            payer={authorization.insuranceCompany || { name: 'the payer' }}
            open={open}
            close={this.toggleModal}
          />
        )}
      </div>
    );
  }
}

export default AuthorizationTimelineButton;
