import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { BaseText } from 'Segment/StyledComponents';
import DownArrow from 'react-icons/lib/md/keyboard-arrow-down';
import UpArrow from 'react-icons/lib/md/keyboard-arrow-up';

import BaseButton from '../../components/BaseButton';

const TableContainer = styled.div`
  width: 700px;
  padding: 10px;
  border: 1px dashed ${props => props.theme.purple};
`;

const NameContainer = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProcedureRow = styled.tr`
  text-align: left;
  td, th {
    padding: 5px 10px;
  }
  th {
    font-weight: 500;
  }
`;

const ExpandButton = styled.div`
  padding: 3px 7px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  color: white;
  background-color: ${props => props.theme.darkGray};
  margin: auto;
  text-align: center;
  max-width: 125px;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.purple};
  }
`;

const MultiplierInput = styled(BaseText)`
  width: 75px;
`;

class IntegratedProcedureDetails extends PureComponent {
  state = { extended: false, multiplier: 1 };

  getProcedures() {
    const { authorization } = this.props;
    const { extended } = this.state;

    const procedures = _.sortBy(_.filter(authorization.integratedEMRProcedures, 'quantity'), (prc) => {
      if (prc.code[0].toLowerCase() === 'j') return 1;
      if (prc.code[0].toLowerCase() === 'q') return 2;
      if (prc.code[0].toLowerCase() === '9') return 3;
      return 4;
    });

    if (!extended) {
      return procedures.slice(0, 3);
    }

    return procedures;
  }

  getTableConfig = () => {
    const { isICDConfiguration, setICD, setHCPCS } = this.props;
    const { multiplier } = this.state;

    const procedures = this.getProcedures();

    if (isICDConfiguration) {
      return {
        headers: ['Procedure', 'HCPCS', 'ICD-10'],
        rows: _.map(procedures, (procedure) => {
          const icdsCleaned = _.map((procedure.icd || '').split(','), _.trim);
          return (
            <ProcedureRow key={`integratedProcedureDetails_${procedure.code}`}>
              <td><NameContainer style={{ maxWidth: '200px', overflow: 'ellipses' }}>{procedure.name}</NameContainer></td>
              <td>{procedure.code}</td>
              <td>
                { _.map(icdsCleaned, icd => (
                  <BaseButton
                    style={{ margin: 5, display: 'inline' }}
                    key={`${procedure.code}_${icd}`}
                    onClick={() => { setICD(icd); }}
                  >
                    { `+ ${icd}` }
                  </BaseButton>
                )) }
              </td>
            </ProcedureRow>
          );
        }),
      };
    }

    return {
      headers: ['Procedure', 'HCPCS', 'Quantity / Cycle', 'Cycles', 'Total', ''],
      rows: _.map(procedures, procedure => (
        <ProcedureRow key={`integratedProcedureDetails_${procedure.code}`}>
          <td><NameContainer style={{ maxWidth: '200px', overflow: 'ellipses' }}>{procedure.name}</NameContainer></td>
          <td>{procedure.code}</td>
          <td>{procedure.quantity}</td>
          <td><MultiplierInput value={multiplier} onChange={(e) => { this.setState({ multiplier: e.target.value }); }} type="number" /></td>
          <td>{procedure.quantity * (multiplier || 1)}</td>
          <td>
            <BaseButton
              onClick={() => { setHCPCS({ quantity: (procedure.quantity * (multiplier || 1)).toString(), code: procedure.code }); }}
            >
              Add
            </BaseButton>
          </td>
        </ProcedureRow>
      )),
    };
  }

  render() {
    const { authorization } = this.props;
    const { extended } = this.state;

    const { headers, rows } = this.getTableConfig();

    return (
      <TableContainer>
        <div style={{ margin: '0 10px 0 0', fontWeight: 'bold' }}>Superbill Details</div>
        <table>
          <tbody>
            <ProcedureRow>
              { _.map(headers, header => (<th key={`IntegratedProcedureHeader_${header}`}>{ header }</th>)) }
            </ProcedureRow>
            { rows }
          </tbody>
        </table>
        <div>
          { authorization.integratedEMRProcedures.length > 3 && (
            <ExpandButton onClick={() => { this.setState({ extended: !extended }); }}>
              { extended ? (
                <>
                  {'Less '}
                  <UpArrow />
                </>
              ) : (
                <>
                  {'More '}
                  <DownArrow />
                </>
              ) }
            </ExpandButton>
          ) }
        </div>
      </TableContainer>
    );
  }
}

export default IntegratedProcedureDetails;
