import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';
import { BaseText } from 'Segment/StyledComponents';
import { PhoneCleave } from 'Segment/BaseComponents';

import BaseButton from '../../../components/BaseButton';
import CustomCheckbox from '../../../components/CustomCheckbox';
import { withUpdateInstitution, withDeleteInstitution } from '../../../graphql/Institution';
import { withPortalList } from '../../../graphql/Portal';

const InputLabel = styled.div`
  font-weight: 500;
  margin: 8px 0;
`;

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PortalName = styled.div`
  margin: 8px 0;
  color: ${props => props.theme.purple};
`;

const TitleTag = styled.div`
  color: ${props => props.theme.lightGray};
  margin: 0 8px;
`;

export class SupportInstitutionView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      institution: props.institution,
    };
  }

  deleteInstitution = async () => {
    const { deleteInstitution, institution, alert } = this.props;

    try {
      await deleteInstitution({ variables: { id: institution.id } });
      alert.success('Success');
    } catch {
      alert.error('Error deleting institution. Make sure you have deleted all authorizations associated with this institution');
    }
  }

  handleChange = (e) => {
    const { target } = e;
    const { institution } = this.state;
    this.setState({ institution: { ...institution, [target.name]: target.value } });
  }

  updateSelectedInstitution = async () => {
    const { updateInstitution, alert } = this.props;
    const { institution } = this.state;
    const { integratedPortals, featureFlagsRaw } = institution;

    try {
      await updateInstitution({
        variables: { id: institution.id,
          patch: { ...institution, featureFlags: featureFlagsRaw, integratedPortals: _.map(integratedPortals, portal => _.pick(portal, ['title', 'key'])) } },
      });
      alert.success('Success');
    } catch (e) {
      alert.error('There was an error updating this institution');
    }
  }

  render() {
    const { institution } = this.state;
    const { portalList } = this.props;
    const { FEATURE_FLAGS } = CONFIG.CONSTANTS;
    const portalColumns = _.chunk(institution.integratedPortals, 4);

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
          <InputLabel>Institution Name</InputLabel>
          <BaseText placeholder="Institution Name" name="name" onChange={this.handleChange} value={institution.name || ''} />
          <InputLabel>Institution Fax Number</InputLabel>
          <PhoneCleave
            placeholder="Institution Fax Number"
            name="faxResponseNumber"
            onChange={this.handleChange}
            number={institution.faxResponseNumber || ''}
          />
          <InputLabel>Institution Practicing Specialty</InputLabel>
          <StyledSelect
            value={{ value: institution.practicingSpecialty, label: institution.practicingSpecialty }}
            onChange={({ value }) => { this.handleChange({ target: { name: 'practicingSpecialty', value } }); }}
            options={_.map(_.keys(CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES), type => ({ value: type, label: type }))}
            clearable={false}
          />
          <InputLabel>Feature Flags</InputLabel>
          {_.map(_.values(FEATURE_FLAGS), flag => (
            <div key={`flag-row-${flag}`} style={{ display: 'flex', margin: '8px' }}>
              <CustomCheckbox
                checked={_.includes(institution.featureFlagsRaw, flag)}
                onChange={() => {
                  if (_.includes(institution.featureFlagsRaw, flag)) {
                    this.handleChange({ target: { name: 'featureFlagsRaw',
                      value: _.pull(institution.featureFlagsRaw, flag) } });
                  } else {
                    this.handleChange({ target: { name: 'featureFlagsRaw',
                      value: [...institution.featureFlagsRaw, flag] } });
                  }
                }}
              />
              <div style={{ margin: 'flex', marginLeft: '8px' }}>{flag}</div>
            </div>
          ))}
        </div>
        <div style={{ width: '80%', display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '8px', alignItems: 'center' }}>
            <InputLabel>Portals</InputLabel>
            <TitleTag>(Edit Input Field to Change Portal Title this Practice Will See, Click X to Remove a Portal for this Practice)</TitleTag>
            <BaseButton style={{ margin: '0 10px 0 auto' }} onClick={this.deleteInstitution}>Delete Institution</BaseButton>
            <BaseButton style={{ marginLeft: 0 }} onClick={this.updateSelectedInstitution}>Update</BaseButton>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {_.map(portalColumns, (column, i) => (
              <div key={`portalColumns-${i}`} style={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
                {_.map(column, ({ key }) => (
                  <div key={key} style={{ display: 'block', margin: '8px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <PortalName>{key}</PortalName>
                      <BaseButton
                        onClick={() => {
                          this.handleChange({ target: { name: 'integratedPortals', value: _.reject(institution.integratedPortals, { key }) } });
                        }}
                        style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px' }}
                      >
                        X
                      </BaseButton>
                    </div>
                    <BaseText
                      placeholder="Portal Title"
                      onChange={(e) => {
                        this.handleChange({ target: { name: 'integratedPortals',
                          value: _.map(institution.integratedPortals, portal => portal.key === key ? ({ key, title: e.target.value }) : portal),
                        } });
                      }}
                      value={_.get(_.find(institution.integratedPortals, { key }), 'title')}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
              <StyledSelect
                style={{ width: '400px' }}
                onChange={({ value }) => {
                  this.handleChange({ target: { name: 'integratedPortals', value: [...institution.integratedPortals, { key: value, title: 'NEW PORTAL' }] } });
                }}
                options={_.map(_.difference(portalList, _.map(institution.integratedPortals, 'key')), type => ({ value: type, label: type }))}
                placeholder="Select a Portal Key to add it to this institution..."
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withDeleteInstitution, withUpdateInstitution, withPortalList)(withAlert(SupportInstitutionView));
