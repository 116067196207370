import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Select from 'react-select';

const StyledSelect = styled(Select)`
  width: 100%;
  border: ${props => props.highlight ? `2px solid ${props.theme.red}` : ''};
`;

class SegmentOptions extends PureComponent {
  handleOptionChange = (keyToSet) => {
    const { handleChange, item } = this.props;
    handleChange(_.assign(_.reduce(item.options, (toSet, obj) => (_.assign(toSet, { [obj.key]: null })), {}), { [keyToSet]: 'On' }));
  }

  render() {
    const { item, result, disabled, highlightIfMissing } = this.props;

    return (
      <StyledSelect
        highlight={highlightIfMissing && !result}
        disabled={disabled}
        onChange={(e) => { this.handleOptionChange(_.get(e, 'value')); }}
        options={_.map(item.options, option => ({ value: option.key, label: option.title }))}
        value={result}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return _.reduce(_.pick(state.form.results, _.map(props.item.options, 'key')), (total, value, key) => (
    value ? { result: key } : total
  ), { result: null });
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentOptions);
