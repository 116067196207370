import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import ActionRequiredRowContainer from './ActionRequiredRowContainer';
import BaseButton from '../BaseButton';

const AutoAssociatedData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

const AutomatedRow = ({ i, keyOff, row, handleAutomatedRowClick }) => (
  <ActionRequiredRowContainer keyOff={keyOff} i={i} row={row}>
    <AutoAssociatedData>
      { 'An automated ' }
      <b>
        {_.upperFirst(_.replace(row.model.status, /_/g, ' '))}
      </b>
      { 'response has been associated with this authorization for ' }
      <b>
        { `${_.upperFirst(row.model.patient.firstName)} ${_.upperFirst(row.model.patient.lastName)}` }
      </b>
      { ' and requires your review.' }
      <BaseButton
        style={{ marginLeft: 'auto' }}
        onClick={async () => handleAutomatedRowClick(row)}
      >
        Review Response
      </BaseButton>
    </AutoAssociatedData>
  </ActionRequiredRowContainer>
);

export default AutomatedRow;
