import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { withApollo } from 'react-apollo';
import strings from 'Resources/strings';
import styled from 'styled-components';
import { BaseMultiline } from 'Segment/StyledComponents';

import { reportError } from '../graphql/RequestHelp';
import BaseButton from './BaseButton';
import AlertModal from './AlertModal';

const ResponseContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin-left: 10px;
    padding: 10px;
  }
`;
class ReportErrorModal extends PureComponent {
  state = { serverResponse: '', userReportedDetails: '' };

  handleReportError = () => {
    const { alert, client, error } = this.props;
    const { userReportedDetails } = this.state;
    const { title, details } = error;

    client.mutate({
      mutation: reportError,
      variables: { title, message: `${details}<br /><br />${userReportedDetails}` },
    }).then(({ data }) => this.setState({ serverResponse: data.reportError.message }))
      .catch(() => { alert.error(strings.MISSING_INFORMATION.ERROR); });
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { serverResponse, userReportedDetails } = this.state;

    return (
      <div>
        <AlertModal
          buttons={(
            <ModalButtonContainer>
              <BaseButton key="reportErrorModal-close" style={{ marginRight: 10 }} onClick={onClose}>{strings.COMMON.CLOSE}</BaseButton>
              { !serverResponse && (<BaseButton key="reportErrorModal-submit" onClick={this.handleReportError}>{strings.REPORT_ERROR.SUBMIT}</BaseButton>) }
            </ModalButtonContainer>
          )}
          content={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <BaseMultiline onChange={(e) => { this.setState({ userReportedDetails: e.target.value }); }} value={userReportedDetails} />
              {serverResponse && (<ResponseContainer>{ serverResponse }</ResponseContainer>) }
            </div>
          )}
          header={strings.REPORT_ERROR.MODAL_HEADER}
          open={isOpen}
          closeModal={onClose}
        />
      </div>
    );
  }
}

export default withAlert(withApollo(ReportErrorModal));
