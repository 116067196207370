import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import styled from 'styled-components';
import axios from 'axios';
import { withAlert } from 'react-alert';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import Select from 'react-select';

import AlertModal from '../../components/AlertModal';
import { withCreateTestPDF } from '../../graphql/TestEndpoints';
import { withUpsertForm } from '../../graphql/AuthorizationForm';
import BaseButton from '../../components/BaseButton';
import getFileByExtension from '../../util/getFileByExtension';
import { removeInvalidRadioGroups, setTabIndexOnBoxes, trimBoxesByMaxPageLength } from './taggerUtils';
import ImportConfigButton from './ImportConfigButton';
import ExportConfigButton from './ExportConfigButton';
import SaveButton from './SaveButton';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled(FlexContainer)`
  padding: 5px 0;
`;

const SelectContainer = styled.div`
  margin-left: auto;
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const ImportInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
`;

const RelativeButton = styled(BaseButton)`
  position: relative;
  margin-right: 10px;
`;

const TestViewButton = styled(BaseButton)`
  margin: 0 auto;
`;

class TaggerHeader extends PureComponent {
  state = { pageKeys: null, testPDF: null, pdfKey: null, importedRemoteFormId: null };

  newPDF = null;

  currentPDF = null;

  getFormMetadata = () => {
    const { imageContainer } = this.props;
    const { pageKeys } = this.state;

    return {
      NODE_ENV: CONFIG.NODE_ENV,
      pageKeys,
      taggedWidth: imageContainer.getBoundingClientRect().width,
      taggedHeight: imageContainer.getBoundingClientRect().height,
    };
  }

  resetState = () => {
    this.setState({ pageKeys: null, testPDF: null, pdfKey: null, importedRemoteFormId: null });
  }

  createTestPDF = () => {
    const { createTestPDF, alert, inputs, setLoading } = this.props;

    setLoading(true);
    axios.post('/upload', { name: this.currentPDF.name, contentType: CONFIG.CONSTANTS.CONTENT_TYPES.PDF, contentDisposition: 'inline' })
      .then((res) => {
        const data = _.reduce(res.data.fields, (dataField, fieldValue, fieldKey) => {
          dataField.append(fieldKey, fieldValue);
          return dataField;
        }, new FormData());
        data.append('file', this.currentPDF);

        return axios({
          method: 'POST',
          data,
          url: res.data.url,
        })
          .then(() => (createTestPDF({ variables: { fileKey: res.data.fields.key, formSpecification: inputs, formMetadata: this.getFormMetadata() } })))
          .catch(() => { throw new Error('Error uploading'); });
      })
      .then((testPDFres) => {
        this.setState({ testPDF: testPDFres.data.createTestPDF });
      })
      .catch((err) => { alert.error(`There was an error creating the test filled out PDF, message: ${err.message}`); })
      .finally(() => { setLoading(false); });
  }

  export = () => {
    const { inputs } = this.props;
    const zip = new JSZip();

    zip.file('output.pdf', this.currentPDF);
    zip.file('output.json', JSON.stringify(setTabIndexOnBoxes(removeInvalidRadioGroups(inputs))));
    zip.file('output_metadata.json', JSON.stringify(this.getFormMetadata()));
    zip.generateAsync({ type: 'blob' }).then(blob => (saveAs(blob, 'tagged.zip')));
  }

  upload = (pdfFile) => {
    const { alert, setLoading, newUpload } = this.props;

    setLoading(true);

    let file = pdfFile;
    if (!file) {
      const input = this.newPDF;
      file = getFileByExtension(input.files, 'pdf');
    }

    const pdf = new FormData();
    pdf.append('file', file);

    return axios.post('/taggerUpload', pdf)
      .then((res) => {
        newUpload(res.data.pages);
        this.currentPDF = file;
        this.resetState();
        this.setState({ pageKeys: res.data.pageKeys, testPDF: null, pdfKey: res.data.pdfKey });
        this.newPDF.value = '';
      })
      .catch((err) => { alert.error(err); })
      .finally(() => { setLoading(false); });
  }

  importConfig = () => {
    const { setNewInputs, alert } = this.props;
    const input = this.imported;

    if (input.files.length < 3) { return alert.error('Ensure you\'re uploading all three exported files associated with a PDF configuration'); }

    const config = _.find(input.files, file => (file.name === 'output.json'));
    this.currentPDF = getFileByExtension(input.files, 'pdf');
    this.resetState();

    return this.upload(this.currentPDF).then(() => {
      const formConfigReader = new FileReader();
      formConfigReader.onload = (event) => {
        const { pageKeys } = this.state;
        const inputs = JSON.parse(event.target.result);
        // trimBoxesByMaxPageLength is used because users will sometimes use the output.json of
        // a prior tagged form (large amt of overlap) on a new PDF.
        // The pain point this solves is that users will have to delete excess boxes if the prior output.json
        // had an excess amt of pages relative to the new pdf, and therefore excess boxes tagged
        setNewInputs(trimBoxesByMaxPageLength(inputs, pageKeys.length));
      };
      formConfigReader.readAsText(config);
    });
  }

  importRemoteConfig = (id, pdfURL, specification, imgKeys) => {
    const { setNewInputs, setNewImage, alert, setLoading } = this.props;
    setLoading(true);
    this.resetState();
    // Set new specs
    setNewInputs(specification);
    // Set keys
    this.setState({ pageKeys: imgKeys, importedRemoteFormId: id });

    axios.post('/imageUrls', { pageKeys: imgKeys }).then((res) => {
      setNewImage(res.data.pages);
      setLoading(false);
    }).catch(() => { alert.error('There was an error generating image URLs'); });

    axios.get(pdfURL, { responseType: 'blob' }).then((res) => {
      const file = new File([res.data], 'output.pdf', { type: 'application/pdf' });
      this.currentPDF = file;
    }).catch(() => { alert.error('There was an error setting the PDF'); });
  }

  upsertForm = async (id, opts) => {
    const { upsertForm, inputs, alert } = this.props;
    const { pdfKey, pageKeys } = this.state;
    const cleanedInputs = removeInvalidRadioGroups(inputs);

    if (id) {
      if (pdfKey) {
        return upsertForm({ variables: { formId: id, pdfKey, imgKeys: pageKeys, specification: cleanedInputs, metadata: this.getFormMetadata() } });
      }
      return upsertForm({ variables: { formId: id, specification: cleanedInputs, metadata: this.getFormMetadata() } });
    }

    const { title, description } = opts;
    if (title && description) {
      try {
        const { data } = await upsertForm({ variables: {
          formId: id, pdfKey, imgKeys: pageKeys, specification: cleanedInputs, metadata: this.getFormMetadata(), title, description,
        } });
        this.setState({ importedRemoteFormId: data.upsertForm.id });
      } catch {
        alert.error('Error upserting form');
      }
    }
    return alert.error('Both a title and description are required');
  }

  render() {
    const { pageLength, currentPage, step, displayByOptions, displayBy, setDisplayBy, toggleTestView, isTestView } = this.props;
    const { testPDF, importedRemoteFormId } = this.state;

    return (
      <Container>
        { !isTestView && (
          <FlexContainer>
            <RelativeButton>
              <ImportInput type="file" accept="application/pdf" ref={ref => this.newPDF = ref} onChange={() => { this.upload(); }} />
              Import a PDF
            </RelativeButton>
            <ImportConfigButton
              importLocalConfig={(importedFilesRef) => {
                this.imported = importedFilesRef;
                this.importConfig();
              }}
              importRemoteConfig={this.importRemoteConfig}
            />
            <ExportConfigButton exportLocally={this.export} upsertForm={this.upsertForm} importedRemoteFormId={importedRemoteFormId} />
            <SaveButton upsertForm={this.upsertForm} formId={importedRemoteFormId} />
            { pageLength > 0 && (
              <div>
                { (currentPage > 0) && (
                  <BaseButton onClick={() => { step(); }}>
                    Back
                  </BaseButton>
                ) }
                { (currentPage < pageLength - 1) && (
                  <BaseButton onClick={() => { step(true); }}>
                    Forward
                  </BaseButton>
                ) }
              </div>
            ) }
          </FlexContainer>
        ) }
        { pageLength > 0 && (
          <TestViewButton onClick={() => { toggleTestView(!isTestView); }}>{ isTestView ? 'Display Tagger View' : 'Display Test View' }</TestViewButton>
        ) }
        { pageLength > 0 && (
          <TestViewButton onClick={this.createTestPDF}>Create Test PDF</TestViewButton>
        ) }
        <SelectContainer>
          Displaying:
          <StyledSelect
            value={displayBy}
            onChange={setDisplayBy}
            options={displayByOptions}
            clearable={false}
            searchable={false}
          />
        </SelectContainer>
        <AlertModal
          buttons={(
            <BaseButton
              style={{ width: '100%' }}
              onClick={() => { this.setState({ testPDF: null }); }}
            >
              Close
            </BaseButton>
          )}
          content={(
            <div>
              <a target="_blank" rel="noopener noreferrer" href={testPDF}>Download test PDF</a>
            </div>
          )}
          header="Download Test PDF"
          open={!!testPDF}
          closeModal={() => { this.setState({ testPDF: null }); }}
        />
      </Container>
    );
  }
}

export default compose(withCreateTestPDF, withUpsertForm)(withAlert(TaggerHeader));
