import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const reportError = gql`
  mutation reportError($title: String, $message: String) {
    reportError(title: $title, message: $message)
  }
`;

export const withReportError = graphql(
  reportError,
  { name: 'reportError' },
);
