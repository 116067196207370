import gql from 'graphql-tag';

export default gql`
  fragment authorizationCorrespondenceInfo on AuthorizationCorrespondence {
    id
    createdAt
    startDate
    endDate
    fileURL
    mimeType
    isLatest
    authorizedProcedures
    code
    type
    isHidden
    InstitutionId
    patientMemberId
    patientLastName
    suggestedStatus
    fileAWSKey
  }
`;
