import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import colors from 'Resources/colors';

import BaseButton from './BaseButton';
import DetailsContainer from './DetailsContainer';

const AssociationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

const DetailTitle = styled.div`
  font-weight: bold;
  color: ${props => props.theme.purple};
`;

const DetailsSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const DetailContainer = styled.div`
  margin: 5px;
`;

const SeparatorLine = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.purple};
  margin: 10px 0;
`;

const ModalText = styled.div`
  padding-bottom: 10px;
  text-align: center;
  color: ${props => props.warn ? props.theme.red : ''};
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
`;

const AutoAssociationDetailsContainer = ({ authorization, handleReviewAutoAssociationConfirmation, handleIncorrectAutoAssociation }) => (
  <AssociationDetailsContainer>
    <DetailsContainer authorization={authorization} />
    <DetailTitle>Automated Response</DetailTitle>
    <DetailsSectionContainer>
      <DetailContainer>
        <b>Status</b>
        <div>{ _.upperFirst(authorization.status) }</div>
      </DetailContainer>
    </DetailsSectionContainer>
    <SeparatorLine />
    <ModalText>
      Does this payer response belong to this authorization?
      <div style={{ fontWeight: 'normal', fontSize: '16px', marginTop: '5px' }}>(Any incorrect automations will be fixed by the SamaCare team)</div>
    </ModalText>
    <BaseButton
      onClick={async () => { await handleReviewAutoAssociationConfirmation(authorization.id); }}
      style={{ width: '100%', fontSize: 16, padding: 5, margin: '15px 0px 5px' }}
      color={colors.green}
    >
      Yes, this response has been automated correctly
    </BaseButton>
    <BaseButton
      onClick={async () => { await handleIncorrectAutoAssociation(authorization.id); }}
      style={{ width: '100%', fontSize: 16, padding: 5, margin: '5px 0' }}
    >
      No, the automation is incorrect
    </BaseButton>
  </AssociationDetailsContainer>
);

export default AutoAssociationDetailsContainer;
