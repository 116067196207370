import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import Select from 'react-select';
import strings from 'Resources/strings';
import styled from 'styled-components';
import { setAuthorizationListFilters } from '../reducers/authorizationListReducer';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
`;

class ExpirationDateFilter extends PureComponent {
  render() {
    const { authorizationList, onChange } = this.props;
    const { filters } = authorizationList;

    return (
      <Container>
        <FilterLabel>
          {strings.AUTHORIZATIONS.EXPIRATION_DATE_FILTER_LABEL}
        </FilterLabel>
        <StyledSelect
          value={_.get(filters, 'expirationDate', 'NOW')}
          onChange={({ value }) => onChange({ ...filters, expirationDate: CONFIG.CONSTANTS.EXPIRATION_DATE_FILTERS[value] })}
          options={_.map(_.keys(CONFIG.CONSTANTS.EXPIRATION_DATE_FILTERS), key => ({ value: key, label: CONFIG.CONSTANTS.EXPIRATION_DATE_FILTERS[key].label }))}
          clearable={false}
          searchable={false}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: toSet => dispatch(setAuthorizationListFilters(toSet)),
  };
}

export const ExpirationDateFilterEnhanced = withAlert(ExpirationDateFilter);

export default connect(mapStateToProps, mapDispatchToProps)(ExpirationDateFilterEnhanced);
