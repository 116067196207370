import _ from 'lodash';
import gql from 'graphql-tag';
import React, { PureComponent } from 'react';
import { Query, compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';

import institutionInfo from '../../../graphql/fragments/institutionInfo';
import AlertModal from '../../../components/AlertModal';
import InstitutionView from './InstitutionView';
import BaseButton from '../../../components/BaseButton';
import { withCreateInstitution, SUPPORT_INSTITUTION_QUERY } from '../../../graphql/Institution';

const withInstitutionsQuery = gql`
  query ${SUPPORT_INSTITUTION_QUERY} {  
    institutionsFindAll {
      ...institutionInfo
      featureFlagsRaw
      integratedPortals
    }
  }
  ${institutionInfo}
`;


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
`;

const FilterContainer = styled.div`
  height: 100px;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  height: 100%;
  margin-top: 20px;
`;

const ViewContainer = styled.div`
  ${BaseButton} {
    margin-left: auto;
  }
`;

const InputLabel = styled.div`
  font-weight: 500;
`;

const OneLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  > div {
    width: 600px;
  }
`;

class SupportOnboarding extends PureComponent {
  state = { selectedInstitution: {}, alertModalOpen: false }

  createInstitution = async () => {
    const { createInstitution, alert } = this.props;

    try {
      const res = await createInstitution();
      this.setState({ selectedInstitution: res.data.createInstitution, alertModalOpen: false });
    } catch (e) {
      alert.error(`ERROR!!! ${e}`);
    }
  }

  render() {
    const { alertModalOpen, selectedInstitution } = this.state;

    return (
      <Query query={withInstitutionsQuery}>
        {({ loading, data }) => {
          if (loading) { return (<div>Loading</div>); }
          const institutions = data.institutionsFindAll;
          return (
            <MainContainer>
              <FilterContainer>
                <InputLabel>Select an Institution</InputLabel>
                <OneLine>
                  <Select
                    onChange={(selected) => { this.setState({ selectedInstitution: selected || {} }); }}
                    labelKey="name"
                    valueKey="id"
                    options={institutions}
                    value={selectedInstitution}
                  />
                  <BaseButton onClick={() => { this.setState({ alertModalOpen: true }); }}>Create New Institution</BaseButton>
                </OneLine>
              </FilterContainer>
              <ContentContainer>
                <ViewContainer>
                  {
                    // Need to use _.find because selectedInstitution can be stale
                    // Mapping a single item array with a dynamic key in order to force rerender
                    _.find(institutions, { id: selectedInstitution.id })
                    && (
                      <div key={`selectedInstitution-id-${selectedInstitution.id}`}>
                        <InstitutionView institution={_.find(institutions, { id: selectedInstitution.id })} />
                      </div>
                    )
                  }
                </ViewContainer>
              </ContentContainer>
              <AlertModal
                header="ARE YOU SURE?!"
                open={alertModalOpen}
                closeModal={() => { this.setState({ alertModalOpen: false }); }}
                content="Are you sure you want to create an institution?"
                buttons={
                  (<BaseButton style={{ width: '100%' }} key="createInstitutionButton" onClick={this.createInstitution}>DO IT!!!</BaseButton>)
                }
              />
            </MainContainer>
          );
        }}
      </Query>
    );
  }
}

export default compose(withCreateInstitution)(withAlert(SupportOnboarding));
