import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0;
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background: ${props => props.theme.lightGray};
    z-index: 1
  }
`;

const Block = styled.div`
  width: calc(50% + 8px);
  display: flex;
  justify-content: space-between;
  clear: both;
  float: ${({ left }) => left ? 'left' : 'right'};
  direction: ${({ left }) => left ? 'rtl' : ''};
`;

const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid;
  border-color: white;
  background: ${({ syntheticEvent, theme }) => syntheticEvent ? theme.lightGray : theme.purple};
  margin-top: 10px;
  z-index: 9999
`;

const Content = styled.div`
  width: 95%;
  padding: 0 10px;
`;

const Title = styled.div`
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ syntheticEvent, theme }) => syntheticEvent ? theme.darkGray : theme.purple};
`;

const Details = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  color: ${({ syntheticEvent, theme }) => syntheticEvent ? theme.dullGray : theme.darkGray};
`;

const Date = styled.div`
  color: ${({ syntheticEvent, theme }) => syntheticEvent ? theme.dullGray : theme.darkGray};
  font-size: 12px;
`;

const AuthorizationTimeline = ({ authorizationId, compiledEvents }) => (
  <Wrapper>
    <Container>
      {_.map(compiledEvents, ({ text, createdAt, details }, i) => {
        const isSynthetic = !createdAt;
        return (
          <Block key={`auth-${authorizationId}-authorizationEvent-${i}`} left={(i + 1) % 2 === 0}>
            <Marker syntheticEvent={isSynthetic} />
            <Content>
              <Title syntheticEvent={isSynthetic}>{text}</Title>
              <Details syntheticEvent={isSynthetic}>{details}</Details>
              { createdAt && (<Date>{ moment(createdAt).format('LL') }</Date>) }
            </Content>
          </Block>
        );
      })}
    </Container>
  </Wrapper>
);

export default AuthorizationTimeline;
