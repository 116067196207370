import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import dimensions from 'Resources/dimensions';
import strings from 'Resources/strings';
import styled from 'styled-components';

import BaseButton from '../../components/BaseButton';
import { withUpdatePassword } from '../../graphql/Account';
import { getFailedConditions, isPasswordComplexEnough } from '../../util/validatePassword';

const { RETURN_CODES } = CONFIG.CONSTANTS;

const Body = styled.div`
  margin: 20px 0;
`;
/* eslint-disable max-len */
const Input = styled.input`
  border: ${props => `1px solid ${props.theme.lightGray}`};
  padding: 10px ${props => props.paddingleft};
  border-radius: 3px;
  box-shadow: none;
  width: 100%;

  &:focus {
    border-color: ${props => props.theme.purple};
    outline:0;
  }

  &:invalid {
    border: 1px solid ${({ theme }) => theme.warningRed};
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBmaWxsPSIjZmY0MjQyIiBkPSJNMTEgMTVoMnYyaC0yem0wLThoMnY2aC0yem0uOTktNUM2LjQ3IDIgMiA2LjQ4IDIgMTJzNC40NyAxMCA5Ljk5IDEwQzE3LjUyIDIyIDIyIDE3LjUyIDIyIDEyUzE3LjUyIDIgMTEuOTkgMnpNMTIgMjBjLTQuNDIgMC04LTMuNTgtOC04czMuNTgtOCA4LTggOCAzLjU4IDggOC0zLjU4IDgtOCA4eiIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: right center;
    transition: none;
  }
`;
/* eslint-enable max-len */

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${dimensions.font_size};
  ${BaseButton} {
    flex: 1;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
  width: 400px;
  margin: auto;
`;

const Header = styled.div`
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  padding: 10px;
`;

const LogoContainer = styled.div`
  padding: 20px;
  text-align: center;
  margin: 50px 20px 20px 20px;
`;

const Logo = styled.img`
  height: 110px;
  width: 110px;
  margin: auto;
`;

const Label = styled.div`
  font-weight: 500;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  margin-top: 20px;
  color: ${props => props.theme.red};
`;

export class ResetPassword extends PureComponent {
  state = { password: '', errorMessage: '', isRequesting: false };

  onKeydown = (e) => {
    const code = e.keyCode || e.which;
    const ENTER_KEYCODE = 13;

    if (code === ENTER_KEYCODE) {
      this.onUpdatePassword();
    }
  };

  checkPasswordValidity = ({ target }) => {
    const isPasswordValid = isPasswordComplexEnough(target.value);
    this.setState({
      password: target.value,
      errorMessage: isPasswordValid ? '' : strings.RESET_PASSWORD.INVALID_PASSWORD(_.map(getFailedConditions(target.value), 'description')),
    });
  };

  onUpdatePassword = () => {
    const { updatePassword, alert, match, toRoot } = this.props;
    const { password } = this.state;
    this.setState({ isRequesting: true });
    updatePassword({
      variables: {
        password,
        nonce: (match && match.params) ? match.params.nonce : null,
      },
    }).then((response) => {
      switch (response.data.updatePassword.code) {
        case RETURN_CODES.OK:
          alert.success(strings.RESET_PASSWORD.SUCCESS_SETTING_PASSWORD);
          toRoot();
          break;
        case RETURN_CODES.PASSWORD_RESET_LINK_ERROR:
          alert.error(response.data.updatePassword.message);
          break;
        default:
          alert.error('Unexpected error. Please try again.');
          break;
      }
    }).catch(() => {
      alert.error(strings.RESET_PASSWORD.ERROR_SETTING_PASSWORD);
      this.setState({ isRequesting: false });
    });
  };

  render() {
    const { password, errorMessage, isRequesting } = this.state;

    return (
      <Container>
        <LogoContainer>
          <Logo src="../../assets/logo.svg" />
        </LogoContainer>
        <Header>
          { strings.RESET_PASSWORD.HEADER }
        </Header>
        <Body>
          <Label>{strings.RESET_PASSWORD.PASSWORD}</Label>
          <Input
            type="password"
            onChange={this.checkPasswordValidity}
            onKeyDown={this.onKeydown}
            placeholder={strings.RESET_PASSWORD.PASSWORD_PLACEHOLDER}
            value={password || ''}
            data-for="password_tooltip"
            data-tip=""
          />
          <ErrorMessage>{ password && errorMessage ? errorMessage : ''}</ErrorMessage>
          { password && errorMessage && (<ReactTooltip id="password_tooltip">{errorMessage}</ReactTooltip>) }
        </Body>
        <ModalFooter>
          <BaseButton
            onClick={this.onUpdatePassword}
            disabled={!password || errorMessage || isRequesting}
          >
            {strings.RESET_PASSWORD.UPDATE_PASSWORD}
          </BaseButton>
        </ModalFooter>
      </Container>
    );
  }
}

const mapDispatchProps = dispatch => ({
  toRoot() { dispatch(push('/')); },
});

export default connect(() => ({}), mapDispatchProps)(
  compose(withUpdatePassword)(withAlert(ResetPassword))
);
