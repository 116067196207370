import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { graphql, compose, Query } from 'react-apollo';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { withAuthorizationQuery, withUpdateAuthorizationStatus } from '../../../graphql/Authorization';
import { withUpdateAuthorizationCorrespondence } from '../../../graphql/AuthorizationCorrespondence';
import Modal from '../../../components/Modal';
import CorrespondenceViewer from '../../../components/CorrespondenceViewer';
import BaseButton from '../../../components/BaseButton';
import StatusRecommendation from './StatusRecommendation';
import DetailsSection from './DetailsSection';
import SetAutomatedCorrespondence from './SetAutomatedCorrespondence';
import DetailsContainer from '../../../components/DetailsContainer';

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const AuthorizationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

const withCorrespondenceFullText = gql`
  query withCorrespondenceFullText($id: Int!) {
    correspondenceById(id: $id) {
      fullText {
        text
      }
    }
  }
`;

const DetailTitle = styled.div`
  font-weight: bold;
  color: ${props => props.theme.purple};
`;

class UpdateAuthorizationDetailsButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestedStatus: props.correspondence.suggestedStatus,
      hideShowIsAutomatedCorrespondence: false,
    };
  }

  submit = async () => {
    const { setCorrespondenceOnAuthorization } = this.props;

    await setCorrespondenceOnAuthorization();
  }

  acceptSuggestedStatus = async () => {
    const { suggestedStatus } = this.state;
    const { setCorrespondenceOnAuthorization, updateAuthorizationStatus, authorizationId, alert } = this.props;
    try {
      await updateAuthorizationStatus({
        variables: {
          id: authorizationId,
          status: suggestedStatus,
        },
      });
      await setCorrespondenceOnAuthorization();
    } catch (err) {
      alert.error(`There was an error updating the Authorization ${err.message}`);
    }
  }

  rejectSuggestion = async () => {
    const { correspondence, updateAuthorizationCorrespondence } = this.props;
    await updateAuthorizationCorrespondence({
      variables: { id: correspondence.id, patch: { code: null, startDate: null, endDate: null, authorizedProcedures: [] } },
    });
    this.setState({ hideShowIsAutomatedCorrespondence: true });
  }

  render() {
    const { onClose, authorization, correspondence } = this.props;
    const { hideShowIsAutomatedCorrespondence, suggestedStatus } = this.state;
    const notApprovalSuggestion = suggestedStatus && suggestedStatus !== CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED;

    if (!authorization) { return (<div />); }

    const showIsAutomatedCorrespondence = correspondence.code
      && correspondence.endDate
      && correspondence.startDate
      && !_.isEmpty(correspondence.authorizedProcedures)
      && !hideShowIsAutomatedCorrespondence;

    return (
      <Modal onClick={onClose} open header="Associate Response - Don't Mess Up!">
        <Query
          query={withCorrespondenceFullText}
          variables={{ id: correspondence.id }}
        >
          {({ data, loading }) => {
            if (loading) return (<div />);
            const fullText = _.get(data.correspondenceById, 'fullText.text');

            return (
              <TwoPaneContainer>
                <CorrespondenceViewer correspondence={correspondence} width="700px" height="550px" />
                <AuthorizationDetailsContainer>
                  <DetailsContainer authorization={authorization} />
                  { !notApprovalSuggestion
                    ? (
                      <div>
                        <StatusRecommendation
                          suggestedStatus={suggestedStatus}
                          fullText={fullText}
                          authorization={authorization}
                        />
                        {
                          (showIsAutomatedCorrespondence || suggestedStatus) && (
                            <SetAutomatedCorrespondence
                              correspondence={correspondence}
                              set={this.submit}
                              rejectSuggestion={this.rejectSuggestion}
                              disabled={!_.includes(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE, authorization.status)}
                            />
                          )
                        }
                        {
                          !showIsAutomatedCorrespondence && (
                            <>
                              <DetailsSection
                                fullText={fullText}
                                key={`AuthorizationDetailsFor_${authorization.id}`}
                                authorization={authorization}
                                correspondenceId={correspondence.id}
                              />
                              <BaseButton
                                disabled={!_.includes(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE, authorization.status)}
                                style={{ width: '100%', marginTop: 'auto' }}
                                onClick={this.submit}
                              >
                                Associate and close
                              </BaseButton>
                            </>
                          )
                        }
                      </div>
                    )
                    : (
                      <div>
                        <DetailTitle>Automated Authorization Status Detected</DetailTitle>
                        <div style={{ margin: ' 16px 0' }}>
                          Would you like to set this authorization to
                          <strong>
                            {` ${suggestedStatus}`}
                          </strong>
                          ?
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <BaseButton
                            style={{ width: '48%', marginTop: 'auto' }}
                            onClick={this.acceptSuggestedStatus}
                          >
                            Associate and close
                          </BaseButton>
                          <BaseButton
                            style={{ width: '48%', marginTop: 'auto' }}
                            onClick={() => { this.setState({ suggestedStatus: null }); }}
                          >
                            Incorrect
                          </BaseButton>
                        </div>
                      </div>
                    )}
                </AuthorizationDetailsContainer>
              </TwoPaneContainer>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

const withAuthorization = graphql(withAuthorizationQuery, {
  options: props => ({ variables: { id: props.authorizationId } }),
  props({ data }) { return { authorization: data.authorizationById }; },
});

export default compose(withAuthorization, withUpdateAuthorizationCorrespondence, withUpdateAuthorizationStatus)(withAlert(UpdateAuthorizationDetailsButton));
