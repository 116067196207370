import React from 'react';
import _ from 'lodash';
import colors from 'Resources/colors';

const { DATE_FORMAT } = CONFIG.CONSTANTS;

export default {
  COMMON: {
    EMAIL_PLACEHOLDER: 'sama@pa.solution',
    CLOSE: 'Close',
    SEARCH: 'Search',
    NO: 'No',
    YES: 'Yes',
  },
  LOGIN: {
    HEADER: 'Log into your SamaCare account',
    LOGIN: 'Login',
    TERMS_OF_SERVICE: [
      'By logging in, you agree to SamaCare\'s  ',
      <a key="TOS_HREF" href="/sama_tos.pdf" target="_blank">Terms of Use</a>,
      ' and ',
      <a key="PRIVACY_HREF" href="/sama_privacy.pdf" target="_blank">Privacy Policy</a>,
      '. ',
      <a
        key="ANON_REPORTING"
        href="https://docs.google.com/forms/d/1npABPJ7RXVNiiv9l_fIu1rWkNhCQ3tqZq479zMie9WA/edit?usp=drive_web"
        target="_blank"
        rel="noopener noreferrer"
      >
        { 'Click here' }
      </a>,
      ' to anonymously report security issues or unethical behavior.',
    ],
    GENERAL_ERROR: 'Something on our side isn\'t working. Please check back in a few hours.',
    INVALID_LOGIN: 'Incorrect email or password',
    EXPIRED_ACCOUNT: `
      This account has been inactive for longer than 60 days.
      Please contact your practice administrator to reactivate your account through the SamaCare Admin Dashboard.
    `,
    EXPIRED_PASSWORD: `
      Your password has not been updated in 180 days and is expired.
      Please click Forgot Password button below to send yourself an email with a reset link.
    `,
    MAX_LOGINS_ATTEMPTED: `
      You have unsuccessfully attempted to login too many times.
      Please contact your practice administrator to reactivate your account through the SamaCare Admin Dashboard.
    `,
    EMAIL: 'Email',
    PASSWORD: 'Password',
  },
  RESET_PASSWORD: {
    FORGOT_PASSWORD: 'Forgot password',
    HEADER: 'Reset your SamaCare password',
    PASSWORD: 'Password',
    PASSWORD_PLACEHOLDER: 'Enter a new password',
    UPDATE_PASSWORD: 'Update password',
    CONFIRM_EMAIL: 'Confirm your email',
    SEND_RESET_LINK: 'Send reset password link',
    SUCCESS_SENDING_LINK: 'A reset password link has been sent. Please check your email.',
    SUCCESS_SETTING_PASSWORD: 'Your password has successfully been updated.',
    ERROR_SENDING_LINK: 'An error occurred while sending the reset password link. Please try again.',
    ERROR_INVALID_LINK: 'The reset password link used is invalid. Please return to the home page and follow the forgot password link again.',
    ERROR_SETTING_PASSWORD: 'An error occurred while setting your new password. Please try again.',
    INVALID_PASSWORD: failedConditions => `Your password must ${_.join(failedConditions, ', ')}.`,
    PASSWORD_REQ_UPPER: 'contain at least one uppercase letter',
    PASSWORD_REQ_LOWER: 'contain at least one lowercase letter',
    PASSWORD_REQ_NUMBER: 'contain at least one number',
    PASSWORD_REQ_SPECIAL: 'contain at least one special character',
    PASSWORD_REQ_LENGTH: 'have a minimum length of 8 characters',
  },
  AUTHORIZATIONS: {
    ERROR_FETCHING_RESULTS: 'There was an error fetching results',
    CHECK_MODAL: 'Request that the SamaCare team check on the status of this request.'
    + " Give at least 3 days after submission for us to call. You will receive an email from us when we're done.",
    CHECKUP_TIP: 'Request SamaCare team check-up on this authorization',
    DATE_PLACEHOLDER: `Date in ${CONFIG.CONSTANTS.DATE_FORMAT} format.`,
    MENU_BUTTON: 'Menu',
    DELETE_AUTH: 'Delete Auth',
    ARCHIVE: 'Archive',
    UNARCHIVE: 'Unarchive',
    HIDE: 'Hide from Expiring',
    UNHIDE: 'Unhide from Expiring',
    ARCHIVED: 'Archived',
    EXPIRING: 'Expiring',
    ERROR_ARCHIVING: 'There was an error archiving the Authorization',
    ERROR_HIDING: 'There was an error hiding the Authorization from expiring',
    RESEND_AUTH: 'Resend Authorization',
    REQUEST_FU: 'Request Follow-up',
    VIEW_OTHER_AUTHS: 'View All Patient Auths',
    VIEW_OTHER_AUTHS_TOOLTIP: 'View this patient\'s other authorizations',
    VIEW_OTHER_AUTHS_MODAL_HEADER: name => `Other Authorizations for ${name}`,
    NO_OTHER_AUTHORIZATIONS: 'No other authorizations were found.',
    FIRST_NAME_SEARCH_PLACEHOLDER: 'First Name',
    LAST_NAME_SEARCH_PLACEHOLDER: 'Last Name',
    EXPIRATION_DATE_FILTER_LABEL: 'Filter by Expiration Date:',
    UPSERT_INTEGRATED_AUTH: 'Sync approval',
    UPSERT_INTEGRATED_AUTH_TOOLTIP: 'Create or update this auth in your practice management system.',
    VIEW_NOTES: 'View Notes',
    VIEW_NOTES_TOOLTIP: 'View notes associated with this authorization.',
    CANCEL_AUTH_SEND_BUTTON: 'Cancel sending',
    CANCEL_AUTH_SEND_BUTTON_TOOLTIP: 'Cancel sending of this authorization',
  },
  AUTHORIZATION_DETAILS: {
    ERROR_UPDATING: 'There was an error updating the details.',
    START_DATE_PLACEHOLDER: `(Optional) Enter start date in ${CONFIG.CONSTANTS.DATE_FORMAT} format.`,
    END_DATE_PLACEHOLDER: `(Optional) Enter end date in ${CONFIG.CONSTANTS.DATE_FORMAT} format.`,
    AUTHORIZATION_NUMBER_PLACEHOLDER: '(Optional) Enter an Authorization Number',
    PROCEDURE_TITLE_PLACEHOLDER: 'Enter name of service...',
    PROCEDURE_VALUE_PLACEHOLDER: 'Enter # of approved units...',
    UPDATE_DETAILS: 'Update authorization details',
  },
  FOLLOW_UPS: {
    CONTACT_PAYER: 'This authorization requires payer contact. Please reach out directly to the insurance company at your earliest convenience',
    GENERAL_QUESTIONS: 'This authorization requires additional information. Click to view the latest payer correspondence and respond to this request.',
    PRINTABLE_FORM: 'This authorization requires a form be printed and faxed manually. Please print and fill out the form at your earliest convenience.',
  },
  NEW_AUTHORIZATIONS: {
    VIEW_FORM: 'View form template',
    CHANGE_FORM_INSTRUCTIONS: 'To change the selected form, delete the current authorization and create a new one.',
    SAVE: 'Save Changes',
    DELETE_AUTH: 'Delete Authorization',
    ERROR_SETTING_NUMBER: 'There was an error setting this submission number',
    NO_PATIENT: 'No patient selected',
    LARGE_FILE_WARNING: sizeMB => `Warning: your file is larger than ${sizeMB} MB and may take a while to upload`,
    LARGE_FILE_ERROR: sizeMB => `There was an error uploading the file. Make sure the file is no larger than ${sizeMB} MB.`,
    COVER_SHEET_MODAL_HEADER: 'Cover Sheet',
    COVER_SHEET_MODAL_DESCRIPTION: 'Would you like to attach a cover sheet? Doing so will highlight key information and improve the chances of an approval.',
  },
  INITIALIZE_SECTION: {
    SELECT_PATIENT_MODAL_HEADER: 'Matching Patient Found',
    SELECT_PATIENT_MODAL_BODY: 'An existing patient was found. Selecting the correct patient below will allow us to automate more of your workflow. '
      + 'Click on the patient below if you would like to reuse the previous information.',
  },
  DUPLICATE_AUTHORIZATIONS: {
    MODAL_WARN: "Your patient's clinical information may be out of date in the new authorization. Be sure to double check your data before submitting.",
  },
  PDF_EDITOR: {
    CHAR_BOX_ERROR: numChars => `Warning: More characters have been entered than will fit in the available ${numChars} boxes.`,
  },
  INTEGRATED_AUTHORIZATIONS: {
    DUXWARE: 'Duxware',
    MODAL_HEADER_CREATE: 'Create Duxware Authorization',
    MODAL_HEADER_UPDATE: 'Update Duxware Authorization',
    MODAL_CONTENT: (isEditing, integratedAppName) => [
      `Would you SamaCare to ${isEditing ? 'update' : 'create'} a Pending Authorization for this patient in `,
      <span key="integrated_app_name" style={{ color: colors.orange, fontWeight: 'bold' }}>{integratedAppName}</span>,
    ],
    ERROR_INTEGRATING: 'There was an error integrating with Duxware. SamaCare has been alerted, and we have continued to submit your authorization.',
    SUCCESS_INTEGRATING: 'The authorization was successfully integrated with Duxware.',
    INVALID_DATE: `Please ensure dates are in the "${DATE_FORMAT}" format`,
    INVALID_VISITS: 'Remaining visits should not be greater than allowed visits',
    ERROR_TITLES: {
      UNEXPECTED: 'Unexpected Error',
      SERVICE_DOWN: 'Portal Service Down',
    },
  },
  MISSING_INFORMATION: {
    ERROR: 'There was an error, please contact us through the chat button on the bottom right',
  },
  SWITCH_INSTITITIONS: {
    SUCCESS: 'Successfully updated institution.',
    ERROR: 'Error updating instituion',
  },
  REPORT_ERROR: {
    MODAL_HEADER: 'Please provide relevant details',
    SUBMIT: 'Report error',
    ERROR_SUBMIT_SUCCESS: 'Error successfully reported',
    ERROR_DETAILS_FAILURE: 'Error was not reported please try again',
    LOGIN_ERROR_BUTTON: 'I can\'t login',
    SELECTION_UNAVAILABLE_ERROR_BUTTON: 'My selection is unavailable',
    FORM_MISSING: 'The form I need is missing',
    PAYER_MISSING: 'The payer I need is missing',
  },
  UPDATE_AUTH_STATUS: {
    EDIT_AND_RESEND_BUTTON_LABEL: 'Edit and resend (UNSENT)',
    EDIT_AND_RESEND_NOTE: 'Note: to resend you will need to add a signature and re-press submit.',
    SUCCESS_CANCELLING_SEND: 'The authorization send was successfully cancelled.',
    ERROR_CANCELLING_SEND: 'The authorization was unable to be cancelled.',
  },
};
