import _ from 'lodash';
import strings from 'Resources/strings';

// Should have upper / lower / number / special character / 8 minimum characters
export const passwordComplexityRegexConditions = [
  {
    regex: /^(?=.*[a-z])/,
    description: strings.RESET_PASSWORD.PASSWORD_REQ_LOWER,
  },
  {
    regex: /(?=.*[A-Z])/,
    description: strings.RESET_PASSWORD.PASSWORD_REQ_UPPER,
  },
  {
    regex: /(?=.*[0-9])/,
    description: strings.RESET_PASSWORD.PASSWORD_REQ_NUMBER,
  },
  {
    regex: /(?=.*[!@#$%^&*])/,
    description: strings.RESET_PASSWORD.PASSWORD_REQ_SPECIAL,
  },
  {
    regex: /(?=.{8,})/,
    description: strings.RESET_PASSWORD.PASSWORD_REQ_LENGTH,
  },
];

export const getFailedConditions = password => _.filter(passwordComplexityRegexConditions, ({ regex }) => !_.isString(password) || !password.match(regex));

export const isPasswordComplexEnough = password => _.isEmpty(getFailedConditions(password));
