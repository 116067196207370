import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { setAttachment } from '../../reducers/formReducer';
import { withCreateAuthUploadFromAwsKey } from '../../graphql/File';
import BaseButton from '../BaseButton';
import Modal from '../Modal';

const ModalContent = styled.div`
  padding: 20px 0;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;

const Tab = styled.div`
  padding: 10px;
  border-bottom: 2px solid ${props => props.selected ? props.theme.purple : 'transparent'};
  color: ${props => props.selected ? props.theme.defaultFontColor : props.theme.dullGray};
  font-weight: 400;

  :hover {
    cursor: pointer;
    border-color: ${props => props.theme.purple};
    color: ${props => props.theme.defaultFontColor};
  }
`;

const ModalBody = styled.div`
  width: 700px;
  max-height: 500px;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  border: 1px dashed ${props => props.theme.purple};
  padding: 10px;
  margin: 10px 0;
  width: 400px;
  color: ${props => props.theme.purple};
  text-align: center;
`;

export class IntegratedDownloadedFilesSuggestionModal extends PureComponent {
  state = { open: false, selectedCategory: null };

  select = async (awsFile) => {
    const { set, createAuthUploadFromAwsKey, authorization, maxFileSizeMB, alert } = this.props;

    try {
      const fileResp = await createAuthUploadFromAwsKey({ variables: {
        authorizationId: authorization.id, title: awsFile.title, awsKey: awsFile.key, maxFileSizeMB,
      } });
      const file = fileResp.data.createAuthUploadFromAwsKey;
      set({ title: file.title, awsKey: file.awsKey, id: file.id });
    } catch (e) {
      if (_.includes(e.message, 'too large')) {
        alert.error(`The selected file is larger than the allowed limit of ${maxFileSizeMB}MB`);
      } else {
        alert.error('There was an error uploading your file, please try again or reach out to us if this problem continues');
      }
    }
  }

  getFilteredFiles = (selectedCategory) => {
    const { authorization, attachments } = this.props;

    return _.sortBy(_.filter(
      authorization.integratedEMRFilesDownloaded,
      file => (
        file.category === (selectedCategory)
        && !_.find(attachments, { awsKey: file.key })
      ),
    ), ({ date }) => moment(date)).reverse();
  }

  render() {
    const { authorization } = this.props;
    const { open, selectedCategory } = this.state;

    const categories = _.uniq(_.map(authorization.integratedEMRFilesDownloaded, 'category'));
    const filteredFiles = this.getFilteredFiles(selectedCategory || categories[0]);
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Modal
          header="Select uploads from OncoEMR"
          onClick={() => { this.setState({ open: !open }); }}
          open={open}
        >
          <ModalBody>
            <ModalContent>
              <Tabs>
                {
                  _.map(categories, (category, i) => (
                    <Tab
                      onClick={() => { this.setState({ selectedCategory: category }); }}
                      key={`integratedFiles_${category}`}
                      selected={selectedCategory ? category === selectedCategory : i === 0}
                    >
                      { category }
                    </Tab>
                  ))
                }
              </Tabs>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ textAlign: 'left' }}>File Name</th>
                    <th style={{ textAlign: 'left' }}>Date</th>
                    <th />
                  </tr>
                  {
                    _.map(filteredFiles, file => (
                      <tr key={`filtered_file_${file.key}`}>
                        <td>
                          <div style={{ maxWidth: 300 }}>
                            <a target="_blank" rel="noopener noreferrer" href={file.url}>{ file.title }</a>
                          </div>
                        </td>
                        <td>{ file.date }</td>
                        <td>
                          <BaseButton
                            style={{ margin: 'auto' }}
                            onClick={() => { this.select(file); }}
                          >
                            Attach File
                          </BaseButton>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </ModalContent>
          </ModalBody>
        </Modal>
        <ButtonContainer>
          <BaseButton style={{ margin: 'auto' }} onClick={() => { this.setState({ open: !open }); }}>
            Attach from OncoEMR
          </BaseButton>
        </ButtonContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  attachments: state.form.attachments,
});

const mapDispatchToProps = dispatch => ({
  set(file) { dispatch(setAttachment(file)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withCreateAuthUploadFromAwsKey)(withAlert(IntegratedDownloadedFilesSuggestionModal))
);
