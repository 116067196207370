import _ from 'lodash';
import React, { useState } from 'react';
import { compose } from 'react-apollo';

import styled from 'styled-components';
import { withCurrentAccount, withAccounts } from '../../graphql/Account';
import { withLocations } from '../../graphql/Location';

import ListFilter from '../../components/MainList/ListFilter';
import AccountsTable from './Accounts/AccountsTable';
import LocationsTable from './Locations/LocationsTable';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
`;

const WelcomeMessage = styled.div`
  font-size: 26px;
  font-weight: 500;
  color: ${props => props.theme.darkGray};
  margin-bottom: 16px;
`;

const LabelHelperText = styled.em`
  font-size: 13px;
  font-weight: 400;
  color: ${props => props.theme.purple};
  margin: 16px;
`;


const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 48px;
  color: ${props => props.theme.lightGray};
  margin-top: 24px;
`;

const tabs = [
  { title: 'Users', tabTitle: 'Users' },
  { title: 'Prescribers', tabTitle: 'Prescribers' },
  { title: 'Locations', tabTitle: 'Locations' },
];

const { PRESCRIBER } = CONFIG.CONSTANTS.ACCOUNT_TYPES;

const sortNewUser = ({ firstName, lastName }) => (
  firstName === CONFIG.CONSTANTS.DEFAULT_USER.firstName && lastName === CONFIG.CONSTANTS.DEFAULT_USER.lastName ? 0 : 1
);
const sortNewLocation = location => location.name === CONFIG.CONSTANTS.DEFAULT_LOCATION_NAME ? 0 : 1;

const generateAccounts = (list, type, isSamaUser, isAdmin) => (
  <div>
    {type === PRESCRIBER && <LabelHelperText>Labels can help distinguish between multiple occurrences of the same prescriber</LabelHelperText>}
    <AccountsTable type={type} accountList={list} isSamaUser={isSamaUser} isAdmin={isAdmin} />
    {_.isEmpty(list) && <SectionHeader>{`No ${type}s have been added.`}</SectionHeader>}
  </div>
);

const generateLocations = (list, isSamaUser) => (
  <div>
    <LocationsTable locationList={list} isSamaUser={isSamaUser} />
    {_.isEmpty(list) && <SectionHeader>No locations have been added.</SectionHeader>}
  </div>
);

const Admin = ({ account, accounts, locations }) => {
  const [view, setView] = useState(tabs[0]);
  if (account && accounts && locations) {
    const prescribers = _.sortBy(_.filter(accounts, { type: PRESCRIBER }), [sortNewUser, 'lastName']);
    const users = _.sortBy(_.reject(accounts, { type: PRESCRIBER }), [sortNewUser, 'isExpired', 'lastName']);
    return (
      <MainContainer>
        <WelcomeMessage>
          {`${account.institution.name} Administrative Dashboard`}
        </WelcomeMessage>
        <ListFilter
          onSelect={(tab) => {
            setView(tab);
          }}
          selected={view}
          tabs={tabs}
          hideFilters
        />
        {(view === tabs[0]) && generateAccounts(users, 'user', account.isSamaUser, account.isAdmin)}
        {(view === tabs[1]) && generateAccounts(prescribers, PRESCRIBER, account.isSamaUser, account.isAdmin)}
        {(view === tabs[2]) && generateLocations(_.sortBy(locations, [sortNewLocation, 'name']))}
      </MainContainer>
    );
  }
  return <div />;
};

export default compose(withAccounts, withCurrentAccount, withLocations)(Admin);
