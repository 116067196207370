import gql from 'graphql-tag';

import institutionInfo from './institutionInfo';
import locationInfo from './locationInfo';

export default gql`
  fragment detailedInstitutionInfo on Institution {
    ...institutionInfo

    locations {
      ...locationInfo
    }
  }
  ${institutionInfo}
  ${locationInfo}
`;
