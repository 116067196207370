import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { BaseText } from 'Segment/StyledComponents';
import { withAlert } from 'react-alert';

import { withLatestUpdateAuthorizationCorrespondence } from '../../../graphql/AuthorizationCorrespondence';
import { withCreateAuthorizationNote } from '../../../graphql/Note';
import BaseButton from '../../../components/BaseButton';
import getAuthorizationNumberRecs from './getAuthorizationNumberRecommendation';
import RecommendationEvidence from './RecommendationEvidence';
import getDenialRecs from './getDenialRecommendation';
import getActionRequiredRecs from './getActionRequiredRecommendation';

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuggestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  font-weight: bold;
`;

const SeparatorLine = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.purple};
  margin: 10px 0;
`;

class AuthorizationDetailsSuggestAndUpdate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAllAuthNumRecs: false,
      authNumberRecommendations: getAuthorizationNumberRecs(props.fullText),
      submitted: false,
      code: '',
      denialRecommendations: getDenialRecs(props.fullText),
      showAllDenialRecs: false,
      note: '',
      actionRequiredRecommendations: getActionRequiredRecs(props.fullText),
      showAllActionRequiredRecs: false,
    };
  }

  submit = async () => {
    const { alert, updateLatestAuthorizationCorrespondence, authorizationId, authorizationStatus, createAuthorizationNote } = this.props;
    const { code, note } = this.state;

    try {
      if (authorizationStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED) {
        await updateLatestAuthorizationCorrespondence({ variables: { authorizationId, code } });
        this.setState({ submitted: true });
      } else if (authorizationStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING) {
        await createAuthorizationNote({ variables: { note, authorizationId } });
      }
    } catch {
      alert.error('There was an error saving the authorization number');
    }
  }

  render() {
    const { fullText, authorizationStatus } = this.props;
    const {
      showAllAuthNumRecs,
      authNumberRecommendations,
      submitted,
      code,
      denialRecommendations,
      showAllDenialRecs,
      actionRequiredRecommendations,
      showAllActionRequiredRecs,
    } = this.state;

    const setAuthorizationNumberStatuses = [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED, CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING];

    if (submitted) {
      return (
        <div>
          <SuggestionHeader>
            Authorization # Recommendation(s)
          </SuggestionHeader>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {`User submitted authorization code ${code}`}
            <BaseButton
              onClick={() => { this.setState({ submitted: false }); }}
              style={{ fontSize: 12, marginLeft: 'auto' }}
            >
              Show suggestions
            </BaseButton>
          </div>
          <SeparatorLine />
        </div>
      );
    }

    return (
      <SuggestionContainer key="AuthNumberSuggestionContainer">
        {
          authNumberRecommendations.length > 0 && (
            <>
              <SuggestionHeader>
              Authorization # Recommendation(s)
              </SuggestionHeader>
              <div>
                {
                  _.map(showAllAuthNumRecs ? authNumberRecommendations : [authNumberRecommendations[0]], evidenceIndex => (
                    <RecommendationEvidence
                      key={`AuthNumberSuggestionEvidence_${evidenceIndex[0]}_${evidenceIndex[1]}`}
                      startIndex={evidenceIndex[0]}
                      endIndex={evidenceIndex[1]}
                      fullText={fullText}
                      startMultiplierIncrements={5}
                      endMultiplierIncrements={40}
                    />
                  ))
                }
              </div>
              { authNumberRecommendations.length > 1 && (
                <BaseButton
                  onClick={() => {
                    this.setState({
                      showAllAuthNumRecs: !showAllAuthNumRecs,
                    });
                  }}
                  style={{ width: '100%', fontSize: 12, padding: 5 }}
                >
                  { showAllAuthNumRecs ? 'See Less' : 'See More' }
                </BaseButton>
              ) }
            </>
          )
        }
        {
          authorizationStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED && actionRequiredRecommendations.length > 0 && (
            <>
              <SuggestionHeader>
                Action Required Reason(s)
              </SuggestionHeader>
              <div>
                {
                  _.map(showAllActionRequiredRecs ? actionRequiredRecommendations : [actionRequiredRecommendations[0]], evidenceIndex => (
                    <RecommendationEvidence
                      key={`ActionRequiredSuggestionEvidence_${evidenceIndex[0]}_${evidenceIndex[1]}`}
                      startIndex={evidenceIndex[0]}
                      endIndex={evidenceIndex[1]}
                      fullText={fullText}
                      startMultiplierIncrements={5}
                      endMultiplierIncrements={40}
                    />
                  ))
                }
              </div>
              { actionRequiredRecommendations.length > 1 && (
                <BaseButton
                  onClick={() => {
                    this.setState({
                      showAllActionRequiredRecs: !showAllActionRequiredRecs,
                    });
                  }}
                  style={{ width: '100%', fontSize: 12, padding: 5 }}
                >
                  { showAllActionRequiredRecs ? 'See Less' : 'See More' }
                </BaseButton>
              ) }
            </>
          )
        }
        {
          authorizationStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.DENIED && denialRecommendations.length > 0 && (
            <>
              <SuggestionHeader>
                Denial Reason(s)
              </SuggestionHeader>
              <div>
                {
                  _.map(showAllDenialRecs ? denialRecommendations : [denialRecommendations[0]], evidenceIndex => (
                    <RecommendationEvidence
                      key={`DenialSuggestionEvidence_${evidenceIndex[0]}_${evidenceIndex[1]}`}
                      startIndex={evidenceIndex[0]}
                      endIndex={evidenceIndex[1]}
                      fullText={fullText}
                      startMultiplierIncrements={5}
                      endMultiplierIncrements={40}
                    />
                  ))
                }
              </div>
              { denialRecommendations.length > 1 && (
                <BaseButton
                  onClick={() => {
                    this.setState({
                      showAllDenialRecs: !showAllDenialRecs,
                    });
                  }}
                  style={{ width: '100%', fontSize: 12, padding: 5 }}
                >
                  { showAllDenialRecs ? 'See Less' : 'See More' }
                </BaseButton>
              ) }
            </>
          )
        }
        {
          _.includes(setAuthorizationNumberStatuses, authorizationStatus) && (
            <>
              <div>
                { authorizationStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED ? 'Set Authorization #:' : 'Create Note for Pending Authorization:' }
              </div>
              <div style={{ display: 'flex', marginTop: 10 }}>
                <BaseText
                  style={{ padding: 3 }}
                  onChange={(e) => {
                    if (authorizationStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED) {
                      this.setState({ code: e.target.value });
                    } else {
                      this.setState({ note: e.target.value });
                    }
                  }}
                />
                <BaseButton style={{ width: '50px', padding: 5, marginLeft: 5 }} onClick={this.submit}>Save</BaseButton>
              </div>
            </>
          )
        }
        <SeparatorLine />
      </SuggestionContainer>
    );
  }
}

export default compose(withLatestUpdateAuthorizationCorrespondence, withCreateAuthorizationNote)(withAlert(AuthorizationDetailsSuggestAndUpdate));
