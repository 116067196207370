import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import _ from 'lodash';
import moment from 'moment';
import SegmentSelector from 'Segment/SegmentSelector';
import { BaseText } from 'Segment/StyledComponents';
import MailIcon from 'react-icons/lib/go/mail';

import AssociationModal from './AssociationModal';
import BaseButton from '../../components/BaseButton';
import { withCurrentAccount } from '../../graphql/Account';
import { withInsuranceResponses, withSetCorrespondenceOnAuthorization, withHideAuthorizationCorrespondence } from '../../graphql/AuthorizationCorrespondence';
import { withAuthorizationSearch } from '../../graphql/Authorization';
import { withInstitutions } from '../../graphql/Institution';
import AuthorizationListManager from '../Authorizations/AuthorizationListManager';
import CorrespondenceViewer from '../../components/CorrespondenceViewer';
import ROUTE_PATHS from '../ROUTE_PATHS';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
`;

const ListInfo = styled.div`
  width: 90px;
  margin: 0 16px;
  font-size: 14px;
  text-align: center;
`;

const ResponseOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResponseRow = styled.div`
  cursor: pointer;
  font-weight: ${({ selected }) => selected ? 'bold' : ''};
  background-color: ${({ theme, selected }) => selected ? theme.lightGray : 'white'};
  padding: 5px 10px;

  :hover {
    color: ${({ theme }) => theme.purple};
    background-color: ${({ theme }) => theme.lightGray};
  }
`;

const ScrollContainer = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  height: ${props => props.height || '450px'};
  min-width: 100px;
  overflow: scroll;
`;

const { CONSTANTS, SITE_DOMAIN, SITE_PROTOCOL, NODE_ENV } = CONFIG;
const statuses = CONSTANTS.AUTHORIZATION_STATUSES;
const { AETNA_FAX_STATUS_CHECKER_KEY } = CONSTANTS;
class SupportResponses extends PureComponent {
  state = {
    patientFirstName: '',
    patientLastName: '',
    patientMemberId: '',
    selectedResponse: {},
    selectedAuthorization: null,
    toHideQueue: [],
    InstitutionId: null,
    AuthorizationId: null,
  };

  onSearch = async (statusesOverride) => {
    const { alert, authorizationSearch } = this.props;
    const { patientFirstName, patientLastName, InstitutionId, AuthorizationId, patientMemberId } = this.state;

    try {
      await authorizationSearch.refetch({
        // defaulting empty string to null, otherwise will error graphql
        AuthorizationId: (AuthorizationId || null),
        patientFirstName,
        patientLastName,
        InstitutionId,
        authorizationStatuses: statusesOverride || [],
        patientMemberId,
      });
    } catch (e) {
      alert.error(`There was an error searching authorizations, ${e.message}`);
    }
  };

  onSelectResponseSearch = async (patientMemberId, patientLastName) => {
    const { authorizationSearch, alert } = this.props;
    const { InstitutionId } = this.state;

    const defaultRefetchProps = {
      InstitutionId,
      authorizationStatuses: [
        statuses.EDIT_AND_RESUBMIT, statuses.SENT, statuses.PENDING, statuses.SENDING_FAILED, statuses.ACTION_REQUIRED,
      ],
      patientMemberId: '',
      patientLastName: '',
      patientFirstName: '',
    };

    try {
      if (patientMemberId) {
        this.setState({ patientMemberId: patientMemberId.slice(0, 7) });
        await authorizationSearch.refetch({ ...defaultRefetchProps, patientMemberId: patientMemberId.slice(0, 3) });
      } else if (patientLastName) {
        this.setState({ patientLastName: patientLastName.slice(0, 3) });
        await authorizationSearch.refetch({
          ...defaultRefetchProps,
          patientLastName: patientLastName.slice(0, 3),
        });
      } else {
        await authorizationSearch.refetch(defaultRefetchProps);
      }
    } catch (e) {
      alert.error(`There was an error finding related authorizations, ${e.message}`);
    }
  };

  setCorrespondenceOnAuthorization = async () => {
    const { setCorrespondenceOnAuthorization, alert } = this.props;
    const { selectedResponse, selectedAuthorization } = this.state;

    try {
      await setCorrespondenceOnAuthorization(
        { variables: { authorizationId: selectedAuthorization.id, correspondenceId: selectedResponse.id } }
      );

      this.setState({ selectedAuthorization: null, selectedResponse: {} });
      alert.success('Success!');
    } catch (e) {
      alert.error('There was an error setting your response');
    } finally {
      // Very hacky 'fix'. This makes it so that after associating response, your auth doesn't disappear.
      // Only doing this because it's an internal tool
      setImmediate(() => { this.onSearch(); });
    }
  };

  initiateHideResponse = (responseId) => {
    const { hideCorrespondence } = this.props;

    const clearMethod = setTimeout(
      () => {
        const { toHideQueue } = this.state;
        hideCorrespondence({ variables: { id: responseId } })
          .then(() => this.setState({ toHideQueue: _.reject(toHideQueue, { id: responseId }) }));
      },
      5000,
    );

    const undoHideMethod = () => {
      const { toHideQueue } = this.state;
      clearTimeout(clearMethod);
      this.setState({ toHideQueue: _.reject(toHideQueue, { id: responseId }) });
    };

    const { toHideQueue } = this.state;
    this.setState({ toHideQueue: toHideQueue.concat({ id: responseId, undoHideMethod }) });
  };

  render() {
    const {
      insuranceResponses, refetchTimeStamp, insuranceCompanies, authorizationSearch, institutions, alert, toSendFile, account,
    } = this.props;

    const {
      selectedResponse,
      patientFirstName,
      patientLastName,
      selectedAuthorization,
      toHideQueue,
      InstitutionId,
      AuthorizationId,
      patientMemberId,
    } = this.state;

    const checkAetnaAuthURLRoot = `${SITE_PROTOCOL}${SITE_DOMAIN}${NODE_ENV === 'dev' ? ':8080' : ''}`;
    const checkAetnaAuthURL = `${checkAetnaAuthURLRoot}/#${ROUTE_PATHS.CHECK_AUTHORIZATION_STATUS}`
      + `?accountId=${_.get(account, 'id')}&key=${AETNA_FAX_STATUS_CHECKER_KEY}`;
    return (
      <div>
        <div style={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
          <ListInfo>
            <div>
              <b>{`${_.size(insuranceResponses)} `}</b>
              items
            </div>
            <div>
              refreshed at
              {` ${refetchTimeStamp}`}
            </div>
          </ListInfo>
          <div style={{ display: 'flex' }}>
            <BaseText
              style={{ flex: 1, marginRight: '5px' }}
              onChange={(e) => { this.setState({ patientFirstName: e.target.value, AuthorizationId: null }); }}
              value={patientFirstName || ''}
              placeholder="First Name"
              onKeyDown={({ key }) => {
                if (key === 'Enter') { this.onSearch(); }
              }}
            />
            <BaseText
              style={{ flex: 1, margin: '0 5px' }}
              onChange={(e) => { this.setState({ patientLastName: e.target.value, AuthorizationId: null }); }}
              onKeyDown={({ key }) => {
                if (key === 'Enter') { this.onSearch(); }
              }}
              value={patientLastName || ''}
              placeholder="Last Name"
            />
            <BaseText
              style={{ flex: 1, margin: '0 5px', maxWidth: 200 }}
              type="number"
              onChange={(e) => {
                this.setState({
                  AuthorizationId: e.target.value,
                  patientFirstName: '',
                  patientLastName: '',
                  patientMemberId: '',
                });
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter') { this.onSearch(); }
              }}
              value={AuthorizationId || ''}
              placeholder="Authorization Id"
            />
            <BaseText
              style={{ flex: 1, margin: '0 5px' }}
              onChange={(e) => { this.setState({ patientMemberId: e.target.value, AuthorizationId: null }); }}
              value={patientMemberId || ''}
              placeholder="Member Id"
              onKeyDown={({ key }) => {
                if (key === 'Enter') { this.onSearch(); }
              }}
            />
            <BaseButton style={{ width: 100, margin: '0 10px 0 5px' }} onClick={() => { this.onSearch(); }}>Search</BaseButton>
          </div>
          <SegmentSelector
            style={{ flex: 1, marginBottom: '8px' }}
            labelKey="name"
            valueKey="id"
            options={institutions}
            width="400px"
            onChange={(selected) => {
              this.setState({ InstitutionId: _.get(selected, 'id') });
            }}
            value={_.find(institutions, { id: InstitutionId })}
            placeholder="Filter By Instituton"
          />
        </div>
        <Container>
          <ScrollContainer height="750px">
            <div>
              <BaseButton style={{ fontSize: 10, width: '90px', margin: 'auto' }} href="">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={checkAetnaAuthURL}
                >
                  Check Availity Aetna Auths
                </a>
              </BaseButton>
            </div>
            { _.map(_.sortBy(insuranceResponses, 'createdAt'), response => (
              <ResponseRow
                selected={selectedResponse.id === response.id}
                onClick={() => {
                  this.setState({
                    selectedResponse: response,
                    InstitutionId: response.InstitutionId,
                    patientFirstName: '',
                    patientLastName: '',
                    patientMemberId: '',
                  }, () => {
                    this.onSelectResponseSearch(response.patientMemberId, response.patientLastName);
                  });
                }}
                key={`insurance_response_support_${response.id}`}
              >
                { _.some(toHideQueue, { id: response.id })
                  ? (
                    <div>
                      <BaseButton onClick={_.find(toHideQueue, { id: response.id }).undoHideMethod}>Undo</BaseButton>
                    </div>
                  ) : (
                    <Container>
                      <div>
                        <div style={{ fontSize: 14 }}>
                          { `ID ${response.id}` }
                        </div>
                        <div style={{ fontSize: 10 }}>
                          { moment(response.createdAt).format(CONFIG.CONSTANTS.DATE_FORMAT) }
                        </div>
                      </div>
                      <ResponseOptionContainer>
                        <BaseButton
                          onClick={(e) => {
                            e.stopPropagation();
                            this.initiateHideResponse(response.id);
                          }}
                          style={{ padding: 5, borderRadius: 5 }}
                        >
                          X
                        </BaseButton>
                        <BaseButton
                          onClick={(e) => {
                            e.stopPropagation();
                            toSendFile(response.fileAWSKey, _.find(institutions, { id: response.InstitutionId }).name);
                          }}
                          style={{ padding: 5, borderRadius: 5 }}
                        >
                          <MailIcon />
                        </BaseButton>
                      </ResponseOptionContainer>
                    </Container>
                  )
                }
              </ResponseRow>
            )) }
          </ScrollContainer>
          <CorrespondenceViewer expandOnHover correspondence={selectedResponse} />
          <div style={{ flex: 1 }}>
            <ScrollContainer height="750px">
              <AuthorizationListManager
                minWidth="600px"
                authorizations={_.get(authorizationSearch, 'authorizationSearch')}
                insuranceCompanies={insuranceCompanies}
                alwaysOpenInBlank
                emptyStateText="None Found"
                tableConfig={{
                  headers: ['Patient', 'Status', 'Details'],
                  columns: [
                    'stackedPatient',
                    'status',
                    'authorizationDetails',
                    { type: 'customButton',
                      text: 'Associate',
                      onClick: (authorization) => {
                        if (!selectedResponse.id) {
                          alert.error('You must first select a response');
                        } else {
                          this.setState({ selectedAuthorization: authorization });
                        }
                      },
                    },
                  ],
                }}
              />
            </ScrollContainer>
            { selectedAuthorization && (
              <AssociationModal
                onClose={() => { this.setState({ selectedAuthorization: null }); }}
                authorizationId={selectedAuthorization.id}
                correspondence={_.find(insuranceResponses, { id: _.get(selectedResponse, 'id') })}
                setCorrespondenceOnAuthorization={this.setCorrespondenceOnAuthorization}
              />
            ) }
          </div>
        </Container>
      </div>
    );
  }
}

export default compose(
  withInsuranceResponses,
  withAuthorizationSearch,
  withSetCorrespondenceOnAuthorization,
  withHideAuthorizationCorrespondence,
  withInstitutions,
  withCurrentAccount,
)(withAlert(SupportResponses));
