import _ from 'lodash';
import getMatchingIndicesFromTextFragments from './getMatchingIndicesFromTextFragments';

const approved = [
  'we authorized your',
  'we approved the service',
  'approved the request as follows',
  'services approved:',
  'your request has been approved',
  'we have certified the requested services',
  'has approved the following',
  'we have approved it for coverage',
  'the following service(s) has/have been APPROVED:',
  'the following service(s) have been approved:',
  'the following services have been approved',
  'has authorized the following',
  'Approved Services:',
  'notice of approval of request for service(s)',
  'we approved the service(s)',
  'Approval Notification',
];

const actionRequired = [
  'not managed by this department',
  'unable to complete this review',
];

const denied = [
  'Your request was denied',
  'We\'ve denied the medical services',
  'We denied the medical services',
  'Notice of denial of medical coverage',
  'Why did we deny your request',
];

const noAuthRequired = [
  'no prior authorization is required for this medication',
  'no precertification is required under the members plan',
  'there is no prior authorization is required for',
];

const s = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
const statuses = [
  { status: s.APPROVED, textFragments: approved },
  { status: s.DENIED, textFragments: denied },
  { status: s.ACTION_REQUIRED, textFragments: actionRequired },
  { status: s.NO_AUTH_REQUIRED, textFragments: noAuthRequired },
];

const getStatusSuggestions = (fullText) => {
  const filteredStatuses = _.map(
    statuses,
    status => ({
      status: status.status,
      evidenceIndices: getMatchingIndicesFromTextFragments(status.textFragments, fullText),
    }),
  );

  const ordered = _.sortBy(
    _.filter(filteredStatuses, status => !_.isEmpty(status.evidenceIndices)),
    status => (status.evidenceIndices.length)
  );

  // An array of objects [{ status: String, evidenceIndices: [[Int, Int]] }]
  // Sorted by the ones with the most evidenceIndices.
  // All returned values have evidence indices
  return ordered;
};

export default getStatusSuggestions;
