import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from './LoadingSpinner';
import BaseButton from './BaseButton';

const Button = styled(BaseButton)`
  border-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.purple)};
  background: ${props => (props.disabled ? props.theme.lightGray : props.theme.purple)};
  color: white;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: ${props => (props.disabled ? '' : 'pointer')};
    color: ${props => (props.disabled ? 'white' : (props.color || props.theme.purple))};
    background: ${props => (props.disabled ? props.theme.lightGray : 'white')};

    ${LoadingSpinner} {
      border-top-color: ${props => (props.disabled ? 'white' : (props.color || props.theme.purple))};
    }
  }
`;


const LoadingButton = ({
  children, loading, disabled = false, onClick, style = {},
}) => (
  <Button disabled={disabled || loading} onClick={() => { if (!(disabled || loading)) { if (onClick) { onClick(); } } }} style={style}>
    { loading && (<LoadingSpinner />) }
    { children }
  </Button>
);

export default styled(LoadingButton)``;
