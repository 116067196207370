import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose, Query } from 'react-apollo';
import { withAlert } from 'react-alert';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import RequiredFieldModal from 'AuthorizationSharedComponents/RequiredFieldModal';
import BaseButton from '../../BaseButton';
import CustomCheckbox from '../../CustomCheckbox';

import SignatureUpload from './SignatureUpload';
import SignatureInput from './SignatureInput';
import ListFilter from '../../MainList/ListFilter';
import { withSignatures, signatureByIdQuery, withDeleteSignature } from '../../../graphql/Signature';
import { withCurrentAccount } from '../../../graphql/Account';
import { withUpdateAuthorization } from '../../../graphql/Authorization';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PDFContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${props => props.theme.lightGray};
`;

const SignatureImageContainer = styled.div`
  border: ${props => (props.selected ? '3px' : '1px')} solid ${props => props.theme.purple};
  padding: 2px;
  cursor: pointer;
  margin: 10px;
  display: flex;
  flex-direction: row;

  img {
    height: 50px;
  }
`;

const SignaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const HelperText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.darkGray};
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const DeleteSignatureButton = styled(BaseButton)`
  padding: 0 10px;
  border-radius: 3px;
  height: 100%;
  border: 0px solid transparent;
`;

const DeleteSignatureButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
`;

const NEW_TAB = 'NEW_TAB';
const SAVED_TAB = 'SAVED_TAB';
const UPLOAD_TAB = 'UPLOAD_TAB';
const tabs = [
  { title: 'New Signature', tabTitle: 'New Signature', value: NEW_TAB },
  { title: 'Saved Signatures', tabTitle: 'Saved Signatures', value: SAVED_TAB },
  { title: 'Upload Signature', tabTitle: 'Upload Signature', value: UPLOAD_TAB },
];

export class Signature extends PureComponent {
  constructor(props) {
    super(props);

    const { signatureId } = props.authorization.formDetails;
    this.mySignature = null;
    this.state = {
      tab: (!signatureId ? NEW_TAB : SAVED_TAB),
      selectedSignatureId: signatureId,
      requiredFieldModalOpen: false,
    };

    if (props.getSignatureId) {
      props.getSignatureId(this.saveAndGetSignatureId);
    }
  }

  componentWillUnmount() {
    const { getSignatureId } = this.props;

    if (getSignatureId) {
      getSignatureId(null);
    }
  }

  saveAndSetSignature = async (dataURL) => {
    const { addSignature, alert } = this.props;

    try {
      const res = await addSignature({ variables: { data: dataURL } });
      this.setState({ selectedSignatureId: res.data.createSignature.id, tab: SAVED_TAB });
    } catch (e) {
      alert.error('There was an error setting your signature');
    }
  }

  saveAndGetSignatureId = async () => {
    const { addSignature, alert } = this.props;
    const { tab, selectedSignatureId } = this.state;
    const signature = this.mySignature;

    if (tab === NEW_TAB) {
      try {
        const res = await addSignature({ variables: { data: signature.toDataURL() } });
        return res.data.createSignature.id;
      } catch {
        alert.error('There was an error saving your signature');
      }
    }

    return selectedSignatureId;
  }

  signAndSubmit = async () => {
    const { nextStep } = this.props;

    const signatureId = await this.saveAndGetSignatureId();
    nextStep(signatureId);
  }

  triggerSubmit = () => {
    const { disabled } = this.props;
    const { tab, selectedSignatureId } = this.state;
    const signature = this.mySignature;

    if (disabled) return;
    if (
      ((tab === NEW_TAB) && !signature.isSigned())
      || ((tab === SAVED_TAB) && !selectedSignatureId)
      || (tab === UPLOAD_TAB)
    ) {
      this.setState({ requiredFieldModalOpen: true });
      return;
    }

    this.signAndSubmit();
  }

  handleDeleteSignature = async (id) => {
    const { deleteSignature, alert } = this.props;

    try {
      await deleteSignature({ variables: { id } });
      alert.info('Successfully deleted prescriber signature!');
    } catch {
      alert.error('Error deleting prescriber signature');
    }
  }

  handleShouldBeResent = async () => {
    const { authorization, alert, updateAuthorization } = this.props;

    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { shouldBeResent: !authorization.shouldBeResent } } });
      alert.info('Successfully updated your resend authorization selection!');
    } catch {
      alert.error('There was an error updating your resend authorization selection');
    }
  }

  render() {
    const {
      pdfPath,
      back,
      signatures,
      disabled,
      authorization,
    } = this.props;
    const { tab, selectedSignatureId, requiredFieldModalOpen } = this.state;

    // This happens when one user saves a signature and another user goes to submit
    let otherUserSignatureId;
    if (selectedSignatureId && !_.find(signatures, { id: selectedSignatureId })) {
      otherUserSignatureId = selectedSignatureId;
    }

    let shouldRenderSendFaxesTwiceCheckmark;
    if (authorization.insuranceCompany) {
      shouldRenderSendFaxesTwiceCheckmark = authorization.insuranceCompany.notReceivedNonApprovals >= CONFIG.CONSTANTS.NOT_RECEIVED_NON_APPROVAL_LIMIT;
    }

    return (
      <FormContainer>
        <PDFContainer>
          <object
            data={pdfPath}
            height="700px"
            type="application/pdf"
            width="900px"
          >
            <embed height="500px" src={`${pdfPath}#view=FitH`} type="application/pdf" width="800px" />
          </object>
        </PDFContainer>
        <HelperText>Note: Our fax number will be entered into the form to allow receiving and processing responses on your behalf.</HelperText>
        { shouldRenderSendFaxesTwiceCheckmark && (
          <div
            style={{
              display: 'flex', justifyContent: 'flex-start', padding: '15px 10px', margin: '10px 0px', border: '1px dashed #676767', alignItems: 'center',
            }}
          >
            <CustomCheckbox
              checked={authorization.shouldBeResent}
              onChange={this.handleShouldBeResent}
            />
            <div style={{ fontWeight: 'bold', marginLeft: '15px', fontSize: '16px', cursor: 'pointer' }} onClick={this.handleShouldBeResent}>
              Check here to send this authorization twice!
              <div style={{ fontWeight: 'normal', fontSize: '14px', marginTop: '5px' }}>
                We&#39;ve seen instances of this payer losing faxes, so if checked, we will send this authorization twice to ensure successful submission.
              </div>
            </div>
          </div>
        ) }
        <div>
          <ListFilter
            onSelect={(newTab) => {
              this.setState({ tab: newTab.value });
            }}
            selected={_.find(tabs, { value: tab })}
            tabs={tabs}
            hideFilters
          />
        </div>
        { (tab === SAVED_TAB) && (
          <>
            <SignaturesContainer>
              { otherUserSignatureId && (
                <Query
                  query={signatureByIdQuery}
                  variables={{ id: otherUserSignatureId }}
                >
                  {({ data, loading }) => {
                    if (loading || _.isNil(data.signatureById)) return null;
                    const sig = data.signatureById;
                    return (
                      <SignatureImageContainer
                        key={`SignatureImageContainer${sig.id}`}
                        onClick={() => { this.setState({ selectedSignatureId: sig.id }); }}
                        selected={sig.id === selectedSignatureId}
                      >
                        <img src={sig.url} />
                      </SignatureImageContainer>
                    );
                  }}
                </Query>
              ) }
              { _.map(signatures, sig => (
                <>
                  <SignatureImageContainer
                    key={`SignatureImageContainer${sig.id}`}
                    onClick={() => { this.setState({ selectedSignatureId: sig.id }); }}
                    selected={sig.id === selectedSignatureId}
                  >
                    <img src={sig.url} />
                    <DeleteSignatureButtonContainer>
                      <DeleteSignatureButton
                        onClick={async () => { await this.handleDeleteSignature(sig.id); }}
                      >
                        X
                      </DeleteSignatureButton>
                    </DeleteSignatureButtonContainer>
                  </SignatureImageContainer>
                </>
              )) }
            </SignaturesContainer>
          </>
        )}
        { (tab === NEW_TAB) && (
          <SignatureContainer>
            <SignatureInput ref={ref => this.mySignature = ref} disabled={disabled} />
          </SignatureContainer>
        )}
        { (tab === UPLOAD_TAB) && (
          <SignatureUpload
            saveAndSetSignature={this.saveAndSetSignature}
          />
        ) }
        <FormSubmitButtons
          back={back}
          submit={disabled ? null : this.triggerSubmit}
          submitText={authorization.isSupported ? 'Submit Supported Authorization' : 'Submit'}
          isSupported={authorization.isSupported}
        />
        <RequiredFieldModal closeModal={() => { this.setState({ requiredFieldModalOpen: false }); }} open={requiredFieldModalOpen} />
      </FormContainer>
    );
  }
}

export default compose(withSignatures, withCurrentAccount, withDeleteSignature, withUpdateAuthorization)(withAlert(Signature));
