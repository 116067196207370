import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import ReactTooltip from 'react-tooltip';

import strings from 'Resources/strings';
import { withCancelAuthorizationSend } from '../graphql/Authorization';

class CancelAuthorizationSendButton extends PureComponent {
  cancelAuthorizationSend = () => {
    const { authorization, cancelAuthorizationSend, alert } = this.props;

    cancelAuthorizationSend({ variables: { id: authorization.id } })
      .then(() => alert.success(strings.UPDATE_AUTH_STATUS.SUCCESS_CANCELLING_SEND))
      .catch(() => alert.error(strings.UPDATE_AUTH_STATUS.ERROR_CANCELLING_SEND));
  };

  render() {
    const { children } = this.props;

    return (
      <div>
        <div
          data-for="cancelAuthorizationSendButton"
          data-tip=""
          onClick={(e) => {
            e.stopPropagation();
            this.cancelAuthorizationSend();
          }}
        >
          <ReactTooltip id="cancelAuthorizationSendButton">{strings.AUTHORIZATIONS.CANCEL_AUTH_SEND_BUTTON_TOOLTIP}</ReactTooltip>
          { children }
        </div>
      </div>
    );
  }
}

export default compose(withCancelAuthorizationSend)(withAlert(CancelAuthorizationSendButton));
