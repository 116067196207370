import _ from 'lodash';
import uuid from 'uuid/v4';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import { PhoneCleave, ExtensionCleave } from 'Segment/BaseComponents';
import { BaseText } from 'Segment/StyledComponents';

import BaseButton from '../../../components/BaseButton';
import { withUpsertAccount, withGeneratePasswordToken, withResetPassword, withUnlockAccount } from '../../../graphql/Account';
import CustomCheckbox from '../../../components/CustomCheckbox';

const sharedRowStyles = `
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const TableRow = styled.tr`
  ${sharedRowStyles}
  &:hover {
    background-color: ${props => props.theme.lightPurple};
    cursor: pointer;
  }
`;

const TableRowEdit = styled.tr`
  ${sharedRowStyles}
  height: 175px;
  background-color: ${props => props.theme.lightPurple};
`;

const Cell = styled.td`
  padding: 16px;
  font-weight: 300;
`;

const Name = styled.div`
  color: ${props => props.theme.purple};
`;

const Link = styled.a`
  display: block;
  font-weight: 500;
  color: ${props => props.theme.purple};
`;

const Email = styled.div`
  color: ${props => props.theme.darkGray};
`;

const EditButton = styled(BaseButton)`
  padding: 5px;
  width: 100%;
  margin-top: 8px;
`;

const { CONSTANTS, SITE_DOMAIN, SITE_PROTOCOL, NODE_ENV } = CONFIG;
const { PRESCRIBER } = CONSTANTS.ACCOUNT_TYPES;
export class AccountRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      link: null,
      isLockedOut: props.account.isLockedOut,
      editMode: props.account.firstName === CONSTANTS.DEFAULT_USER.firstName && props.account.lastName === CONSTANTS.DEFAULT_USER.lastName,
      showExpiredButton: false,
      showLockedButton: false,
    };
  }

  upsert = async () => {
    const { upsertAccount, alert } = this.props;
    const { account } = this.state;

    try {
      await upsertAccount({ variables: { id: account.id, patch: { ...account } } });
      this.setState({ editMode: false, isLockedOut: false });
      alert.success('Success');
    } catch (e) {
      alert.error(`There was an error updating this account, ${e.message}`);
    }
  }

  unlockAccount = async () => {
    const { unlockAccount, alert, account } = this.props;

    try {
      await unlockAccount({ variables: { id: account.id } });
      this.setState({ editMode: false, isLockedOut: false });
      alert.success('Success');
    } catch (e) {
      alert.error(`There was an error unlocking this account, ${e.message}`);
    }
  }

  generatePasswordLink = async () => {
    const { generatePasswordToken, alert } = this.props;
    const { account } = this.state;
    const urlRoot = `${SITE_PROTOCOL}${SITE_DOMAIN}${NODE_ENV === 'dev' ? ':8080' : ''}`;
    try {
      const res = await generatePasswordToken({ variables: { id: account.id } });
      const link = `${urlRoot}/#/reset-password/${res.data.generatePasswordToken}`;
      this.setState({ link, editMode: false });
      alert.success('Success');
    } catch (e) {
      alert.error(`There was an error updating this account, ${e.message}`);
    }
  }

  sendPasswordEmail = async () => {
    const { resetPassword, alert } = this.props;
    const { account } = this.state;
    try {
      await resetPassword({ variables: { email: account.email } });
      alert.success('Reset password email has been sent');
    } catch (e) {
      alert.error('There was an error sending reset password email');
    }
  }

  handleChange = (e) => {
    const { target } = e;
    const { account } = this.state;

    this.setState({ account: { ...account, [target.name]: target.value } });
  }

  handleDeactivateAccount = async () => {
    const { account } = this.state;

    await this.setState({ account: { ...account, isDeactivated: true, email: `DEACTIVATED@${uuid()}.com` } });
    await this.upsert();
  }

  getEditRows = (isPrescriber) => {
    const { isSamaUser, isAdmin } = this.props;
    const { account } = this.state;

    const nameHandler = (
      <div>
        <div style={{ display: 'flex', flexDirection: isPrescriber && 'column', justifyContent: 'space-between' }}>
          <BaseText placeholder="First" name="firstName" value={account.firstName || ''} onChange={this.handleChange} />
          <div style={{ width: '8px', height: '8px' }} />
          <BaseText placeholder="Last" name="lastName" value={account.lastName || ''} onChange={this.handleChange} />
        </div>
        { !isPrescriber && (
          <BaseText style={{ marginTop: '8px' }} placeholder="Email" name="email" value={account.email || ''} onChange={this.handleChange} />
        ) }
      </div>
    );

    if (isPrescriber) {
      return [nameHandler].concat(_.map(['label', 'NPI', 'TIN', 'DEA', 'licenseNumber', 'specialty'], acctValKey => (
        <div key={`account-${account.id}-edit-cell-${acctValKey}`}>
          <BaseText
            placeholder={_.upperFirst(acctValKey)}
            name={acctValKey}
            value={account[acctValKey] || ''}
            onChange={this.handleChange}
          />
        </div>
      )));
    }

    const phoneHandler = (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
        <PhoneCleave style={{ marginRight: '8px' }} name="phone" number={account.phone || ''} onChange={this.handleChange} />
        <div style={{ fontSize: '16px', margin: '16px 8px 0 24px' }}>x</div>
        <ExtensionCleave name="extension" number={account.extension || ''} onChange={this.handleChange} />
      </div>
    );

    const adminHandler = (
      <div style={{ marginLeft: '20px' }}>
        <CustomCheckbox
          checked={account.isAdmin}
          onChange={() => { this.handleChange({ target: { name: 'isAdmin', value: !account.isAdmin } }); }}
        />
      </div>
    );

    const readOnlyHandler = (
      <div style={{ marginLeft: '20px' }}>
        <CustomCheckbox
          checked={account.isReadOnly}
          onChange={() => { this.handleChange({ target: { name: 'isReadOnly', value: !account.isReadOnly } }); }}
        />
      </div>
    );

    return [nameHandler, phoneHandler].concat(isSamaUser ? [adminHandler] : []).concat(isAdmin ? [readOnlyHandler] : []);
  }

  render() {
    const { isSamaUser, isAdmin } = this.props;
    const { editMode, account, link, isLockedOut, showExpiredButton, showLockedButton } = this.state;
    const fullName = `${account.lastName}, ${account.firstName}`;
    const isPrescriber = account.type === PRESCRIBER;

    const rows = this.getEditRows(isPrescriber);

    if (isLockedOut && !isPrescriber) {
      const accountErrorMessage = 'This account is locked out because the user has had too many invalid login '
      + 'attempts or because the user has not logged in for too long.';

      return (
        <TableRowEdit>
          <td colSpan={`${rows.length}`}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '16px' }}>
              <BaseButton
                onClick={() => { this.setState({ isLockedOut: null, showExpiredButton: true, showLockedButton: true }); }}
                style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px' }}
              >
                  X
              </BaseButton>
            </div>
            <div style={{ height: '125px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <div style={{ width: '100%', textAlign: 'center' }}>{`${account.firstName} ${account.lastName} - ${account.email}`}</div>
              <div style={{ width: '100%', textAlign: 'center' }}>{accountErrorMessage}</div>
              <BaseButton
                style={{ width: '22.5%', height: '40px', margin: '8px' }}
                onClick={this.unlockAccount}
              >
                Unlock This Account
              </BaseButton>
              <BaseButton
                style={{ width: '22.5%', height: '40px', margin: '8px' }}
                onClick={this.handleDeactivateAccount}
              >
                Deactivate This Account Permanently
              </BaseButton>
            </div>
          </td>
        </TableRowEdit>
      );
    }

    if (editMode) {
      return (
        <TableRowEdit>
          {_.map(rows.slice(0, -1), (elm, i) => (
            <Cell key={`account-${account.id}-edit-cell-${i}`}>
              {elm}
            </Cell>
          ))}
          <Cell>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {showLockedButton && (
                <BaseButton
                  onClick={() => { this.setState({ isLockedOut: true }); }}
                  style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px', marginRight: '8px' }}
                >
                  Account is Locked
                </BaseButton>
              )}
              {showExpiredButton && (
                <BaseButton
                  onClick={() => { this.setState({ isLockedOut: true }); }}
                  style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px', marginRight: '8px' }}
                >
                  Account is Expired
                </BaseButton>
              )}
              <BaseButton
                onClick={() => { this.setState({ editMode: false }); }}
                style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px' }}
              >
                X
              </BaseButton>
            </div>
            {_.last(rows)}
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
              {isAdmin && <EditButton onClick={this.handleDeactivateAccount}>Deactivate This Account</EditButton>}
              {isSamaUser && !isPrescriber && <EditButton onClick={this.generatePasswordLink}>Generate Reset Password Link</EditButton>}
              {!isPrescriber && <EditButton onClick={this.sendPasswordEmail}>Send Password Email</EditButton>}
              <EditButton onClick={this.upsert}>Save Changes</EditButton>
            </div>
          </Cell>
        </TableRowEdit>
      );
    }

    if (link) {
      return (
        <TableRowEdit>
          <td colSpan={`${rows.length}`}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '16px' }}>
              <BaseButton
                onClick={() => { this.setState({ link: null }); }}
                style={{ padding: '6px', borderRadius: '5px', marginBottom: '4px' }}
              >
                  X
              </BaseButton>
            </div>
            <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
              <Link href={link}>{link}</Link>
            </div>
          </td>
        </TableRowEdit>
      );
    }

    return (
      <TableRow onClick={() => { this.setState({ editMode: true }); }}>
        <Cell>
          <Name>{fullName}</Name>
          { !isPrescriber && (
            <Email>{account.email}</Email>
          ) }
        </Cell>
        {account.type !== PRESCRIBER
          ? (
            <>
              <Cell>
                {`${account.phone || ''}${account.extension ? `   x${account.extension}` : ''}`}
              </Cell>
              { isSamaUser && (<Cell><CustomCheckbox checked={account.isAdmin} /></Cell>) }
              { isAdmin && (<Cell><CustomCheckbox checked={account.isReadOnly} /></Cell>) }
            </>
          )
          : (
            <>
              {
                _.map(
                  _.values(_.pick(account, ['label', 'NPI', 'TIN', 'DEA', 'licenseNumber', 'specialty'])),
                  (acctVal, i) => <Cell key={`account-${account.id}-static-cell-${i}`}>{acctVal}</Cell>,
                )
              }
            </>
          )
        }
      </TableRow>
    );
  }
}

export default compose(withUpsertAccount, withGeneratePasswordToken, withResetPassword, withUnlockAccount)(withAlert(AccountRow));
