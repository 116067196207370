import _ from 'lodash';
import React from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';

import AccountRow from './AccountRow';
import BaseButton from '../../../components/BaseButton';
import { withUpsertAccount } from '../../../graphql/Account';

const TableContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
`;

const TableHeader = styled.th`
  text-align: left;
  font-weight: 500;
  color: ${props => props.theme.lightGray};
`;

const generateAccountRow = (user, isSamaUser, isAdmin) => (
  <AccountRow
    key={`account-id-${user.id}`}
    account={user}
    isSamaUser={isSamaUser}
    isAdmin={isAdmin}
  />
);

const columns = {
  user: ['Name (click to edit)', 'Phone'],
  prescriber: ['Name (click to edit)', 'Label', 'NPI', 'TIN', 'DEA', 'License', 'Specialty'],
};

const AccountsTable = ({ accountList, type, alert, upsertAccount, isSamaUser, isAdmin }) => {
  let selectedColumn = columns[type];
  if (type === 'user' && isSamaUser) {
    selectedColumn = selectedColumn.concat('Is Admin');
  }
  if (type === 'user' && isAdmin) {
    selectedColumn = selectedColumn.concat('Is Read Only');
  }
  return (
    <TableContainer>
      <thead>
        <tr>
          {selectedColumn.map(columnName => (
            <TableHeader key={`${type}-column-${columnName}`}>
              {columnName !== _.last(selectedColumn)
                ? <div style={{ padding: '0 16px' }}>{columnName}</div>
                : (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ padding: '0 16px' }}>{columnName}</div>
                    <BaseButton
                      style={{ width: '75px' }}
                      onClick={async () => {
                        try {
                          await upsertAccount({ variables: { patch: {
                            firstName: CONFIG.CONSTANTS.DEFAULT_USER.firstName,
                            lastName: CONFIG.CONSTANTS.DEFAULT_USER.lastName,
                            email: `new@${Math.floor((Math.random() * 10000000))}.com`,
                            type,
                          } } });
                          alert.success('Success');
                        } catch (e) {
                          alert.error(`There was an error creating this account, ${e.message}`);
                        }
                      }}
                    >
                      New
                    </BaseButton>
                  </div>
                )}
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {accountList.map(acct => (generateAccountRow(acct, isSamaUser, isAdmin)))}
      </tbody>
    </TableContainer>
  );
};

export default compose(withUpsertAccount)(withAlert(AccountsTable));
