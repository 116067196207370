import gql from 'graphql-tag';

import authorizationInfo from './authorizationInfo';

export default gql`
  fragment authorizationPage on AuthorizationConnection {
    totalCount
    authorizations {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;
