import gql from 'graphql-tag';
import _ from 'lodash';
import { graphql } from 'react-apollo';
import institutionInfo from './fragments/institutionInfo';
import detailedInstitutionInfo from './fragments/detailedInstitutionInfo';

import { withDefaultProps } from './utils';

const withInstitutionsQuery = gql`
  query Institutions {
    institutionsFindAll {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withInstitutions = graphql(withInstitutionsQuery, {
  props: withDefaultProps(({ data }) => ({ institutions: _.get(data, 'institutionsFindAll') })),
});

export const SUPPORT_INSTITUTION_QUERY = 'supportInstitutionQuery';
export const DETAILED_INSTITUTIONS_QUERY = 'DetailedInstitutions';
const withDetailedInstitutionsQuery = gql`
  query ${DETAILED_INSTITUTIONS_QUERY} {
    institutionsFindAll {
      ...detailedInstitutionInfo
    }
  }
  ${detailedInstitutionInfo}
`;

export const withDetailedInstitutions = graphql(withDetailedInstitutionsQuery, {
  options: { fetchPolicy: 'network-only' },
  props: withDefaultProps(({ data }) => ({ institutions: _.get(data, 'institutionsFindAll') })),
});

const withUpdateInstitutionMutation = gql`
  mutation updateInstitution($id: Int!, $patch: JSON!) {
    updateInstitution(id: $id, patch: $patch) {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withUpdateInstitution = graphql(withUpdateInstitutionMutation, {
  name: 'updateInstitution',
  options: () => ({ refetchQueries: () => [DETAILED_INSTITUTIONS_QUERY, SUPPORT_INSTITUTION_QUERY] }),
});

const withCreateInstitutionMutation = gql`
  mutation createInstitution {
    createInstitution {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withCreateInstitution = graphql(withCreateInstitutionMutation, {
  name: 'createInstitution',
  options: () => ({ refetchQueries: () => [DETAILED_INSTITUTIONS_QUERY, SUPPORT_INSTITUTION_QUERY] }),
});

const withDeleteInstitutionMutation = gql`
  mutation deleteInstitution($id: Int!) {
    deleteInstitution(id: $id)
  }
`;

export const withDeleteInstitution = graphql(withDeleteInstitutionMutation, {
  name: 'deleteInstitution',
  options: () => ({ refetchQueries: () => [DETAILED_INSTITUTIONS_QUERY] }),
});
