import React from 'react';
import { DragSource } from 'react-dnd';

function Draggable(props) {
  const { children, connectDragSource } = props;

  return connectDragSource(<div>{ children }</div>);
}

export const DRAGGABLE_TYPE = 'inputDraggable';
export default DragSource(
  DRAGGABLE_TYPE,
  {
    beginDrag: (props) => { props.onDragStart(); return { id: props.id }; },
    endDrag: (props) => { props.onDragEnd(); },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(Draggable);
