import React, { PureComponent } from 'react';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import strings from 'Resources/strings';

import Modal from '../../Modal';
import BaseButton from '../../BaseButton';
import PatientSearchList from '../PatientSearch/PatientSearchList';
import { ModalFooter } from '../../ModalStyledComponents';

const ModalBody = styled.div`
  width: 640px;
`;

const CustomModalFooter = styled(ModalFooter)`
  margin-top: 10px;
  ${BaseButton} {
    width: 120px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const ModalDescription = styled.div`
  padding: 10px 0;
`;

class SelectPatientModal extends PureComponent {
  setPatient = (patient) => {
    const { setPatient } = this.props;
    mixpanel.track('Duplicate patient resolved');
    setPatient(patient);
  }

  close = () => {
    const { onCloseModal } = this.props;
    mixpanel.track('Duplicate patient ignored');
    onCloseModal();
  }

  render() {
    const { open, patients } = this.props;

    return (
      <Modal header={strings.INITIALIZE_SECTION.SELECT_PATIENT_MODAL_HEADER} open={open}>
        <ModalBody>
          <ModalDescription>{strings.INITIALIZE_SECTION.SELECT_PATIENT_MODAL_BODY}</ModalDescription>
          <PatientSearchList
            patients={patients}
            setPatient={this.setPatient}
          />
        </ModalBody>
        <CustomModalFooter>
          <BaseButton onClick={this.close}>{strings.COMMON.CLOSE}</BaseButton>
        </CustomModalFooter>
      </Modal>
    );
  }
}

export default SelectPatientModal;
