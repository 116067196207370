import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: ${props => props.theme.darkGray};
  width: 175px;
  white-space: normal;
`;

const PayerResponseRange = ({ min, max }) => {
  let text;
  let tooltipText = 'Data based off the last 100 authorizations submitted to this payer';
  if (_.isNull(min) || _.isNull(max)) {
    text = 'Not enough data to determine expected payer response time';
    tooltipText = '';
  } else if (min === 0 && max === 0) {
    text = 'Payer generally responds within 24 hours';
  } else if (min === max) {
    text = `Payer generally responds within ${min} days`;
  } else {
    text = `Payer generally responds between ${min} - ${max} days`;
  }

  const toolTipId = `payerResponseRange_${min}_${max}`;
  return (
    <Container
      data-for={toolTipId}
      data-tip=""
    >
      { text }
      { tooltipText && (<ReactTooltip id={toolTipId}>{tooltipText}</ReactTooltip>) }
    </Container>
  );
};

export default PayerResponseRange;
