import gql from 'graphql-tag';

import authorizationFormInfo from './authorizationFormInfo';
import insuranceCompanyInfo from './insuranceCompanyInfo';
import formNumberInfo from './formNumberInfo';
import formFilterInfo from './formFilterInfo';

export default gql`
  fragment detailedAuthorizationFormInfo on AuthorizationForm {
    ...authorizationFormInfo
    insuranceCompanies {
      ...insuranceCompanyInfo
    }

    formNumbers {
      ...formNumberInfo
    }

    formFilters {
      ...formFilterInfo
    }
  }

  ${authorizationFormInfo}
  ${insuranceCompanyInfo}
  ${formNumberInfo}
  ${formFilterInfo}
  `;
