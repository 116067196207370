import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import { BaseText } from 'Segment/StyledComponents';

import CreateInsuranceCompanyModal from './CreateInsuranceCompanyModal';
import FiltersContainer from './FiltersContainer';
import DeleteFax from './DeleteFax';
import BaseButton from '../../../../components/BaseButton';
import { withInsuranceCompanies } from '../../../../graphql/InsuranceCompany';
import { withUpdateFormNumber } from '../../../../graphql/FormNumber';
import { withUpsertFaxFilter } from '../../../../graphql/FaxFilter';
import filterInsuranceCompanyOptions from '../../../../util/filterInsuranceCompanyOptions';

const InsuranceSelector = styled(Creatable)`
  width: 100%;
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 500;
  margin: 10px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.selected && props.theme.lightPurple};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.lightPurple};
  }
`;

const DarkGray = styled.div`
  color: ${props => props.theme.darkGray};
`;

const StyledButton = styled(BaseButton)`
  width: 100%;
  margin-bottom: 8px;
`;

const StyledInput = styled(BaseText)`
  width: 100%;
  margin: 0 16px 8px 0;
`;

const EditContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin-bottom: 10px;
  flex-direction: row;
`;

const EditColumn = styled.div`
  flex: 1;
  margin: 0 8px;

  ${BaseButton} {
    margin-left: auto;
  }
`;

const Scrollable = styled.div`
  margin: 8px 0;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const Pill = styled.div`
  border-radius: 2px;
  font-size: 12px;
  background: white;
  border: 1px solid ${({ disabled, warning, theme }) => (warning ? theme.red : (disabled ? theme.darkGray : theme.purple))};
  color: ${({ disabled, warning, theme }) => (warning ? theme.red : (disabled ? theme.darkGray : theme.purple))};
  padding: 4px;
  margin-left: 10px;
`;

const { SUGGEST, HIDE_UNLESS } = CONFIG.CONSTANTS.FILTER_TYPES;

export class FaxEditContainer extends PureComponent {
  state = {
    updateForms: [],
    updateNumber: '',
    updateDescription: '',
    updateForbiddenReason: '',
    unassociateInstitution: false,
    selectedPayer: { id: null },
  };

  componentDidMount() {
    const { selectedNumber } = this.props;
    this.updateSelectedNumber(selectedNumber);
  }

  componentDidUpdate(prevProps) {
    const { selectedNumber } = this.props;
    if (selectedNumber.id !== prevProps.selectedNumber.id) {
      this.updateSelectedNumber(selectedNumber);
    }
  }

  updateSelectedNumber = ({ associatedForms, number, description, forbiddenReason, InsuranceCompanyId }) => {
    const { insuranceCompanies } = this.props;
    this.setState({
      updateForms: associatedForms,
      updateNumber: number,
      updateDescription: description,
      updateForbiddenReason: forbiddenReason,
      unassociateInstitution: false,
      selectedPayer: _.find(insuranceCompanies, { id: InsuranceCompanyId }) || { id: null },
    });
  }

  checkForUnsavedChanges = () => {
    const { selectedNumber } = this.props;
    const { updateForms, selectedPayer, updateNumber, updateDescription, updateForbiddenReason, unassociateInstitution } = this.state;
    return (
      selectedPayer.id !== selectedNumber.InsuranceCompanyId
      || updateDescription !== selectedNumber.description
      || updateNumber !== selectedNumber.number
      || unassociateInstitution
      || updateForbiddenReason !== selectedNumber.forbiddenReason
      || !_.isEqual(_.sortBy(_.map(selectedNumber.associatedForms, 'id')), _.sortBy(_.map(updateForms, 'id')))
    );
  }

  saveChanges = async () => {
    const { updateFormNumber, selectedNumber, alert } = this.props;
    const { updateForms, selectedPayer, updateNumber, updateDescription, updateForbiddenReason, unassociateInstitution } = this.state;
    if (!updateDescription || !updateNumber) {
      alert.error('Please enter both a description and a correct number');
    } else {
      try {
        const patch = {
          description: updateDescription,
          number: updateNumber,
          forbiddenReason: updateForbiddenReason,
          ...(unassociateInstitution ? { InstitutionId: null } : {}),
          ...(selectedPayer.id ? { InsuranceCompanyId: selectedPayer.id } : {}),
          formIds: _.map(updateForms, 'id'),
        };
        await updateFormNumber({ variables: { id: selectedNumber.id, patch } });
        alert.info('Successfully updated form number');
      } catch (e) {
        alert.error(`Failed to update ${e}`);
      }
    }
  }

  render() {
    const { insuranceCompanies, upsertFaxFilter, selectedNumber, clearSelectedNumber, forms } = this.props;
    const {
      updateForms,
      selectedPayer,
      selectedForm,
      updateNumber,
      updateDescription,
      updateForbiddenReason,
      unassociateInstitution,
      showDeleteModal,
      newPayer,
    } = this.state;
    const unsavedChanges = this.checkForUnsavedChanges();

    return (
      <div>
        <EditContainer>
          <EditColumn>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <InputLabel>Change associated insurance company</InputLabel>
              <InsuranceSelector
                style={{ marginBottom: '8px' }}
                labelKey="name"
                onChange={(company) => { this.setState({ selectedPayer: company || {} }); }}
                options={_.sortBy(insuranceCompanies, 'name')}
                valueKey="id"
                value={selectedPayer}
                sorted
                placeholder="Change associated insurance company"
                filterOption={filterInsuranceCompanyOptions}
                onNewOptionClick={({ name }) => { this.setState({ newPayer: name }); }}
              />
            </div>
            <StyledInput
              onChange={(e) => { this.setState({ updateDescription: e.target.value }); }}
              value={updateDescription}
              placeholder="Update Description"
            />
            <StyledInput
              onChange={(e) => { this.setState({ updateNumber: e.target.value }); }}
              value={updateNumber}
              placeholder="Update Fax"
            />
            {updateForbiddenReason && (
              <StyledInput
                onChange={(e) => { this.setState({ updateForbiddenReason: e.target.value }); }}
                value={updateForbiddenReason}
              />
            )}
            <div style={{ display: 'flex' }}>
              <StyledButton
                style={{ marginRight: '8px' }}
                disabled={!selectedNumber.InstitutionId || updateForbiddenReason || unassociateInstitution}
                onClick={() => { this.setState({ unassociateInstitution: true }); }}
              >
                Unassociate from Institution
              </StyledButton>
              <StyledButton
                onClick={() => { this.setState({ updateForbiddenReason: updateForbiddenReason ? '' : 'Forbidden number reason' }); }}
              >
                {updateForbiddenReason ? 'Remove from Blacklist' : 'Add to Blacklist'}
              </StyledButton>
            </div>
          </EditColumn>
          <EditColumn>
            <div style={{ height: '200px', marginBottom: '8px' }}>
              {updateForbiddenReason ? <InputLabel>Blacklisted numbers are not form associated</InputLabel> : (
                <div>
                  <InputLabel>Associate forms with this fax number</InputLabel>
                  <Select
                    onChange={(form) => { this.setState(prevState => ({ updateForms: _.uniqBy(_.compact([...prevState.updateForms, form]), 'id') })); }}
                    labelKey="title"
                    valueKey="id"
                    options={forms}
                    value={selectedForm}
                  />
                  <Scrollable style={{ height: '150px' }}>
                    {_.map(updateForms, ({ title, description, id }) => (
                      <ItemContainer style={{ display: 'flex', margin: '0 8px' }} key={`associatedform-id-${id}`}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>{title}</div>
                          <DarkGray>{description.length > 30 ? [description.slice(0, 30), '...'].join('') : description}</DarkGray>
                        </div>
                        <BaseButton
                          onClick={() => { this.setState(prevState => ({ updateForms: _.reject(prevState.updateForms, { id }) })); }}
                        >
                          X
                        </BaseButton>
                      </ItemContainer>
                    ))}
                  </Scrollable>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '0 8px' }}>
              {unsavedChanges && (
                <>
                  <InputLabel style={{ color: 'red' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ margin: '0 0 8px 8px' }}>Unsaved Changes</div>
                      <div style={{ display: 'flex' }}>
                        {selectedNumber.InstitutionId && (unassociateInstitution || updateForbiddenReason) && <Pill warning>Unassociate</Pill>}
                        {updateForbiddenReason && <Pill warning>Blacklist</Pill>}
                      </div>
                    </div>
                  </InputLabel>
                  <BaseButton style={{ width: '100px', marginRight: '8px' }} disabled={!unsavedChanges} onClick={this.saveChanges}>Save</BaseButton>
                </>
              )}
              <BaseButton color="red" style={{ width: '125px' }} onClick={() => { this.setState({ showDeleteModal: true }); }}>Delete</BaseButton>
            </div>
          </EditColumn>
        </EditContainer>
        <FiltersContainer
          suggestFilter={_.find(selectedNumber.faxFilters, { type: SUGGEST })}
          hideFilter={_.find(selectedNumber.faxFilters, { type: HIDE_UNLESS })}
          associationId={selectedNumber.id}
          upsertFunction={upsertFaxFilter}
          associationKey="formNumberId"
        />
        {showDeleteModal && (
          <DeleteFax
            id={selectedNumber.id}
            description={selectedNumber.description}
            number={selectedNumber.description}
            clear={() => { clearSelectedNumber(); }}
          />
        )}
        {newPayer && <CreateInsuranceCompanyModal onClose={() => { this.setState({ newPayer: null }); }} name={newPayer} />}
      </div>
    );
  }
}

export default compose(withInsuranceCompanies, withUpsertFaxFilter, withUpdateFormNumber)(withAlert(FaxEditContainer));
