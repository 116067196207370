import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import colors from 'Resources/colors';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { withResolvePortalMetric } from '../../../graphql/Portal';
import PortalErrorAndNightlyTestModal from '../../../components/PortalErrorAndNightlyTestModal';

const Container = styled.div`
  display: flex;
  flex-diretion: row;
  align-items: center;
  height: 50px
  padding: 5px;
`;

const StatusBox = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${props => props.color};
  margin: 0 2px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class NightlyStatusDisplay extends PureComponent {
  state = { errorId: null, showErrorImageModal: false, expandImage: false }

  onNightlyTestsClick = (e, isSuccess, id) => {
    e.stopPropagation();

    if (!isSuccess) {
      this.setState({ showErrorImageModal: true, errorId: id });
    }
  }

  handleResolveNightlyTest = async () => {
    const { resolvePortalMetric, alert } = this.props;
    const { errorId } = this.state;

    try {
      await resolvePortalMetric({ variables: { id: errorId, isForAuthError: false } });
      this.setState({ showErrorImageModal: false });
      alert.success('Success');
    } catch {
      alert.error('Error resolving nightly test error');
    }
  }

  render() {
    const { statuses } = this.props;
    const { errorId, showErrorImageModal, expandImage } = this.state;
    const sorted = _.orderBy(statuses, 'createdAt', 'desc');
    const lastSevenNightlyStatuses = _.reverse(_.slice(sorted, 0, 7));

    const errorImage = _.find(statuses, { id: errorId });
    return (
      <Container>
        {
          _.times(7, (dayOfWeek) => {
            const status = lastSevenNightlyStatuses[dayOfWeek];
            if (!status) {
              return (
                <StatusBox
                  key={`StatusDisplay_notAvailable_${dayOfWeek}`}
                  color={colors.lightGray}
                >
                  N/a
                </StatusBox>
              );
            }
            return (
              <StatusBox
                key={`StatusDisplay_${status.id}`}
                color={(status.isSuccess ? colors.green : colors.red)}
                onClick={(e) => { this.onNightlyTestsClick(e, status.isSuccess, status.id); }}
              >
                { status.displayDate }
              </StatusBox>
            );
          })
        }
        { showErrorImageModal && (
          <PortalErrorAndNightlyTestModal
            header="Nightly Portal Test Error"
            open={showErrorImageModal}
            modalOrBackOnClick={() => { this.setState({ showErrorImageModal: false }); }}
            expandImageOnClick={() => { this.setState({ expandImage: true }); }}
            errorModalError={errorImage}
            expandImage={expandImage}
            condenseImageOnClick={() => { this.setState({ expandImage: false }); }}
            handleResolve={this.handleResolveNightlyTest}
            shouldAllowResolve
          />
        )}
      </Container>
    );
  }
}

export default compose(withResolvePortalMetric)(withAlert(NightlyStatusDisplay));
