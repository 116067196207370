import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

export const Checkbox = styled.input`
  cursor: pointer;
  flex: 1;
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  margin: 5px;
  cursor: pointer;
`;

class SegmentCheckbox extends PureComponent {
  render() {
    const {
      item, handleChange, result, disabled, name,
    } = this.props;

    return (
      <Checkbox
        checked={result === 'On'}
        disabled={disabled}
        id={name}
        name={name}
        onChange={(event) => {
          const checkedValue = event.target.checked ? 'On' : null;
          handleChange({ [item.key]: checkedValue });
        }}
        type="checkbox"
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentCheckbox);
