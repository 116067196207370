import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const DetailTitle = styled.div`
  font-weight: bold;
  color: ${props => props.theme.purple};
`;

const DetailsSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const DetailContainer = styled.div`
  margin: 5px;
`;

const fields = CONFIG.DEFAULT_FIELDS;
class DetailsContainer extends PureComponent {
  state = {};

  render() {
    const { authorization } = this.props;

    const authorizationDetails = [
      {
        title: 'Patient Details',
        items: [
          ['Name', `${authorization.patient.lastName}, ${authorization.patient.firstName}`],
        ],
      },
      {
        title: 'Request Details',
        items: [
          ['Description', authorization.config[fields.REQUEST_DESCRIPTION.key]],
          ['Drug', authorization.config[fields.PRIMARY_DRUG_NAME.key]],
          ['Dose', authorization.config[fields.PRIMARY_DOSE.key]],
          ['Service Date', authorization.config[fields.DATE_OF_SERVICE.key]],
        ],
      },
    ];

    return (
      <>
        { _.map(authorizationDetails, detailSection => (
          <div key={`association_detailSection_${detailSection.title}`}>
            <DetailTitle>{ detailSection.title }</DetailTitle>
            <DetailsSectionContainer>
              {
                _.map(detailSection.items, prop => (
                  <DetailContainer key={`SupportView_${prop[0]}`}>
                    <b>{ prop[0] }</b>
                    <div>{ prop[1] }</div>
                  </DetailContainer>
                ))
              }
            </DetailsSectionContainer>
          </div>
        )) }
      </>
    );
  }
}

export default DetailsContainer;
