import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BaseMultiline } from './StyledComponents';

class SegmentMultiline extends PureComponent {
  render() {
    const { item, handleChange, result, disabled, highlightIfMissing } = this.props;

    return (
      <BaseMultiline
        disabled={disabled}
        onChange={(event) => { handleChange({ [item.key]: event.target.value }); }}
        value={result || ''}
        highlight={highlightIfMissing && !result}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentMultiline);
