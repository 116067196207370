import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import styled from 'styled-components';

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const Selector = styled(Select)`
  width: ${props => props.width || '600px'};
  border: ${props => props.highlight ? `2px solid ${props.theme.red}` : ''};
`;

const Creater = styled(Creatable)`
  width: ${props => props.width || '600px'};
  border: ${props => props.highlight ? `2px solid ${props.theme.red}` : ''};
`;

const SegmentSelector = (props) => {
  const { sorted, title, setRef, createable, options, filterOption, highlight } = props;

  let sortableOptions = options;
  if (sorted && options) {
    sortableOptions = _.sortBy(options, 'label');
  }

  return (
    <div>
      <SelectorTitle>{title}</SelectorTitle>
      { createable
        // Expected warning from react here
        // https://github.com/JedWatson/react-select/pull/2416
        // Not currently fixed in lib
        ? (
          <Creater
            menuStyle={{ backgroundColor: '#a9a9e0' }}
            ref={setRef}
            placeholder="Begin typing to search or create an option"
            {...props}
            options={sortableOptions}
            highlight={highlight}
          />
        )
        : (
          <Selector
            ref={setRef}
            menuStyle={{ backgroundColor: '#a9a9e0' }}
            placeholder="Begin typing to select an option"
            {...props}
            options={sortableOptions}
            filterOption={filterOption}
            highlight={highlight}
          />
        )
      }
    </div>
  );
};

export default SegmentSelector;
