import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';

import { DRAGGABLE_TYPE } from './DraggableTag';

const Droppable = styled.div``;
class DroppableContainer extends PureComponent {
  render() {
    const { children, connectDropTarget, isOver } = this.props;

    return connectDropTarget(
      <div>
        <Droppable isOver={isOver}>
          { children }
        </Droppable>
      </div>
    );
  }
}

export default DropTarget(
  DRAGGABLE_TYPE,
  { drop: () => {} },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }),
)(DroppableContainer);
