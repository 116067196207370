import React from 'react';

const SamaIcon = () => (
  <img
    style={{ width: '18px', height: '18px', verticalAlign: 'middle' }}
    src="../../assets/whiteLogo.svg"
  />
);

export default SamaIcon;
