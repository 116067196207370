import React, { PureComponent } from 'react';

import { genericInput } from '../InputPropTypes';
import { BaseTextArea, getPropsFromInput } from './BaseInput';

class TextMultilineInput extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <BaseTextArea
        type="text"
        {...getPropsFromInput(input)}
        onChange={(e) => { input.onChange({ [input.id]: e.target.value }); }}
        value={input.value || ''}
      />
    );
  }
}

TextMultilineInput.propTypes = {
  input: genericInput.isRequired,
};

export default TextMultilineInput;
