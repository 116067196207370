import axios from 'axios';
import mixpanel from 'mixpanel-browser';

export const login = (email, password, onComplete) => {
  axios.post('/login', { email: email.trim(), password })
    .then((res) => {
      const { user } = res.data;
      mixpanel.register({
        userId: user.id,
        institutionId: user.InstitutionId,
        isSamaUser: user.isSamaUser,
      });

      if (CONFIG.NODE_ENV === 'production') {
        satismeter({
          writeKey: CONFIG.SATISMETER_KEY,
          userId: user.id,
          traits: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            createdAt: user.createdAt,
          },
        });
      }

      onComplete();
    })
    .catch(err => onComplete(err));
};

export const logout = () => {
  axios.post('/logout').then(() => { window.location.reload(); });
};
