import _ from 'lodash';
import React, { useState } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import SegmentSelector from 'Segment/SegmentSelector';
import PatientIntegrationsRow from './PatientIntegrationsRow';
import BaseButton from '../../../components/BaseButton';
import { withInstitutions } from '../../../graphql/Institution';
import {
  withPatientIntegrationCredentials,
  withPatientIntegrationTitles,
  withUpsertPatientIntegrationCredential,
} from '../../../graphql/PatientIntegrationCredential';

const TableContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
`;

const TableHeader = styled.th`
  text-align: left;
  font-weight: 500;
  color: ${props => props.theme.lightGray};
`;

const columns = ['Institution', 'Integration Title', 'Username', 'Password', 'LoginURL', 'Office Key'];

const SupportPatientSyncer = ({ patientIntegrationCredentials, patientIntegrationTitles, institutions, upsertPatientIntegrationCredential, alert }) => {
  const [institutionId, setInstitution] = useState(0);
  return (
    <div>
      <div style={{ display: 'flex', marginLeft: '16px' }}>
        <SegmentSelector
          style={{ flex: 1, marginBottom: '8px' }}
          labelKey="name"
          valueKey="id"
          options={institutions}
          width="400px"
          onChange={(selected) => {
            setInstitution(_.get(selected, 'id'));
          }}
          value={institutionId && _.find(institutions, { id: institutionId })}
          placeholder="Select an institution to create credential"
        />
        <BaseButton
          style={{ width: '150px', marginLeft: '16px' }}
          disabled={!institutionId}
          onClick={async () => {
            try {
              await upsertPatientIntegrationCredential({ variables: { institutionId, patch: { title: patientIntegrationTitles[0] } } });
              alert.success('Success');
            } catch (e) {
              alert.error(`There was an error creating this credential, ${e.message}`);
            }
          }}
        >
          Create New Syncer Credential
        </BaseButton>
      </div>
      <TableContainer>
        <thead>
          <tr>
            {columns.map(columnName => (
              <TableHeader key={`column-${columnName}`}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ padding: '0 16px' }}>{columnName}</div>
                </div>
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {_.map(
            _.sortBy(patientIntegrationCredentials, 'institution.name'),
            ({ id, title, username, password, loginURL, officeKey, institution, latestErrorLogs }) => (
              <PatientIntegrationsRow
                id={id}
                key={`patient-integration-credential-id-${id}`}
                title={title}
                username={username}
                password={password}
                loginURL={loginURL}
                officeKey={officeKey}
                institution={institution}
                alert={alert}
                upsert={upsertPatientIntegrationCredential}
                patientIntegrationTitles={patientIntegrationTitles}
                errorLogs={latestErrorLogs}
              />
            )
          )}
        </tbody>
      </TableContainer>
    </div>
  );
};

export default compose(
  withPatientIntegrationCredentials,
  withPatientIntegrationTitles,
  withUpsertPatientIntegrationCredential,
  withInstitutions,
)(withAlert(SupportPatientSyncer));
