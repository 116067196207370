import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { AUTHORIZATION_ERRORS_QUERY_NAME } from './AuthorizationError';
import portalIntegrationDetailsInfo from './fragments/portalIntegrationDetailsInfo';
import { withDefaultProps } from './utils';

export const getPortalPreview = gql`
  query getPortalPreview($key: String!) {
    getPortalPreview(key: $key)
  }
`;

const PORTAL_ERRORS_QUERY_NAME = 'getPortalIntegrationErrors';
export const getPortalIntegrationErrorsQuery = gql`
  query ${PORTAL_ERRORS_QUERY_NAME}($hasBeenResolved: Boolean!, $daysToLookBack: Int, $filterByPortalKey: String) {
    getPortalIntegrationErrors(hasBeenResolved: $hasBeenResolved, daysToLookBack: $daysToLookBack, filterByPortalKey: $filterByPortalKey) {
      id
      message
      imageURL
      portalKey
      AuthorizationId
      createdAt
      hasAuthBeenSubmitted
      hasAuthBeenDeleted
      createdByUserEmail
      institutionName
      resolutionStatus
      resolutionDetails
      resolvedBy {
        firstName
      }
      type
    }
  }
`;
export const getPortalIntegrationErrors = graphql(
  getPortalIntegrationErrorsQuery,
  {
    fetchPolicy: 'no-cache',
    props: withDefaultProps(({ data }) => ({ portalErrors: _.get(data, 'getPortalErrors') })),
  }
);

export const getDisabledPortals = graphql(
  gql`
    query getDisabledPortals {
      getDisabledPortals {
        id
        key
        userMessage
      }
    }
  `,
  {
    fetchPolicy: 'no-cache',
    props: withDefaultProps(({ data }) => ({ disabledPortals: _.get(data, 'getDisabledPortals') })),
  }
);

const ALL_PORTALS_QUERY_NAME = 'getAllPortals';
export const getAllPortals = graphql(
  gql`
    query ${ALL_PORTALS_QUERY_NAME} {
      getAllPortals {
        id
        key
        isDisabled
        userMessage
      }
    }
  `,
  {
    fetchPolicy: 'no-cache',
    props: withDefaultProps(({ data }) => ({ allPortals: _.get(data, 'getAllPortals') })),
  }
);

export const listActivePortalIntegrations = graphql(
  gql`
    query listActivePortalIntegrations {
      listActivePortalIntegrations {
        ...portalIntegrationDetailsInfo
      }
    }
    ${portalIntegrationDetailsInfo}
  `,
  {
    fetchPolicy: 'no-cache',
    props: withDefaultProps(({ data }) => ({ integrations: _.get(data, 'listActivePortalIntegrations') })),
  }
);

export const withTerminatePortalIntegration = graphql(
  gql`
    mutation terminatePortalIntegration($uuid: String!) {
      terminatePortalIntegration(uuid: $uuid)
    }
  `, {
    name: 'terminatePortalIntegration',
  }
);

const PORTAL_METRICS_QUERY_NAME = 'getPortalMetrics';
export const getPortalMetrics = graphql(
  gql`
    query ${PORTAL_METRICS_QUERY_NAME} {
      getPortalMetrics {
        total {
          id
          status
          createdAt
        }
        dailyStatus {
          id
          isSuccess
          createdAt
          portalKey
          displayDate
          imageURL
          errorMessage
        }
        errors {
          id
          message
          AuthorizationId
          createdAt
          imageURL
          hasBeenResolved
        }
        key
      }
    }
  `, {
    props: withDefaultProps(({ data }) => ({ portalMetrics: _.get(data, PORTAL_METRICS_QUERY_NAME) })),
  }
);

const withPortalsQuery = gql`
  query portalsFindAll {
    portalsFindAll
  }
`;

export const withPortalList = graphql(withPortalsQuery, {
  props: withDefaultProps(({ data }) => ({ portalList: _.get(data, 'portalsFindAll') })),
});

export const withResolvePortalMetric = graphql(
  gql`
    mutation resolvePortalMetric($id: Int!, $isForAuthError: Boolean!, $resolutionType: String, $resolutionDetails: String) {
      resolvePortalMetricById(id: $id, isForAuthError: $isForAuthError, resolutionType: $resolutionType, resolutionDetails: $resolutionDetails)
    }
  `,
  {
    name: 'resolvePortalMetric',
    options: () => ({
      refetchQueries: () => [PORTAL_METRICS_QUERY_NAME, PORTAL_ERRORS_QUERY_NAME, AUTHORIZATION_ERRORS_QUERY_NAME],
    }),
  },
);

export const withUpdatePortalStatus = graphql(
  gql`
    mutation updatePortalStatus($id: Int!, $shouldEnablePortal: Boolean!, $userMessage: String) {
      updatePortalStatusById(id: $id, shouldEnablePortal: $shouldEnablePortal, userMessage: $userMessage)
    }
  `,
  {
    name: 'updatePortalStatus',
    options: () => ({
      refetchQueries: () => [ALL_PORTALS_QUERY_NAME],
    }),
  },
);
