import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import BaseButton from '../../components/BaseButton';

const RelativeButton = styled(BaseButton)`
  position: relative;
  margin-right: 10px;
`;

class SaveButton extends PureComponent {
  save = async () => {
    const { upsertForm, alert, formId } = this.props;

    try {
      await upsertForm(formId, {});
      alert.success('Changes saved!');
    } catch {
      alert.error('Error!');
    }
  }

  render() {
    const { formId } = this.props;

    const isDisabled = !formId;
    return (
      <div>
        <RelativeButton disabled={isDisabled} onClick={this.save}>Save</RelativeButton>
      </div>
    );
  }
}

export default withAlert(SaveButton);
