import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import styled from 'styled-components';

import { withFollowUpSubmit } from '../graphql/FollowUp';
import Modal from './Modal';
import CorrespondenceViewer from './CorrespondenceViewer';
import LoadingButton from './LoadingButton';

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 30px 0;
`;

const FollowUpRepsonseContainer = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  margin-left: 20px;
`;

const HelperText = styled.div`
  display: flex;
  flex-direction: column;
  white-space: initial;
  text-align: center;
  font-size: 16px;
  margin: 0 8px 20px 0;
  color: ${props => props.theme.darkGray};
`;

const ResponseTextArea = styled.textarea`
  resize: none;
  height: 400px;
  margin: 0 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  border: 2px solid ${props => props.theme.darkGray};
  &:focus { outline: 0 }
`;
const { ERROR_TYPES } = CONFIG.CONSTANTS;

export class FollowUpModal extends PureComponent {
  state = { text: '', loading: false };


  submit = async () => {
    const { text } = this.state;
    const { followUpId, followUpSubmit, redirect, alert } = this.props;
    try {
      this.setState({ loading: true });
      await followUpSubmit({ variables: { id: followUpId, text } });
      alert.info('Successfully submitted payer follow up');
      redirect();
    } catch (e) {
      if (_.includes(e.message, ERROR_TYPES.FOLLOW_UP_SEND)) {
        alert.info('Successfully created payer follow up');
        redirect();
      } else {
        alert.error(`Failed to submit payer follow up ${e}`);
      }
      this.setState({ loading: false });
    }
  }

  render() {
    const { onClose, correspondence } = this.props;
    const { text, loading } = this.state;

    return (
      <Modal onClick={onClose} open header="Answer Payer Questions Below" styleOverride={{ cursor: 'default' }}>
        <TwoPaneContainer>
          <div style={{ marginBottom: 'none' }}>
            <CorrespondenceViewer correspondence={correspondence} width="700px" height="550px" />
          </div>
          <FollowUpRepsonseContainer>
            <HelperText>
              <div style={{ marginBottom: '14px' }}>
                Please type your answers to the payer questions below.
                Your responses will be attached as a cover sheet to your previous authorization.
                SamaCare will alert you when the payer responds to your follow up.
              </div>
            </HelperText>
            <ResponseTextArea value={text} onChange={(e) => { this.setState({ text: e.target.value }); }} />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
              <LoadingButton onClick={this.submit} loading={loading}>
                Submit Response to Payer
              </LoadingButton>
            </div>
          </FollowUpRepsonseContainer>
        </TwoPaneContainer>
      </Modal>
    );
  }
}

export default compose(withFollowUpSubmit)(withAlert(FollowUpModal));
