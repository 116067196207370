import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { setAuthorizationListFilters } from '../reducers/authorizationListReducer';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-weight: bold;
  font-size: 14px;
`;

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`;

export class AuthorizationStatusFilter extends PureComponent {
  handleOnChange = (selectedOption) => {
    const { authorizationList, onChange } = this.props;
    const { filters } = authorizationList;

    return onChange({ ...filters, status: _.get(selectedOption, 'value') });
  }

  render() {
    const { authorizationList } = this.props;
    const { filters } = authorizationList;

    const defaultAuthorizationStatusOption = { value: null, label: 'Any' };
    const authorizationStatusOptions = [defaultAuthorizationStatusOption, ..._.map(
      _.values(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES), (status) => {
        const cleanStatus = _.upperFirst(_.replace(status, /_/g, ' '));
        return { value: status, label: cleanStatus };
      }
    )];

    return (
      <Container>
        <FilterLabel>
          w/ status
        </FilterLabel>
        <StyledSelect
          value={_.get(filters, 'status') ? _.get(filters, 'status') : defaultAuthorizationStatusOption}
          onChange={this.handleOnChange}
          options={authorizationStatusOptions}
          clearable
          searchable
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: toSet => dispatch(setAuthorizationListFilters(toSet)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withAlert(AuthorizationStatusFilter));
