import getMatchingIndicesFromTextFragments from './getMatchingIndicesFromTextFragments';

const procedures = [
  'Medication:',
  'Drug:',
  'HCPCS Treatment Code:',
  'Approved Services:',
];

const getProcedureLocations = fullText => (getMatchingIndicesFromTextFragments(procedures, fullText));

export default getProcedureLocations;
