import _ from 'lodash';

const rejectHiddenFields = (items, formFieldConfig) => _.reject(items, ({ key }) => _.includes(_.get(formFieldConfig, 'hiddenFields'), key));

const transformItem = (item, formFieldConfig) => {
  const base = {
    ...item,
    required: item.required || _.includes(_.get(formFieldConfig, 'requiredFields'), item.key),
  };

  if (item.options) {
    return {
      ...base,
      options: rejectHiddenFields(item.options, formFieldConfig),
    };
  }
  return base;
};

export const transformSections = (sections, formFieldConfig) => _.reject(_.map(sections, section => ({
  title: section.title,
  items: _.map(rejectHiddenFields(section.items, formFieldConfig), item => (
    _.isArray(item)
      ? _.map(rejectHiddenFields(item, formFieldConfig), nestedItem => transformItem(nestedItem, formFieldConfig))
      : transformItem(item, formFieldConfig)
  )),

})), section => _.isEmpty(section.items));
