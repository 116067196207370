import React, { PureComponent } from 'react';
import styled from 'styled-components';

import SupportedAuthorizationModal from '../SupportedAuthorizationModal';
import LoadingButton from '../LoadingButton';
import BaseButton from '../BaseButton';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const BackButton = styled(BaseButton)`
  color: white;
  border-color: ${props => props.theme.lightGray};
  background-color: ${props => props.theme.lightGray};
  width: 150px;
  margin-right: ${props => (props.hasSubmit ? 'auto' : 0)};
  &:hover {
    color: white;
    background-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.gray)};
    border-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.gray)};
  }
`;

const SupportedAuthorizationModalText = styled.span`
  cursor: pointer;
  margin: 16px 0;
  text-decoration: underline;
  color: ${props => props.theme.purple};
  font-weight: 500;
`;

export class FormSubmitButtons extends PureComponent {
  state = { open: false };

  toggleModal = (e) => {
    e.stopPropagation();
    const { open } = this.state;
    this.setState({ open: !open });
  }

  render() {
    const { open } = this.state;
    const { back, submit, submitText, loading = false, disabled, isSupported } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ButtonContainer>
          { back && <BackButton disabled={disabled} hasSubmit={!!submit} onClick={back}>Back</BackButton> }
          { submit
            && (
              <LoadingButton disabled={disabled} loading={loading} onClick={submit} style={{ width: isSupported ? 300 : 150, marginLeft: 'auto' }}>
                { submitText || 'Next' }
              </LoadingButton>
            ) }
        </ButtonContainer>
        {isSupported && submit && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SupportedAuthorizationModalText onClick={this.toggleModal}>What is a Supported Authorization?</SupportedAuthorizationModalText>
          </div>
        )}
        {open && <SupportedAuthorizationModal open={open} closeModal={this.toggleModal} />}
      </div>
    );
  }
}

export default FormSubmitButtons;
