import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import { withCreateInsuranceCompany } from '../../../../graphql/InsuranceCompany';
import BaseButton from '../../../../components/BaseButton';
import Modal from '../../../../components/Modal';
import { ModalBodyLarge } from '../../../../components/ModalStyledComponents';

const Container = styled.div`
  padding: 20px 0;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FormSubmitButton = styled(BaseButton)`
  margin-left: 10px;
`;

class SelectPhoneList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
    };
  }

  create = () => {
    const { createInsuranceCompany, onClose, alert } = this.props;
    const { name } = this.state;

    if (!name) {
      alert.error('Missing required field');
      return;
    }

    createInsuranceCompany({ variables: { name } })
      .then(() => { alert.success('Success'); onClose(); })
      .catch((e) => { alert.error(`Error: ${e.message}`); });
  }

  render() {
    const { onClose } = this.props;
    const { name } = this.state;

    return (
      <Modal header="Add Insurance Company" onClick={onClose} open>
        <ModalBodyLarge>
          <Container>
            <SelectorTitle>Company name</SelectorTitle>
            <BaseText onChange={(e) => { this.setState({ name: e.target.value }); }} value={name} />
          </Container>
          <ButtonContainer>
            <FormSubmitButton onClick={this.create}>Create</FormSubmitButton>
          </ButtonContainer>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default compose(withCreateInsuranceCompany)(withAlert(SelectPhoneList));
