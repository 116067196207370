import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import strings from 'Resources/strings';

import ArchiveIcon from 'react-icons/lib/md/archive';
import DeleteIcon from 'react-icons/lib/md/delete';
import RemoveFromExpired from 'react-icons/lib/md/remove';
import Download from 'react-icons/lib/md/vertical-align-bottom';
import Duplicate from 'react-icons/lib/md/content-copy';
import Edit from 'react-icons/lib/md/edit';
import Resend from 'react-icons/lib/md/cached';
import SamaIcon from '../SamaIcon';

import AuthorizationTimelineButton from '../AuthorizationTimelineButton';
import SupportedFollowUpButton from '../SupportedFollowUpButton';
import ToggleArchiveButton from '../ToggleArchiveButton';
import ToggleHideButton from '../ToggleHideButton';
import DeleteButton from '../DeleteAuthorizationButton';
import DownloadFormsButton from '../DownloadFormsButton';
import DuplicateAuthorizationButton from '../DuplicateAuthorizationButton';
import RequestResendButton from '../RequestResendButton';
import ResubmitPortalAuthButton from '../ResubmitPortalAuthButton';
import EditAndResubmitButton from '../EditAndResubmitButton';

const ActionButton = styled.button`
  display: flex;
  padding: 3px 5px;
  font-size: 13px;
  flex-direction: row;
  color: ${({ supported, theme }) => supported ? 'white' : theme.darkGray};
  background-color: ${({ supported, theme }) => supported ? theme.purple : 'white'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ supported, theme }) => supported ? theme.purple : theme.darkGray};
  margin-right: 15px;
  border-radius: 3px;

  &:hover {
    color: white;
    background: ${({ theme }) => theme.darkGray};
    border: 1px solid ${({ theme }) => theme.darkGray};
  }

  &:focus {
    outline: 0;
  };
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  font-size: 18px;
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const ButtonWrap = ({ Icon, text, props }) => (
  <ActionButton {...props}>
    <IconWrapper><Icon /></IconWrapper>
    <TextWrapper>{ text }</TextWrapper>
  </ActionButton>
);

export class ActionButtons extends PureComponent {
  getButtons = () => {
    const { authorization, authorizationList, loading } = this.props;
    const { tabTitle } = authorizationList.selected;
    const statuses = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
    const buttons = [];

    if (loading) return null;

    if (authorization.isSupported) {
      buttons.push(
        <AuthorizationTimelineButton
          key={`authSupportedTimelineButton-id-${authorization.id}`}
          authorization={authorization}
        >
          <ButtonWrap props={{ supported: true }} Icon={SamaIcon} text="View Authorization Timeline" />
        </AuthorizationTimelineButton>
      );
    }
    if (authorization.isSupported && _.includes(CONFIG.CONSTANTS.PROGRESS_AUTHORIZATION_STATUSES, authorization.status)) {
      buttons.push(
        <SupportedFollowUpButton
          key={`authSupportedFollowUpButton-id-${authorization.id}`}
          id={authorization.id}
        >
          <ButtonWrap props={{ supported: true }} Icon={SamaIcon} text="Request SamaCare Follow Up" />
        </SupportedFollowUpButton>
      );
    }
    if (authorization.status === statuses.PRESUBMISSION) {
      buttons.push((
        <DeleteButton
          key={`authDeleteButton-id-${authorization.id}`}
          id={authorization.id}
          step={authorization.formDetails.currentStep}
          portalKey={authorization.portalKey}
        >
          <ButtonWrap Icon={DeleteIcon} text="Delete" />
        </DeleteButton>
      ));
    }
    if (_.includes(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE, authorization.status)) {
      buttons.push((
        <ToggleArchiveButton
          key={`authArchiveButton-id-${authorization.id}`}
          id={authorization.id}
          isArchived={authorization.isArchived}
        >
          <ButtonWrap Icon={ArchiveIcon} text={(authorization.isArchived ? strings.AUTHORIZATIONS.UNARCHIVE : strings.AUTHORIZATIONS.ARCHIVE)} />
        </ToggleArchiveButton>));
    }
    if (tabTitle === strings.AUTHORIZATIONS.EXPIRING || (tabTitle === strings.AUTHORIZATIONS.ARCHIVED && authorization.hideFromExpiring)) {
      buttons.push((
        <ToggleHideButton
          key={`authHideButton-id-${authorization.id}`}
          id={authorization.id}
          hideFromExpiring={authorization.hideFromExpiring}
        >
          <ButtonWrap Icon={RemoveFromExpired} text={(authorization.hideFromExpiring ? strings.AUTHORIZATIONS.UNHIDE : strings.AUTHORIZATIONS.HIDE)} />
        </ToggleHideButton>));
    }
    if (authorization.submittedAt) {
      buttons.push((
        <DuplicateAuthorizationButton
          key={`duplicateAuthorizationButton-id-${authorization.id}`}
          authorization={authorization}
        >
          <ButtonWrap Icon={Duplicate} text="Duplicate" />
        </DuplicateAuthorizationButton>
      ));
      if (!_.isEmpty(_.filter(authorization.correspondences, 'fileURL'))) {
        buttons.push((
          <DownloadFormsButton
            key={`authDownloadFormsButton-id-${authorization.id}`}
            authorization={authorization}
          >
            <ButtonWrap Icon={Download} text="Download Files" />
          </DownloadFormsButton>
        ));
      }
    }

    if (!_.includes([statuses.PRESUBMISSION, statuses.EDIT_AND_RESUBMIT], authorization.status) && !authorization.portalKey) {
      buttons.push(
        (
          <RequestResendButton
            key={`authRequestResendButton-id-${authorization.id}`}
            authorizationId={authorization.id}
            isAdministrativeResend={authorization.status !== statuses.SENDING_FAILED}
          >
            <ButtonWrap Icon={Resend} text="Resend" />
          </RequestResendButton>
        )
      );

      buttons.push(
        <EditAndResubmitButton
          key={`editAndResubmitButton-id-${authorization.id}`}
          authorizationId={authorization.id}
        >
          <ButtonWrap Icon={Edit} text="Edit and Resend" />
        </EditAndResubmitButton>
      );
    }

    if (!_.includes([statuses.PRESUBMISSION, statuses.EDIT_AND_RESUBMIT], authorization.status) && authorization.portalKey) {
      buttons.push((
        <ResubmitPortalAuthButton
          key={`ResubmitPortalAuthButton-id-${authorization.id}`}
          authorizationId={authorization.id}
        >
          <ButtonWrap Icon={Resend} text="Resend" />
        </ResubmitPortalAuthButton>
      ));
    }

    return buttons;
  }

  render() {
    return (
      <ButtonRow>
        { this.getButtons() }
      </ButtonRow>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

export default connect(mapStateToProps)(ActionButtons);
