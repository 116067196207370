import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { graphql, compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import mixpanel from 'mixpanel-browser';
import ReactTooltip from 'react-tooltip';
import EditIcon from 'react-icons/lib/md/edit';
import RequiredFieldModal from 'AuthorizationSharedComponents/RequiredFieldModal';
import ContextPane from 'AuthorizationSharedComponents/ContextPane';
import Prescriber from 'AuthorizationSharedSteps/PrescriberSection';
import ResponseSection from 'AuthorizationSharedSteps/ResponseSection';
import RequestDetails from 'AuthorizationSharedSteps/RequestDetails';
import Signature from 'AuthorizationSharedSteps/SignatureSection';
import strings from 'Resources/strings';

import IconButton, { IconButtonStyled } from '../../components/IconButton';
import SelectPhoneList from './InitializeSection/SelectFormModal/SelectPhoneList';
import Modal from '../../components/Modal';
import AlertModal from '../../components/AlertModal';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { resetForm, setCurrentStep, setFormFields, syncAuthorizationDetails, toggleHighlightRequiredFields } from '../../reducers/formReducer';
import InitializeSection from './InitializeSection';
import PDFEditor from '../../components/PDFEditor';
import ROUTE_PATHS from '../ROUTE_PATHS';
import { withFormById } from '../../graphql/AuthorizationForm';
import {
  withUpdateAuthorizationProgress,
  withSubmitAuthorization,
  withUpdateFormDetails,
  withAuthorizationQuery,
  withUpdateAuthorization,
} from '../../graphql/Authorization';
import { withCurrentAccount } from '../../graphql/Account';
import NewAuthorizationHOC from '../../components/NewAuthorizationHOC';

const { ERROR_TYPES } = CONFIG.CONSTANTS;

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: row;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  margin: 50px 0 50px 75px;
  flex-direction: column;
  width: 900px;
  @media (min-width: 1300px) {
    width: 1100px;
  }
`;

export const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
`;

export const FormSubTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.darkGray};
  margin-bottom: 10px;
`;

const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const phoneButtonPadding = 5;
const IconButtonContainer = styled.div`
  ${IconButtonStyled} {
    padding: 0 ${phoneButtonPadding}px;
    font-size: 14px;
    margin-right: -${phoneButtonPadding}px;
  }
`;

const STEPS = CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL;
export class Form extends PureComponent {
  state = {
    loading: false,
    requiredFieldModalOpen: false,
    alertModalOpen: false,
    showSelectPhoneModal: false,
  }

  getSignatureId = null

  componentDidMount() {
    const { syncStepToUrl } = this.props;
    syncStepToUrl();
    this.syncLocalState();
  }

  componentDidUpdate(prevProps) {
    const { onComponentUpdate, authorization, results } = this.props;

    onComponentUpdate(prevProps, authorization, results, this.syncLocalState.bind(this));
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;
    if (authorization) {
      syncLocalState(authorization.config, authorization.status, authorization.attachments);
    }
  }

  saveChanges = async () => {
    const { saveAuthorizationChanges, shouldIncludeCoverLetter } = this.props;
    const details = { shouldIncludeCoverLetter };

    if (this.getSignatureId) {
      const signatureId = await this.getSignatureId();
      details.signatureId = signatureId;
    }

    await saveAuthorizationChanges({ details });
  };

  updateAndStep = async (isForward) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;

    if (disabled || !isForward) {
      await takeStep(isForward);
    } else if (!hasInvalidResults()) {
      await this.saveChanges();
      await takeStep(isForward);
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  saveAndSubmitAuthorization = () => {
    const { submitAuthorization, authorization, alert } = this.props;
    return this.saveChanges()
      .then(() => (submitAuthorization({ variables: { id: authorization.id, send: false } })))
      .catch((error) => {
        if (_.includes(error.message, ERROR_TYPES.PAGE_LIMIT_EXCEEDED)) {
          this.setState({ alertModalOpen: true });
          throw new Error(ERROR_TYPES.PAGE_LIMIT_EXCEEDED);
        } else {
          alert.error('There was an error submitting your authorization');
        }
      });
  };

  submitAndStep = (onStart, onEnd, shouldStart = () => true) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;
    if (disabled) {
      takeStep(true);
    } else if (!hasInvalidResults()) {
      if (!shouldStart()) {
        return;
      }

      if (onStart) { onStart(); }

      this.saveAndSubmitAuthorization().then(() => {
        if (onEnd) { onEnd(); }
        takeStep(true);
      }).catch(() => {
        if (onEnd) { onEnd(); }
      });
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  submitAndSend = async (signatureId) => {
    const { submitAuthorization, authorization, goToLocation, alert, disabled, hasInvalidResults } = this.props;
    if (disabled) {
      alert.error('Authorization already submitted');
    } else if (!hasInvalidResults()) {
      try {
        await submitAuthorization({ variables: { id: authorization.id, send: true, signatureId } });
        mixpanel.track('New Authorization Submitted', { id: authorization.id });
        goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST);
      } catch (e) {
        this.setState({ loading: false });
        alert.error(`There was an error submitting your authorization, ${e}`);
      }
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  triggerRequiredModalOnInvalid = (manuallyTrigger) => {
    const { hasInvalidResults } = this.props;

    if (hasInvalidResults(manuallyTrigger)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }

    return false;
  }

  setFormNumber = async (formNumberId) => {
    const { authorization, updateAuthorization, alert } = this.props;

    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { FormNumberId: formNumberId } } });
      this.setState({ showSelectPhoneModal: false });
    } catch {
      alert.error(strings.NEW_AUTHORIZATIONS.ERROR_SETTING_NUMBER);
    }
  }

  getContent = (step, activeStepConfig) => {
    const { authorization, results, setToForm, disabled, hasInvalidResults, account } = this.props;
    let content;
    switch (step) {
      case STEPS.INITIATE.step:
        content = (
          <InitializeSection
            saveChanges={this.saveChanges}
            authorization={authorization}
            isInvalid={this.triggerRequiredModalOnInvalid}
            nextStep={() => { this.updateAndStep(true); }}
          />
        );
        break;
      case STEPS.REQUEST_DETAILS.step:
        content = (
          <RequestDetails
            authorization={authorization}
            triggerRequiredModalOnInvalid={() => { this.triggerRequiredModalOnInvalid(true); }}
            back={() => { this.updateAndStep(false); }}
            nextStep={() => { this.updateAndStep(true); }}
          />
        );
        break;
      case STEPS.PRESCRIBER_AND_LOCATION_DETAILS.step:
        content = (
          <Prescriber authorization={authorization} back={() => { this.updateAndStep(false); }} nextStep={() => { this.updateAndStep(true); }} />
        );
        break;
      case STEPS.FORM_DETAILS.step:
        content = (
          <PDFEditor
            onBack={() => { this.updateAndStep(false); }}
            onNext={(onStart, onEnd, shouldStart, scrollToRequired) => {
              if (hasInvalidResults()) {
                scrollToRequired();
                this.setState({ requiredFieldModalOpen: true });
              } else {
                this.submitAndStep(onStart, onEnd, shouldStart);
              }
            }}
            authorization={authorization}
            results={results}
            showOncoEMRLoader={_.get(account, 'institution.featureFlags', {})[CONFIG.CONSTANTS.FEATURE_FLAGS.EMRPullEnabled]}
            reportErrorUserDetails={`${account.firstName} ${account.lastName} with email ${account.email}`}
          />
        );
        break;
      case STEPS.REVIEW_SIGN_SUBMIT.step:
        content = (
          <Signature
            back={() => { this.updateAndStep(false); }}
            disabled={disabled}
            nextStep={(signatureId) => {
              if (!disabled) { this.setState({ loading: true }); }
              this.submitAndSend(signatureId);
            }}
            pdfPath={authorization.submittedFormURL}
            set={setToForm}
            authorization={authorization}
            getSignatureId={(method) => { this.getSignatureId = method; }}
          />
        );
        break;
      case STEPS.RESPONSE.step:
        content = (
          <ResponseSection
            back={() => { this.updateAndStep(false); }}
            authorization={authorization}
          />
        );
        break;
      default:
        content = (<FormContainer />);
        break;
    }

    return (
      <FormContainer>
        <FormTitle>Prior Authorization</FormTitle>
        <FormSubTitle>{activeStepConfig.title}</FormSubTitle>
        { content }
      </FormContainer>
    );
  }

  render() {
    const { authorization, goToLocation, disabled, step, setStepAndUpdateURL, form } = this.props;
    const { requiredFieldModalOpen, loading, alertModalOpen, showSelectPhoneModal } = this.state;

    const activeStepConfig = _.find(CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL, { step });
    if (authorization) {
      const content = this.getContent(step, activeStepConfig, authorization.isPortalSubmission);

      return (
        <Container>
          { content }
          <ContextPane
            sections={CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS}
            authorizationId={authorization.id}
            backToAuthorizations={() => { goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST); }}
            saveChanges={this.saveChanges}
            setStep={(stepTo) => {
              if (stepTo === STEPS.RESPONSE.step || disabled) {
                setStepAndUpdateURL(stepTo);
              } else if (stepTo === STEPS.REVIEW_SIGN_SUBMIT.step) {
                this.setState({ loading: true });
                this.saveAndSubmitAuthorization().then(() => {
                  this.setState({ loading: false });
                  setStepAndUpdateURL(stepTo);
                }).catch(() => {
                  this.setState({ loading: false });
                });
              } else {
                this.saveChanges().then(() => { setStepAndUpdateURL(stepTo); });
              }
            }}
          >
            <OverviewRow>
              <div style={{ flex: 1 }}>Selected Form</div>
              { form ? (
                <div>
                  <a
                    data-for="selectedFormLink"
                    data-tip=""
                    href={form.pdfURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {strings.NEW_AUTHORIZATIONS.VIEW_FORM}
                  </a>
                  <ReactTooltip id="selectedFormLink">{strings.NEW_AUTHORIZATIONS.CHANGE_FORM_INSTRUCTIONS}</ReactTooltip>
                </div>
              ) : (<div>No form selected</div>) }
            </OverviewRow>
            <OverviewRow>
              <div style={{ flex: 1 }}>Fax Number</div>
              <div>
                { authorization.submissionPhoneNumber ? (
                  <IconButtonContainer>
                    <IconButton backgroundColor="#CCCCCC" icon={EditIcon} onClick={() => this.setState({ showSelectPhoneModal: true })}>
                      {authorization.submissionPhoneNumber}
                    </IconButton>
                  </IconButtonContainer>
                ) : 'No number selected' }
              </div>
            </OverviewRow>
          </ContextPane>
          <RequiredFieldModal
            closeModal={() => { this.setState({ requiredFieldModalOpen: false }); }}
            open={requiredFieldModalOpen}
            content={_.get(_.find(STEPS, ['step', step]), 'requiredFieldModalContent')}
          />
          <AlertModal
            closeModal={() => { this.setState({ alertModalOpen: false }); }}
            open={alertModalOpen}
            content={ERROR_TYPES.PAGE_LIMIT_EXCEEDED}
            header="Submission Error"
          />
          <LoadingSpinner open={loading} />
          <Modal
            header="Edit Fax Number" onClick={() => this.setState({ showSelectPhoneModal: false })} open={showSelectPhoneModal}
          >
            <div style={{ width: 600 }}>
              <SelectPhoneList
                authorizationFormId={_.get(form, 'id')}
                insuranceCompanyId={_.get(authorization, 'patient.InsuranceCompanyId')}
                onSelect={this.setFormNumber}
              />
            </div>
          </Modal>
        </Container>
      );
    }
    return (<div />);
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
    shouldIncludeCoverLetter: state.form.shouldIncludeCoverLetter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: (config, status, attachments) => { dispatch(syncAuthorizationDetails(config, status, attachments)); },
    goToLocation: (location) => { dispatch(push(location)); },
    setToForm: toSet => dispatch(setFormFields(toSet)),
    setStep(step) { dispatch(setCurrentStep(step)); },
    reset() { dispatch(resetForm()); },
    toggleHighlightRequiredFields(shouldBeHighlighted) { dispatch(toggleHighlightRequiredFields(shouldBeHighlighted)); },
  };
}

export const withAuthorization = graphql(
  withAuthorizationQuery,
  {
    options: (props) => {
      const params = new URLSearchParams(props.location.search);
      return { variables: { id: _.toNumber(params.get('id')) }, fetchPolicy: 'network-only' };
    },
    props: ({ data }) => ({
      authorization: data.authorizationById,
      authorizationRefetch: _.get(data, 'refetch'),
    }),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(
    withUpdateFormDetails,
    withSubmitAuthorization,
    withUpdateAuthorizationProgress,
    withAuthorization,
    withFormById, // withFormById must be after withAuthorization
    withUpdateAuthorization,
    withCurrentAccount,
  )(
    withAlert(NewAuthorizationHOC(Form))
  )
);
