import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import AddIcon from 'react-icons/lib/md/add-circle-outline';
import styled from 'styled-components';
import strings from 'Resources/strings';
import { BaseCleave as DateInput, BaseText } from 'Segment/StyledComponents';

import { withLatestUpdateAuthorizationCorrespondence, withUpdateAuthorizationCorrespondence } from '../graphql/AuthorizationCorrespondence';
import AlertModal from './AlertModal';
import AuthorizedProceduresSetter from './AuthorizedProceduresSetter';
import BaseButton from './BaseButton';
import IconButton, { IconButtonStyled } from './IconButton';
import LoadingButton from './LoadingButton';

const Container = styled.div`
  max-height: 160px;
  overflow: scroll;
`;

const ScrollContainer = styled(Container)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.gray};
  font-weight: 500;
  margin-top: 10px;
  margin-right: auto;
`;

const inputTopMargin = '5px';

const AddButtonIcon = styled(AddIcon)`
  width: 20px;
  height: 20px;
  margin-top: 2px;
`;

const AddButton = styled.div`
  margin-bottom: 5px;
  ${IconButtonStyled} {
    padding: 0;
    color: ${props => (props.theme.gray)};
    background-color: transparent;
    border: transparent;

    &:hover {
      cursor: ${props => (props.disabled ? '' : 'pointer')};
      color: ${props => (props.disabled ? props.theme.lightGray : props.theme.purple)};
      background-color: transparent;
    }
  }
`;

const HelperText = styled.div`
  font-size: 14px;
  color: ${props => props.theme.red};
`;

const DetailsButton = styled(BaseButton)`
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
`;

export class UpdateAuthorizationDetailsButton extends PureComponent {
  constructor(props) {
    super(props);

    if (props.correspondenceId && props.authorizationId) {
      throw new Error('Expected only correspondenceId OR authorizationId not both.');
      /*
        This component is a little wonky.
        When given an authorizationId, it creates a new latest authorization correspondence, inheriting props from the previous latestCorrespondence
        When given a correspondenceId, it updates the correspondence passed to it.
        When passed both, it defaults to the correspondence, so we're forcing users to be explicit
      */
    }

    this.state = { isLoading: false, openModal: false, ...this.getDetails() };
  }

  onUpdateAuthorizationCorrespondence = async () => {
    const {
      updateLatestAuthorizationCorrespondence, authorizationId, correspondenceId, alert, latestCorrespondence, updateAuthorizationCorrespondence,
    } = this.props;
    const { correspondenceStartDate, correspondenceEndDate, correspondenceCode, authorizedProcedures } = this.state;
    this.setState({ isLoading: true });
    const optionalParams = {};
    if (!_.isEmpty(correspondenceStartDate)) {
      optionalParams.startDate = moment(correspondenceStartDate).toDate();
    } else if (latestCorrespondence && latestCorrespondence.startDate) {
      optionalParams.startDate = null;
    }
    if (!_.isEmpty(correspondenceEndDate)) {
      optionalParams.endDate = moment(correspondenceEndDate).toDate();
    } else if (latestCorrespondence && latestCorrespondence.endDate) {
      optionalParams.endDate = null;
    }
    if (!_.isEmpty(_.trim(correspondenceCode))) {
      optionalParams.code = correspondenceCode;
    } else if (latestCorrespondence && latestCorrespondence.code) {
      optionalParams.code = null;
    }
    // only send procedure entries that have both a title and value
    optionalParams.authorizedProcedures = _.filter(authorizedProcedures, procedure => !_.isEmpty(_.trim(procedure.title))
      && !_.isEmpty(_.trim(procedure.value)));

    try {
      if (correspondenceId) {
        await updateAuthorizationCorrespondence({
          variables: {
            id: correspondenceId,
            patch: optionalParams,
          },
        });
      } else {
        await updateLatestAuthorizationCorrespondence({
          variables: {
            authorizationId,
            ...optionalParams,
          },
        });
      }

      this.setState({ openModal: false });
    } catch (e) {
      alert.error(strings.AUTHORIZATION_DETAILS.ERROR_UPDATING);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getErrorMessages = () => {
    const { correspondenceStartDate, correspondenceEndDate, correspondenceCode, authorizedProcedures } = this.state;
    let errorMessages = [];

    const isAnythingEntered = correspondenceStartDate || correspondenceEndDate || correspondenceCode
       || !_.isEmpty(_.filter(authorizedProcedures, ({ title, value }) => !_.isEmpty(_.trim(title)) && !_.isEmpty(_.trim(value))));

    if (!isAnythingEntered) {
      return ['No details entered'];
    }

    if (!_.isEmpty(correspondenceStartDate)) {
      if (!moment(correspondenceStartDate, CONFIG.CONSTANTS.DATE_FORMAT, true).isValid()) {
        errorMessages = [...errorMessages, 'Invalid start date'];
      }

      if (moment(correspondenceStartDate, CONFIG.CONSTANTS.DATE_FORMAT, true) < moment().subtract(2, 'years')) {
        errorMessages = [...errorMessages, 'Start date too far in the past'];
      }
    }

    if (!_.isEmpty(correspondenceEndDate) && !moment(correspondenceEndDate, CONFIG.CONSTANTS.DATE_FORMAT, true).isValid()) {
      errorMessages = [...errorMessages, 'Invalid end date'];
    }

    if (!_.isEmpty(correspondenceEndDate) && !_.isEmpty(correspondenceStartDate)) {
      if (moment(correspondenceEndDate, CONFIG.CONSTANTS.DATE_FORMAT) < moment(correspondenceStartDate, CONFIG.CONSTANTS.DATE_FORMAT)) {
        errorMessages = [...errorMessages, 'End date before start date'];
      }

      if (
        moment(correspondenceEndDate, CONFIG.CONSTANTS.DATE_FORMAT)
        > moment(correspondenceStartDate, CONFIG.CONSTANTS.DATE_FORMAT).add(21 * 365, 'days')
      ) {
        errorMessages = [...errorMessages, 'Authorized range is greater than 20 years'];
      }
    }

    if (!_.isEmpty(_.reject(authorizedProcedures, procedure => (!_.isEmpty(_.trim(procedure.title)) && !_.isEmpty(_.trim(procedure.value)))
      && !(_.isEmpty(_.trim(procedure.title)) && _.isEmpty(_.trim(procedure.title)))))) {
      errorMessages = [...errorMessages, 'Authorized procedures missing details'];
    }

    return errorMessages;
  }

  addAuthorizedProcedureRow = () => {
    this.setState(prevState => ({
      authorizedProcedures: [...prevState.authorizedProcedures, { title: '', value: '' }],
    }));
  };

  removeAuthorizedProcedureRow = (i) => {
    const { authorizedProcedures } = this.state;
    const updatedAuthorizedProcedures = _.filter(authorizedProcedures, (__, index) => index !== i);
    this.setState({ authorizedProcedures: _.isEmpty(updatedAuthorizedProcedures) ? [{ title: '', value: '' }] : updatedAuthorizedProcedures });
  };

  onAuthorizedProcedureRowUpdated = (i, title, value) => {
    const { authorizedProcedures } = this.state;
    const newAuthorizedProcedures = authorizedProcedures.slice();
    newAuthorizedProcedures[i].title = title;
    newAuthorizedProcedures[i].value = value;
    this.setState({ authorizedProcedures: newAuthorizedProcedures });
  };

  onCloseModal = () => {
    // close modal and reset uncommitted changes
    this.setState({ openModal: false, ...this.getDetails() });
  };

  getDetails = () => {
    const { latestCorrespondence } = this.props;

    return {
      correspondenceStartDate: _.get(latestCorrespondence, 'startDate') ? moment(latestCorrespondence.startDate).format(CONFIG.CONSTANTS.DATE_FORMAT) : '',
      correspondenceEndDate: _.get(latestCorrespondence, 'endDate') ? moment(latestCorrespondence.endDate).format(CONFIG.CONSTANTS.DATE_FORMAT) : '',
      correspondenceCode: _.get(latestCorrespondence, 'code') ? latestCorrespondence.code : '',
      authorizedProcedures: !_.isEmpty(_.get(latestCorrespondence, 'authorizedProcedures'))
        ? latestCorrespondence.authorizedProcedures : [{
          title: '',
          value: '',
        }],
    };
  };

  render() {
    const { isSupport } = this.props;
    const {
      openModal,
      correspondenceStartDate,
      correspondenceEndDate,
      correspondenceCode,
      authorizedProcedures,
      isLoading,
    } = this.state;

    const errorMessages = this.getErrorMessages().join(', ');
    return (
      <div style={{ marginTop: '5px' }}>
        <AlertModal
          styleOverride={(isSupport ? { marginLeft: 325 } : {})}
          buttons={(
            <LoadingButton
              style={{ width: '100%' }}
              disabled={!!errorMessages}
              loading={isLoading} onClick={this.onUpdateAuthorizationCorrespondence}
            >
              Update
            </LoadingButton>
          )}
          content={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SectionTitle>Authorization begins</SectionTitle>
              <DateInput
                disabled={isLoading}
                onChange={(e) => { this.setState({ correspondenceStartDate: e.target.value }); }}
                options={{ blocks: [2, 2, 4], delimiter: '/' }}
                placeholder={strings.AUTHORIZATION_DETAILS.START_DATE_PLACEHOLDER}
                value={correspondenceStartDate || ''}
                style={{ marginTop: inputTopMargin }}
              />
              <SectionTitle>Authorization ends</SectionTitle>
              <DateInput
                disabled={isLoading}
                onChange={(e) => { this.setState({ correspondenceEndDate: e.target.value }); }}
                options={{ blocks: [2, 2, 4], delimiter: '/' }}
                placeholder={strings.AUTHORIZATION_DETAILS.END_DATE_PLACEHOLDER}
                value={correspondenceEndDate || ''}
                style={{ marginTop: inputTopMargin }}
              />
              <SectionTitle>Authorization number</SectionTitle>
              <BaseText
                disabled={isLoading}
                onChange={(e) => { this.setState({ correspondenceCode: e.target.value }); }}
                placeholder={strings.AUTHORIZATION_DETAILS.AUTHORIZATION_NUMBER_PLACEHOLDER}
                value={correspondenceCode || ''}
                style={{ marginTop: inputTopMargin }}
              />
              <SectionTitle>Authorized services</SectionTitle>
              <ScrollContainer>
                {
                  authorizedProcedures && _.map(authorizedProcedures, (procedure, i) => (
                    <div key={`Authorized-procedure-${i}`} style={{ marginTop: inputTopMargin }}>
                      <AuthorizedProceduresSetter
                        disabled={isLoading}
                        title={procedure.title}
                        value={procedure.value}
                        titlePlaceholder={strings.AUTHORIZATION_DETAILS.PROCEDURE_TITLE_PLACEHOLDER}
                        valuePlaceholder={strings.AUTHORIZATION_DETAILS.PROCEDURE_VALUE_PLACEHOLDER}
                        onRemove={() => this.removeAuthorizedProcedureRow(i)}
                        onChange={(title, value) => this.onAuthorizedProcedureRowUpdated(i, title, value)}
                      />
                    </div>
                  ))
                }
              </ScrollContainer>
              <AddButton>
                <IconButton onClick={this.addAuthorizedProcedureRow} icon={AddButtonIcon}>Click to add an authorized service</IconButton>
              </AddButton>
              { errorMessages && (<HelperText>{errorMessages}</HelperText>) }
            </div>
          )}
          header={strings.AUTHORIZATION_DETAILS.UPDATE_DETAILS}
          open={openModal}
          closeModal={this.onCloseModal}
        />
        <DetailsButton
          onClick={(e) => { e.stopPropagation(); this.setState({ openModal: true, ...this.getDetails() }); }}
        >
          + Click to edit
        </DetailsButton>
      </div>
    );
  }
}

const mapStateToProps = state => ({ isSupport: _.get(state.router, 'location.pathname').includes('support') });

// connect must be executed after compose so that the redux authorizationList store values
// are available for use by the initial authorizationsPaginated fetch on mount
export default connect(mapStateToProps)(
  compose(withLatestUpdateAuthorizationCorrespondence, withUpdateAuthorizationCorrespondence)(withAlert(UpdateAuthorizationDetailsButton))
);
