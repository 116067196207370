import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { BaseInputCss, getPropsFromInput } from './BaseInput';

export const ImmutableInputComponent = styled.div`
  ${BaseInputCss}
  text-align: center;
  border: 2px solid ${props => props.theme.darkGray};
  background: ${props => props.theme.disabledGray};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

class ImmutableInput extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <ImmutableInputComponent
        type="text"
        {..._.omit(getPropsFromInput(input), 'tabindex')}
        disabled
      >
        SET BY SAMA
      </ImmutableInputComponent>
    );
  }
}

export default ImmutableInput;
