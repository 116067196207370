const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';

const initialState = { redirectUrl: '/' };

export function setLoginRedirect(redirectUrl) {
  return { type: SET_LOGIN_REDIRECT, redirectUrl };
}

export default function loginRedirectReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_REDIRECT:
      return { ...state, redirectUrl: action.redirectUrl };
    default:
      return state;
  }
}
