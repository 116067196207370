import React, { PureComponent } from 'react';
import RemoveIcon from 'react-icons/lib/md/remove-circle-outline';
import styled from 'styled-components';
import { BaseText } from 'Segment/StyledComponents';

const Remove = styled(RemoveIcon)`
  height: 40px;
  width: 60px;
  color: ${props => props.theme.gray};
  cursor: pointer;
  margin: auto;
`;

class AuthorizedProceduresSetter extends PureComponent {
  onChangeKey = (e) => {
    const { value, onChange = () => {} } = this.props;
    onChange(e.target.value, value);
  }

  onChangeValue = (e) => {
    const { title, onChange = () => {} } = this.props;
    onChange(title, e.target.value);
  }

  render() {
    const { title, value, titlePlaceholder, valuePlaceholder, disabled, onRemove } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <BaseText
          disabled={disabled}
          onChange={this.onChangeKey}
          placeholder={titlePlaceholder || 'Enter a key...'}
          value={title}
          style={{ marginRight: 5 }}
        />
        <BaseText
          disabled={disabled}
          onChange={this.onChangeValue}
          placeholder={valuePlaceholder || 'Enter a value...'}
          value={value}
          style={{ marginRight: 5 }}
        />
        { onRemove && (<Remove onClick={onRemove} />) }
      </div>
    );
  }
}

export default AuthorizedProceduresSetter;
