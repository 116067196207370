import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import BaseButton from '../../../components/BaseButton';
import ROUTE_PATHS from '../../ROUTE_PATHS';

const CenteredButton = styled(BaseButton)`
  margin: auto;
`;

const MessageContainer = styled.div`
  max-width: 550px;
  word-break: break-word;
  text-align: center;
  overflow: hidden;
  transition: all 1s ease;

  &:hover {
    white-space: pre-line;
    word-break: break-word;
    overflow: auto;
    cursor: pointer;
    background-color: ${props => props.theme.lightGray};
  }
`;

const openAuthorizationInNewWindow = (id) => {
  const route = `${ROUTE_PATHS.PORTAL_AUTHORIZATION}?step=1&id=${id}`;
  window.open(`#${route}`, '_blank');
};

const ErrorRow = ({ error, handleResolve }) => (
  <tr key={`PortalError_${error.id}`} onClick={(e) => { e.stopPropagation(); }}>
    <td>{ moment(error.createdAt).format('MM/DD/YYYY') }</td>
    <td><MessageContainer>{ error.message }</MessageContainer></td>
    <td>
      { error.imageURL
        ? (
          <a
            href={error.imageURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click to view
          </a>
        )
        : (<div>No Image</div>)
      }
    </td>
    <td>
      <CenteredButton onClick={() => { openAuthorizationInNewWindow(error.AuthorizationId); }}>
        { `Auth ${error.AuthorizationId}` }
      </CenteredButton>
    </td>
    <td>
      <CenteredButton onClick={() => { handleResolve(error.id); }}>
        Resolve Error
      </CenteredButton>
    </td>
  </tr>
);

export default ErrorRow;
