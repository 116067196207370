import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import moment from 'moment';

import Modal from './Modal';
import BaseButton from './BaseButton';
import CustomCheckbox from './CustomCheckbox';
import { BaseCleave, BaseText } from './Segment/StyledComponents';
import { withSetAuthorizationReminder } from '../graphql/Authorization';

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
  width: 120px;
  padding: 5px 10px;
  margin-right: 15px;
`;

const ModalContent = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 16px;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;
`;

const ModalBody = styled.div`
  width: 600px;
`;

export class AuthorizationReminderButton extends PureComponent {
  state = { isModalOpen: false, date: null, note: null, displayNote: false };

  getErrors = () => {
    const { date } = this.state;
    if (!moment(date, CONFIG.CONSTANTS.DATE_FORMAT, true).isValid()) {
      return 'Invalid date';
    }

    if (moment(date, CONFIG.CONSTANTS.DATE_FORMAT).isBefore(moment())) {
      return 'Date must be in the future';
    }

    if (moment(date, CONFIG.CONSTANTS.DATE_FORMAT).isAfter(moment().add(2, 'years'))) {
      return 'Date is too far in the future';
    }

    return null;
  }

  scheduleReminder = async () => {
    const { setAuthorizationReminder, authorization, alert } = this.props;
    const { note, date } = this.state;

    if (this.getErrors()) {
      return;
    }

    try {
      await setAuthorizationReminder({ variables: {
        id: authorization.id,
        emailReminderDescription: note,
        emailReminderAt: date,
      } });

      this.toggleModal();
      alert.success('Success');
    } catch {
      alert.error('There was an error scheduling your reminder');
    }
  };

  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen, date: '', note: '', displayNote: false });
  }

  toggleNote = () => {
    const { displayNote } = this.state;
    this.setState({ displayNote: !displayNote, note: '' });
  }

  render() {
    const { children, authorization } = this.props;
    const { isModalOpen, date, note, displayNote } = this.state;
    const errors = this.getErrors();

    return (
      <div onClick={this.toggleModal}>
        {children}
        <Modal
          header="Set a reminder for this authorization"
          onClick={this.toggleModal}
          open={isModalOpen}
        >
          <ModalBody>
            <ModalContent>
              { moment(authorization.emailReminderAt, CONFIG.CONSTANTS.DATE_FORMAT).isAfter(moment()) && (
                <div style={{ margin: '5px 0 15px 0' }}>
                  {
                    'Warning: this will override an existing reminder set for: '
                    + `${authorization.emailReminderAt} with note: ${authorization.emailReminderDescription}`
                  }
                </div>
              ) }
              <div style={{ display: 'flex', textAlign: 'left' }}>
                I would like to set a reminder email for the creator of this authorization on
                <BaseCleave
                  style={{ width: '150px', marginLeft: '20px' }}
                  onChange={(e) => { this.setState({ date: e.target.value }); }}
                  options={{ date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' }}
                  placeholder="MM/DD/YYYY"
                  value={date || ''}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                <CustomCheckbox
                  checked={displayNote}
                  onChange={this.toggleNote}
                />
                <div
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={this.toggleNote}
                >
                  { ' Add a note' }
                </div>
              </div>
              { displayNote && (
                <div style={{ display: 'flex' }}>
                  <BaseText
                    value={note || ''}
                    onChange={(e) => { this.setState({ note: e.target.value }); }}
                    placeholder="Add your note here"
                    style={{ marginLeft: 0 }}
                  />
                </div>
              ) }
              { date && errors && (
                <div style={{ marginTop: 10, color: 'red' }}>
                  { errors }
                </div>
              ) }
            </ModalContent>
            <ModalFooter>
              <GrayButton onClick={this.toggleModal}>Cancel</GrayButton>
              <BaseButton disabled={!!errors} onClick={this.scheduleReminder}>Schedule</BaseButton>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default compose(withSetAuthorizationReminder)(withAlert(AuthorizationReminderButton));
