import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import TiDelete from 'react-icons/lib/ti/delete';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Upload from '../Upload';
import { removeAttachment, setAttachment, toggleLoadingSegment } from '../../reducers/formReducer';

const Container = styled.div`
  width: 400px;
`;

const DeleteIcon = styled(TiDelete)`
  width: 22px;
  height: 22px;
  float: right;
  cursor: pointer;
`;

const File = styled.div`
  border: 1px dashed ${props => props.theme.purple};
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  color: ${props => props.theme.purple};
`;

class SegmentUpload extends PureComponent {
  onDrop = () => {
    const { toggleSegmentLoading } = this.props;
    toggleSegmentLoading(true);
  }

  onDropSuccess = (file, res) => {
    const { set, toggleSegmentLoading } = this.props;
    const { data } = res;
    set({ title: file.name, id: data.id, awsKey: res.data.fields.key });
    toggleSegmentLoading(false);
  }

  onDropError = () => {
    const { toggleSegmentLoading } = this.props;
    toggleSegmentLoading(false);
  }

  render() {
    const { attachments, disabled, remove, customMaxSize } = this.props;

    return (
      <Container>
        <Upload
          disabled={disabled}
          onDrop={this.onDrop}
          onDropSuccess={this.onDropSuccess}
          onDropError={this.onDropError}
          customMaxSize={customMaxSize}
          multiple
        />
        { attachments && !_.isEmpty(attachments) && 'Uploaded attachments' }
        { _.map(attachments, file => (
          <File key={`uploaded-file-id-${file.id}`}>
            { file.title }
            { !disabled && (<DeleteIcon onClick={() => { remove(file); }} />) }
          </File>
        )) }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  attachments: state.form.attachments,
});

const mapDispatchToProps = dispatch => ({
  set(file) { dispatch(setAttachment(file)); },
  remove(file) { dispatch(removeAttachment(file)); },
  toggleSegmentLoading(forceValue) { dispatch(toggleLoadingSegment(forceValue)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert(SegmentUpload));
