import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { setAuthorizationListFilters } from '../reducers/authorizationListReducer';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-weight: bold;
  font-size: 14px;
`;

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`;

export class DateOfServiceFilter extends PureComponent {
  handleOnChange = (selectedOption) => {
    const { authorizationList, onChange } = this.props;
    const { filters } = authorizationList;

    return onChange({ ...filters, filterByUpcomingDateOfServiceDays: _.get(selectedOption, 'value') });
  }

  render() {
    const { authorizationList } = this.props;
    const { filters } = authorizationList;

    const defaultDateOfServiceOption = { value: null, label: 'All Time' };
    const dateOfServiceOptions = [
      defaultDateOfServiceOption,
      { value: '7', label: 'in Next 7 Days' },
      { value: '14', label: 'in Next 14 Days' },
      { value: '30', label: 'in Next 30 Days' },
    ];

    return (
      <Container>
        <FilterLabel>
          for DoS
        </FilterLabel>
        <StyledSelect
          value={_.get(filters, 'filterByUpcomingDateOfServiceDays')
            ? _.find(dateOfServiceOptions, { value: filters.filterByUpcomingDateOfServiceDays }) : defaultDateOfServiceOption}
          onChange={this.handleOnChange}
          options={dateOfServiceOptions}
          clearable
          searchable
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: toSet => dispatch(setAuthorizationListFilters(toSet)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withAlert(DateOfServiceFilter));
