import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import authorizationFormInfo from './fragments/authorizationFormInfo';
import detailedAuthorizationFormInfo from './fragments/detailedAuthorizationFormInfo';
import { withDefaultProps } from './utils';

export const withForms = graphql(
  gql`
    query getPossibleForms($jCode: String, $state: String!, $insuranceCompanyId: Int!) {
      getPossibleForms(insuranceCompanyId: $insuranceCompanyId, jCode: $jCode, state: $state) {
        suggestedForms {
          ...authorizationFormInfo
        }
        stateForms {
          ...authorizationFormInfo
        }
        payerForms {
          ...authorizationFormInfo
        }
        hasForms
      }
    }
    ${authorizationFormInfo}
  `,
  {
    options: props => ({
      variables: { state: props.state, insuranceCompanyId: props.insuranceCompanyId, jCode: props.jCode },
      fetchPolicy: 'network-only',
    }),
    props: withDefaultProps(({ data }) => ({ forms: _.get(data, 'getPossibleForms') })),
  }
);

export const withFormById = graphql(
  gql`
    query formById($id: Int!) {
      formById(id: $id) {
        ...authorizationFormInfo
      }
    }
    ${authorizationFormInfo}
  `,
  {
    options: props => ({ variables: { id: props.authorization.formId } }),
    skip: props => (!_.get(props.authorization, 'formId')),
    props: withDefaultProps(({ data }) => ({ form: _.get(data, 'formById') })),
  }
);
/* eslint-disable max-len */
export const withReportMissingInformation = graphql(
  gql`
    mutation reportMissingInformation($type: String!, $authorizationId: Int!, $insuranceCompanyId: Int, $state: String, $details: String, $fileKey: String) {
      reportMissingInformation(type: $type, authorizationId: $authorizationId, insuranceCompanyId: $insuranceCompanyId, state: $state, details: $details, fileKey: $fileKey)
    }
  `,
  {
    name: 'reportMissingInformation',
  }
);

export const formsFindAllQuery = gql`
  query formsFindAll {
    formsFindAll {
      ...authorizationFormInfo
    }
  }
  ${authorizationFormInfo}
`;

export const DETAILED_FORMS_QUERY = 'withDetailedForms';
export const withDetailedForms = graphql(
  gql`
    query ${DETAILED_FORMS_QUERY} {
      formsFindAll {
        ...detailedAuthorizationFormInfo
      }
    }
    ${detailedAuthorizationFormInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ forms: _.get(data, 'formsFindAll') })),
  }
);

export const withUpsertForm = graphql(
  gql`
    mutation upsertForm($formId: Int, $metadata: JSON!, $specification: JSON!, $title: String, $description: String, $imgKeys: [String], $pdfKey: String) {
      upsertForm(formId: $formId, metadata: $metadata, specification: $specification, title: $title, description: $description, imgKeys: $imgKeys, pdfKey: $pdfKey) {
        ...authorizationFormInfo
      }
    }
    ${authorizationFormInfo}
  `,
  {
    name: 'upsertForm',
  }
);

export const withAssociateForm = graphql(
  gql`
    mutation associateAuthorizationForm($id: Int!, $formNumbers: [Int], $insuranceCompanies: [Int]) {
      associateAuthorizationForm(id: $id, formNumbers: $formNumbers, insuranceCompanies: $insuranceCompanies) {
        ...detailedAuthorizationFormInfo
      }
    }
    ${detailedAuthorizationFormInfo}
  `,
  {
    name: 'associateForm',
    options: () => ({
      refetchQueries: () => [DETAILED_FORMS_QUERY],
    }),
  }
);

export const withDeleteAuthorizationForm = graphql(
  gql`
    mutation deleteAuthorizationForm($id: Int!) {
      deleteAuthorizationForm(id: $id)
    }
  `,
  {
    name: 'deleteAuthorizationForm',
    options: () => ({ refetchQueries: () => [DETAILED_FORMS_QUERY] }),
  }
);
/* eslint-enable */
