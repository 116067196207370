import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import strings from 'Resources/strings';
import PropTypes from 'prop-types';

import { genericInput } from '../InputPropTypes';
import { BaseInput, getPropsFromInput } from './BaseInput';

class TextInput extends PureComponent {
  render() {
    const { input, number } = this.props;
    return (
      <div>
        <BaseInput
          type={number ? 'number' : 'text'}
          {...getPropsFromInput(input)}
          onChange={({ target }) => {
            if (input.characterBoxes) {
              // validate character boxes if necessary
              target.setCustomValidity(target.value.length > input.characterBoxes ? strings.PDF_EDITOR.CHAR_BOX_ERROR : '');
            }
            input.onChange({ [input.id]: target.value });
          }}
          value={input.value || ''}
          data-for={`input_${input.id}_tooltip`}
          data-tip=""
        />
        { input.value && input.value.length > input.characterBoxes
        && (<ReactTooltip id={`input_${input.id}_tooltip`}>{strings.PDF_EDITOR.CHAR_BOX_ERROR(input.characterBoxes)}</ReactTooltip>)
        }
      </div>
    );
  }
}

TextInput.propTypes = {
  input: genericInput.isRequired,
  number: PropTypes.bool,
};

TextInput.defaultProps = {
  number: false,
};

export default TextInput;
