import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { withLocations } from '../graphql/Location';
import { setAuthorizationListFilters } from '../reducers/authorizationListReducer';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSelect = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`;

const defaultLocationOption = { value: null, label: 'All Locations' };
export class LocationFilter extends PureComponent {
  getLocationFilterOptions = () => {
    const { locations } = this.props;

    return [defaultLocationOption, ..._.map(_.sortBy(locations || [], 'name'), ({ id, name }) => ({ value: id, label: name }))];
  }

  handleOnChange = (selectedOption) => {
    const { authorizationList, onChange } = this.props;
    const { filters } = authorizationList;

    return onChange({ ...filters, locationId: _.get(selectedOption, 'value') });
  }

  render() {
    const { authorizationList } = this.props;
    const { filters } = authorizationList;

    return (
      <Container>
        <StyledSelect
          value={_.get(filters, 'locationId') ? _.get(filters, 'locationId') : defaultLocationOption}
          onChange={this.handleOnChange}
          options={this.getLocationFilterOptions()}
          clearable
          searchable
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: toSet => dispatch(setAuthorizationListFilters(toSet)),
  };
}

const LocationFilterEnhanced = compose(withLocations)(withAlert(LocationFilter));

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilterEnhanced);
