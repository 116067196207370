import gql from 'graphql-tag';

export default gql`
  fragment patientInfo on Patient {
    id
    dob
    firstName
    lastName
    InsuranceCompanyId
    status
  }
`;
