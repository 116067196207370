import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { BaseText } from 'Segment/StyledComponents';

const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  padding: 3px;
`;

const UserInput = ({ input, onChange, value }) => {
  const { key, label, type, options } = input;
  let content;

  if (type === 'password') {
    content = (
      <BaseText
        type="password"
        onChange={(e) => { onChange(key, e.target.value); }}
        value={value || ''}
      />
    );
  }

  if (type === 'text') {
    content = (
      <BaseText
        onChange={(e) => { onChange(key, e.target.value); }}
        value={value || ''}
      />
    );
  }

  if (type === 'select') {
    content = (
      <Select
        value={_.find(options, { value }) || {}}
        // Selected can be undefined if we're clearing the select
        onChange={(selected) => { onChange(key, _.get(selected, 'value')); }}
        options={options}
      />
    );
  }

  return (
    <UserInputContainer>
      <TitleContainer>
        { label }
      </TitleContainer>
      { content }
    </UserInputContainer>
  );
};

export default UserInput;
