import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';

const Container = styled.div`
  width: 640px;
  border: 1px solid ${props => props.theme.lightGray};
  padding: 20px;
  margin-top: 20px;
  border-radius: 3px;
`;

const MatchingTitle = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
`;

const SearchResult = styled.div`
  display: flex;
  border-radius: 3px;
  padding: 5px;
  &:hover {
    background-color: ${props => props.theme.green};
    cursor: pointer;
    color: white;
  }
  span {
    flex: 1;
  }
  .gray {
    color: ${props => props.theme.lightGray};
  }
`;

const PatientSearchList = ({ setPatient, patients }) => (
  <Container>
    <MatchingTitle>Matching patients</MatchingTitle>
    { _.map(patients, patient => (
      <div
        key={`patientSearch${patient.id}`}
        onClick={() => { setPatient(patient); }}
      >
        <SearchResult>
          <span>
            {patient.firstName}
            {' '}
            {patient.lastName}
          </span>
          {' '}
          <span className="gray">{moment.parseZone(patient.dob).format('LL')}</span>
        </SearchResult>
      </div>
    )) }
  </Container>
);

export default PatientSearchList;
