import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import colors from 'Resources/colors';

import BaseButton from '../../../components/BaseButton';
import UpdateAuthorizationDetailsButton from '../../../components/UpdateAuthorizationDetailsButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3px 0;
  width: 100%;
  border-radius: 3px;
  padding: 2px;
`;

const Title = styled.div`
  color: ${props => props.theme.purple};
  font-weight: bold;
  margin-bottom: 5px;
`;

const SetAutomatedCorrespondence = ({ correspondence, set, rejectSuggestion, disabled }) => (
  <Container>
    <Title>Automated Recommendation</Title>
    { _.map({
      Code: correspondence.code,
      'Start Date': correspondence.startDate,
      'End Date': correspondence.endDate,
      Procedures: correspondence.authorizedProcedures,
    }, (value, key) => (
      <div key={`setautomated_${key}`}>
        { `${key}: ` }
        <b>{ JSON.stringify(value) }</b>
      </div>
    )) }
    <UpdateAuthorizationDetailsButton correspondenceId={correspondence.id} latestCorrespondence={correspondence} />
    <BaseButton
      style={{ width: '100%', fontSize: 12, padding: 5, margin: '5px 0' }}
      onClick={set}
      disabled={disabled}
      color={colors.green}
    >
      { disabled ? 'Change status to associate' : 'Accept & close' }
    </BaseButton>
    <BaseButton
      style={{ width: '100%', fontSize: 12, padding: 5, margin: '5px 0' }}
      onClick={rejectSuggestion}
    >
      Reject suggestions
    </BaseButton>
  </Container>
);

export default SetAutomatedCorrespondence;
