import gql from 'graphql-tag';

import authorizationInfo from './authorizationInfo';
import accountInfo from './accountInfo';

export default gql`
  fragment portalIntegrationDetailsInfo on PortalIntegrationDetails {
    minutesRunning
    portalKey
    runnerId

    account {
      ...accountInfo
    }

    authorization {
      ...authorizationInfo
    }
  }

  ${authorizationInfo}
  ${accountInfo}
`;
