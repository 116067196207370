import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import moment from 'moment';
import colors from 'Resources/colors';

import BaseButton from '../../../components/BaseButton';
import MainList from '../../../components/MainList';
import Modal from '../../../components/Modal';
import { ModalFooter, ModalContent } from '../../../components/ModalStyledComponents';
import { BaseMultiline } from '../../../components/Segment/StyledComponents';
import { getPortalMetrics, listActivePortalIntegrations, withTerminatePortalIntegration, getAllPortals, withUpdatePortalStatus } from '../../../graphql/Portal';
import NightlyStatusDisplay from './NightlyStatusDisplay';
import PortalDetailsModal from './PortalDetailsModal';

const Container = styled.div`
  width: 1600px;
  margin: 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
  font-weight: bold;
`;

const ModalBody = styled.div`
  width: 500;
  height: 250px;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const CustomModalContent = styled(ModalContent)`
  font-size: 16px;
  margin: 0 5px;
  width: 500px;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const daysSinceStartOfPortalErrorTracking = moment().diff(moment('09/06/2019', 'MM/DD/YYYY'), 'days');

const filterErrorsByAuths = (authorizations, errors) => (
  _.uniq(_.map(_.filter(errors, err => _.find(authorizations, { id: err.AuthorizationId })), 'AuthorizationId'))
);

const getTableCells = (integration, onClick) => {
  const uniqueKeyPrefix = `support_${integration.account.email}`;
  return [
    (<div key={uniqueKeyPrefix}>{ integration.account.email }</div>),
    (<div key={`${uniqueKeyPrefix}_${integration.portalKey}`}>{ integration.portalKey }</div>),
    (<div key={`${uniqueKeyPrefix}_${integration.minutesRunning}`}>{ Math.floor(integration.minutesRunning) }</div>),
    (
      <div key={`${uniqueKeyPrefix}_customPortalSteps`}>
        { _.map(
          _.map(_.get(integration.authorization, 'customPortalSteps', []), 'value'),
          (val, i) => (
            <div key={`${uniqueKeyPrefix}_customStepRow_${i}`}>
              <b>
                {i + 1}
                .
              </b>
              <span>{ JSON.stringify(val) }</span>
            </div>
          )
        ) }
      </div>
    ),
    (<BaseButton key={`${uniqueKeyPrefix}_terminateButton`} onClick={() => { onClick(integration.runnerId); }}>Terminate integration</BaseButton>),
  ];
};

const getMetricCells = (metrics, portal, buttonOnClick) => {
  const uniqueKeyPrefix = `support_portalMetrics_${metrics.key}`;
  const last30Days = _.filter(metrics.total, auth => (moment(auth.createdAt) > moment().subtract(30, 'days')));
  const errorsLast30Days = _.filter(metrics.errors, err => (moment(err.createdAt) > moment().subtract(30, 'days')));
  const percentErrors = `${Math.floor((filterErrorsByAuths(last30Days, errorsLast30Days).length / last30Days.length) * 100)}%`;

  const sinceStartOfTracking = _.filter(metrics.total, auth => (moment(auth.createdAt) > moment().subtract(daysSinceStartOfPortalErrorTracking, 'days')));
  const errorsSinceStartOfTracking = _.filter(metrics.errors, err => (moment(err.createdAt) > moment().subtract(daysSinceStartOfPortalErrorTracking, 'days')));
  const percentErrorsTotal = `${Math.floor(
    (filterErrorsByAuths(sinceStartOfTracking, errorsSinceStartOfTracking).length / sinceStartOfTracking.length) * 100
  )}%`;

  const errorsUnresolved = (errorsLast30Days.length - (_.filter(errorsLast30Days, 'hasBeenResolved')).length) || 0;

  return [
    (<div key={uniqueKeyPrefix}>{ metrics.key }</div>),
    (<div key={`${uniqueKeyPrefix}_resolved7Days`}>{ last30Days.length }</div>),
    (<div key={`${uniqueKeyPrefix}_total`}>{ errorsLast30Days.length }</div>),
    (<div key={`${uniqueKeyPrefix}_total`}>{ errorsUnresolved }</div>),
    (<div key={`${uniqueKeyPrefix}_total`}>{ percentErrors }</div>),
    (<div key={`${uniqueKeyPrefix}_total`}>{ percentErrorsTotal }</div>),
    (<NightlyStatusDisplay key={`${uniqueKeyPrefix}_statusDays`} statuses={metrics.dailyStatus} />),
    (
      <BaseButton
        key={`${uniqueKeyPrefix}_button`}
        style={{ marginLeft: '15px', width: '125px', color: portal.isDisabled ? colors.green : colors.red }}
        onClick={(e) => { e.stopPropagation(); buttonOnClick(); }}
      >
        { `${portal.isDisabled ? 'Enable' : 'Disable'} Portal` }
      </BaseButton>
    ),
  ];
};

class PortalDetails extends PureComponent {
  state = { selectedPortalKey: null, portalToUpdate: null, disabledPortalUserMessage: '' }

  terminateIntegration = async (uuid) => {
    const { alert, terminatePortalIntegration } = this.props;

    try {
      await terminatePortalIntegration({ variables: { uuid } });
      alert.success('Success!');
    } catch (e) {
      alert.error(`Error! ${e}`);
    }
  }

  openPortalModal = (metric) => {
    this.setState({ selectedPortalKey: metric.key });
  };

  handleUpdatePortalStatus = async () => {
    const { updatePortalStatus, alert } = this.props;
    const { portalToUpdate, disabledPortalUserMessage } = this.state;

    try {
      await updatePortalStatus({ variables: { id: portalToUpdate.id, shouldEnablePortal: portalToUpdate.isDisabled, userMessage: disabledPortalUserMessage } });
      this.setState({ portalToUpdate: null, disabledPortalUserMessage: '' });
      alert.success('Successfully updated portal status');
    } catch {
      alert.error('Error updating portal status');
    }
  }

  render() {
    const { integrations, portalMetrics, allPortals } = this.props;
    const { selectedPortalKey, portalToUpdate, disabledPortalUserMessage } = this.state;

    const headers = ['Created by', 'Portal key', 'Minutes active', 'Steps taken', 'Terminate Connection'];
    const metricsHeaders = [
      'Portal',
      'Created (30 days)',
      '# Errors (30 days)',
      'Unresolved Errors (30 days)',
      'Errored Auths (%, 30 days)',
      'Errored Auths (%, All)',
      'Nightly Health',
    ];

    return (
      <Container>
        <TitleContainer>Active Portals</TitleContainer>
        <MainList
          dataRows={_.map(integrations, integration => ({ cells: getTableCells(integration, this.terminateIntegration) }))}
          headers={headers}
          keyOff="portalList"
        />
        <TitleContainer>Portal Metrics</TitleContainer>
        <MainList
          dataRows={
            _.map(portalMetrics, (metrics) => {
              const portal = _.find(allPortals, { key: metrics.key });
              return { cells: getMetricCells(metrics, portal, () => { this.setState({ portalToUpdate: portal }); }), model: metrics };
            })
          }
          headers={metricsHeaders}
          keyOff="portalMetrics"
          onClick={this.openPortalModal}
        />
        { selectedPortalKey && (
          <PortalDetailsModal
            selectedPortalMetrics={_.find(portalMetrics, { key: selectedPortalKey })}
            close={() => { this.setState({ selectedPortalKey: null }); }}
          />
        ) }
        { portalToUpdate && (
          <Modal
            open={portalToUpdate}
            header={`${portalToUpdate.isDisabled ? 'Enable' : 'Disable'} ${portalToUpdate.key} Portal`}
            onClick={() => { this.setState({ portalToUpdate: null, disabledPortalUserMessage: '' }); }}
          >
            <ModalBody>
              <CustomModalContent>
                <div
                  style={{ marginBottom: '10px', color: portalToUpdate.isDisabled ? colors.gray : colors.defaultFontColor }}
                >
                  Disabled Portal User Message
                </div>
                <BaseMultiline
                  onChange={(e) => { this.setState({ disabledPortalUserMessage: e.target.value }); }}
                  value={disabledPortalUserMessage}
                  disabled={portalToUpdate.isDisabled}
                />
                { portalToUpdate.isDisabled
                  ? (
                    <div style={{ marginTop: '15px', fontWeight: '500' }}>
                      {'Make sure that any necessary changes to the portal integration have been made and then '
                      + `click the confirm button below to enable the ${portalToUpdate.key} portal.`}
                    </div>
                  )
                  : (
                    <div style={{ marginTop: '20px', fontWeight: '500' }}>
                      {`Add a user message and click the confirm button to disable the ${portalToUpdate.key} portal.`}
                    </div>
                  )
                }
              </CustomModalContent>
            </ModalBody>
            <CustomModalFooter>
              <BaseButton
                onClick={() => { this.setState({ portalToUpdate: null, disabledPortalUserMessage: '' }); }}
              >
                Cancel
              </BaseButton>
              <BaseButton onClick={this.handleUpdatePortalStatus}>Confirm</BaseButton>
            </CustomModalFooter>
          </Modal>
        ) }
      </Container>
    );
  }
}

export default compose(
  getPortalMetrics, listActivePortalIntegrations, withTerminatePortalIntegration, getAllPortals, withUpdatePortalStatus
)(withAlert(PortalDetails));
