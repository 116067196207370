import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import Modal from '../../../components/Modal';
import BaseButton from '../../../components/BaseButton';
import Backdrop from '../../../components/Backdrop';
import { withUpdateAuthorization } from '../../../graphql/Authorization';
import PortalErrorMainNextStepActions from './PortalErrorMainNextStepActions';
import PortalErrorLoginNextStepActions from './PortalErrorLoginNextStepActions';
import PortalErrorLoginDefault from './PortalErrorLoginDefault';
import { ModalContent } from '../../../components/ModalStyledComponents';

const CustomModalContent = styled(ModalContent)`
  font-size: 16px;
  margin: 0 5px;
  width: 650px;
`;

const ModalBody = styled.div`
  width: 1100px;
  height: 650px;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const ErrorImage = styled.img`
  width: 100%;
  border: 1px solid ${({ theme, color }) => color || theme.darkGray};
  object-fit: contain
`;

const ScrollableContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
`;

const ImageContainer = styled(ScrollableContainer)`
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  background: ${props => props.theme.lightGray};
  &:hover {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
`;

const FullPageImageContainer = styled.div`
  width: 1000px;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 700px;
  transform: translate(-50%, -50%);
  z-index: ${props => props.theme.zTop};
  overflow-y: scroll;
`;

const FullPageImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const ModalButtonContainer = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid ${props => props.theme.purple};
  border-radius: 3px;
  text-align: center;
`;

const ModalButton = styled(BaseButton)`
  margin: 10px auto 0 auto;
  flex-direction: column;
  min-width: 300px;
`;

export const errorModalConstants = {
  RETRY: 'retry',
  LOADING: 'loading',
  OTHER: 'other',
  PRIOR_STEPS: 'priorSteps',
  LAST_STEP: 'lastStep',
  REPORT_ERROR: 'reportError',
  PATIENT_DETAILS: 'patientDetails',
  PRESCRIBER_DETAILS: 'prescriberDetails',
  RESUBMISSION: 'resubmission',
  SAMA_ERROR: 'samaError',
};

export class PortalErrorModal extends PureComponent {
  state = {
    showErrorModal: true,
    expandImage: false,
    showDefaultLoginError: true,
  };

  render() {
    const {
      message,
      title,
      authorization,
      unsetStep,
      resetToStep,
      reenterCredentials,
      retryFromLastStep,
      takeStepsBack,
      removeTerminalAndRetry,
      retry,
    } = this.props;
    const { showErrorModal, expandImage, showDefaultLoginError } = this.state;

    return (
      <div>
        <Modal
          header={title}
          open={showErrorModal && !expandImage}
          styleOverride={{ overflow: 'auto' }}
          onClick={() => { this.setState({ showErrorModal: false }); }}
        >
          <ModalBody>
            <CustomModalContent>
              <ScrollableContainer>
                { message.isLogin && showDefaultLoginError && (
                  <PortalErrorLoginDefault
                    message={message}
                    toggleNonCredentialError={() => { this.setState({ showDefaultLoginError: false }); }}
                    onSubmit={retry}
                    portalKey={authorization.portalKey}
                  />
                ) }
                { message.isLogin && !showDefaultLoginError && (
                  <PortalErrorLoginNextStepActions
                    message={message}
                    reenterCredentials={() => { this.setState({ showDefaultLoginError: true }); }}
                    authorization={authorization}
                  />
                ) }
                { !message.isLogin && (
                  <PortalErrorMainNextStepActions
                    message={message}
                    authorization={authorization}
                    unsetStep={unsetStep}
                    resetToStep={resetToStep}
                    reenterCredentials={reenterCredentials}
                    retryFromLastStep={retryFromLastStep}
                    takeStepsBack={takeStepsBack}
                    removeTerminalAndRetry={removeTerminalAndRetry}
                    hideErrorModal={() => { this.setState({ showErrorModal: false }); }}
                  />
                ) }
              </ScrollableContainer>
            </CustomModalContent>
            <CustomModalContent isImage>
              { message.imageURL && (
                <ImageContainer onClick={() => { this.setState({ expandImage: true }); }}>
                  <ErrorImage src={message.imageURL} />
                </ImageContainer>
              ) }
            </CustomModalContent>
          </ModalBody>
          <div style={{ fontSize: 12, textAlign: 'center' }}>Click image to expand</div>
        </Modal>
        { !showErrorModal && (
          <ModalButtonContainer>
            <div>
              Click below to view the error with this integration and what steps you can take to submit your authorization smoothly.
              If you feel this error is something SamaCare can help resolve, don&#39;t hesitate to reach out with the
              chat bubble on the bottom right of your screen.
            </div>
            <ModalButton
              onClick={() => { this.setState({ showErrorModal: true }); }}
              data-for="reopenErrorModal"
              data-tip=""
            >
              Reopen Next Steps Helper
            </ModalButton>
            <ReactTooltip id="reopenErrorModal">
              Click here to go back and view the error!
            </ReactTooltip>
          </ModalButtonContainer>
        ) }
        { expandImage && (
          <div onClick={() => { this.setState({ expandImage: false }); }}>
            <Backdrop />
            <FullPageImageContainer>
              <FullPageImage src={message.imageURL} />
            </FullPageImageContainer>
          </div>
        ) }
      </div>
    );
  }
}

export default compose(withUpdateAuthorization)(withAlert(PortalErrorModal));
