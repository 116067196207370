import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { push } from 'connected-react-router';

import ROUTE_PATHS from '../routes/ROUTE_PATHS';
import { withUpdateAuthorizationStatus } from '../graphql/Authorization';

export class EditAndResubmitButton extends PureComponent {
  triggerEditAndResubmit = () => {
    const { authorizationId, updateAuthorizationStatus, alert, toAuthorization } = this.props;

    updateAuthorizationStatus({
      variables: { id: authorizationId, status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT },
    })
      .then(() => { toAuthorization(authorizationId); })
      .catch(() => { alert.error('There was an error in edit and resubmit'); });
  };

  render() {
    const { children } = this.props;

    return (
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.triggerEditAndResubmit();
          }}
        >
          { children }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toAuthorization(id) {
    const { step } = CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL.FORM_DETAILS;
    const route = `${ROUTE_PATHS.FORM_AUTHORIZATION}?step=${step}&id=${id}`;
    dispatch(push(route));
  },
});

export default connect(() => ({}), mapDispatchToProps)(compose(withUpdateAuthorizationStatus)(withAlert(EditAndResubmitButton)));
