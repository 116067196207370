import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import formNumberInfo from './fragments/formNumberInfo';
import faxFilterInfo from './fragments/faxFilterInfo';
import authorizationFormInfo from './fragments/authorizationFormInfo';
import { withDefaultProps } from './utils';

const POSSIBLE_NUMBERS_QUERY = 'getPossibleNumbers';
export const withPossibleFormNumbers = graphql(
  gql`
    query ${POSSIBLE_NUMBERS_QUERY}($authorizationFormId: Int!, $insuranceCompanyId: Int!, $state: String) {
      getPossibleNumbers(authorizationFormId: $authorizationFormId, insuranceCompanyId: $insuranceCompanyId, state: $state) {
        suggestedNumbers {
          ...formNumberInfo
        }
        remainingNumbers {
          ...formNumberInfo
        }
        hiddenNumbers {
          ...formNumberInfo
        }
        hasNumbers
      }
    }
    ${formNumberInfo}
  `,
  {
    options: props => ({ variables: { authorizationFormId: props.authorizationFormId, insuranceCompanyId: props.insuranceCompanyId, state: props.state } }),
    props: withDefaultProps(({ data }) => ({ numbers: _.get(data, 'getPossibleNumbers') })),
  }
);

export const FORM_NUMBERS_QUERY = 'formNumbersFindAll';
export const withFormNumbers = graphql(
  gql`
    query ${FORM_NUMBERS_QUERY} {
      formNumbersFindAll {
        ...formNumberInfo
        InstitutionId
        InsuranceCompanyId
        associatedForms {
          ...authorizationFormInfo
        }
        faxFilters {
          ...faxFilterInfo
        }
      }
    }
    ${formNumberInfo}
    ${authorizationFormInfo}
    ${faxFilterInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ formNumbers: _.get(data, 'formNumbersFindAll') })),
  }
);

export const formNumbersFindAllQuery = gql`
  query ${FORM_NUMBERS_QUERY} {
    formNumbersFindAll {
      ...formNumberInfo
    }
  }
  ${formNumberInfo}
`;

export const withCreateFormNumber = graphql(
  gql`
    mutation createFormNumber($number: String!, $description: String!, $insuranceCompanyId: Int!) {
      createFormNumber(number: $number, description: $description, insuranceCompanyId: $insuranceCompanyId) {
        ...formNumberInfo
      }
    }
    ${formNumberInfo}
  `,
  {
    name: 'createFormNumber',
    options: () => ({ refetchQueries: () => [POSSIBLE_NUMBERS_QUERY, FORM_NUMBERS_QUERY] }),
  }
);

export const getForbiddenFormNumberQuery = gql`
  query getForbiddenFormNumber($number: String!) {
    getForbiddenFormNumber(number: $number) {
      ...formNumberInfo
    }
  }
  ${formNumberInfo}
`;

export const withUpdateFormNumber = graphql(
  gql`
    mutation updateFormNumber($id: Int!, $patch: JSON!) {
      updateFormNumber(id: $id, patch: $patch) {
        ...formNumberInfo
      }
    }
    ${formNumberInfo}
  `,
  {
    name: 'updateFormNumber',
    options: () => ({ refetchQueries: () => [FORM_NUMBERS_QUERY] }),
  }
);

export const withDeleteFormNumber = graphql(
  gql`
    mutation deleteFormNumber($id: Int!) {
      deleteFormNumber(id: $id)
    }
  `,
  {
    name: 'deleteFormNumber',
    options: () => ({ refetchQueries: () => [FORM_NUMBERS_QUERY, POSSIBLE_NUMBERS_QUERY] }),
  }
);

export const searchFormNumbersQuery = gql`
  query searchFormNumbers($partialNumber: String!) {
    searchFormNumbers(partialNumber: $partialNumber) {
      ...formNumberInfo
    }
  }
  ${formNumberInfo}
`;
