import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  padding: 10px;
  flex-direction: row;
  border: 1px solid ${({ color, theme, disabled }) => (disabled ? theme.disabledGray : (color || theme.purple))};
  color: ${({ color, theme, disabled }) => (disabled ? theme.disabledGray : (color || theme.purple))};
  background-color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: ${props => (props.disabled ? '' : 'pointer')};
    color: ${({ disabled, theme }) => (disabled ? theme.disabledGray : 'white')};
    background: ${props => (props.disabled ? 'white' : (props.color || props.theme.purple))};
  }

  &:focus {
    outline: 0;
  };
`;

export default Button;
