import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import colors from 'Resources/colors';
import { withApollo, compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import Modal from './Modal';
import BaseButton from './BaseButton';
import { ModalFooter, ModalContent } from './ModalStyledComponents';
import { getPortalPreview, getDisabledPortals } from '../graphql/Portal';

const baseWidth = 600;
const imageMargin = 10;

const ModalBody = styled.div`
  width: ${baseWidth + imageMargin * 2}px;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;

  img {
    margin: ${imageMargin}px;
    border: 1px solid black;
  }
`;

const ModalText = styled.div`
  padding-bottom: 10px;
  text-align: center;
  color: ${props => props.warn ? props.theme.red : ''};
`;

const PortalSelection = styled.div`
  font-weight: 300;
  text-align: left;
  padding: 5px 0;

  &:hover {
    cursor: pointer;
    background-color: ${colors.lightGray};
  }
`;

const DisabledPortalContainer = styled.div`
  display: block;
  padding: 5px 0;
  text-align: left;
  color: ${colors.gray};
`;

const DisabledPortalTitle = styled.div`
  font-weight: 425;
`;

const DisabledPortalMessage = styled.div`
  font-weight: bold;
  margin-top: 5px;
  font-style: italic;
  font-size: 12px;
`;

export class PortalSelectionModal extends PureComponent {
  state = { previewLink: '', selectedKey: '', insuranceCompanyName: '', showPortalMessage: false };

  handlePortalMessageLogic = () => {
    const { portals, onSelect } = this.props;
    const { selectedKey, insuranceCompanyName } = this.state;

    const selectedPortal = _.find(portals, { key: selectedKey });
    if (selectedPortal.totalAuthCount <= 10) {
      this.setState({ showPortalMessage: true });
    } else {
      onSelect(selectedKey, insuranceCompanyName);
    }
  }

  onClick = async (key, insuranceCompanyName) => {
    const { client, alert } = this.props;

    try {
      const response = await client.query({ query: getPortalPreview, variables: { key } });
      const previewFileName = _.get(response, 'data.getPortalPreview');
      if (previewFileName) {
        if (_.includes(['dev', 'test', ''], CONFIG.NODE_ENV)) {
          this.setState({ previewLink: `http://${CONFIG.SITE_DOMAIN}:8080/${previewFileName}`, selectedKey: key, insuranceCompanyName });
        } else {
          this.setState({ previewLink: `https://${CONFIG.SITE_DOMAIN}/${previewFileName}`, selectedKey: key, insuranceCompanyName });
        }
      } else {
        this.setState({ selectedKey: key, insuranceCompanyName }, async () => { await this.handlePortalMessageLogic(); });
      }
    } catch (e) {
      console.error(`Error selecting portal ${key}\n${e}\n${e.stack}`);
      alert.error('There was an error selecting your portal, please contact SamaCare using the chat button at the bottom');
    }
  }

  getPortalList = () => {
    const { closeModal, portals, disabledPortals } = this.props;

    return (
      <div>
        <ModalBody>
          <CustomModalContent>
            <ModalText>Please select where you would like to submit an authorization</ModalText>
            <ModalText warn>Alert: working directly on a payer website while logged into that website through SamaCare may cause unexpected errors</ModalText>
            {
              _.map(portals, (portal) => {
                const disabled = _.find(disabledPortals, { key: portal.key });
                if (!disabled) {
                  return (
                    <PortalSelection onClick={() => { this.onClick(portal.key, portal.insuranceCompanyName); }} key={`integratedPortalSelector_${portal.key}`}>
                      { portal.title }
                    </PortalSelection>
                  );
                }
                return (
                  <DisabledPortalContainer>
                    <DisabledPortalTitle>
                      { portal.title }
                    </DisabledPortalTitle>
                    <DisabledPortalMessage>
                      { disabled.userMessage }
                    </DisabledPortalMessage>
                  </DisabledPortalContainer>
                );
              })
            }
          </CustomModalContent>
          <CustomModalFooter>
            <BaseButton onClick={closeModal}>Return</BaseButton>
          </CustomModalFooter>
        </ModalBody>
      </div>
    );
  }

  handlePostPortalPreviewSelection = () => {
    this.setState({ previewLink: '' }, async () => { await this.handlePortalMessageLogic(); });
  }

  getPortalPreview = () => {
    const { previewLink } = this.state;

    return (
      <div>
        <ModalBody>
          <CustomModalContent>
            <ModalText>Please confirm this is the correct portal</ModalText>
            <img width={baseWidth} src={previewLink} />
          </CustomModalContent>
          <CustomModalFooter>
            <BaseButton onClick={() => { this.setState({ previewLink: '', selectedKey: '' }); }}>Back</BaseButton>
            <BaseButton onClick={this.handlePostPortalPreviewSelection}>This is the correct portal</BaseButton>
          </CustomModalFooter>
        </ModalBody>
      </div>
    );
  }

  getPortalMessage = () => {
    const { onSelect } = this.props;
    const { selectedKey, insuranceCompanyName } = this.state;

    return (
      <div>
        <ModalBody>
          <CustomModalContent>
            <ModalText>
              We have recently launched this payer portal integration. During this time, we are keeping a close eye on it to make sure errors are minimal.
              If you experience an error during your authorization, we will be sure to fix it within 48 hours.
            </ModalText>
          </CustomModalContent>
          <CustomModalFooter>
            <BaseButton onClick={() => { onSelect(selectedKey, insuranceCompanyName); }}>Ok, I understand</BaseButton>
          </CustomModalFooter>
        </ModalBody>
      </div>
    );
  }

  render() {
    const { open, closeModal } = this.props;
    const { previewLink, showPortalMessage } = this.state;

    return (
      <Modal header="Integrated Portals" open={open} onClick={closeModal}>
        { !previewLink && !showPortalMessage && (this.getPortalList()) }
        { previewLink && (this.getPortalPreview()) }
        { showPortalMessage && (this.getPortalMessage()) }
      </Modal>
    );
  }
}

export default compose(getDisabledPortals)(withApollo(withAlert(PortalSelectionModal)));
