import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { withApollo } from 'react-apollo';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import { BaseText } from 'Segment/StyledComponents';
import { withAlert } from 'react-alert';

import hcpcsModifiers from './hcpcsModifiers';
import BaseButton from './BaseButton';
import HcpcsCodeSelector from './HcpcsCodeSelector';

const TableContainer = styled.div`
  padding: 10px;
  th {
    font-weight: 300;
  }
  table {
    margin: 10px 0;
  }
  ${BaseText} {
    margin-right: 10px;
  }
`;

const AddRowText = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.purple};
  text-decoration: underline;
  margin: 10px 0;
`;

const HCPCS_CODE_KEY = 'code';
export const newValue = { [HCPCS_CODE_KEY]: '', quantity: '', modifier: '' };
export class HCPCSSelector extends Component {
  constructor(props) {
    super(props);

    this.state = { results: _.isEmpty(props.initializedHCPCSCodes) ? [{ ...newValue }] : props.initializedHCPCSCodes };
    if (props.setAddHCPCSFromParent) {
      props.setAddHCPCSFromParent(this.addHCPCSFromParent);
    }
  }

  updateResults = (newResults) => {
    const { onChange, alert } = this.props;

    const withoutBlankCodes = _.compact(_.map(newResults, 'code'));
    const hasDuplicates = !_.isEqual(_.uniq(withoutBlankCodes), withoutBlankCodes);

    if (hasDuplicates) {
      alert.error('Duplicate HCPCS codes are not allowed');
    } else {
      this.setState({ results: newResults }, () => onChange(newResults));
    }
  }

  addHCPCSFromParent = ({ code = '', quantity = '', modifier = '' }) => {
    const { results } = this.state;

    this.updateResults(results.concat({ [HCPCS_CODE_KEY]: code, quantity, modifier }));
  }

  updateRow = (index, key, updatedValue) => {
    const { results } = this.state;
    this.updateResults(_.map(results, (value, i) => {
      if (index === i) {
        return { ...value, [key]: (updatedValue || '').toString() };
      }
      return value;
    }));
  }

  removeRow = (index) => {
    const { onChange } = this.props;
    const { results } = this.state;

    const updatedResults = results.slice(0, index).concat(results.slice(index + 1, results.length));
    this.setState({ results: updatedResults }, () => { onChange(updatedResults); });
  }

  render() {
    const { max, units, disabled, hideModifiers, modifiersOverride, highlightRequiredFields, maxQuantity } = this.props;
    const { results } = this.state;

    return (
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>HCPCS Code</th>
              <th>{`Quantity ${(maxQuantity ? `(Max ${maxQuantity})` : '')}`}</th>
              { !_.isEmpty(units) && (<th>Units</th>) }
              { !hideModifiers && (<th>Modifier</th>) }
            </tr>
          </thead>
          <tbody>
            { _.map(results, (row, i) => (
              <tr key={`HCPCS_row_${i}`}>
                <td style={{ width: 400 }}>
                  <HcpcsCodeSelector
                    onChange={selected => this.updateRow(i, HCPCS_CODE_KEY, _.get(selected, 'value', '').toUpperCase())}
                    value={row[HCPCS_CODE_KEY] ? { label: row[HCPCS_CODE_KEY], value: row[HCPCS_CODE_KEY] } : ''}
                    highlight={highlightRequiredFields && !row[HCPCS_CODE_KEY]}
                  />
                </td>
                <td style={{ width: 100 }}>
                  <BaseText
                    type="number"
                    value={row.quantity || ''}
                    onChange={(e) => {
                      if (!(maxQuantity && e.target.value > maxQuantity)) {
                        this.updateRow(i, 'quantity', e.target.value);
                      }
                    }}
                    highlight={highlightRequiredFields && !row.quantity}
                  />
                </td>
                {
                  !_.isEmpty(units) && (
                    <td style={{ width: 150 }}>
                      <Select
                        style={{ maxWidth: 150, height: 40 }}
                        options={units}
                        onChange={(selected) => {
                          this.updateRow(i, 'unit', _.get(selected, 'value') || '');
                        }}
                        value={row.unit || ''}
                      />
                    </td>
                  )
                }
                { !hideModifiers && (
                  <td style={{ width: 200 }}>
                    <Creatable
                      style={{ maxWidth: 200, height: 40 }}
                      options={_.map(
                        modifiersOverride || hcpcsModifiers,
                        modifier => ({
                          label: `${modifier.code ? `${modifier.code} :` : ''} ${modifier.description}`,
                          value: modifier.code,
                        })
                      )}
                      filterOptions={(options, filterString) => {
                        const filterByCode = _.filter(options, option => option.value.toLowerCase().includes(filterString.toLowerCase()));

                        if (filterByCode.length >= 5) {
                          return filterByCode.slice(0, 5);
                        }

                        const filterByDescription = _.filter(options, option => option.label.toLowerCase().includes(filterString.toLowerCase()));
                        return _.uniq(filterByCode.concat(filterByDescription)).slice(0, 5);
                      }}
                      onChange={(selected) => {
                        this.updateRow(i, 'modifier', _.get(selected, 'value', '').toUpperCase());
                      }}
                      value={row.modifier ? { label: row.modifier, value: row.modifier } : ''}
                    />
                  </td>
                ) }
                <td>
                  { i > 0 && (
                    <BaseButton onClick={() => { this.removeRow(i); }}>Remove Row</BaseButton>
                  ) }
                </td>
              </tr>
            )) }
          </tbody>
        </table>
        { ((!max || results.length < max) || disabled) && (
          <AddRowText onClick={() => { this.setState({ results: results.concat({ ...newValue }) }); }}>
            + Add Row
          </AddRowText>
        ) }
      </TableContainer>
    );
  }
}

export default withApollo(withAlert(HCPCSSelector));
