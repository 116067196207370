import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';

import { withResolvePortalMetric } from '../../graphql/Portal';
import BaseButton from '../../components/BaseButton';
import Modal from '../../components/Modal';
import { ModalContent, ModalFooter } from '../../components/ModalStyledComponents';
import { BaseMultiline } from '../../components/Segment/StyledComponents';

const CustomModalContent = styled(ModalContent)`
  font-size: 16px;
  margin: 0 5px;
  width: 650px;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;
const ModalBody = styled.div`
  width: 650;
  height: 450px;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const DecisionButton = styled(BaseButton)`
  color: ${props => props.isSelected ? 'white' : props.theme.purple};
  background-color: ${props => props.isSelected ? props.theme.purple : 'white'};
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin: 5px 0;
  }
`;
class ErrorResolutionModal extends PureComponent {
  state = { resolutionType: '', resolutionDetails: '' }

  getResolutionButtons = () => {
    const resolutionButtons = _.map(
      _.values(CONFIG.CONSTANTS.AUTHORIZATION_ERROR_RESOLUTION_TYPES),
      type => ({ title: type, action: () => { this.setState({ resolutionType: type }); }, isSelected: selection => selection === type })
    );

    return resolutionButtons;
  };

  handleResolveError = async () => {
    const { clearState, errorId, resolvePortalMetric, alert } = this.props;
    const { resolutionType, resolutionDetails } = this.state;

    if (resolutionType) {
      try {
        await resolvePortalMetric({ variables: { id: errorId, isForAuthError: true, resolutionType, resolutionDetails } });
        alert.success('Success');
        clearState();
      } catch {
        alert.error('Error resolving portal error');
      }
    } else {
      alert.error('Resolution type selection is required!');
    }
  };

  render() {
    const { clearState, errorId } = this.props;
    const { resolutionType, resolutionDetails } = this.state;

    return (
      <Modal
        open={errorId}
        header="Resolve Error"
        onClick={clearState}
      >
        <ModalBody>
          <CustomModalContent>
            <ModalButtonContainer>
              { _.map(this.getResolutionButtons(), ({ title, action, isSelected }) => (
                <DecisionButton
                  key={title}
                  onClick={action}
                  isSelected={isSelected(resolutionType)}
                >
                  { title }
                </DecisionButton>
              )) }
            </ModalButtonContainer>
            <div style={{ marginTop: '10px', marginBottom: '5px' }}>Resolution Details</div>
            <BaseMultiline onChange={(e) => { this.setState({ resolutionDetails: e.target.value }); }} value={resolutionDetails} />
          </CustomModalContent>
        </ModalBody>
        <CustomModalFooter>
          <BaseButton
            onClick={clearState}
          >
            Back
          </BaseButton>
          <BaseButton onClick={this.handleResolveError}>Submit</BaseButton>
        </CustomModalFooter>
      </Modal>
    );
  }
}

export default compose(withResolvePortalMetric)(withAlert(ErrorResolutionModal));
