import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import Modal from '../../components/Modal';
import { withUpsertPortalCredentials, withCurrentAccount } from '../../graphql/Account';
import BaseButton from '../../components/BaseButton';
import { ModalBodyLarge, ModalFooter, ModalContent } from '../../components/ModalStyledComponents';

const CustomModalContent = styled(ModalContent)`
  margin: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
`;

const LoginWarning = styled.p`
  b {
    margin 0 5px 0 4px;
  }
`;

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
  width: 120px;
  margin-left: 10px;
  padding: 5px 10px;
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.gray};
  font-weight: 500;
  margin-top: 10px;
  margin-right: auto;
  text-align: left;
`;

class PortalLoginModal extends PureComponent {
  constructor(props) {
    super(props);
    const { account, portalKey } = this.props;
    const creds = _.find(_.get(account, 'portalCredentials', []), { key: portalKey }) || {};

    this.state = { username: creds.username || '', password: creds.password || '' };
  }

  submit = async () => {
    const { upsertPortalCredentials, onSubmit, portalKey } = this.props;
    const { username, password } = this.state;

    try {
      await upsertPortalCredentials({ variables: { credentials: { key: portalKey, username, password } } });
      await onSubmit();
    } catch (e) {
      alert.error(e.message);
    }
  }

  component

  render() {
    const { closeModal, open, portalTitle } = this.props;
    const { username, password } = this.state;

    return (
      <Modal
        header="Login Credentials"
        onClick={this.toggleModal}
        open={open}
      >
        <ModalBodyLarge>
          <CustomModalContent>
            <div>
              Add your login credentials for the
              <b>
                { ` ${portalTitle} ` }
              </b>
              portal. We use your credentials submit authorizations on your behalf.
            </div>
            <LoginWarning>
              Please note, sharing credentials may lead
              <b>
                unexpected errors
              </b>
              if multiple users are logged in at the same time.
            </LoginWarning>
            <SectionTitle>
              { `${portalTitle} Username` }
            </SectionTitle>
            <BaseText
              onChange={(e) => { this.setState({ username: e.target.value }); }}
              value={username || ''}
            />
            <SectionTitle>
              { `${portalTitle} Password` }
            </SectionTitle>
            <BaseText
              type="password"
              onChange={(e) => { this.setState({ password: e.target.value }); }}
              value={password || ''}
            />
            All credentials are protected with 256-bit encryption
          </CustomModalContent>
          <CustomModalFooter>
            <GrayButton onClick={closeModal}>Close</GrayButton>
            <BaseButton style={{ marginLeft: 10 }} onClick={this.submit}>
              Submit
            </BaseButton>
          </CustomModalFooter>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { portalTitle: state.form.portalTitle };
}

export default connect((mapStateToProps))(compose(withUpsertPortalCredentials, withCurrentAccount)(withAlert(PortalLoginModal)));
