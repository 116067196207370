import getMatchingIndicesFromTextFragments from './getMatchingIndicesFromTextFragments';

const actionRequiredIndicators = [
  'not managed by this department',
  'unable to complete this review',
];

const getActionRequiredRecs = fullText => (getMatchingIndicesFromTextFragments(actionRequiredIndicators, fullText));

export default getActionRequiredRecs;
