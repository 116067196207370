import React from 'react';
import styled from 'styled-components';

const ActionRequiredRow = styled.tr`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  background-color: ${props => props.theme.lightPurple};
`;

const AutomatedRow = ({ i, keyOff, row, children }) => (
  <ActionRequiredRow key={`ActionRequiredRow${i}-${keyOff}`}>
    <td colSpan={row.cells.length}>
      { children }
    </td>
  </ActionRequiredRow>
);

export default AutomatedRow;
