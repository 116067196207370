import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';

import CreateFormNumberModal from '../components/CreateFormNumberModal';
import CreateInsuranceCompanyModal from '../components/CreateInsuranceCompanyModal';
import DeleteForm from '../components/DeleteForm';
import FiltersContainer from '../components/FiltersContainer';
import BaseButton from '../../../../components/BaseButton';
import { withDetailedForms, withAssociateForm } from '../../../../graphql/AuthorizationForm';
import { withInsuranceCompanies } from '../../../../graphql/InsuranceCompany';
import { withUpsertFormFilter } from '../../../../graphql/FormFilter';
import { withFormNumbers } from '../../../../graphql/FormNumber';
import filterInsuranceCompanyOptions from '../../../../util/filterInsuranceCompanyOptions';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FormSelectionContainer = styled.div`
  display: flex;
  height: 100px;
  flex-wrap: wrap;
`;

const StyledSelect = styled(Select)`
  width: 80%;
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;
`;

const ViewContainer = styled.div`
  flex: 1;

  ${BaseButton} {
    margin-left: auto;
  }
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 500;
  margin: 10px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${props => props.theme.lightPurple};
  }
`;

const ScrollContainer = styled.div`
  max-height: 400px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const { SUGGEST, HIDE_UNLESS } = CONFIG.CONSTANTS.FILTER_TYPES;
const homogenizeString = string => (string.replace(/[^A-Za-z]/g, '').toLowerCase());
class FormEdit extends PureComponent {
  state = { selectedFormId: null, payersUpdate: [], numbersUpdate: [], newInsuranceCompany: null, newFormNumber: null, formDeleteId: null }

  editFormAssociation = (key, formNumberOrInsuranceCompany, isRemove) => {
    const stateHandler = prevState => ({
      [key]: isRemove ? _.reject(prevState[key], { id: formNumberOrInsuranceCompany.id }) : [...prevState[key], formNumberOrInsuranceCompany],
    });

    this.setState(stateHandler, this.updateForm);
  }

  updateForm = async () => {
    const { associateForm, alert } = this.props;
    const { selectedFormId, payersUpdate, numbersUpdate } = this.state;
    try {
      await associateForm({ variables: { id: selectedFormId, insuranceCompanies: _.map(payersUpdate, 'id'), formNumbers: _.map(numbersUpdate, 'id') } });
    } catch (e) {
      alert.error(`Error, ${e.message}`);
    }
  }

  render() {
    const { forms, insuranceCompanies, formNumbers, upsertFormFilter } = this.props;
    const { selectedFormId, newFormNumber, newInsuranceCompany, formDeleteId } = this.state;

    const augmentedForms = _.map(forms, form => ({ ...form, displayValue: `${form.title} - ${form.description}` }));
    const selectedForm = _.find(augmentedForms, { id: selectedFormId });

    return (
      <MainContainer>
        <FormSelectionContainer>
          <InputLabel>Select a form</InputLabel>
          <StyledSelect
            onChange={(selected) => {
              this.setState({ selectedFormId: selected.id, payersUpdate: selected.insuranceCompanies, numbersUpdate: selected.formNumbers });
            }}
            labelKey="displayValue"
            valueKey="id"
            options={augmentedForms}
            filterOptions={(options, string) => (
              _.filter(options, option => (homogenizeString(option.displayValue).includes(homogenizeString(string))))
            )}
            value={selectedForm}
          />
          {!_.isEmpty(selectedForm) && (
            <BaseButton style={{ width: '10%', height: '35%' }} onClick={() => { this.setState({ formDeleteId: selectedForm.id }); }}>
              Delete Form
            </BaseButton>
          )}
        </FormSelectionContainer>
        {formDeleteId && (
          <DeleteForm
            id={formDeleteId}
            title={selectedForm.title}
            description={selectedForm.description}
            clear={() => this.setState({ formDeleteId: null, selectedFormId: null })}
          />
        )}
        <ContentContainer>
          {selectedFormId && (
            <ViewContainer style={{ marginRight: '16px' }}>
              <InputLabel>Associate or Create Fax Number</InputLabel>
              <Creatable
                labelKey="number"
                onChange={(number) => { this.editFormAssociation('numbersUpdate', number, false); }}
                options={formNumbers}
                valueKey="id"
                placeholder="Associate a fax number"
                onNewOptionClick={(newNumber) => { this.setState({ newFormNumber: newNumber }); }}
              />
              <InputLabel>Form Fax Numbers</InputLabel>
              <ScrollContainer>
                {_.map(selectedForm.formNumbers, number => (
                  <ItemContainer style={number.forbiddenReason ? { color: 'red' } : {}} key={`formNumber_${number.id}`}>
                    <div style={{ marginLeft: '5px' }}>{`${number.forbiddenReason ? '[BLACKLISTED] ' : ''}${number.number} - ${number.description}`}</div>
                    <BaseButton onClick={() => { this.editFormAssociation('numbersUpdate', number, true); }}>
                      Remove
                    </BaseButton>
                  </ItemContainer>
                ))}
              </ScrollContainer>
              {newFormNumber && (
                <CreateFormNumberModal
                  onClose={() => { this.setState({ newFormNumber: null }); }}
                  insuranceCompanies={insuranceCompanies}
                  number={newFormNumber.number}
                />
              )}
            </ViewContainer>
          )}
          {selectedFormId && (
            <ViewContainer>
              <InputLabel>Associate or Create Insurance Company</InputLabel>
              <Creatable
                labelKey="name"
                onChange={(company) => { this.editFormAssociation('payersUpdate', company, false); }}
                options={_.sortBy(insuranceCompanies, 'name')}
                valueKey="id"
                sorted
                placeholder="Associate an insurance company"
                filterOption={filterInsuranceCompanyOptions}
                onNewOptionClick={(company) => { this.setState({ newInsuranceCompany: company }); }}
              />
              <InputLabel>Insurance Companies</InputLabel>
              <ScrollContainer>
                {_.map(selectedForm.insuranceCompanies, company => (
                  <ItemContainer key={`formNumber_${company.id}`}>
                    <div style={{ marginLeft: '5px' }}>{company.name}</div>
                    <BaseButton onClick={() => { this.editFormAssociation('payersUpdate', company, true); }}>
                      Remove
                    </BaseButton>
                  </ItemContainer>
                ))}
              </ScrollContainer>
              {newInsuranceCompany && (
                <CreateInsuranceCompanyModal
                  onClose={() => { this.setState({ newInsuranceCompany: null }); }}
                  name={newInsuranceCompany.name}
                />
              )}
            </ViewContainer>
          )}
        </ContentContainer>
        {selectedFormId && (
          <FiltersContainer
            suggestFilter={_.find(selectedForm.formFilters, { type: SUGGEST })}
            hideFilter={_.find(selectedForm.formFilters, { type: HIDE_UNLESS })}
            associationId={selectedFormId}
            upsertFunction={upsertFormFilter}
            associationKey="formId"
          />
        )}
      </MainContainer>
    );
  }
}

export default compose(withDetailedForms, withAssociateForm, withInsuranceCompanies, withFormNumbers, withUpsertFormFilter)(withAlert(FormEdit));
