import Cleave from 'cleave.js/react';
import styled from 'styled-components';

export const BaseCleave = styled(Cleave)`
  border: ${props => props.highlight ? `2px solid ${props.theme.red}` : `1px solid ${props.theme.lightGray}`};
  padding: 10px ${props => props.paddingleft};
  border-radius: 3px;
  box-shadow: none;
  width: 100%;

  &:focus {
    border-color: ${props => props.theme.purple};
    outline:0;
  }

  &:disabled {
    background: ${props => props.theme.disabledGray};
  }
`;

export const BaseText = styled.input`
  border: ${props => props.highlight ? `2px solid ${props.theme.red}` : `1px solid ${props.theme.lightGray}`};
  padding: 10px ${props => props.paddingleft};
  border-radius: 3px;
  box-shadow: none;
  width: 100%;

  &:focus {
    border-color: ${props => props.theme.purple};
    outline:0;
  }

  &:disabled {
    background: ${props => props.theme.disabledGray};
  }
`;


export const BaseMultiline = styled.textarea`
  border: ${props => props.highlight ? `2px solid ${props.theme.red}` : `1px solid ${props.theme.lightGray}`};
  padding: 10px ${props => props.paddingleft};
  border-radius: 3px;
  box-shadow: none;
  height: 150px;
  width: 100%;
  resize: none;

  &:focus {
    border-color: ${props => props.theme.purple};
    outline:0;
  }

  &:disabled {
    background: ${props => props.theme.disabledGray};
  }
`;
