import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { BaseText } from './StyledComponents';

class SegmentText extends PureComponent {
  render() {
    const { item, handleChange, result, disabled, highlightIfMissing } = this.props;
    const onBlur = _.get(item, 'onBlur', () => {});

    return (
      <BaseText
        disabled={disabled}
        onChange={(event) => { handleChange({ [item.key]: event.target.value }); }}
        onBlur={onBlur}
        value={result || ''}
        // This is the only solution I've tried that removes auto complete
        // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
        autoComplete="new-password"
        highlight={highlightIfMissing && !result}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentText);
