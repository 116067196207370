import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withApollo, compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import isMobilePhone from 'validator/lib/isMobilePhone';
import SegmentPhone from 'Segment/SegmentPhone';
import { BaseText } from 'Segment/StyledComponents';

import { withSendAuthorization, withAuthorizationQuery } from '../graphql/Authorization';
import Modal from './Modal';
import BaseButton from './BaseButton';
import RequiredTag from './RequiredTag';
import { ModalBody, ModalFooter } from './ModalStyledComponents';

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: 10px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  ${BaseButton} {
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const ModalText = styled.div`
  text-align: center;
  white-space: normal;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${BaseButton} {
    margin-left: 5px;
  }
`;

const DetailsButton = styled(BaseButton)`
  background-color: ${props => (props.selected ? props.theme.purple : 'white')};
  color: ${props => (props.selected ? 'white' : props.theme.purple)};
  flex: 1;
  margin-left: ${props => (props.isFirst ? 0 : 5)}px;
`;

class RequestResendButton extends PureComponent {
  state = { open: false, phone: '', tempKey: `temp_${Math.random()}`, reason: {}, resendAdditionalInfo: '' };

  toggleModal = async () => {
    const { client, authorizationId } = this.props;
    const { open } = this.state;

    if (open) {
      this.setState({ open: false });
    } else {
      const { data } = await client.query({
        query: withAuthorizationQuery,
        variables: { id: authorizationId },
      });

      this.setState({
        open: true,
        phone: _.get(data, 'authorizationById.submissionPhoneNumber', ''),
        tempKey: `temp_${Math.random()}`,
        reason: {},
        resendAdditionalInfo: '',
      });
    }
  };

  submit = () => {
    const { sendAuthorization, authorizationId, alert } = this.props;
    const { phone, reason, resendAdditionalInfo } = this.state;

    if (isMobilePhone(phone, 'en-US')) {
      sendAuthorization({ variables: { id: authorizationId, phone, resendReasonKey: reason.key, resendReasonAdditionalInfo: resendAdditionalInfo } })
        .then(this.toggleModal)
        .catch(() => { alert.error('There was an error resending your authorization'); });
    } else {
      alert.error('Please input a valid phone number');
    }
  };

  render() {
    const { children, isAdministrativeResend } = this.props;
    const { open, phone, tempKey, reason } = this.state;

    return (
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.toggleModal();
          }}
        >
          { children }
        </div>
        <Modal header="Resend Authorization" onClick={this.toggleModal} open={open}>
          <ModalBody>
            <ModalContent>
              { !isAdministrativeResend && (
                <ModalText>
                  Sama has tried sending your authorization 5 times before reporting failure,
                  please ensure the fax number is correct before attempting to resend.
                  If problems continue to occur, please contact us via chat at the bottom right.
                </ModalText>
              )}
              <div>
                <SelectorTitle>
                  Submission Fax Number
                  <RequiredTag style={{ marginLeft: '10px' }} />
                </SelectorTitle>
                <SegmentPhone
                  handleChange={(res) => {
                    this.setState({ phone: res[tempKey] });
                  }}
                  item={{ key: tempKey }}
                  override={phone}
                />
              </div>
              { isAdministrativeResend && (
                <div>
                  <SelectorTitle>Resend Reason</SelectorTitle>
                  <ButtonContainer>
                    { _.map(CONFIG.CONSTANTS.AUTHORIZATION_RESEND_REASONS, (resendReason, i) => (
                      <DetailsButton
                        key={`resend_reason_button_${resendReason.key}`}
                        onClick={() => { this.setState({ reason: resendReason }); }}
                        selected={resendReason.key === reason.key}
                        isFirst={i === 0}
                      >
                        { resendReason.title }
                      </DetailsButton>
                    )) }
                  </ButtonContainer>
                </div>
              ) }
              { isAdministrativeResend && (
                <div>
                  <SelectorTitle>Additional Info</SelectorTitle>
                  <BaseText onChange={(event) => { this.setState({ resendAdditionalInfo: event.target.value }); }} />
                </div>
              ) }
            </ModalContent>
            <CustomModalFooter>
              <GrayButton onClick={this.toggleModal}>Cancel</GrayButton>
              <BaseButton onClick={this.submit} disabled={!phone || (isAdministrativeResend && _.isEmpty(reason))}>Resend Authorization</BaseButton>
            </CustomModalFooter>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default compose(withSendAuthorization)(withApollo(withAlert(RequestResendButton)));
