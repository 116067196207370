import gql from 'graphql-tag';
import HCPCSCodeInfo from './fragments/HCPCSCodeInfo';

export const HCPCSCodeSearchQuery = gql`
  query searchHCPCS($searchString: String!) {
    searchHCPCS(searchString: $searchString) {
      ...HCPCSCodeInfo
    }
  }
  ${HCPCSCodeInfo}
`;
