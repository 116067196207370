import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import mixpanel from 'mixpanel-browser';

import PortalFillTableResultsDisplay from './PortalFillTableResultsDisplay';
import BaseButton from '../../components/BaseButton';

const StepContainer = styled.div`
  padding: 10px;
`;

const StepDescription = styled.div`
  max-width: calc(100% - 200px);
  text-align: left;
`;

const ResetToStepButton = styled(BaseButton)`
  margin-left: auto;
  flex-direction: column;
  min-width: 190px;
`;

const ErrorModalResetToStepButton = styled(BaseButton)`
  margin-left: auto;
  flex-direction: column;
  min-width: 100px;
`;

const PriorStepTitle = styled.div`
  font-weight: 500;
`;

class PortalPriorSteps extends Component {
  render() {
    const { authorization, resetToStep, unsetStep, isCondensed } = this.props;

    return _.map(_.filter(authorization.customPortalSteps, auth => !auth.isTerminal && !auth.isDraft), step => (
      <StepContainer style={{ display: 'flex', flexDirection: 'row' }} key={step.id}>
        <StepDescription>
          <PriorStepTitle>{ step.title }</PriorStepTitle>
          <PortalFillTableResultsDisplay display={step.display} isCondensed={isCondensed} />
        </StepDescription>
        { !authorization.submittedAt && (isCondensed
          ? (
            <ErrorModalResetToStepButton
              onClick={() => {
                if (step.requiresHardReset) {
                  resetToStep(step.id);
                } else {
                  unsetStep(step.id);
                }
              }}
            >
              { step.requiresHardReset
                ? (<div data-for="resetButtonHelper" data-tip="">Reset to this section</div>)
                : (<div data-for="reinputHelper" data-tip="">Reinput information</div>)
              }
            </ErrorModalResetToStepButton>
          )
          : (
            <ResetToStepButton
              onClick={() => {
                if (step.requiresHardReset) {
                  mixpanel.track(
                    '[Portal Prior Steps] Reset to this section used',
                    { InstitutionId: authorization.InstitutionId, AuthorizationId: authorization.id },
                  );
                  resetToStep(step.id);
                } else {
                  mixpanel.track(
                    '[Portal Prior Steps] Reinput information used',
                    { InstitutionId: authorization.InstitutionId, AuthorizationId: authorization.id },
                  );
                  unsetStep(step.id);
                }
              }}
            >
              { step.requiresHardReset
                ? (<div data-for="resetButtonHelper" data-tip="">Reset to this section</div>)
                : (<div data-for="reinputHelper" data-tip="">Reinput information</div>)
              }
            </ResetToStepButton>
          ))
        }
        <ReactTooltip id="resetButtonHelper">
          Resetting re-ask information from later steps. This is because future questions change depending on your response here.
        </ReactTooltip>
        <ReactTooltip id="reinputHelper">
          Reinput this information. Will NOT re-ask future questions.
        </ReactTooltip>
      </StepContainer>
    ));
  }
}

export default PortalPriorSteps;
