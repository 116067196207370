import React, { PureComponent } from 'react';
import styled from 'styled-components';
import SearchIcon from 'react-icons/lib/go/search';
import CloseIcon from 'react-icons/lib/md/close';
import { BaseText } from 'Segment/StyledComponents';
import strings from 'Resources/strings';

import IconButton, { IconButtonStyled } from './IconButton';
import AlertModal from './AlertModal';
import BaseButton from './BaseButton';

const Container = styled.div`
  font-size: 16px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  ${IconButtonStyled} {
    background-color: white;
    border: 1px solid ${props => props.theme.purple};
    color: ${props => props.theme.purple};

    &:hover {
      background-color: ${props => props.theme.purple};
      color: white;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;

  ${IconButtonStyled} {
    background-color: ${props => (props.clearState ? props.theme.purple : 'white')};
    border: 1px solid ${props => props.theme.purple};
    color: ${props => (!props.clearState ? props.theme.purple : 'white')};

    &:hover {
      background-color: ${props => props.theme.purple};
      color: white;
    }
  }
`;

export default class SearchButton extends PureComponent {
  state = { displaySearchModal: false, firstName: '', lastName: '' };

  triggerSearch = () => {
    const { onSearch } = this.props;
    const { firstName, lastName } = this.state;

    onSearch(firstName, lastName);
    this.setState({ displaySearchModal: false });
  }

  render() {
    const { searchValue } = this.props;
    const { displaySearchModal, firstName, lastName } = this.state;

    return (
      <Container>
        <AlertModal
          buttons={(
            <BaseButton
              style={{ width: '100%' }}
              onClick={this.triggerSearch}
              disabled={!firstName && !lastName}
            >
              {strings.COMMON.SEARCH}
            </BaseButton>
          )}
          content={(
            <ModalContent onKeyDown={(e) => { if (e.keyCode === 13) { this.triggerSearch(); } }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <BaseText
                  style={{ width: '48.75%' }}
                  value={firstName}
                  onChange={(e) => { this.setState({ firstName: e.target.value }); }}
                  placeholder={strings.AUTHORIZATIONS.FIRST_NAME_SEARCH_PLACEHOLDER}
                />
                <BaseText
                  style={{ width: '48.75%' }}
                  value={lastName}
                  onChange={(e) => { this.setState({ lastName: e.target.value }); }}
                  placeholder={strings.AUTHORIZATIONS.LAST_NAME_SEARCH_PLACEHOLDER}
                />
              </div>
            </ModalContent>
          )}
          header="Search by Patient Name"
          open={displaySearchModal}
          closeModal={() => { this.setState({ displaySearchModal: false }); }}
        />
        { !searchValue && (
          <SearchButtonWrapper>
            <IconButton
              icon={SearchIcon}
              onClick={() => { this.setState({ displaySearchModal: true }); }}
            >
              Search By Name
            </IconButton>
          </SearchButtonWrapper>
        ) }
        { searchValue && (
          <IconButtonWrapper clearState>
            <IconButton
              icon={CloseIcon} onClick={() => { this.setState({ firstName: '', lastName: '' }, this.triggerSearch); }}
            >
              Clear search
            </IconButton>
          </IconButtonWrapper>
        ) }
      </Container>
    );
  }
}
