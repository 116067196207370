import _ from 'lodash';
import React, { PureComponent } from 'react';

import { checkInput } from '../InputPropTypes';
import { CheckmarkContainer, CheckmarkImageContainer, getPropsFromInput } from './BaseInput';

class CheckInput extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <CheckmarkContainer
        {...(input.disabled ? _.omit(getPropsFromInput(input), 'tabIndex') : getPropsFromInput(input))}
        isCircle={input.type === CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.CHECK_CIRCLE.key}
        onKeyUp={({ key }) => {
          if (key === 'Enter') {
            // toggle the radio on enter key
            input.onChange({ [input.id]: !input.value });
          }
        }}
      >
        <input
          type="checkbox"
          id={`checkbox_id_${input.id}`}
          onChange={(e) => { input.onChange({ [input.id]: e.target.checked }); }}
          checked={input.value || ''}
          disabled={input.disabled}
        />
        <CheckmarkImageContainer disabled={input.disabled} />
      </CheckmarkContainer>
    );
  }
}

CheckInput.propTypes = {
  input: checkInput.isRequired,
};

export default CheckInput;
