import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import BaseButton from '../../components/BaseButton';
import HCPCSSelector from '../../components/HCPCSSelector';
import AlertModal from '../../components/AlertModal';
import SectionHeader from './SectionHeader';
import IntegratedProceduresDetails from './IntegratedProceduresDetails';

const TableContainer = styled.div`
  padding: 10px;
  th {
    font-weight: 300;
  }

  table {
    margin: 10px 0;
  }

  ${BaseText} {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  ${BaseButton} {
    margin-right: 10px;
  }
`;

const newValue = { code: '', quantity: '', modifier: '' };
export class PortalHCPCS extends Component {
  constructor(props) {
    super(props);
    const { message } = this.props;

    this.state = { results: _.get(message, 'priorValue', [{ ...newValue }]), validationModalOpen: false };
  }

  // Set by HCPCSSelector
  setHCPCS;

  isValidSubmission = (results) => {
    const { message } = this.props;
    if (message.units) {
      return _.every(results, resultRow => resultRow.code && resultRow.quantity && !_.isNil(resultRow.unit));
    }
    return _.every(results, resultRow => resultRow.code && resultRow.quantity);
  }

  submit = async (isDraft = false) => {
    const { message, onSelect } = this.props;
    const { results } = this.state;

    const rowsWithValues = _.filter(results, result => (result.code || result.quantity));
    if ((this.isValidSubmission(rowsWithValues) && !_.isEmpty(rowsWithValues)) || isDraft) {
      if (isDraft) {
        await onSelect({
          key: message.key,
          value: rowsWithValues,
          isDraft,
        });
      } else {
        onSelect({
          ...message,
          value: rowsWithValues,
          display: _.map(
            rowsWithValues,
            ({ code, quantity, unit, modifier }) => (`${code}${modifier ? ` (${modifier})` : ''}: ${quantity}${unit ? ` ${unit}` : ''}`),
          ).join(', '),
        });
      }
    } else {
      this.setState({ validationModalOpen: true });
    }
  }

  onKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.submit();
    }
  }

  saveDraft = async () => {
    const { alert } = this.props;

    try {
      await this.submit(true);
      alert.success('Your progress has been saved!');
    } catch (e) {
      alert.error('There was an error saving your draft');
    }
  }

  handleOnChange = async (newCodes) => {
    this.setState({ results: newCodes });
    await this.saveDraft();
  }


  render() {
    const { message, authorization, alert } = this.props;
    const { validationModalOpen, results } = this.state;

    return (
      <>
        { !_.isEmpty(authorization.integratedEMRProcedures) && (
          // Needs to wrap setHCPCS in anonymous function because the method is set asynchronously
          // Otherwise, we just pass undefined in to IntegratedProceduresDetails
          <IntegratedProceduresDetails
            authorization={authorization}
            setHCPCS={(hcpcs) => {
              if (message.maxQuantity && hcpcs.quantity > message.maxQuantity) {
                alert.error(`Attempting to set a quantity greater than the allowed maximum of ${message.max}`);
              } else {
                this.setHCPCS(hcpcs);
              }
            }}
          />
        ) }
        <TableContainer onKeyDown={this.onKeyPress}>
          <SectionHeader>{ message.title }</SectionHeader>
          <HCPCSSelector
            onChange={this.handleOnChange}
            max={message.max}
            maxQuantity={message.maxQuantity}
            units={message.units}
            modifiersOverride={message.modifiersOverride}
            initializedHCPCSCodes={results}
            setAddHCPCSFromParent={(fn) => { this.setHCPCS = fn; }}
          />
          <AlertModal
            header="Error"
            closeModal={() => { this.setState({ validationModalOpen: false }); }}
            open={validationModalOpen}
            content="Please check the HCPCS rows to ensure they all have a valid code, unit and a non-zero quantity"
          />
          <ButtonContainer>
            <BaseButton
              type="submit"
              onClick={() => { this.submit(false); }}
            >
              Submit
            </BaseButton>
          </ButtonContainer>
        </TableContainer>
      </>
    );
  }
}

export default withAlert(PortalHCPCS);
