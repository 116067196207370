import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

import { withDefaultProps } from './utils';

export const ICD10SearchQuery = gql`
  query icd10($searchTerm: String!) {
    icd10(searchTerm: $searchTerm)
  }
`;

export const withICD10Search = graphql(ICD10SearchQuery,
  {
    options: () => ({ variables: { searchTerm: '' } }),
    props: withDefaultProps(({ data }) => ({ icd10: _.get(data, 'icd10'), icdError: _.get(data, 'error') })),
  });
