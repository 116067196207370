import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import form from './formReducer';
import authorizationList from './authorizationListReducer';
import otherAuthorizationsList from './otherAuthorizationsListReducer';
import loginRedirect from './loginRedirectReducer';

export default history => combineReducers({
  router: connectRouter(history),
  authorizationList,
  otherAuthorizationsList,
  form,
  loginRedirect,
});
