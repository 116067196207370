import React from 'react';

import Backdrop from '../Backdrop';

import './LoadingSpinner.scss';

const LoadingSpinner = ({ open }) => {
  if (open) {
    return (
      <div>
        <Backdrop />
        <div className="loader" />
      </div>
    );
  }
  return (<span />);
};

export default LoadingSpinner;
