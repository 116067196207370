import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import Modal from './Modal';
import { withDeleteAuthorization } from '../graphql/Authorization';
import BaseButton from './BaseButton';
import AuthorizationErrorModal from './AuthorizationErrorModal';
import { ModalBody, ModalFooter, ModalContent } from './ModalStyledComponents';

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  ${BaseButton} {
    width: 120px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

export class DeleteAuthorizationButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  toggleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  // delete is a reserved word, changing as this now used as a prop
  deleteAuth = () => {
    const { id, deleteAuthorization, onDelete, alert } = this.props;
    deleteAuthorization({ variables: { id } })
      .then(() => {
        this.toggleModal();
        if (onDelete) { onDelete(); }
      }, () => {
        alert.error('There was an error in deleting the authorization');
        this.toggleModal();
      });
  }

  getAuthErrorCase = () => {
    const { INITIATE, FORM_DETAILS, REVIEW_SIGN_SUBMIT } = CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL;
    const { INITIATE_FORM_AUTH, IN_PROCESS_FORM_AUTH, IN_PROCESS_PORTAL_AUTH } = CONFIG.CONSTANTS.AUTHORIZATION_ERROR_MODAL_CASES;
    const { portalKey, step, isReferral } = this.props;
    if (!isReferral) {
      if (!portalKey && (!step || step === INITIATE.step)) {
        // step is null during step 1
        return INITIATE_FORM_AUTH;
      }
      if (!portalKey && (step === FORM_DETAILS.step || step === REVIEW_SIGN_SUBMIT.step)) {
        return IN_PROCESS_FORM_AUTH;
      }
      if (portalKey && step === FORM_DETAILS.step) {
        return IN_PROCESS_PORTAL_AUTH;
      }
    }
    return null;
  }

  render() {
    const { children, id } = this.props;
    const { open } = this.state;
    const authErrorCase = this.getAuthErrorCase();
    return (
      <div>
        {authErrorCase
          ? (
            <AuthorizationErrorModal
              authorizationId={id}
              close={this.toggleModal}
              authErrorCase={authErrorCase}
              open={open}
              deleteAuth={this.deleteAuth}
            />
          )
          : (
            <Modal header="Delete Confirmation" onClick={this.toggleModal} open={open}>
              <ModalBody>
                <CustomModalContent>
                  Are you sure you want to delete this authorization?
                </CustomModalContent>
                <CustomModalFooter>
                  <GrayButton onClick={() => { this.toggleModal(); }}>Cancel</GrayButton>
                  <BaseButton onClick={this.deleteAuth}>Yes</BaseButton>
                </CustomModalFooter>
              </ModalBody>
            </Modal>
          )}
        <div
          id={`deleteAuthorization_${id}`}
          onClick={(e) => {
            e.stopPropagation();
            this.toggleModal();
          }}
        >
          { children }
        </div>
      </div>
    );
  }
}

export const DeleteAuthorizationButtonWithAlert = withAlert(DeleteAuthorizationButton);

export default compose(withDeleteAuthorization)(DeleteAuthorizationButtonWithAlert);
