import _ from 'lodash';
import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import styled from 'styled-components';
import colors from 'Resources/colors';

import PDFViewer from '../../../../components/PDFViewer';
import BaseButton from '../../../../components/BaseButton';

const withFormById = graphql(
  gql`
    query formById($id: Int!) {
      formById(id: $id) {
        pdfURL
      }
    }
  `,
  {
    options: props => ({ variables: { id: props.formId } }),
    props: ({ data }) => ({ form: _.get(data, 'formById') }),
  }
);

const PDFContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: gray;
  margin: 10px 0;
`;

const ConfirmButton = styled(BaseButton)`
  width: 250px;
`;
export class FormConfirmation extends PureComponent {
  render() {
    const { form, onSubmit, onBack } = this.props;

    if (form) {
      return (
        <div>
          <div style={{ padding: '10px', textAlign: 'center' }}>
            Is this the form you intend to use?
          </div>
          <PDFContainer>
            <PDFViewer
              fileURL={form.pdfURL}
              height="400px"
            />
          </PDFContainer>
          <div style={{ display: 'flex', flexDiretion: 'row', justifyContent: 'space-between' }}>
            <ConfirmButton color={colors.gray} onClick={onBack}>Back to Forms</ConfirmButton>
            <ConfirmButton onClick={onSubmit}>Correct Form</ConfirmButton>
          </div>
        </div>
      );
    }

    return (<div />);
  }
}

export default compose(withFormById)(FormConfirmation);
