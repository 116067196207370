import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Backdrop from './Backdrop';
import { ModalFooter, ModalContent } from './ModalStyledComponents';
import BaseButton from './BaseButton';
import Modal from './Modal';

const baseWidth = 600;
const imageMargin = 10;
const ModalBody = styled.div`
  width: ${baseWidth + imageMargin * 2}px;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;

  img {
    margin: ${imageMargin}px;
    border: 1px solid black;
  }
`;

const ModalText = styled.div`
  padding-bottom: 10px;
  text-align: center;
`;

const ScrollableContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
`;

const ImageContainer = styled(ScrollableContainer)`
  justify-content: center;
  align-items: center;
  max-height: 450px;
  width: 100%;
  padding: 0 10px;
  background: ${props => props.theme.lightGray};
  &:hover {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
`;

const FullPageImageContainer = styled.div`
  width: 1000px;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 700px;
  transform: translate(-50%, -50%);
  z-index: ${props => props.theme.zTop};
  overflow-y: scroll;
`;

const FullPageImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const MessageContainer = styled.div`
  word-break: break-word;
  text-align: center;
  overflow: hidden;
  transition: all 1s ease;
  white-space: nowrap;
  max-height: 200px;
  margin-top: 10px;

  &:hover {
    white-space: pre-line;
    word-break: break-word;
    overflow: scroll;
    cursor: pointer;
    background-color: ${props => props.theme.lightGray};
  }
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const PortalErrorAndNightlyTestModal = (
  { header, open, modalOrBackOnClick, expandImageOnClick, errorModalError, expandImage, condenseImageOnClick, handleResolve, shouldAllowResolve }
) => (
  <Modal header={header} open={open} onClick={modalOrBackOnClick}>
    <ModalBody>
      <CustomModalContent>
        <ImageContainer onClick={expandImageOnClick}>
          <img width={400} src={_.get(errorModalError, 'imageURL')} />
        </ImageContainer>
        { expandImage && (
          <div onClick={condenseImageOnClick}>
            <Backdrop />
            <FullPageImageContainer>
              <FullPageImage src={_.get(errorModalError, 'imageURL')} />
            </FullPageImageContainer>
          </div>
        ) }
        <MessageContainer>
          <ModalText>{_.get(errorModalError, 'message') || _.get(errorModalError, 'errorMessage')}</ModalText>
        </MessageContainer>
      </CustomModalContent>
    </ModalBody>
    <CustomModalFooter>
      <BaseButton onClick={modalOrBackOnClick}>Back</BaseButton>
      { shouldAllowResolve && (
        <BaseButton onClick={handleResolve}>
          Resolve Error
        </BaseButton>
      ) }
    </CustomModalFooter>
  </Modal>
);

export default PortalErrorAndNightlyTestModal;
