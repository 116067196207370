import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BaseButton from '../../../components/BaseButton';
import AuthorizationDetails from '../../../components/MainList/AuthorizationDetails';
import RecommendationEvidence from './RecommendationEvidence';
import AuthorizationDetailsSuggestAndUpdate from './AuthorizationDetailsSuggestAndUpdate';
import getProceduresRecommendations from './getProceduresRecommendation';

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuggestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  font-weight: bold;
`;

const Title = styled.div`
  color: ${props => props.theme.purple};
  font-weight: bold;
  margin-bottom: 5px;
`;

const SeparatorLine = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.purple};
  margin: 10px 0;
`;

class DetailsSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAllProcedureRecommendations: false,
      procedureRecommendations: getProceduresRecommendations(props.fullText),
    };
  }

  render() {
    const { authorization, fullText } = this.props;
    const {
      showAllProcedureRecommendations,
      procedureRecommendations,
    } = this.state;

    let innerContent = [];
    if (
      _.includes(CONFIG.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES.concat([CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED]), authorization.status)
    ) {
      innerContent = innerContent.concat((
        <AuthorizationDetailsSuggestAndUpdate
          key={`AuthorizationDetailsSuggestAndUpdate_${authorization.id}`}
          fullText={fullText}
          authorizationId={authorization.id}
          authorizationStatus={authorization.status}
        />
      ));
    }

    if (
      !_.isEmpty(procedureRecommendations)
      && _.includes(CONFIG.CONSTANTS.APPROVED_STATUSES, authorization.status)
    ) {
      const recordsToDisplay = showAllProcedureRecommendations
        ? procedureRecommendations
        : [procedureRecommendations[0]];

      innerContent = innerContent.concat((
        <SuggestionContainer key="AuthNumberSuggestionContainer">
          <SuggestionHeader>
            Procedure recommendation(s)
          </SuggestionHeader>
          {
            _.map(recordsToDisplay, evidenceIndex => (
              <RecommendationEvidence
                key={`AuthNumberSuggestionEvidence_${evidenceIndex[0]}_${evidenceIndex[1]}`}
                startIndex={evidenceIndex[0]}
                endIndex={evidenceIndex[1]}
                fullText={fullText}
                startMultiplierIncrements={5}
                endMultiplierIncrements={40}
              />
            ))
          }
          { recordsToDisplay.length > 1 && (
            <BaseButton
              onClick={() => {
                this.setState({
                  showAllProcedureRecommendations: !showAllProcedureRecommendations,
                });
              }}
              style={{ width: '100%', fontSize: 12, padding: 5 }}
            >
              { showAllProcedureRecommendations ? 'See Less' : 'See More' }
            </BaseButton>
          ) }
          <SeparatorLine />
        </SuggestionContainer>
      ));
    }

    return (
      <div>
        <Title>Authorization Details</Title>
        { innerContent }
        <AuthorizationDetails authorization={authorization} />
      </div>
    );
  }
}

export default DetailsSection;
