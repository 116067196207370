import moment from 'moment';

const warnings = CONFIG.CONSTANTS.AUTHORIZATION_WARNINGS;
const warningConstants = CONFIG.CONSTANTS.AUTHORIZATION_WARNING_CONSTANTS;

const getAuthorizationAlertStatus = (authorization) => {
  // Too many days have passed, should warn user to look at this authorization
  if (
    moment(authorization.submittedAt) < moment().subtract(warningConstants.WAITING_TOO_LONG_DAYS, 'days')
    && authorization.status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENT
  ) {
    return warnings.WAITING_TOO_LONG;
  }
  // This authorization has been in progress for 3 days, should warn user to look at this authorization
  if (
    moment(authorization.createdAt) < moment().subtract(warningConstants.STALE_DAYS, 'day')
    && authorization.status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION
  ) {
    return warnings.STALE;
  }

  return null;
};

export default getAuthorizationAlertStatus;
