import React from 'react';
import styled from 'styled-components';
import BaseButton from './BaseButton';

export const IconButtonStyled = styled(BaseButton)`
  border-color: ${props => props.backgroundColor || props.theme.green};
  background: ${props => props.backgroundColor || props.theme.green};
  color: ${props => props.color || 'white'};

  &:hover {
    background-color: ${props => props.color || 'white'};
    color: ${props => props.backgroundColor || props.theme.green};
  }
`;

const IconContainer = styled.div`
  margin-right: 10px;
  margin-top: -1px;
`;

const IconButton = ({ children, icon, color, backgroundColor, onClick = () => {}, disabled }) => {
  const IconComponent = icon;
  return (
    <IconButtonStyled backgroundColor={backgroundColor} color={color} onClick={onClick} disabled={disabled}>
      <IconContainer>
        <IconComponent />
      </IconContainer>
      { children }
    </IconButtonStyled>
  );
};

export default IconButton;
