import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withAuthorizationErrors } from '../../../graphql/AuthorizationError';
import ErrorResolutionModal from '../ErrorResolutionModal';
import BaseButton from '../../../components/BaseButton';
import MainList from '../../../components/MainList';

const Container = styled.div`
  width: 1500px;
  margin-left: 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const ContainOverflowRow = styled.div`
  width: 200px;
  white-space: break-spaces;
`;

class AuthorizationErrorDashboard extends PureComponent {
  state = { resolutionModalErrorId: null }

  getTableCells = (error) => {
    const keyPrefix = `AuthError_${error.id}`;

    let formOrPortal = error.portalKey;
    if (!formOrPortal) {
      formOrPortal = error.AuthorizationForm ? `${error.AuthorizationForm.title} - ${error.AuthorizationForm.description}` : 'No Form';
    }
    return [
      (<div key={`${keyPrefix}_ErrorId`}>{error.id}</div>),
      (<div key={`${keyPrefix}_AuthId`}>{error.AuthorizationId}</div>),
      (<div key={`${keyPrefix}_CreatedAt`}>{moment(error.createdAt).format('M/D')}</div>),
      (<div key={`${keyPrefix}_UserEmail`}>{error.createdByUserEmail}</div>),
      (<div key={`${keyPrefix}_Institution`}>{error.institutionName}</div>),
      (<div key={`${keyPrefix}_ErrorType`}>{error.type}</div>),
      (<ContainOverflowRow key={`${keyPrefix}_Message`}>{error.message}</ContainOverflowRow>),
      (<ContainOverflowRow key={`${keyPrefix}_Form`}>{formOrPortal}</ContainOverflowRow>),
      (<BaseButton key={`${keyPrefix}_ResolveButton`} onClick={() => { this.setState({ resolutionModalErrorId: error.id }); }}>Resolve</BaseButton>),
    ];
  }

  render() {
    const { resolutionModalErrorId } = this.state;
    const { authorizationErrors } = this.props;

    const headers = ['ErrorId', 'AuthId', 'CreatedAt', 'UserEmail', 'Institution', 'ErrorType', 'Message', 'Form/Portal'];

    return (
      <Container>
        <TitleContainer>
          <div style={{ marginRight: '25px' }}>Recent Authorization Errors</div>
        </TitleContainer>
        {authorizationErrors && (
          <MainList
            headers={headers}
            keyOff="authorizationErrorList"
            dataRows={_.map(authorizationErrors, err => ({ cells: this.getTableCells(err), model: err }))}
          />
        )}
        { resolutionModalErrorId && (
          <ErrorResolutionModal
            errorId={resolutionModalErrorId}
            header="Resolve Error"
            clearState={() => { this.setState({ resolutionModalErrorId: null }); }}
          />
        ) }
      </Container>
    );
  }
}

export default compose(withAuthorizationErrors)(withAlert(AuthorizationErrorDashboard));
