import React from 'react';
import styled from 'styled-components';

import BaseButton from '../../components/BaseButton';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${({ theme, color }) => color || theme.purple};
  border-radius: 3px;
  ${BaseButton} {
    margin-left: auto;
  }
`;

const HelperTextContainer = styled.div`
  background-color: ${({ theme }) => theme.lightGray};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${({ theme, color }) => color || theme.purple};
  border-radius: 3px;
`;

export default function PortalComplete({ onSubmit, message }) {
  return (
    <div>
      <LoadingContainer>
        Authorization Ready For Submission
        <BaseButton onClick={() => { onSubmit({ ...message, display: message.title, value: true }); }}>Click to Submit and Return To Authorizations</BaseButton>
      </LoadingContainer>
      <HelperTextContainer>
        After the authorization is submitted, SamaCare will check on the authorization daily to get the payer response letter.
        If the portal instead renders a decision right away, the response should appear right after the submit button is clicked.
        If the immediate response from the payer is not showing, please contact SamaCare to resolve this issue.
      </HelperTextContainer>
    </div>
  );
}
