import React from 'react';
import styled from 'styled-components';

const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin: 0 5px;
`;

const UnsupportedBrowserComponent = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-align: center;
`;

const UnsupportedBrowser = () => (
  <UnsupportedBrowserComponent>
    { 'SamaCare does NOT support' }
    <Logo src="../../assets/ie-icon.png" />
    {'Internet Explorer or Edge web browsers. Please log in using' }
    <Logo src="../../assets/chrome-icon.png" />
    { 'Chrome.' }
  </UnsupportedBrowserComponent>
);

export default UnsupportedBrowser;
