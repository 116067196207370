import React from 'react';
import styled from 'styled-components';

const ResponseObject = styled.object`
  width: 100%;
  height: ${props => (props.heightOverride || '700px')};
  width: ${props => (props.widthOverride || '500px')};
`;

function PDFViewer({ fileURL, height, width, mimeTypeOverride }) {
  return (
    <ResponseObject
      data={`${fileURL}#view=FitH`}
      heightOverride={height}
      type={mimeTypeOverride || 'application/pdf'}
      widthOverride={width}
      aria-label="Authorization attachment"
    />
  );
}

export default PDFViewer;
