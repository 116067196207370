import gql from 'graphql-tag';

export default gql`
  fragment locationInfo on Location {
    id
    name
    address
    city
    state
    zip
    fax
    facilityName
    taxId
    NPI
  }
`;
