import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import strings from 'Resources/strings';
import { withUpdateAuthorizationAndRefetch } from '../graphql/Authorization';

class ToggleArchiveButton extends PureComponent {
  click = async (e) => {
    const { updateAuthorizationAndRefetch, alert, id, isArchived } = this.props;
    e.stopPropagation();
    try {
      await updateAuthorizationAndRefetch(
        { variables: { id, patch: { isArchived: !isArchived } } }
      );
    } catch (err) {
      alert.error(strings.AUTHORIZATIONS.ERROR_HIDING);
    }
  };

  render() {
    const { children } = this.props;
    return (<div onClick={this.click}>{children}</div>);
  }
}

export const ArchiveButtonWithAlert = withAlert(ToggleArchiveButton);

export default compose(withUpdateAuthorizationAndRefetch)(ArchiveButtonWithAlert);
